import { FormConfig } from '../../../../../../utils/form';
import styled from 'styled-components/macro';
import { Input } from 'antd';
import UploadProfileImg from '../../../../../../ui/forms/UploadLogo';
import SelectInput from '../../../../../../ui/forms/SelectInput';
import { CaretDownFilled } from '@ant-design/icons';
import { StyledButtonDark } from '../../../../offers/offer-groups/offer-groups-details/styled-offerGropDetails/popover.styled';

const FindButton = () => (
    <StyledButtonDark style={{ margin: 0}}>Find Address</StyledButtonDark>
)

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;
`;
const StyledSelectInput = styled(SelectInput)`
    width: 100%;
    max-width: 420px;
    height: unset;
`;

const RessellerSelect = styled(SelectInput)`
    width: 548px;
    font-weight: 600;
`
export const PreferredRessellerForm: FormConfig = [
    {
        title: 'Preffered Resseller',
        name: 'prefferedResseller',
        component: RessellerSelect,
        props: {
          initialData: ['123', '456'],
          placeholder: 'No preferred reseller',
          suffixIcon: <CaretDownFilled />
        },
    }
]

export const AddAddressConfig: FormConfig = [
    {
        title: 'Postcode',
        name: 'postcode',
        component: StyledInput,
        isRequired: true,
    },
    {
        title: '',
        name: 'findAddress',
        component: FindButton
    },
    {
        title: 'Address Line 1',
        name: 'addressLine',
        component: StyledInput,
        isRequired: true,
    },
    {
        title: 'Address Line 2',
        name: 'addressLine1',
        component: StyledInput,
    },
    {
        title: 'Address Line 3',
        name: 'addressLine2',
        component: StyledInput,
    },
    {
        title: 'Town',
        name: 'town',
        component: StyledInput,
        isRequired: true,
    },
    {
        title: 'Country',
        name: 'Country',
        component: StyledInput,
        isRequired: true,
    },
]

export const AddUserConfig: FormConfig = [
    {
      title: 'User Email',
      name: 'addUser',
      component: StyledSelectInput,
      props: {
        initialData: [
            'test@email.com', 'test1@email.com', 'test2@email.com',
          ],
        suffixIcon: <CaretDownFilled/>,
        dropdownStyle: {
          zIndex: 200000,
          position: 'fixed',
        },
      },
      isRequired: true,
    },
  ];

export const BuyersUpdateFormConfig: FormConfig = [
    {
        title: 'Logo',
        name: 'logo',
        component: UploadProfileImg,
    },
    {
        title: 'Name',
        name: 'name',
        component: StyledInput,
        isRequired: true,
    },
    {
        title: 'URN',
        name: 'URN',
        component: StyledInput,
    },
    {
        title: 'Organisation Type',
        name: 'organisationType',
        component: StyledSelectInput,
        isRequired: true,
        props: {
          initialData: ['Maintained School'],
          value: ['Maintained School'],
          suffixIcon: <CaretDownFilled />
        },
    },
    {
        title: 'Telephone Number',
        name: 'phoneNumber',
        component: StyledInput,
    },
]