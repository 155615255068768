import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayoutWithBack from '../../layout/SupplierLayoutWithBack';
import { QuoteCreateType, QuoteServiceItem } from '../../types';
import BuildQuote from './BuildQuote';
import { useBuildQuoteMutation } from '../../../../api/services/supplier/quotesApi';

const Services: Array<QuoteServiceItem> = [
  {
    type: 'Asset Tagging',
    description: 'Asset identification and tagging is an essential component of a good asset management strategy. It helps a company’s assets to become quickly identifiable and ties the asset to the overall asset plan.',
    price: '',
    quantity: 0,
  },
  {
    type: 'Installation',
    description: 'Our Installation Service provides for the basic installation of servers, workstations, desktop systems, notebook PCs, thin clients, storage devices, printers, networking, and software products.',
    price: '',
    quantity: 0,
  },
];

export default function BuildQuotePage() {
  const { push } = useHistory();
  const [buildQuote, { isSuccess }] = useBuildQuoteMutation();

  const onFormSubmit = async (values: QuoteCreateType) => {
    const transformValues = {
      ...values,
      items: values.items.map((item) => ({ ...item, price: Number((item.price as string).slice(1)) })),
      services: values.services.map((item) => ({ ...item, price: Number((item.price as string).slice(1)) })),
      deliveryCharge: Number((values.deliveryCharge as string).slice(1)),
      schoolId: values.schoolName ? values.schoolName : values.schoolId,
    };
    delete transformValues.schoolName;
    await buildQuote(transformValues);
  };

  useEffect(() => {
    if (isSuccess) {
      push(ROUTER_CONFIG.SUPPLIER_QUOTE_CREATED.getPath());
    }
  }, [isSuccess, push]);

  return (
    <SupplierLayoutWithBack onBack={() => push(ROUTER_CONFIG.SUPPLIER_QUOTES.getPath())}>
      <BuildQuote
        onFormSubmit={onFormSubmit}
        services={Services}
      />
    </SupplierLayoutWithBack>
  );
}
