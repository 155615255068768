import styled from 'styled-components';
import { CloudUploadOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

interface IProps {
  formikInstance: any;
  isEditMode: boolean;
}

const ProductGalleryForm: FC<IProps> = ({
  formikInstance,
  isEditMode,
}: IProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>(
    [
      ...(formikInstance.values.imageUrl || []),
      ...formikInstance.values.otherPhotosUrl,
    ].map((url: string) => ({
      uid: url,
      name: url,
      url: url,
    }))
  );

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Container showUpload={isEditMode}>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
      >
        {fileList.length < 5 && (
          <div>
            <div>
              <StyledCloudUploadOutlined />
            </div>
            <div>{'Drag & drop a Image or Browse'}</div>
          </div>
        )}
      </Upload>
    </Container>
  );
};

const Container = styled.span<{ showUpload: boolean }>`
  .ant-upload.ant-upload-select-picture-card {
    padding: 10px;
    width: 325px;
    height: 235px;
    margin-bottom: 25px;
    display: ${(props) => `${props.showUpload ? '' : 'none'}`};
  }
  .ant-upload-list-picture-card-container {
    width: 325px;
    height: 220px;
    margin-bottom: 25px;
  }
`;

const StyledCloudUploadOutlined = styled(CloudUploadOutlined)`
  font-size: 40px;
`;
export default ProductGalleryForm;
