import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const supplierApi = createApi({
  reducerPath: 'supplierApi',
  tagTypes: ['QUOTES'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://sds-backend-buyer-stage-rgrpzfjxqa-uc.a.run.app/api/supplier/', // to env
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
