import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { DatePickerProps } from 'antd';
import { FormConfig, renderFormConfig } from '../../../../../utils/form';
import {
  StyledDataPicker, StyledInput, StyledSelect, StyledSmallInput,
} from '../styled-form';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/arrowDown.svg';
import { manufacturers } from '../dataForForm';
import { IProduct } from '../../../../buyer/types';
import { ReactComponent as Calendar } from '../../../../../img/icons/calendar.svg';

interface IFirstEditForm {
  formik: FormikProps<any>,
  products: IProduct[],
}

const ProductForm: React.FC<IFirstEditForm> = ({ formik, products }: IFirstEditForm) => {
  const secondFormConfig: FormConfig = useMemo(() => ([
    {
      title: 'Select Product',
      name: 'product',
      component: StyledSelect,
      props: {
        initialData: products ?? [],
        suffixIcon: <ArrowDown />,
        onChange: (value: IProduct) => {
          formik.setFieldValue('product', value._id);
        },
        optionName: 'name',
      },
      isRequired: true,
    },
    {
      title: 'SKU',
      name: 'SKU',
      component: StyledSmallInput,
      props: {
        disabled: true,
        style: {
          color: '#102F54',
        },
        value: 'KF1-00007',
      },
    },
    {
      title: 'Original Equipment Manufacturer (OEM)',
      name: 'oem',
      component: StyledSelect,
      props: {
        initialData: manufacturers,
        suffixIcon: <ArrowDown />,
      },
      isRequired: true,
    },
    {
      title: 'Available Quantity',
      name: 'quantity',
      component: StyledInput,
    },
    {
      title: 'Stock Availability Date',
      name: 'stockAvailableDate',
      component: StyledDataPicker,
      props: {
        onChange: (value: DatePickerProps) => {
          formik.setFieldValue('stockAvailableDate', value);
        },
        suffixIcon: <Calendar />,
      },
    },
    {
      title: 'Indicative Reseller Buy Price',
      name: 'indicativeBuyerPrice',
      component: StyledInput,
    },
    {
      title: 'NTE',
      name: 'nte',
      component: StyledInput,
      isRequired: true,
    },
  ]), [formik]);
  return (
    <>
      {renderFormConfig(secondFormConfig, formik)}
    </>
  );
};

export default ProductForm;
