import styled from 'styled-components/macro';
import { Input } from 'antd';
import { DetailsButton } from './buttons';
import { ButtonDark } from '../../../../ui/Button';
import SelectInput from '../../../../ui/forms/SelectInput';

export const ContainerForPopover = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  position: relative;

  label {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const TitleForPopover = styled.h2`
  margin: 0 0 27px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
`;

export const DetailsButtonForPopover = styled(DetailsButton)`
  margin-left: 5px;
`;

export const TextForPopover = styled.span`
  display: block;
  color: #909DA8;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
`;

export const LinkAfterButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const DarkButtonForPopover = styled(ButtonDark)`
  font-size: 14px;
  font-weight: 700;
  width: 228px;
  height: 44px;
  margin-top: 20px;
`;

export const StyledInput = styled(Input)`
  height: 36px;
  width: 100%;
  max-width: 235px;
`;

export const StyledSelect = styled(SelectInput)`
  width: 100%;
  max-width: 425px;

  .ant-select-selector {
    height: 36px !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;
