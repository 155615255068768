import { FC, Key, useState } from 'react';
import { Col, Row, Space } from 'antd';
import {
  SubTitle,
  StyledText,
  StyledButtonDark,
  TableWrapper,
  StyledSwitch,
} from '../styled/new-offer.styled';
import EligibleSuppliersTable from '../new-offer-tables/eligible-suppliers-table/EligibleSuppliersTable';
import { eligibleSuppliersItems } from '../new-offer-tables/eligible-suppliers-table/eligibleSuppliers.mock';

const EligibleSuppliersSection: FC = () => {
  const [data, setData] = useState<any>(eligibleSuppliersItems);
  const [isEligibleSuppliers, setIsEligibleSuppliers] = useState<boolean>(false);

  const onAddEligibleSuppliers = () => {
    const newData = {
      key: Date.now(),
      suppliers: 'suppliers',
    };

    setData((prev: any) => ([...prev, newData]));
  };

  const onDeleteEligibleSuppliers = (key: Key) => {
    setData((prev: any) => prev.filter((item: any) => item.key !== key));
  };

  return (
    <Row
      style={{ marginBottom: '54px' }}
    >
      <Col>
        <Col
          span={16}
          style={{ marginBottom: '31px' }}
        >
          <SubTitle>3. Eligible Suppliers</SubTitle>
          <StyledText>Select supplier(s) eligible to join the Offer</StyledText>
        </Col>
        <Space
          style={{ width: '100%' }}
        >
          <StyledButtonDark
            onClick={onAddEligibleSuppliers}
            style={{ marginRight: 35 }}
          >
            + Add Supplier
          </StyledButtonDark>
          <StyledSwitch
            onChange={() => setIsEligibleSuppliers(!isEligibleSuppliers)}
          />
          <StyledText>Make eligible on all nodes</StyledText>
        </Space>
      </Col>
      <TableWrapper>
        <EligibleSuppliersTable
          dataSource={data}
          isEligibleSuppliers={isEligibleSuppliers}
          onDelete={onDeleteEligibleSuppliers}
        />
      </TableWrapper>
    </Row>
  );
};

export default EligibleSuppliersSection;
