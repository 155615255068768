import { useGetServiceIDQuery } from "../../../../api/services/superadmin/servicesApi";
import { useParams } from "react-router-dom";

const ServiceViewPage = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const {data: getServiceID, status} = useGetServiceIDQuery(serviceId);
  return (
    <>
      Server status {getServiceID ? JSON.stringify(getServiceID, null, 2) : status}
      hello
    </>
  );
};

export default ServiceViewPage;