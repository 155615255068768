import {
  CreateInsurerForm,
  IInsurer,
} from '../../../components/superadmin/insurers/types';
import { superadminApi } from './superadminApi';

const insurersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getInsurersList: build.query<IInsurer[], void>({
      providesTags: ['INSURER'],
      query: () => ({
        url: 'insurer',
      }),
    }),
    getSearchInsurersList: build.query<IInsurer[], { name: string }>({
      providesTags: ['INSURER'],
      query: (body) => ({
        url: 'insurer/search',
        params: body,
      }),
    }),
    getInsurer: build.query<IInsurer, string>({
      providesTags: ['INSURER'],
      query: (id: string) => ({
        url: `insurer/byId/${id}`,
      }),
    }),
    createInsurer: build.mutation<void, CreateInsurerForm>({
      invalidatesTags: ['INSURER'],
      query: (body: CreateInsurerForm) => ({
        url: 'insurer',
        method: 'POST',
        body,
      }),
    }),
    updateInsurer: build.mutation<
      void,
      { body: CreateInsurerForm; insurerId: string }
    >({
      invalidatesTags: ['INSURER'],
      query: ({ body, insurerId }) => ({
        url: `insurer/${insurerId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useCreateInsurerMutation,
  useGetInsurersListQuery,
  useGetInsurerQuery,
  useUpdateInsurerMutation,
  useGetSearchInsurersListQuery,
} = insurersApi;
