import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import OfferGroupsContent from './offer-groups-content/OfferGroupsContent';
import OfferGroupsHeader from './offer-groups-content/OfferGroupsHeader';
import ROUTER_CONFIG from '../../../../router/router-config';
import { useGetOfferGroupsListQuery } from '../../../../api/services/superadmin/offerGroupsApi';
import LayoutV1 from '../../../layout/LayoutV1';

const OfferGroupPage: React.FC = () => {
  const { push } = useHistory();
  const [selectedOffers, setSelectedOffers] = useState<Array<string>>([]);
  const onDeleteOfferGroupClickHandler = () => console.log(selectedOffers);
  const [searchValue, setSearchValue] = useState<string>('');

  const onOfferGroupsRowClick = (offerGroupId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_OFFER_GROUP_DETAILS.getPath({ offerGroupId }));

  const { data: offerGroups } = useGetOfferGroupsListQuery();
  const tableData = offerGroups?.map((offerGroup) => ({
    key: offerGroup._id,
    name: offerGroup.name,
    numberOfOffers: offerGroup.order,
    eligibleNodes: offerGroup.description,
  }));

  return (
    <LayoutV1>
      <Container>
        <OfferGroupsHeader />
        {tableData && (
          <OfferGroupsContent
            offerGroupTableData={tableData}
            onSelectedOffers={setSelectedOffers}
            selectedOffers={selectedOffers}
            onDeleteOfferGroupClickHandler={onDeleteOfferGroupClickHandler}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onOfferGroupsRowClick={onOfferGroupsRowClick}
          />
        )}
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div`
`;

export default OfferGroupPage;
