import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { OfferGroupsTableRow } from '../types';
import { TableStyled } from '../../../shared/table/TableStyled';

const tableConfig: ColumnsType<OfferGroupsTableRow> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Number of Offers',
    dataIndex: 'numberOfOffers',
    key: 'numberOfOffers',
    width: '20%',
    align: 'center',
  },
  {
    title: 'Eligible Nodes',
    dataIndex: 'eligibleNodes',
    key: 'eligibleNodes',
  },
];

interface OfferGroupTableProps {
  tableData: Array<OfferGroupsTableRow>;
  onSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
  onOfferGroupsRowClick: (offerGroupId: string) => void;
}

const OfferGroupTable: React.FC<OfferGroupTableProps> = ({
  tableData,
  onSelectedOffers,
  selectedOffers,
  onOfferGroupsRowClick,
}) => (
  <TableStyled
    rowSelection={{
      type: 'checkbox',
      onChange: (ids) => {
        onSelectedOffers(ids as Array<string>);
      },
      selectedRowKeys: selectedOffers,
    }}
    onRow={(rowData) => ({
      onClick: () => onOfferGroupsRowClick(rowData.key),
      style: { cursor: 'pointer' },
    })}
    columns={tableConfig}
    dataSource={tableData}
  />
);

export default OfferGroupTable;
