import { Row, Col, Tabs, Input } from 'antd';
import { FC, Key } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import { ButtonDark } from '../../../../ui/Button';

import LayoutV1 from '../../../layout/LayoutV1';
import { TableStyled } from '../../shared/table/TableStyled';
import { TextBody, Title } from '../../shared/typography/typography.styled';

import { showPriceDigits } from '../../../../utils/formats';
import { QuotesDropdown } from '../../quotes/quotes-list/quotes-dropdown/QuotesDopdown';

import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';
import ROUTER_CONFIG from "../../../../router/router-config";
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

export const columns = [
  {
    title: (
      <TextBody type="secondary">
        Date Submitted <ArrowDownIcon />
      </TextBody>
    ),
    key: 'date',
    dataIndex: 'date',
    sorter: (a: any, b: any) => {
      if (new Date(a.date) < new Date(b.date)) {
        return -1;
      }
      if (new Date(a.date) > new Date(b.date)) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'PO Number',
    key: 'poNumber',
    dataIndex: 'poNumber',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Buyer',
    key: 'buyer',
    dataIndex: 'buyer',
  },
  {
    title: 'Supplier',
    key: 'supplier',
    dataIndex: 'supplier',
  },
  {
    title: 'Node',
    key: 'node',
    dataIndex: 'node',
  },
  {
    title: 'Order Type',
    key: 'orderType',
    dataIndex: 'orderType',
  },
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
  },
];

const mockTableData = [
  {
    key: '1',
    date: '06/06/2021',
    poNumber: '123456',
    status: 'Placed',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    node: 'Intel',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '2',
    date: '06/06/2021',
    poNumber: '123456',
    status: 'Placed',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    node: 'Intel',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '3',
    date: '06/06/2021',
    poNumber: '123456',
    status: 'Placed',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    node: 'Intel',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '4',
    date: '06/06/2021',
    poNumber: '123456',
    status: 'Placed',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    node: 'Intel',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '5',
    date: '06/06/2021',
    poNumber: '123456',
    status: 'Placed',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    node: 'Intel',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
];

const OrdersListPage: FC = () => {
  const { push } = useHistory();
  const onRowClickHandler = (orderId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_ORDER_VIEW.getPath({ orderId }));
  const rowSelection = {
    onChange: () => {},
  };

  return (
    <LayoutV1>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title>Orders</Title>
            </Col>
            <Col span={8} offset={16} style={{ textAlign: 'right' }}>
              <ButtonDark style={{ width: 255, padding: 10 }}>
                +Create New Order
              </ButtonDark>
            </Col>
            <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
              <TabPane tab="Action Required" key="1">
                <Row>
                  <Col span={8}>
                    <Input
                      placeholder="PO Number"
                      suffix={<SearchOutlined />}
                      style={{ width: 215, marginBottom: 10 }}
                    />
                  </Col>
                  <Col span={8} offset={8} style={{ textAlign: 'end' }}>
                    <QuotesDropdown />
                  </Col>
                </Row>

                <TableStyled
                  columns={columns}
                  dataSource={mockTableData}
                  rowSelection={{ type: 'checkbox', ...rowSelection }}
                  onRow={(rowData) => ({
                    onClick: () => onRowClickHandler(rowData._id),
                    style: { cursor: 'pointer' },
                  })}
                />
              </TabPane>
              <TabPane tab="Pending" key="2">
                <Row>
                  <Col span={8}>
                    <Input
                      placeholder="PO Number"
                      suffix={<SearchOutlined />}
                      style={{ width: 215, marginBottom: 10 }}
                    />
                  </Col>
                  <Col span={8} offset={8} style={{ textAlign: 'end' }}>
                    <QuotesDropdown />
                  </Col>
                </Row>

                <TableStyled
                  columns={columns}
                  dataSource={mockTableData}
                  rowSelection={{ type: 'checkbox', ...rowSelection }}
                  onRow={(rowData) => ({
                    onClick: () => onRowClickHandler(rowData._id),
                    style: { cursor: 'pointer' },
                  })}
                />
              </TabPane>
              <TabPane tab="Accepted" key="3">
                <Row>
                  <Col span={8}>
                    <Input
                      placeholder="PO Number"
                      suffix={<SearchOutlined />}
                      style={{ width: 215, marginBottom: 10 }}
                    />
                  </Col>
                  <Col span={8} offset={8} style={{ textAlign: 'end' }}>
                    <QuotesDropdown />
                  </Col>
                </Row>

                <TableStyled
                  columns={columns}
                  dataSource={mockTableData}
                  rowSelection={{ type: 'checkbox', ...rowSelection }}
                  onRow={(rowData) => ({
                    onClick: () => onRowClickHandler(rowData._id),
                    style: { cursor: 'pointer' },
                  })}
                />
              </TabPane>
              <TabPane tab="Rejected" key="4">
                <Row>
                  <Col span={8}>
                    <Input
                      placeholder="PO Number"
                      suffix={<SearchOutlined />}
                      style={{ width: 215, marginBottom: 10 }}
                    />
                  </Col>
                  <Col span={8} offset={8} style={{ textAlign: 'end' }}>
                    <QuotesDropdown />
                  </Col>
                </Row>

                <TableStyled
                  columns={columns}
                  dataSource={mockTableData}
                  rowSelection={{ type: 'checkbox', ...rowSelection }}
                  onRow={(rowData) => ({
                    onClick: () => onRowClickHandler(rowData._id),
                    style: { cursor: 'pointer' },
                  })}
                />
              </TabPane>
            </Tabs>
          </Row>
        </Col>
      </Row>
    </LayoutV1>
  );
};

export default OrdersListPage;