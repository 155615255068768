import { useState } from 'react';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  HomeIcon,
  OffersIcon,
  OrdersIcon,
  PaymentsIcon,
  QuoteIcon,
  ProductsIcon,
  ServicesIcon,
  BuyersIcon,
  SuppliersIcon, LessorsIcon, InsuresIcon, UsersIcon, NodesIcon
} from './SideMenuSvgIcons';
import ROUTER_CONFIG from '../../router/router-config';

interface SideMenuProps {
  className?: string;
}

export default function SideMenu({className}: SideMenuProps) {
  const {push, location} = useHistory();
  const {offerId, orderId, productId, lessorId, insurerId, paymentType, campaignId}: any = useParams();
  const [ isProductsOpen, setIsProductsOpen ] = useState(false);
  const [ isUsersOpen, setIsUsersOpen ] = useState(false);
  const [ isPaymentsOpen, setIsPaymentsOpen ] = useState(false);
  const [ isOffersOpen, setIOffersOpen ] = useState(false);
  
  return (
    <Container className={className}>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.getPath())}>
        <HomeIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.path
          }>
          Home
        </MenuItemText>
      </MenuItem>
      <LineSeparator/>
      <DropDownMenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_NEW_OFFER_PAGE.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_DETAILS.getPath({offerId: offerId})
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_EDIT.getPath({offerId: offerId})
        }
        onMouseOver={() => setIOffersOpen(true)}
        onMouseLeave={() => setIOffersOpen(false)}>
        <DropDownMenuLink onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.getPath())}>
          <OffersIcon
            isSelected={
              location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.path
            }
          />
          <DropDownMenuItemText
            isSelect={
              location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.path
              || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_DETAILS.getPath({offerId: offerId})
              || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_EDIT.getPath({offerId: offerId})
            }
            isOpen={isOffersOpen}>
            Offers
          </DropDownMenuItemText>
        </DropDownMenuLink>
        {isOffersOpen &&
          <DropDownHoverText onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.getPath())}>
            Balance Offers
          </DropDownHoverText>
        }
      </DropDownMenuItem>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_LIST.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_DETAIL.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_CREATE.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_EDIT.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_ORDERS_LIST.getPath())}>
        <OrdersIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_LIST.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_DETAIL.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_EDIT.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_LIST.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_DETAIL.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_ORDERS_EDIT.path
          }>
          Orders
        </MenuItemText>
      </MenuItem>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTES_LIST.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_CREATE.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_DETAIL.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_CREATE_SUCCESS.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_QUOTES_LIST.getPath())}>
        <QuoteIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTES_LIST.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_DETAIL.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_CREATE_SUCCESS.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTES_LIST.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_DETAIL.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_QUOTE_CREATE_SUCCESS.path
          }>
          Quotes
        </MenuItemText>
      </MenuItem>
      <DropDownMenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_DETAILS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_CREATE.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_DETAILS.getPath({productId: productId})
        }
        onMouseOver={() => setIsProductsOpen(true)}
        onMouseLeave={() => setIsProductsOpen(false)}>
        <DropDownMenuLink onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.getPath())}>
          <ProductsIcon
            isSelected={
              location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.path
              || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_DETAILS.path
              || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_CREATE.path
            }
          />
          <DropDownMenuItemText
            isSelect={
              location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.path
            }
            isOpen={isProductsOpen}>
            Products
          </DropDownMenuItemText>
        </DropDownMenuLink>
        {isProductsOpen &&
          <DropDownHoverText onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.getPath())}>
            Balance Products
          </DropDownHoverText>
        }
      </DropDownMenuItem>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_SERVICES.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_SERVICES.getPath())}>
        <ServicesIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_SERVICES.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_SERVICES.path
          }>
          Services
        </MenuItemText>
      </MenuItem>
      <LineSeparator/>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_BUYERS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_BUYER_ORDERS_VIEW.getPath({offerId: offerId})
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_BUYERS.getPath())}>
        <BuyersIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_BUYERS.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_BUYERS.path
          }>
          Buyers
        </MenuItemText>
      </MenuItem>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_SUPPLIERS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_SUPPLIERS_NEW_ACCOUNT.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_SUPPLIERS.getPath())}>
        <SuppliersIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_SUPPLIERS.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_SUPPLIERS_NEW_ACCOUNT.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_SUPPLIERS.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_OFFERS_SUPPLIERS_NEW_ACCOUNT.path
          }>
          Suppliers
        </MenuItemText>
      </MenuItem>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSORS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSOR_CREATE.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSOR_DETAILS.getPath({lessorId: lessorId})
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_LESSORS.getPath())}>
        <LessorsIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSORS.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSOR_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSOR_DETAILS.getPath({lessorId: lessorId})
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSORS.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSOR_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_LESSOR_DETAILS.getPath({lessorId: lessorId})
          }>
          Lessors
        </MenuItemText>
      </MenuItem>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURERS.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURER_CREATE.path
          || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURER_DETAILS.getPath({insurerId: insurerId})
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_INSURERS.getPath())}>
        <InsuresIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURERS.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURER_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURER_DETAILS.getPath({insurerId: insurerId})
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURERS.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURER_CREATE.path
            || location.pathname === ROUTER_CONFIG.SUPER_ADMIN_INSURER_DETAILS.getPath({insurerId: insurerId})
          }>
          Insurers
        </MenuItemText>
      </MenuItem>
      <DropDownMenuItem
        isSelect={location.pathname === ROUTER_CONFIG.SUPER_ADMIN_USERS.path}
        onMouseOver={() => setIsUsersOpen(true)}
        onMouseLeave={() => setIsUsersOpen(false)}>
        <DropDownMenuLink onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_USERS.getPath())}>
          <UsersIcon
            isSelected={
              location.pathname === ROUTER_CONFIG.SUPER_ADMIN_USERS.path
            }
          />
          <DropDownMenuItemText
            isSelect={location.pathname === ROUTER_CONFIG.SUPER_ADMIN_USERS.path}
            isOpen={isUsersOpen}>
            Users
          </DropDownMenuItemText>
        </DropDownMenuLink>
        {isUsersOpen &&
          <DropDownHoverText onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_USERS.getPath())}>
            Balance Users
          </DropDownHoverText>
        }
      </DropDownMenuItem>
      <LineSeparator/>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_NODES.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_NODES.getPath())}>
        <NodesIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_NODES.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_NODES.path}>
          Nodes
        </MenuItemText>
      </MenuItem>
      <LineSeparator/>
      <DropDownMenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PAYMENTS.path
        }
        onMouseOver={() => setIsPaymentsOpen(true)}
        onMouseLeave={() => setIsPaymentsOpen(false)}>
        <DropDownMenuLink onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_PAYMENTS.getPath())}>
          <PaymentsIcon isSelected={
            location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PAYMENTS.path
          }
          />
          <DropDownMenuItemText
            isSelect={
              location.pathname === ROUTER_CONFIG.SUPER_ADMIN_PAYMENTS.path
            }
            isOpen={isPaymentsOpen}>
            Payments
          </DropDownMenuItemText>
        </DropDownMenuLink>
        {isPaymentsOpen &&
          <DropDownHoverText onClick={() => push(ROUTER_CONFIG.SUPER_ADMIN_PAYMENTS.getPath())}>
            Balance Payments
          </DropDownHoverText>
        }
      </DropDownMenuItem>
      <LineSeparator/>
    </Container>
  );
}

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  background-color: #F5F5F5;
  position: fixed;
  height: 100vh;
`;

const LineSeparator = styled.hr`
  width: 177px;
  border: 1px solid #e8e7e7;
`;

const MenuItemText = styled.span<{ isSelect?: boolean }>`
  font-size: 14px;
  line-height: 17px;
  color: ${({isSelect}) => (isSelect ? '#102F54' : '#6B7A87')};
`;

const DropDownHoverText = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
  padding: 12px 20px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const DropDownMenuItemText = styled(MenuItemText)<{ isOpen: boolean }>`
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 6px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #876;
    transform: ${({isOpen}) => (isOpen ? 'none' : 'rotateZ(-90deg)')};
  }
`;

const MenuItem = styled.div<{ isSelect?: boolean }>`
  display: flex;
  gap: 16px;
  padding: 14px 12px;
  align-items: center;
  cursor: pointer;
  background-color: ${({isSelect}) => (isSelect && '#FFFFFF')};
  
  &:hover {
    background: ${({isSelect}) => !isSelect && '#ecedee'};
    
    ${MenuItemText} {
      text-decoration: underline;
    }
  }
`;

const DropDownMenuItem = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;
`;

const DropDownMenuLink = styled.div`
  display: flex;
  gap: 16px;
`;