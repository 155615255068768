import { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import { TableStyled } from '../shared/table/TableStyled';
import { ProductsTableRow } from '../types';

interface IProps {
  tableData: Array<ProductsTableRow>;
  onRowClickHandler: (id: string) => void;
  onSelectedProducts: (ids: Array<string>) => void;
  selectedProducts: Array<string>;
}

const ProductsTable: FC<IProps> = ({
  tableData,
  onRowClickHandler,
  onSelectedProducts,
  selectedProducts,
}) => {
  const tableConfig: ColumnsType<ProductsTableRow> = [
    {
      key: 'SKU',
      dataIndex: 'SKU',
      title: 'SKU',
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'manufacturer',
      dataIndex: 'manufacturer',
      title: 'Manufacturer',
    },
  ];

  return (
    <>
      <TableStyled
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectedProducts(ids as Array<string>);
          },
          selectedRowKeys: selectedProducts,
        }}
        dataSource={tableData}
        columns={tableConfig}
          style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
        onRow={(rowData) => ({
          onClick: () => onRowClickHandler(rowData._id),
          style: { cursor: 'pointer' },
        })}
      />
    </>
  );
};

export default ProductsTable;