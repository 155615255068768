import styled from 'styled-components/macro';
import { Dropdown, Menu } from 'antd';
import React, { useMemo } from 'react';
import OfferGroupsSearch from './OfferGroupsSearch';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/arrowDownBlue.svg';

interface UsersTableControlsProps {
  onDeleteOfferGroupClickHandler: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
}

const OfferGroupsControls: React.FC<UsersTableControlsProps> = ({
  onDeleteOfferGroupClickHandler,
  searchValue,
  setSearchValue,
}) => {
  const dropdownContent = useMemo(() => (
    <Menu>
      <Menu.Item onClick={onDeleteOfferGroupClickHandler}> Delete </Menu.Item>
    </Menu>
  ), [onDeleteOfferGroupClickHandler]);

  return (
    <Container>
      <OfferGroupsSearch searchValue={searchValue} setSearchValue={setSearchValue} />
      <Dropdown overlay={dropdownContent}>
        <InviteButton onClick={(e) => e.preventDefault()}>
          Actions
          {' '}
          <ArrowDown style={{ marginBottom: '2px' }} />
        </InviteButton>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 35px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InviteButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export default OfferGroupsControls;
