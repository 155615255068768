import styled from 'styled-components';

interface IFlexProps {
  readonly align: string;
  readonly justify: string;
  readonly direction?: string;
}

export const Flex = styled.div<IFlexProps>`
  display: flex;

  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};

  flex-direction: ${(props) => props.direction};
`;

Flex.defaultProps = {
  direction: 'row',
};
