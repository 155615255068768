import { BuyerTableColumnProps } from "./types";

export const mockBuyersTableData: Array<BuyerTableColumnProps> = [
  {
    key: '1',
    name: 'John Doe',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Admin',
  },
  {
    key: '2',
    name: 'Tristen Cannon',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '3',
    name: 'Baba Shmaba',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '4',
    name: 'Garik Batrudinov',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '5',
    name: 'Ivan Grozniy',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '6',
    name: 'Alexandr I',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '7',
    name: 'Eleonora Acvitanskaya',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '8',
    name: 'Alexandr Makedonskiy',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '9',
    name: 'Mao Dze Dun',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
  {
    key: '10',
    name: 'Nill Armstrong',
    organisation: 'johndoe@demoresellera.com',
    urn: 'Standard',
  },
];