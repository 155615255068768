import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ROUTER_CONFIG from '../../../router/router-config';
import LayoutV1 from '../../layout/LayoutV1';
import Input from '../../../ui/forms/Input';
import Title from '../shared/header/Title';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import { useFormik } from 'formik';
import { ButtonDark } from '../../../ui/Button';
import ModalInPortal from '../../../ui/Modal/ModalPortal';
import {
  useGetLessorQuery,
  useUpdateLessorMutation,
} from '../../../api/services/superadmin/lessorsApi';
import {
  useCreateLeaseMutation,
  useDeleteLeaseMutation,
  useGetLeaseListQuery,
  useUpdateLeaseMutation,
} from '../../../api/services/superadmin/leaseApi';
import { ILease, ILessor } from './types';
import LeaseDetails from './LeaseDetails';
import LeaseTable from './LeaseTable';
import { createLessorForm } from './lessors-schemas';
import { ReactComponent as BackIcon } from '../../../img/icons/backIcon.svg';

const { TabPane } = Tabs;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
`;

const FieldTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

const ODformConfig: FormConfig = [
  {
    title: <FieldTitle>Name</FieldTitle>,
    name: 'name',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Address line 1</FieldTitle>,
    name: 'addressLine1',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Address line 2</FieldTitle>,
    name: 'addressLine2',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Address line 3</FieldTitle>,
    name: 'addressLine3',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Town</FieldTitle>,
    name: 'town',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Country</FieldTitle>,
    name: 'country',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Postcode</FieldTitle>,
    name: 'postcode',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Telephone Number</FieldTitle>,
    name: 'telephoneNumber',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Contact Email</FieldTitle>,
    name: 'contactEmail',
    component: StyledInput,
    isRequired: true,
  },
];

const AFformConfig: FormConfig = [
  {
    title: <FieldTitle>Default Administrative Fee Amount</FieldTitle>,
    name: 'defaultAdministrativeFee',
    component: StyledInput,
  },
];

const DFformConfig: FormConfig = [
  {
    title: <FieldTitle>Default Documentation Fee Amount</FieldTitle>,
    name: 'defaultDocumentationFee',
    component: StyledInput,
  },
];

const LessorDetailsPage: FC = () => {
  const { push } = useHistory();
  const { lessorId } = useParams<{ lessorId: string }>();

  const { data: lessor } = useGetLessorQuery(lessorId);
  const { data: leaseList } = useGetLeaseListQuery();

  const [updateInsurer, { isSuccess, isLoading }] = useUpdateLessorMutation();

  const [
    createLease,
    { isLoading: isCreateLeaseLoading },
  ] = useCreateLeaseMutation();
  const [
    updateLease,
    { isLoading: isUpdateLeaseLoading },
  ] = useUpdateLeaseMutation();
  const [deleteLease] = useDeleteLeaseMutation();

  const LeaseModal = ModalInPortal();

  const onShowLeaseModal = () => LeaseModal.onShow();

  const onCloseLeaseModal = () => {
    setCurrentLeaseId('');
    LeaseModal.onClose();
  };

  const [currentLeaseId, setCurrentLeaseId] = useState<string>('');

  const onCreateNewLease = () => {
    setCurrentLeaseId('');
    onShowLeaseModal();
  };

  const onEditLease = useCallback(
    (id: string) => {
      setCurrentLeaseId(id);
      onShowLeaseModal();
    },
    [setCurrentLeaseId, onShowLeaseModal]
  );

  const [selectedLeases, setSelectedLeases] = useState<Array<string>>([]);

  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_LESSORS.getPath());

  const tableData = useMemo(
    () =>
      leaseList?.map((lease) => ({
        key: lease._id,
        description: lease.description,
        type: lease.type,
        policyLength: lease.policyLength,
        paymentSchedule: lease.paymentSchedule,
        rate: lease.rate,
        residualValueRate: lease.residualValueRate,
      })) || [],
    [leaseList]
  );

  const onCreateLease = useCallback(
    (values: Omit<ILease, '_id'>) => {
      createLease(values);
    },
    [createLease]
  );

  const onUpdateLease = useCallback(
    (values: Omit<ILease, '_id'>) => {
      updateLease({
        body: values,
        leaseId: currentLeaseId,
      });
    },
    [currentLeaseId, updateLease]
  );

  const onDeleteLease = useCallback(() => console.log('selected'), []);

  useEffect(() => {
    if (isSuccess) {
      push(ROUTER_CONFIG.SUPER_ADMIN_LESSORS.getPath());
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: {
      ...lessor,
    },
    validationSchema: createLessorForm,
    onSubmit: (values) => {
      updateInsurer({ body: values as Omit<ILessor, '_id'>, lessorId });
    },
  });

  useEffect(() => {
    formik.setValues({ ...lessor });
  }, [lessor]);

  return (
    <LayoutV1>
      <Container>
        {LeaseModal.ModalRender && (
          <LeaseModal.ModalRender>
            <LeaseDetails
              initialValues={leaseList?.find(
                (lease) => lease._id === currentLeaseId
              )}
              onClose={onCloseLeaseModal}
              onCreateNewLease={onCreateLease}
              onUpdateLease={onUpdateLease}
              currentLeaseId={currentLeaseId}
              isLoading={isCreateLeaseLoading || isUpdateLeaseLoading}
            />
          </LeaseModal.ModalRender>
        )}
        {lessor && (
          <>
            <Title style={{ margin: '0 0 10px 0' }}>
              <div style={{ display: 'flex' }}>
                <div
                  onClick={onBack}
                  style={{ cursor: 'pointer', marginRight: 15 }}
                >
                  <BackIcon />
                </div>
                {lessor?.name}
              </div>
            </Title>
            <TabsStyled defaultActiveKey="1" tabBarStyle={{ color: '#6B7A87' }}>
              <TabsPaneStyled tab="Lease Rates" key="1">
                <LeaseTable
                  leaseTableData={tableData || []}
                  onSelectedLeases={setSelectedLeases}
                  selectedLeases={selectedLeases}
                  onDeleteLeases={onDeleteLease}
                  onAddNewLease={onCreateNewLease}
                  onEditLease={onEditLease}
                />
              </TabsPaneStyled>
              <TabsPaneStyled tab="Lease Rates for Independent Schools" key="2">
                <LeaseTable
                  leaseTableData={tableData || []}
                  onSelectedLeases={setSelectedLeases}
                  selectedLeases={selectedLeases}
                  onDeleteLeases={onDeleteLease}
                  onAddNewLease={onCreateNewLease}
                  onEditLease={onEditLease}
                />
              </TabsPaneStyled>
            </TabsStyled>
            <TabsStyled defaultActiveKey="1" tabBarStyle={{ color: '#6B7A87' }}>
              <TabsPaneStyled tab="Organisation Details" key="1">
                <FormContainer onSubmit={formik.handleSubmit}>
                  {renderFormConfig(ODformConfig, formik)}
                  <StyledButtonDark type="submit">Save</StyledButtonDark>
                </FormContainer>
              </TabsPaneStyled>
              <TabsPaneStyled tab="Administrative Fee" key="2">
                <FormContainer onSubmit={formik.handleSubmit}>
                  {renderFormConfig(AFformConfig, formik)}
                  <StyledButtonDark type="submit">Save</StyledButtonDark>
                </FormContainer>
              </TabsPaneStyled>
              <TabsPaneStyled tab="Documentation Fee" key="3">
                <FormContainer onSubmit={formik.handleSubmit}>
                  {renderFormConfig(DFformConfig, formik)}
                  {!isLoading ? (
                    <StyledButtonDark type="submit">Save</StyledButtonDark>
                  ) : (
                    <StyledButtonDark disabled type="submit">
                      ...Saving
                    </StyledButtonDark>
                  )}
                </FormContainer>
              </TabsPaneStyled>
            </TabsStyled>
          </>
        )}
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div``;

const TabsStyled = styled(Tabs)``;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 41px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;

const FormContainer = styled.form``;

export default LessorDetailsPage;
