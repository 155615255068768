import { FC, useMemo, useState } from "react";
import { IService } from "../../types";
import LayoutV1 from "../../../layout/LayoutV1";
import Services from './Services';
import { useGetServicesQuery, useDeleteServiceMutation } from "../../../../api/services/superadmin/servicesApi";

const ServicesPage: FC = () => {
  const {data: getServices} = useGetServicesQuery();
  const [ deleteService ] = useDeleteServiceMutation();
  
  const [ selectedUsers, setSelectedUsers ] = useState<Array<string>>([]);
  const [ searchValue, setSearchValue ] = useState<string>('');
  
  const onDeleteService = async () => {
    await deleteService(selectedUsers[0]);
  };
  
  const searchTableValue = (array: any, keyword: string) => {
    const searchTerm = keyword.toLowerCase();
    return array?.filter((value: any) => (
      value.name.toLowerCase().match(new RegExp(searchTerm, 'g'))
    ));
  };
  
  const result = searchTableValue(getServices, searchValue);
  
  const tableData: Array<IService> = useMemo(() =>
      result
        ? result?.map((service: any) => ({
          key: service._id,
          _id: service._id,
          name: service.name,
          iconUrl: service.iconUrl,
        }))
        : [],
    [ result ]
  );
  
  return (
    <LayoutV1>
      <Services
        tableData={tableData}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onDeleteService={onDeleteService}
      />
    </LayoutV1>
  );
};

export default ServicesPage;