import { FC } from "react";
import { Table, TableProps, Tabs } from "antd";
import styled from "styled-components/macro";

const {TabPane} = Tabs;

export const TableStyled: FC<TableProps<any>> = styled(Table)`
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #d5dce5;
  }
  
  .ant-table-thead th {
    color: #6b7a87;
    font-weight: bold;
    font-size: 13px;
    border-bottom: 1px solid #d5dce5;
    border-top: 1px solid #d5dce5;
  }
  
  .ant-table-thead tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: relative;
  }
  
  .ant-table tr {
    color: #102f54;
    font-size: 14px;
    font-weight: 600;
  }
  
  .ant-table {
    border-right: 1px solid #d5dce5 !important;
    border-left: 1px solid #d5dce5 !important;
  }
  
  .ant-table-pagination.ant-pagination {
    margin: 16px 2px;
  }
`;

export const TableControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 10px;
`;

export const CustomTableControlsContainer = styled(TableControls)`
  width: 70px;
`;

export const CustomTableControlsButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledTabPane = styled(TabPane)`
  padding-top: 20px;
`;

export const AddButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin: 0 20px 0 0;
  
  &:hover {
    text-decoration: underline;
  }
`;