import { FC, useMemo, useCallback, useState } from 'react';
import { ButtonWrapper, CustomStyledTable } from '../../styled/new-offer.styled';
import { NewOffer } from '../../../../types';
import { ButtonDark } from '../../../../../../ui/Button';
import { ColumnsType } from 'antd/lib/table';
import { LeaseRatesTableRow } from '../../../../types';
import { Button } from 'antd';

interface ILeaseRatesTableProps {
  tableData: Array<NewOffer>;
}

const LeaseRatesTable: FC<ILeaseRatesTableProps> = ({
  tableData,
}) => {
  const [selectedRates, setSelectedRates] = useState(() => new Set());

  const onAddClickHandler = useCallback((id: string) => {
    setSelectedRates((prev: any) => new Set(prev).add(id));
  }, [selectedRates]);

  const onRemoveClickHandler =  useCallback((id: string) => {
    setSelectedRates((prev: any) => {
      const newSet: any = new Set(prev);
      newSet.delete(id);
      return newSet;
    })
  }, [selectedRates]);

  const columnsItems: ColumnsType<LeaseRatesTableRow> = useMemo(() => [
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Lease Type',
      dataIndex: 'type',
      },
    {
      title: 'Term Length',
      dataIndex: 'policyLength',
    },
    {
      title: 'Payment Schedule',
      dataIndex: 'paymentSchedule',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
    {
      title: 'Residual Value Rate',
      dataIndex: 'residualValueRate',
    },
    {
      render: (record: any) => {

        return (
          <ButtonWrapper>
            {selectedRates.has(record._id) ? (
              <Button
                type="primary"
                danger
                ghost
                onClick={() => onRemoveClickHandler(record._id)}
                style={{ background: '#fff', width: '100px', height: '38px', fontWeight: 700 }}
              >
                Remove
              </Button>
              ):(
              <ButtonDark
                onClick={() => onAddClickHandler(record._id)}
                style={{ fontSize: 14, width: '100px', padding: 8 }}
              >
                Add
              </ButtonDark>)}
          </ButtonWrapper>
        );
      },
    }
], [onAddClickHandler, onRemoveClickHandler]);

return (
  <CustomStyledTable
    dataSource={tableData}
    pagination={false}
    columns={columnsItems}
    rowClassName={(record) => (selectedRates.has(record._id) ? 'blueBackground' : '')}
  />
  );
};

export default LeaseRatesTable;
