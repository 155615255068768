import { FC, useCallback, useState, Key } from 'react';
import { Col, Row } from 'antd';

import LayoutV1 from '../../../layout/LayoutV1';

import { QuotesDropdown } from './quotes-dropdown/QuotesDopdown';
import { SearchInputStyled, StyledButtonDark } from './styled/quotes.styled';
import { Title } from '../../shared/typography/typography.styled';
import { TableStyled } from '../../shared/table/TableStyled';

import { ReactComponent as SearchIcon } from '../../../../img/icons/icon_search.svg';
import {
  useGetQuotesListQuery,
  useGetSearchQuotesListQuery,
} from '../../../../api/services/superadmin/quotesApi';
import { columns, mockTableData } from './table.mock';
import { useHistory } from "react-router-dom";
import ROUTER_CONFIG from "../../../../router/router-config";

const QuotesPage: FC = () => {
  const { push } = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const [submittedValue, setSubmittedValue] = useState<{ number: string }>({
    number: '',
  });
  const { data } = useGetQuotesListQuery();
  const { data: searchQuotes } = useGetSearchQuotesListQuery(submittedValue);
  
  const onRowClickHandler = (quoteId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_QUOTE_VIEW.getPath({ quoteId }));
  
  const onSubmitSearchValue = useCallback(() => {
    setSubmittedValue({ number: searchValue });
  }, [searchValue]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: Number[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
    },
    getCheckboxProps: () => ({}),
  };

  return (
    <LayoutV1>
      <Row>
        <Col span={8}>
          <Title>Quotes</Title>
        </Col>

        <Col
          offset={16}
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <StyledButtonDark>Build Quote</StyledButtonDark>
        </Col>

        <Col span={8}>
          <SearchInputStyled placeholder="Number" suffix={<SearchIcon />} />
        </Col>
        <Col
          span={8}
          offset={8}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <QuotesDropdown />
        </Col>
        <Col span={24} style={{ marginTop: 10 }}>
          <TableStyled
            columns={columns}
            dataSource={mockTableData}
            onChange={() => {}}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            onRow={(rowData) => ({
              onClick: () => onRowClickHandler(rowData._id),
              style: { cursor: 'pointer' },
            })}
          />
        </Col>
      </Row>
    </LayoutV1>
  );
};

export default QuotesPage;