import styled from 'styled-components/macro';
import { useGetSellersQuery, useSignUpMutation } from '../../../api/services/buyerApi';
import BuyerLayout from '../layout/BuyerLayout';
import ROUTER_CONFIG from '../../../router/router-config';
import BuyerRegisterView from './BuyerRegisterView';
import { mapErrors } from '../../../api/services/ApiHelpers';
import { ErrorData } from '../types';
import { useRedirectOnSuccessHook } from '../hooks';

export default function BuyerRegisterPage() {
  const [signUp, { isLoading, error, isSuccess }] = useSignUpMutation(); // todo add spinner

  useRedirectOnSuccessHook(isSuccess, ROUTER_CONFIG.BUYER_VERIFY_ACCOUNT.getPath());

  const { data: resellers } = useGetSellersQuery();

  const preferredResellers = resellers?.filter((r) => r && r.name).map((r) => r.name);

  return (
    <StyledLayout>
      <BuyerRegisterView
        onSubmit={signUp}
        preferredResellersList={preferredResellers || []}
        externalErrors={mapErrors(error as ErrorData)}
      />
    </StyledLayout>
  );
}

const StyledLayout = styled(BuyerLayout)`
  .buyer-content-wrapper {
    padding: 0;
  }
`;
