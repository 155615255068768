import { IBuyersQuotes } from '../../../../types';
import { ColumnsType } from 'antd/lib/table';
import { ButtonDark } from '../../../../../../ui/Button';
import { formatDateShort } from '../../../../../../ui/DateFormatMethods';

import styled from 'styled-components'

export const tableConfig = [
    {
      title: 'Data Submitted',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value:any) => (
        formatDateShort(new Date(value.createdAt))
      ),
    },
    {
      title: 'PO Number',
      key: 'poNumber',
      dataIndex: 'poNumber',
    },
    {
      title: 'Products',
      key: 'product',
      dataIndex: 'product',
    },
    {
       title: 'Order Type',
       key: 'orderType',
       dataIndex: 'orderType',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
    }
  ];

export const tableQuote: ColumnsType<IBuyersQuotes> = [
  {
    title: 'Date Submitted',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorter: (a: any, b: any) => {
      if (new Date(a.createdAt) < new Date(b.createdAt)) {
        return -1;
      }
      if (new Date(a.createdAt) > new Date(b.createdAt)) {
        return 1;
      }
      return 0;
    },
    render: (value:any) => (
      formatDateShort(new Date(value.createdAt))
    ),
    sortDirections: ['descend']
  },
  {
    title: 'Reference',
    key: 'reference',
    dataIndex: 'reference',
  },
  {
    title: 'Supplier',
    key: 'seller',
    dataIndex: 'seller',
  },
  {
    title: 'Quote Expiration Date',
    key: 'expiredAt',
    dataIndex: 'expiredAt',
    render: (value:any) => (
      formatDateShort(new Date(value.expiredAt))
    ),
    sorter: (a: any, b: any) => {
      if (new Date(a.expiredAt) < new Date(b.expiredAt)) {
        return -1;
      }
      if (new Date(a.expiredAt) > new Date(b.expiredAt)) {
        return 1;
      }
      return 0;
    },
    sortDirections: ['descend']
  },
  {
    title: 'Order Type',
    key: 'orderType',
    dataIndex: 'orderType',
  },
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
  },
]

export const options = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Placed',
      value: 'Placed',
    },
    {
      label: 'Accepted',
      value: 'Accepted',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
    {
      label: 'Out for delivery',
      value: 'Out for delivery',
    },
    {
      label: 'Paid',
      value: 'Paid',
    },
  ];

export const userConfig = [
    {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
    },
    {
        title: 'Second Name',
        dataIndex: 'secondName',
        key: 'secondName',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
]

export const addressConfig = [
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: '',
    width: "12%",
    dataIndex: 'editButton',
    key: 'editButton',
    render: () => <StyledButtonDark>
      Edit
    </StyledButtonDark>,
  }
]

const StyledButtonDark = styled(ButtonDark)`
  padding: 9px 35px;
`;