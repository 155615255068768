import { FC } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line object-curly-newline
import { Input, Table, TableProps, InputProps } from 'antd';

import { ButtonDark } from '../../../../../ui/Button';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 50px;
`;

export const StyledButtonDark = styled(ButtonDark)`
  padding: 13px 100px;
  align-self: flex-end;
`;

export const TableStyled: FC<TableProps<any>> = styled(Table)`
  .ant-table-tbody > tr > td {
    border-bottom: 2px solid #d5dce5;
  }

  .ant-table-thead th {
    color: #6b7a87;
    font-weight: 500;
    font-size: 13px;
    border-bottom: 2px solid #d5dce5;
    border-top: 2px solid #2682de;
  }

  .ant-table tr {
    color: #102f54;
    font-size: 14px;
    font-weight: 600;
  }

  .ant-table {
    border-right: 2px solid #d5dce5 !important;
    border-left: 2px solid #d5dce5 !important;
  }
`;

export const SearchInputStyled: FC<InputProps> = styled(Input)`
  width: 200px;
  border-radius: 6px;
  border: 1.7px solid #d5dce5;
  padding: 10px 15px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(107, 122, 135, 1) !important;
  }
`;
