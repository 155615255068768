import styled from 'styled-components';
import { FileFilled, DeleteOutlined } from '@ant-design/icons';
import { ButtonLight, ButtonDark } from '../../../../ui/Button';
import { FC } from 'react';
import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

interface IProps {
  formikInstance: any;
  isEditMode: boolean;
}

const files = [
  { name: 'File1', createdAt: new Date(), directLink: 'link1' },
  { name: 'File2', createdAt: new Date(), directLink: 'link2' },
  { name: 'File3', createdAt: new Date(), directLink: 'link3' },
];

const ProductRelatedFiles: FC<IProps> = ({
  formikInstance,
  isEditMode,
}: IProps) => {
  const onDownload = (file: UploadFile<any>) => {
    console.log(file);
  };

  const onRemoveFile = () => console.log('remove file');

  return (
    <Containter>
      {isEditMode && (
        <div style={{ marginBottom: 30 }}>
          <Upload
            listType="text"
            action=""
            showUploadList={false}
            onDownload={onDownload}
          >
            <StyledButtonDark>+ Add new file</StyledButtonDark>
          </Upload>
        </div>
      )}
      <BlueLine />
      {files.map((file) => (
        <FileContainer key={file.directLink}>
          <div style={{ display: 'flex' }}>
            <StyledFileFilled />
            <div>
              <Text>{file.name}</Text>
              <TextGray>{file.createdAt.toTimeString()}</TextGray>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <StyledButton>View Document</StyledButton>
            {isEditMode && <StyledDelete onClick={onRemoveFile} />}
          </div>
        </FileContainer>
      ))}
      <GrayLine />
    </Containter>
  );
};

const Containter = styled.div`
  width: 670px;
`;

const BlueLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #2682de;
  margin-bottom: 10px;
`;

const GrayLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d5dce5;
  margin-bottom: 10px;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const StyledButton = styled(ButtonLight)`
  padding: 13px 60px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 13px;
  width: 150px;
  border-radius: 3px;
  margin-top: 15px;

  &:disabled {
    background: #f5f5f5;
    color: #909da8;
  }
`;

const StyledFileFilled = styled(FileFilled)`
  color: #2682de;
  font-size: 40px;
  margin-right: 5px;
`;

const Text = styled.div`
  font-size: 14px;
`;

const TextGray = styled.div`
  font-size: 12px;
  color: #6b7a87;
`;

const StyledDelete = styled(DeleteOutlined)`
  margin: auto;
  margin-left: 20px;
  color: #de2644;
  cursor: pointer;
  font-size: 25px;
`;

export default ProductRelatedFiles;
