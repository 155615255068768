import { FC } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';

const { Text } = Typography;

export const Title: FC<TextProps> = styled(Text)`
  font-size: 30px;
  font-weight: 600;

  color: ${(props) => props.theme.textPrimary};
`;

export const Subtitle: FC<TextProps> = styled(Title)`
  font-size: 21px;
`;

export const TextBody: FC<TextProps> = styled(Text)`
  color: ${(props) => {
    if (props.type === 'secondary') {
      return `${props.theme.textSecondary} !important`;
    }

    return props.theme.textPrimary;
  }};
`;

export const FormItemLabel = styled(Title)`
  font-size: 12px;
  font-weight: 600;
`;
