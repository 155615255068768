import { Input } from 'antd';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { ButtonDark } from '../../../../ui/Button';
import SelectInput from '../../../../ui/forms/SelectInput';
import { TECH_SPEC_OPTIONS } from '../../types';
import { FC } from 'react';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';

interface IProps {
  formikInstance: any;
  isEditMode: boolean;
}

const getUnusedTechSpecKeys = (techSpecs: Record<string, string>): string[] => {
  const keys = Object.values(TECH_SPEC_OPTIONS);
  if (!techSpecs) {
    return keys;
  }
  const existingKeys = Object.keys(techSpecs);

  return keys.filter((value) => !existingKeys.includes(value));
};

const ProductTechSpecs: FC<IProps> = ({
  formikInstance,
  isEditMode,
}: IProps) => {
  const techSpecs = formikInstance.values.techSpecs as Record<string, string>;
  const addNewSpec = () => {
    formikInstance.setFieldValue('techSpecs', {
      ...techSpecs,
      ...{
        [getUnusedTechSpecKeys(techSpecs)[0]]: '',
      },
    });
  };

  const removeSpec = (key: string) => {
    const { [key]: remove, ...rest } = techSpecs;
    formikInstance.setFieldValue('techSpecs', rest);
  };

  const setFieldKey = (field: string, newKey: string) => {
    const newTechSpecs = Object.fromEntries(
      Object.entries(techSpecs).map(([key, value]) => {
        if (key === field) {
          return [newKey, ''];
        }
        return [key, value];
      })
    );

    formikInstance.setFieldValue('techSpecs', newTechSpecs, false);
  };

  const setFieldValue = (field: string, value: string) => {
    formikInstance.setFieldValue(`techSpecs.[${field}]`, value, false);
  };

  return (
    <>
      {isEditMode ? (
        <>
          {techSpecs &&
            Object.entries(techSpecs).map((item, index) => (
              <InputField key={index}>
                <StyledSelectInput
                  name={item[0]}
                  value={item[0]}
                  setFieldValue={setFieldKey}
                  initialData={getUnusedTechSpecKeys(techSpecs)}
                  suffixIcon={<ArrowDown />}
                />
                <StyledInput
                  value={item[1]}
                  onChange={(e) => setFieldValue(item[0], e.target.value)}
                />
                <StyledDelete onClick={() => removeSpec(item[0])} />
              </InputField>
            ))}
          {getUnusedTechSpecKeys(techSpecs).length > 0 && (
            <StyledButton onClick={addNewSpec}>+ Add attribute</StyledButton>
          )}
        </>
      ) : (
        <>
          {techSpecs &&
            Object.entries(techSpecs).map((item, index) => (
              <SummaryInfoItem key={index}>
                <Label>{item[0]}</Label>
                <Text>{item[1]}</Text>
              </SummaryInfoItem>
            ))}
        </>
      )}
    </>
  );
};

const InputField = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StyledSelectInput = styled(SelectInput)`
  margin-right: 15px;
  width: 180px;
`;

const StyledInput = styled(Input)`
  margin-right: 15px;
  width: 250px;
`;

const StyledButton = styled(ButtonDark)`
  padding: 10px 23px;
`;

const SummaryInfoItem = styled.div`
  display: flex;
  margin-bottom: 45px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const Label = styled(Text)`
  font-weight: bold;
  color: #6b7a87;
  margin-bottom: 6px;
  min-width: 170px;
  margin-right: 15px;
`;

const StyledDelete = styled(DeleteOutlined)`
  color: #de2644;
  cursor: pointer;
  margin: auto 0;
`;

export default ProductTechSpecs;
