import { FC } from 'react';
import { Col, Row } from 'antd';
import { StyledButtonDark } from '../styled/new-offer.styled';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../../router/router-config';

const ButtonComponent: FC = () => {
  const { push } = useHistory();
  const onOffers = () => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.getPath());

  return (
    <Row
      style={{ marginBottom: '100px' }}
    >
      <Col
        span={16}
      >
        <StyledButtonDark
          type='submit'
          onClick={() => onOffers()}
          style={{ width: '313px' }}
        >
          Generate Offer
        </StyledButtonDark>
      </Col>
    </Row>
  );
};

  export default ButtonComponent;
