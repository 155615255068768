import styled from 'styled-components/macro';
import React from 'react';
import { ButtonLight } from '../../../../../ui/Button';
import { ReactComponent as Icon } from '../../../../../img/icons/school-hat.svg';
import Title from '../../../shared/header/Title';
import { useGetOfferGroupsListQuery } from '../../../../../api/services/superadmin/offerGroupsApi';

interface OfferGroupDetailsDescriptionProps {
  onEditOfferGroupClick: () => void;
}

const OfferGroupDetailsDescription: React.FC<OfferGroupDetailsDescriptionProps> = ({ onEditOfferGroupClick }) => {
  const { data: offerGroups } = useGetOfferGroupsListQuery();

  return (
  <Container>
    <Header>
      <Icon />
      <Title style={{ margin: '0 0 0 23px', fontSize: '21px' }}>Digital Content Access</Title>
      <OfferGroupsDetailsButton onClick={onEditOfferGroupClick}>Edit Offer Group</OfferGroupsDetailsButton>
    </Header>
    <Description>
      <DescriptionTitle>Description</DescriptionTitle>
      <DescriptionInfo>
        {offerGroups?.[0].description}
      </DescriptionInfo>
      <DescriptionLine />
    </Description>
  </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 628px;
`;

const Header = styled.div`
  display: flex;
  align-content: center;
`;

const Description = styled.div`
  box-sizing: border-box;
  border-top: 3px solid #2682DE;
  border-left: 1px solid #F9F9F9;
  border-right: 1px solid #F9F9F9;
  padding: 24px 20px 24px 20px;
  margin-top: 18px;
  margin-bottom: 28px;
`;

const DescriptionTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #6B7A87;
  margin-bottom: 7px;
`;

const DescriptionInfo = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
  max-width: 90%;
`;

const DescriptionLine = styled.div`
  height: 1px;
  background-color: #D5DCE5;
  margin-top: 24px;
`;

const OfferGroupsDetailsButton = styled(ButtonLight)`
  padding: 4px 31px;
  font-size: 14px;
  height: fit-content;
  margin-left: auto;
`;

export default OfferGroupDetailsDescription;
