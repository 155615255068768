import { TableProps, Table } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const StatusCardTable: FC<TableProps<any>> = styled(Table)`
  .ant-table-thead th {
    color: ${(props) => props.theme.textPrimary};

    font-weight: 600;
    font-size: 14px;
  }

  .ant-table tr {
    color: ${(props) => props.theme.textPrimary};
    font-weight: 400;
    font-size: 14px;
  }

  tr {
    background: #f9f9f9 !important;
  }

  .ant-table-thead
    tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: relative;
  }
`;
