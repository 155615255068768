import { FC, useState } from 'react';
import { Col, Row, Select, Form, Space, Button } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/filled-arrow-down.svg';
import {
  SubTitle,
  InputLabel,
  StyledText,
  StyledButtonDark,
  TableWrapper,
  LongSelectWrapper,
  StyledLongSelect,
  StyledCheckbox,
  Container,
} from '../styled/new-offer.styled';
import LeaseRatesTable from '../new-offer-tables/lease-rates-table/LeaseRatesTable';
import { NewOffer } from '../../../types';

const { Option } = Select;

interface ILeaseRatesProps {
  offers: Array<NewOffer>;
}

const LeaseRatesSection: FC<ILeaseRatesProps> = ({ offers }) => {
  const [data, setData] = useState<any>([]);

  const onAddLessor = () => {
    setData((prev: any) => ([...prev, {id: Date.now()}]));
  };

  const onRemoveLessor = (id: any) => {
    const newDataSource = data.filter((value: any) => value.id !== id);
    setData(newDataSource);
  };

  const onSwitchIndependentSchools = () => {
    console.log(offers?.[0].leases?.[0]._id);
  }


  return (
    <Row
      style={{ marginBottom: '54px' }}
    >
      <Col
        span={16}
      >
        <SubTitle>5. Lease Rates</SubTitle>
        <InputLabel>
          Lessor 1 (Default)
        </InputLabel>
        <LongSelectWrapper>
          <Form.Item
            name="lease-rates"
            style={{ margin: "0px" }}
          >
          <StyledLongSelect
            defaultValue="Maxxia Limited"
            suffixIcon={<ArrowDown />}
          >
            {offers.map((item) => (
              <Option
                value={item.leases?.[0].lessor}
                key={item._id}
                style={{ lineHeight: 2, color: '#102F54' }}
              >
                {item.leases?.[0] ? (item.leases?.[0]?.lessor) : 'no data'}
              </Option>
            ))}
          </StyledLongSelect>
          </Form.Item>
        </LongSelectWrapper>
      </Col>
      <TableWrapper>
        {offers &&
        <LeaseRatesTable
          tableData={offers?.[0]?.leases}
        >
        </LeaseRatesTable>}
      </TableWrapper>
      <Space
        style={{ width: '100%', padding: '20px 10px'}}
      >
        <StyledCheckbox
          style={{ marginRight: '5px' }}
          onChange={onSwitchIndependentSchools}
        />
        <StyledText>Show Lease Rates for Independent Schools</StyledText>
      </Space>
      <StyledButtonDark
         onClick={onAddLessor}
      >
        + Add Lessor
      </StyledButtonDark>
      {data.map((item: any) => {
        return (
          <Container
            key={item.id}
          >
            <LongSelectWrapper>
            <Form.Item
              name="lease-rates"
              style={{ margin: "0px" }}
            >
            <StyledLongSelect
              defaultValue="Maxxia Limited"
              suffixIcon={<ArrowDown />}
            >
              {offers.map((item) => (
                <Option
                  value={item.leases?.[0].lessor}
                  key={item._id}
                  style={{ lineHeight: 2, color: '#102F54' }}
                >
                  {item.leases?.[0] ? (item.leases?.[0]?.lessor) : 'no data'}
                </Option>
              ))}
            </StyledLongSelect>
            </Form.Item>
            </LongSelectWrapper>
            <TableWrapper>
              {offers &&
              <LeaseRatesTable
                tableData={offers?.[0]?.leases}
              >
              </LeaseRatesTable>}
            </TableWrapper>
            <Space
              style={{ width: '100%', padding: '20px 10px'}}
            >
              <StyledCheckbox
                style={{ marginRight: '5px' }}
                onChange={onSwitchIndependentSchools}
              />
              <StyledText>Show Lease Rates for Independent Schools</StyledText>
            </Space>
            <Button
              type="primary"
              danger
              ghost
              onClick={() => onRemoveLessor(item.id)}
              style={{ background: '#fff', width: '130px', height: '42px', fontWeight: 700 }}
              >
                Remove Lessor
              </Button>
          </Container>
        );
      })}
    </Row>
  );
};

  export default LeaseRatesSection;
