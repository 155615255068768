import { Form, Input, Row, Col } from 'antd';
import { FC } from 'react';

import { ButtonDark } from '../../../../ui/Button';
import { FormItemLabel, Subtitle } from '../typography/typography.styled';

const AddressEditContent: FC = () => {
  const onFinish = (values: any) => {
    console.log('values', values);
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col span={8}>
          <Subtitle>Edit Address</Subtitle>
        </Col>
      </Row>
      <Form layout="vertical" wrapperCol={{ span: 20 }} onFinish={onFinish}>
        <Form.Item
          rules={[{ required: true, message: 'This field is required' }]}
          label={<FormItemLabel>Postcode</FormItemLabel>}
          required
          name="postcode"
        >
          <Input />
        </Form.Item>
        <ButtonDark
          style={{ width: 208, height: 44, fontSize: 14, marginBottom: 22 }}
        >
          Find your Address
        </ButtonDark>
        <Form.Item
          required
          rules={[{ required: true, message: 'This field is required' }]}
          label={<FormItemLabel>Address Line 1</FormItemLabel>}
          name="addressLine1"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<FormItemLabel>Address Line 2</FormItemLabel>}
          name="addressLine2"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<FormItemLabel>Address Line 3</FormItemLabel>}
          name="addressLine3"
        >
          <Input />
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: 'This field is required' }]}
          label={<FormItemLabel>Town</FormItemLabel>}
          name="town"
        >
          <Input />
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: 'This field is required' }]}
          label={<FormItemLabel>Country</FormItemLabel>}
          name="country"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <ButtonDark
            type="submit"
            style={{ width: 208, height: 44, fontSize: 14, marginBottom: 22 }}
          >
            Save
          </ButtonDark>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddressEditContent;
