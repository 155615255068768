import { FC, useState } from "react";
import { Container, StyledInput, Title, CloseButton, SubmitButton } from './service.styled';
import CloseIcon from '../../../../img/close.svg';
import ImageUploader from "../../../../ui/ImageUploader/ImageUploader";
import { IAddService, IParamsEvent } from "./types";
import InputWrap from "../../../../ui/forms/InputWrap";
import { IService } from "../../types";

const AddServiceContent: FC<IAddService> = ({onClose, createService}) => {
  const [ serviceType, setServiceType ] = useState<IService>({
    iconUrl: '',
    name: '',
    logo: '',
  });

  const onChange = ({target: {name, value}}: IParamsEvent) => {
    setServiceType({
      ...serviceType,
      [name]: value
    });
  };
  
  const submitService = (e: any) => {
    e.preventDefault();
    if (createService) {
      createService({
        iconUrl: serviceType?.logo || '',
        name: serviceType?.name || '',
      });
    }
    onClose();
  };
  
  return (
    <Container onSubmit={submitService} >
      <CloseButton alt="close" src={CloseIcon} onClick={onClose}/>
      <Title>New Service</Title>
      <ImageUploader state={serviceType} setState={setServiceType} iconText="Icon"/>
      <InputWrap title="Service Type Name" isRequired={true}>
        <StyledInput onChange={onChange} name="name" value={serviceType.name}/>
      </InputWrap>
      <SubmitButton type="submit">
        Add Service
      </SubmitButton>
    </Container>
  );
};

export default AddServiceContent;