import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import { Dropdown, Input, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ButtonDark } from '../../../ui/Button';
import { LeaseTableRow } from './types';
import { TableStyled } from '../shared/table/TableStyled';
import { ReactComponent as ArrowDown } from '../../../img/icons/arrowDownBlue.svg';

interface IProps {
  leaseTableData: Array<LeaseTableRow>;
  onSelectedLeases: (selectedIds: Array<string>) => void;
  selectedLeases: Array<string>;
  onDeleteLeases: () => void;
  onAddNewLease: () => void;
  onEditLease: (id: string) => void;
}

const LeaseTable: React.FC<IProps> = ({
  leaseTableData,
  onSelectedLeases,
  selectedLeases,
  onDeleteLeases,
  onAddNewLease,
  onEditLease,
}) => {
  const tableConfig: ColumnsType<LeaseTableRow> = useMemo(
    () => [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '20%',
      },
      {
        title: 'Lease Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Term Length',
        dataIndex: 'policyLength',
        key: 'policyLength',
      },
      {
        title: 'Payment Schedule',
        dataIndex: 'paymentSchedule',
        key: 'paymentSchedule',
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
      },
      {
        title: 'Residual Value Rate',
        dataIndex: 'residualValueRate',
        key: 'residualValueRate',
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        render: (id: string) => (
          <StyledButtonDark onClick={() => onEditLease(id)}>
            Edit
          </StyledButtonDark>
        ),
      },
    ],
    [onEditLease]
  );

  const dropdownContent = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={onDeleteLeases}> Delete </Menu.Item>
      </Menu>
    ),
    [onDeleteLeases]
  );

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          gap: 20,
          marginBottom: 10,
        }}
      >
        <InviteButton onClick={onAddNewLease}>+ Add Lease Rate</InviteButton>
        <Dropdown overlay={dropdownContent}>
          <DropDownButton onClick={(e) => e.preventDefault()}>
            Actions <ArrowDown style={{ marginBottom: '2px' }} />
          </DropDownButton>
        </Dropdown>
      </div>
      <TableStyled
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectedLeases(ids as Array<string>);
          },
          selectedRowKeys: selectedLeases,
        }}
        columns={tableConfig}
        dataSource={leaseTableData}
      />
    </Container>
  );
};
const Container = styled.div`
  margin-bottom: 10px;
`;

const DropDownButton = styled.span`
  cursor: pointer;
  color: #2682de;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;
  min-width: 60px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 420px;
  height: 36px;
  padding: 10px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 96% center;
`;

const InviteButton = styled.span`
  cursor: pointer;
  color: #2682de;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 5px 20px;
`;

export default LeaseTable;
