import styled from "styled-components"
import { useFormik } from 'formik';
import { renderFormConfig } from '../../../../../../utils/form';
import { ButtonDark } from '../../../../../../ui/Button';
import Input from '../../../../../../ui/forms/Input';
import InputWrap from '../../../../../../ui/forms/InputWrap';

const BillingForm = () => {

    const formik = useFormik({
        initialValues: {
          accountNumber: '',
          branchSortCode1: '',
          branchSortCode2: '',
          branchSortCode3: '',
          bankAccountName: '',
        },
        onSubmit: (values) => console.log(values),
      });

    return(
        <Container onSubmit={formik.handleSubmit}>
            <Title>Bank Details</Title>
            <Text>The account for which any scheme support funds should be sent to.</Text>
            {renderFormConfig([
            {
                title: 'Name(s) of account holder(s)',
                name: 'bankAccountName',
                component: StyledInput
            },
            ], formik)}
            <InputWrap title="Branch Sort Code">
            <BranchInputWrap>
                <BranchInput isError={!!formik.errors.branchSortCode1} name="branchSortCode1" value={formik.values.branchSortCode1} onChange={formik.handleChange} />
                <Dot />
                <BranchInput isError={!!formik.errors.branchSortCode2} name="branchSortCode2" value={formik.values.branchSortCode2} onChange={formik.handleChange} />
                <Dot />
                <BranchInput isError={!!formik.errors.branchSortCode3} name="branchSortCode3" value={formik.values.branchSortCode3} onChange={formik.handleChange} />
            </BranchInputWrap>
            </InputWrap>
            {renderFormConfig([
            {
                title: 'Bank/building society account number',
                name: 'accountNumber',
                component: StyledInput,
            },
        ], formik)}
            <StyledButtonDark type="submit">Save</StyledButtonDark>
        </Container>
    )
}

const StyledInput = styled(Input)`
  width: 422px;
  height: unset;
  padding: 10.5px 11.5px;

  &:disabled {
    background-color: #F5F5F5;
  }
`;

const StyledButtonDark = styled(ButtonDark)`
  margin-top: 66px;
  padding: 14px 88px;
`;

const Container = styled.form`
`
const Title = styled.h1`
  margin: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const BranchInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BranchInput = styled(Input).attrs({ maxLength: 2 })<{ isError: boolean }>`
  width: 60px;
  font-size: 14px;
  padding: 21px;
  border: ${({ isError }) => isError && '1px solid red'};

  &:disabled {
    background-color: #F5F5F5;
  }
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #102F54;
`;

export default BillingForm