import { FC, useMemo } from 'react';
import { Row, Col, Card, Form } from 'antd';

import {
  InputTable,
  ITableCustomProps,
  TableCreateQuote,
} from '../../styled/quotes-create.styled';
import InputNumber from '../../../../shared/inputs/input-number/InputNumber';

import { useServices } from '../../../../../../hooks/superadmin/services/useServices';

import { dataServices } from '../../quotes.create.mock';
import { ButtonDanger, ButtonDark } from '../../../../../../ui/Button';

interface IServicesProps extends ITableCustomProps {}

const Services: FC<IServicesProps> = ({ shadow }) => {
  const { onAddService, onRemoveService, services } = useServices();

  const columns = useMemo(() => {
    return [
      {
        title: 'Service Type',
        dataIndex: 'serviceType',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: 410,
      },
      {
        title: 'Unit Price',
        dataIndex: 'unitPrice',
        // eslint-disable-next-line arrow-body-style
        render: () => {
          return (
            <Form.Item style={{ width: 120 }} name="unitPrice">
              <InputTable placeholder="$123.1" value="$123.1" />
            </Form.Item>
          );
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        // eslint-disable-next-line arrow-body-style
        render: () => {
          return (
            <Form.Item style={{ width: 120 }} name="quantity">
              <InputNumber />
            </Form.Item>
          );
        },
      },
      {
        title: '',
        key: 'action',
        // eslint-disable-next-line no-confusing-arrow
        render: (record: any) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          services.has(record.serviceId) ? (
            <ButtonDanger
              style={{ width: 110, padding: 7, fontSize: 14 }}
              onClick={() => onRemoveService(record.serviceId)}
            >
              Remove
            </ButtonDanger>
          ) : (
            <ButtonDark
              style={{ width: 110, padding: 7, fontSize: 14 }}
              onClick={() => onAddService(record.serviceId)}
            >
              Add
            </ButtonDark>
          ),
      },
    ];
  }, [services]);

  return (
    <Row>
      <Form name="servicesForm" style={{ width: '100%' }}>
        <Col span={24} style={{ marginTop: 20 }}>
          <Card bodyStyle={{ padding: 0 }}>
            <TableCreateQuote
              shadow={shadow}
              pagination={false}
              columns={columns}
              dataSource={dataServices}
            />
          </Card>
        </Col>
      </Form>
    </Row>
  );
};

export default Services;
