import { FC, Key, useState } from 'react';
import { Col, Row, Space } from 'antd';
import {
  SubTitle,
  StyledText,
  StyledButtonDark,
  TableWrapper,
  StyledSwitch,
} from '../styled/new-offer.styled';
import EligibleNodesTable from '../new-offer-tables/eligible-nodes-table/EligibleNodesTable';
import { eligibleNodesItems } from '../new-offer-tables/eligible-nodes-table/eligibleNodes.mock';

const EligibleNodesSection: FC = () => {
  const [data, setData] = useState<any>(eligibleNodesItems);
  const [isEligibleNodes, setIsEligibleNodes] = useState<boolean>(false);

  const onAddEligibleNodes = () => {

    const newData = {
      key: Date.now(),
      suppliers: 'suppliers',
    }

    setData((prev: any) => ([...prev, newData]))
  };

  const onDeleteEligibleNodes = (key: Key) => {
    setData((prev: any) => prev.filter((item: any) => item.key !== key))
  }

  return (
    <Row
      style={{ marginBottom: '54px' }}
    >
      <Col>
        <Col
          span={16}
          style={{ marginBottom:'31px'}}
        >
          <SubTitle>4. Eligible Nodes</SubTitle>
          <StyledText>Select eligible node(s)</StyledText>
        </Col>
        <Space
          style={{ width: '100%' }}
        >
          <StyledButtonDark
            onClick={onAddEligibleNodes}
            style={{marginRight: 35}}
          >
            + Add Node
          </StyledButtonDark>
          <StyledSwitch
            onChange={() => setIsEligibleNodes(!isEligibleNodes)}
           />
          <StyledText>Make eligible on all nodes</StyledText>
        </Space>
      </Col>
      <TableWrapper>
        <EligibleNodesTable
          dataSource={data}
          isEligibleNodes={isEligibleNodes}
          onDelete={onDeleteEligibleNodes}
        >
        </EligibleNodesTable>
      </TableWrapper>
    </Row>
  );
};

  export default EligibleNodesSection;
