import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ROUTER_CONFIG from '../../../router/router-config';
import LayoutV1 from '../../layout/LayoutV1';
import Input from '../../../ui/forms/Input';
import Title from '../shared/header/Title';
import {
  useGetInsurerQuery,
  useUpdateInsurerMutation,
} from '../../../api/services/superadmin/insurersApi';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import { useFormik } from 'formik';
import { createInsurerForm } from './insurers-schemas';
import { ButtonDark } from '../../../ui/Button';
import { CreateInsurerForm, IInsurance } from './types';
import InsurerPolicyTable from './InsurerPolicyTable';
import {
  useCreateInsurancePolicyMutation,
  useDeleteInsurancePolicyMutation,
  useGetInsurenceListQuery,
  useUpdateInsurancePolicyMutation,
} from '../../../api/services/superadmin/insuranceApi';
import ModalInPortal from '../../../ui/Modal/ModalPortal';
import InsurerPolicyDetails from './InsurerPolicyDetails';
import { ReactComponent as BackIcon } from '../../../img/icons/backIcon.svg';

const { TabPane } = Tabs;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
`;

const FieldTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

const formConfig: FormConfig = [
  {
    title: <FieldTitle>Name</FieldTitle>,
    name: 'name',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Address line 1</FieldTitle>,
    name: 'addressLine1',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Address line 2</FieldTitle>,
    name: 'addressLine2',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Address line 3</FieldTitle>,
    name: 'addressLine3',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Town</FieldTitle>,
    name: 'town',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Country</FieldTitle>,
    name: 'country',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Postcode</FieldTitle>,
    name: 'postcode',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Telephone Number</FieldTitle>,
    name: 'telephoneNumber',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Contact Email</FieldTitle>,
    name: 'contactEmail',
    component: StyledInput,
    isRequired: true,
  },
];

const InsurerDetailsPage: FC = () => {
  const { push } = useHistory();
  const { insurerId } = useParams<{ insurerId: string }>();

  const { data: insurer } = useGetInsurerQuery(insurerId);
  const { data: insurances } = useGetInsurenceListQuery();

  const [updateInsurer, { isSuccess, isLoading }] = useUpdateInsurerMutation();

  const [
    createInsurancePolicy,
    { isLoading: isCreateInsurancePolicyLoading },
  ] = useCreateInsurancePolicyMutation();
  const [
    updateInsurancePolicy,
    { isLoading: isUpdateInsurancePolicyLoading },
  ] = useUpdateInsurancePolicyMutation();
  const [deleteInsurancePolicy] = useDeleteInsurancePolicyMutation();

  const InsurancePolicyModal = ModalInPortal();

  const onShowInsurancePolicyModal = () => InsurancePolicyModal.onShow();

  const onCloseInsurancePolicyModal = useCallback(() => {
    setCurrentPolicyId('');
    InsurancePolicyModal.onClose();
  }, []);

  const [currentPolicyId, setCurrentPolicyId] = useState<string>('');

  const onCreateNewPolicy = () => {
    setCurrentPolicyId('');
    onShowInsurancePolicyModal();
  };

  const onEditPolicy = useCallback(
    (id: string) => {
      setCurrentPolicyId(id);
      onShowInsurancePolicyModal();
    },
    [setCurrentPolicyId, onShowInsurancePolicyModal]
  );

  const [selectedInsurances, setSelectedInsurances] = useState<Array<string>>(
    []
  );

  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_INSURERS.getPath());

  const tableData = useMemo(
    () =>
      insurances?.map((insurance) => ({
        key: insurance._id,
        name: insurance.name,
        description: insurance.description,
        policyLength: insurance.policyLength,
        paymentSchedule: insurance.paymentSchedule,
        price: insurance.price,
      })),
    [insurances]
  );

  const onCreateInsurancePolicy = useCallback(
    (values: Omit<IInsurance, '_id'>) => {
      createInsurancePolicy(values);
    },
    [createInsurancePolicy]
  );

  const onUpdateInsurancePolicy = useCallback(
    (values: Omit<IInsurance, '_id'>) => {
      updateInsurancePolicy({
        body: values,
        insurancePolicyId: currentPolicyId,
      });
    },
    [currentPolicyId, updateInsurancePolicy]
  );

  const onDeleteInsurance = useCallback(() => console.log('selected'), []);

  useEffect(() => {
    if (isSuccess) {
      push(ROUTER_CONFIG.SUPER_ADMIN_INSURERS.getPath());
    }
  }, [
    isSuccess,
    isCreateInsurancePolicyLoading,
    isUpdateInsurancePolicyLoading,
  ]);

  const formik = useFormik({
    initialValues: {
      ...insurer,
    },
    validationSchema: createInsurerForm,
    onSubmit: (values) => {
      updateInsurer({ body: values as CreateInsurerForm, insurerId });
    },
  });

  useEffect(() => {
    formik.setValues({ ...insurer });
  }, [insurer]);

  return (
    <LayoutV1>
      <Container>
        {InsurancePolicyModal.ModalRender && (
          <InsurancePolicyModal.ModalRender>
            <InsurerPolicyDetails
              initialValues={insurances?.find(
                (insurance) => insurance._id === currentPolicyId
              )}
              onClose={onCloseInsurancePolicyModal}
              onCreateNewPolicy={onCreateInsurancePolicy}
              onUpdatePolicy={onUpdateInsurancePolicy}
              currentPolicyId={currentPolicyId}
              isLoading={
                isCreateInsurancePolicyLoading || isUpdateInsurancePolicyLoading
              }
            />
          </InsurancePolicyModal.ModalRender>
        )}
        {insurer && (
          <>
            <Title style={{ margin: '0 0 10px 0' }}>
              <div style={{ display: 'flex' }}>
                <div
                  onClick={onBack}
                  style={{ cursor: 'pointer', marginRight: 15 }}
                >
                  <BackIcon />
                </div>
                {insurer?.name}
              </div>
            </Title>
            <TabsStyled defaultActiveKey="1" tabBarStyle={{ color: '#6B7A87' }}>
              <TabsPaneStyled tab="Insurance Policies" key="1">
                <InsurerPolicyTable
                  insuranceTableData={tableData || []}
                  onSelectedInsurances={setSelectedInsurances}
                  selectedInsurances={selectedInsurances}
                  onDeleteInsurance={onDeleteInsurance}
                  onAddNewPolicy={onCreateNewPolicy}
                  onEditPolicy={onEditPolicy}
                />
              </TabsPaneStyled>
            </TabsStyled>
            <TabsStyled defaultActiveKey="1" tabBarStyle={{color: '#6B7A87'}}>
              <TabsPaneStyled tab="Organisation Details" key="1">
                <FormContainer onSubmit={formik.handleSubmit}>
                  {renderFormConfig(formConfig, formik)}
                  {!isLoading ? (
                    <StyledButtonDark type="submit">
                      Create Account
                    </StyledButtonDark>
                  ) : (
                    <StyledButtonDark disabled type="submit">
                      Saving...
                    </StyledButtonDark>
                  )}
                </FormContainer>
              </TabsPaneStyled>
            </TabsStyled>
          </>
        )}
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div``;

const Info = styled.div`
  font-size: 16px;
  line-height: 26px;
  display: flex;
`;

const TabsStyled = styled(Tabs)``;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 41px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;

const FormContainer = styled.form``;

export default InsurerDetailsPage;
