import {tableConfig} from '../config/configTable'
import { useGetBuyersOrderQuery } from '../../../../../../api/services/superadmin/buyersOrderApi'
import { TableStyled } from "../../../../../../ui/Table/table.styled";

interface IOrderTableProps {
      status: string;
      onRowClickHandler: (id: string) => void;
}

const OrderTable:React.FC<IOrderTableProps> = ({status, onRowClickHandler}) => {
      const { data } = useGetBuyersOrderQuery()
      
      
      let filteredData;

      if(status === 'All'){
            filteredData = data
      }else{
            filteredData = data?.filter((obj) => {
                  return obj.status === status
            })
      }

      return (
            <TableStyled
            columns={tableConfig}
            dataSource={filteredData}
            onRow={(rowData) => ({
              onClick: () => onRowClickHandler ? onRowClickHandler(rowData?._id) : null,
              style: { cursor: 'pointer' },
            })}
            style={{ marginRight: 2 }}
            />
      );
}

export default OrderTable