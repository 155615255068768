import { FC } from 'react';
import styled from 'styled-components/macro';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { BalanceTransfersTableRow } from '../../types';
import { ButtonDark } from '../../../../ui/Button';
import { TableStyled } from '../../../../ui/Table/table.styled';
import { formatDateShort } from '../../../../ui/DateFormatMethods';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
interface BalanceTransfersTableProps {
  tableData: Array<BalanceTransfersTableRow>;
  onViewClickHandler: (id: string) => void;
}

const BalanceTransfersTable: FC<BalanceTransfersTableProps> = ({ tableData, onViewClickHandler }) => {
  const { push } = useHistory();
  const onOrderClickHandler = (orderId: string, paymentType: string) => push(ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({ orderId, paymentType }));
  const tableConfig: ColumnsType<BalanceTransfersTableRow> = [
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (_v, value) => (
        formatDateShort(new Date(value.createdAt))
      ),
      sorter: (a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return 1;
        }
        return 0;
      },
      sortDirections: ['descend'],
    },
    {
      title: 'BTS Number',
      key: 'btsNumber',
      dataIndex: 'btsNumber',
      render: (_v, value) => (<BlueText onClick={onOrderClickHandler}>{value?.btsNumber}</BlueText>),
    },
    {
      title: 'Balance Settled',
      key: 'balanceSettled',
      dataIndex: 'balanceSettled',
      render: (_v, value) => (
        <ColumnWrapper>
          {value.balanceSettled}
          <ViewButton onClick={() => onViewClickHandler(value.key)}>View</ViewButton>
        </ColumnWrapper>
      ),
    }
  ];

  return (
    <CustomTableStyled
      columns={tableConfig}
      pagination={false}
      dataSource={tableData}
    />
  );
}

const ViewButton = styled(ButtonDark)`
  padding: 8px 36px;
`;

const BlueText: FC<any> = styled.span`
  color: #2682DE;
  cursor: pointer;
`;

const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomTableStyled: FC<TableProps<any>> = styled(TableStyled)`
  .ant-table-thead th {
    color: #6b7a87;
    font-weight: 600;
    font-size: 14px;
  }

  .ant-table-column-sorters {
    width: 45px;
  }

  .ant-table-column-sorter-inner {
    color: #6B7A87;
  }

  .ant-table tr {
    font-size: 14px;
    font-weight: 500;
  }
`;


export default BalanceTransfersTable;
