import styled from 'styled-components/macro';
import { DeleteOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { IProduct } from '../../../buyer/types';

interface IProps {
  product: IProduct;
  onRemoveItem?: (id: string) => void;
}

const RelatedProductRowItem: FC<IProps> = ({ product, onRemoveItem }) => {
  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image
            alt=""
            src={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${product.imageUrl}`}
          />
        </ImageContainer>
        <TitleRow>
          <Name>{product.name}</Name>
        </TitleRow>
      </Content>
      <Content>
        <TitleRow>
          <Name>{product.headlines}</Name>
        </TitleRow>
      </Content>
      <Content style={{ justifyContent: 'end', margin: 'auto', fontSize: 25 }}>
        {onRemoveItem && (
          <StyledDelete onClick={() => onRemoveItem(product._id)} />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  padding: 17px 20px;
  border-bottom: 1px solid #d5dce5;
  margin: 0 20px;

  display: flex;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
`;

const Name = styled(Text)`
  font-weight: bold;
  margin-left: 10px;
`;

const ImageContainer = styled.div`
  width: 75px;
  background-color: #fff;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down; ;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDelete = styled(DeleteOutlined)`
  margin-left: 20px;
  color: #de2644;
  cursor: pointer;
`;

export default RelatedProductRowItem;
