import { FC, useCallback } from "react";
import { IServices } from "./types";
import BodyContainer from "../../../../ui/elements/BodyContainer";
import SearchInput from "../../../../ui/Serach/SearchInput";
import AdditionalControls from "../../../../ui/Table/AdditionalControls";
import ModalInPortal from "../../../../ui/Modal/ModalPortal";
import ServiceTable from "./ServiceTable";
import EditService from "./EditServiceContent";
import AddServiceContent from "./AddServiceContent";
import { TableControlWrap } from "../suppliers/suppliers.styled";
import { useCreateServiceMutation, useUpdateServiceMutation } from "../../../../api/services/superadmin/servicesApi";
import EditServiceContent from "./EditServiceContent";
import ROUTER_CONFIG from "../../../../router/router-config";
import { useHistory } from "react-router-dom";

const Services: FC<IServices> = ({
                                   tableData,
                                   searchValue,
                                   setSearchValue,
                                   selectedUsers,
                                   setSelectedUsers,
                                   onDeleteService
                                 }) => {
  const {push} = useHistory();
  const [ updateService ] = useUpdateServiceMutation();
  const [ createService ] = useCreateServiceMutation();
  
  const EditService = ModalInPortal();
  const AddService = ModalInPortal();

  const onRowClickHandler = (serviceId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_SERVICE_VIEW.getPath({serviceId}));
  
  return (
    <BodyContainer title="Services">
      <TableControlWrap>
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder="Service Type"
        />
        <AdditionalControls
          mainActionText="Action"
          subActionText="Remove Service"
          onClickRemove={onDeleteService}
          additionalActionText=" +Add New Service Type"
          onClickAdd={AddService.onShow}
        />
      </TableControlWrap>

      {AddService.ModalRender && (
        <AddService.ModalRender>
          <AddServiceContent
            onClose={AddService.onClose}
            createService={createService}
          />
        </AddService.ModalRender>
      )}

      {EditService.ModalRender && (
        <EditService.ModalRender>
          <EditServiceContent
            onClose={EditService.onClose}
            updateService={updateService}
            selectedUsers={selectedUsers}
          />
        </EditService.ModalRender>
      )}

      <ServiceTable
        tableData={tableData}
        selectedUsers={selectedUsers}
        setSearchValue={setSearchValue}
        setSelectedUsers={setSelectedUsers}
        editService={(serviceId?: string) => EditService.onShow()}
        onRowClickHandler={onRowClickHandler}
        onDeleteService={onDeleteService}/>
    </BodyContainer>
  );
};
export default Services;
