import { Input, InputProps } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const InputStyled: FC<InputProps> = styled(Input)`
  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.textSecondary};
  }

  border: 1px solid #d5dce5;
  border-radius: 4px;
`;
