import { FC, memo } from 'react';
import { Form, DatePicker } from 'antd';

import { ReactComponent as CalendarIcon } from '../../../../img/icons/calendar.svg';

const ExpirationForm: FC = () => {
  const [form] = Form.useForm();

  return (
    <Form form={form} name="expirationForm" wrapperCol={{ span: 8 }}>
      <Form.Item name="expirationDate">
        <DatePicker suffixIcon={<CalendarIcon />} placeholder="(xx.xx.xxxx)" />
      </Form.Item>
    </Form>
  );
};

export default memo(ExpirationForm);
