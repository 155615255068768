import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { DatePickerProps } from 'antd';
import { FormConfig, renderFormConfig } from '../../../../../utils/form';
import {
  StyledDataPicker, StyledInput, StyledSmallInput, StyledSmallSelect,
} from '../styled-form';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/arrowDown.svg';
import { currency } from '../dataForForm';
import { ReactComponent as Calendar } from '../../../../../img/icons/calendar.svg';

interface IFirstEditForm {
  formik: FormikProps<any>,
}

const OfferForm: React.FC<IFirstEditForm> = ({ formik }: IFirstEditForm) => {
  const formConfig: FormConfig = useMemo(() => ([
    {
      title: 'Offer Number',
      name: 'offerNumber',
      component: StyledInput,
      isRequired: true,
    },
    {
      title: 'Start Date',
      name: 'startDate',
      component: StyledDataPicker,
      isRequired: true,
      props: {
        onChange: (value: DatePickerProps) => {
          formik.setFieldValue('startDate', value);
        },
        suffixIcon: <Calendar />,
      },
    },
    {
      title: 'End Date',
      name: 'endDate',
      component: StyledDataPicker,
      isRequired: true,
      props: {
        onChange: (value: DatePickerProps) => {
          formik.setFieldValue('endDate', value);
        },
        suffixIcon: <Calendar />,
      },
    },
    {
      title: 'Platform Fee',
      name: 'platformFee',
      component: StyledSmallInput,
      isRequired: true,
      props: {
        placeholder: '%',
      },
    },
    {
      title: 'Currency',
      name: 'currency',
      component: StyledSmallSelect,
      props: {
        initialData: currency,
        suffixIcon: <ArrowDown />,
      },
      isRequired: true,
    },
  ]), [formik]);

  return (
    <>
      {renderFormConfig(formConfig, formik)}
    </>
  );
};

export default OfferForm;
