import { Select } from 'antd';
import { CSSProperties, ReactElement, ReactNode } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { StyledSelect } from './SelectInput';

const { Option } = Select;

interface SelectInputProps {
  setFieldValue: (field: string, value: string) => void;
  initialData: Array<string> | Array<any>;
  name: string;
  value: string;
  className?: string;
  placeholder?: string;
  dropdownStyle?: CSSProperties;
  disabled?: boolean;
  size?: SizeType;
  suffixIcon?: ReactNode | ReactElement;
  isAllowClear?: boolean;
}

export default function CustomSelectInput({
  setFieldValue,
  name,
  value,
  className,
  initialData,
  placeholder,
  dropdownStyle,
  disabled,
  size,
  suffixIcon,
  isAllowClear = false,
}: SelectInputProps) {
  return (
    <StyledSelect
      allowClear={isAllowClear}
      dropdownStyle={dropdownStyle}
      placeholder={placeholder}
      className={className}
      value={value}
      disabled={disabled}
      size={size}
      suffixIcon={suffixIcon}
      onChange={(v: any) => setFieldValue(name, v as string)}
    >
      {initialData.map((d) => (
        <Option key={Math.random()} value={d}>
          {d}
        </Option>
      ))}
    </StyledSelect>
  );
}
