import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import LayoutV1 from '../../../layout/LayoutV1';
import Title from '../../shared/header/Title';
import { RedBigButton } from '../styled/buttons';
import OfferDescription from './offers-details/OfferDescription';
import LeaseInsuranceTables from './offers-details/LeaseInsuranceTables';
import OverviewCard from './offers-details/OverviewCard';
import EligibleWrapper from './offers-details/EligibleWrapper';
import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';
import { useDeleteOfferMutation } from '../../../../api/services/superadmin/offersApi';
import ROUTER_CONFIG from '../../../../router/router-config';

const OfferDetailsPage: React.FC = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const [deleteOffer, { isSuccess }] = useDeleteOfferMutation();

  const history = useHistory();
  const onBack = useCallback(
    () => history.goBack(),
    [history],
  );

  const handleClickOffer = async () => {
    await deleteOffer(offerId);
  };

  useEffect(() => {
    if (isSuccess) {
      history.push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.getPath());
    }
  }, [isSuccess, history]);

  return (
    <LayoutV1>
      <Container>
        <GoBack onClick={onBack}>
          <BackIcon />
        </GoBack>
        <Title style={{ margin: '0 0 60px 0' }}>Offer Details</Title>
        <Row style={{ marginBottom: '74px' }}>
          <Col span={14}>
            <OfferDescription />
            <EligibleWrapper />
          </Col>
          <Col offset={2} span={8}>
            <OverviewCard title="OFFER PREVIEW" />
          </Col>
        </Row>
        <Row>
          <LeaseInsuranceTables />
        </Row>
        <RedBigButton onClick={handleClickOffer}>Delete Offer</RedBigButton>
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div`
  position: relative;
`;

const GoBack = styled.div`
  position: absolute;
  left: -53px;
  top: 2px;
  cursor: pointer;
`;

export default OfferDetailsPage;
