import { FormConfig } from "../../../../../utils/form";
import { DonationAmout } from "../../../../school/campaign/create-campaign/DonationAmout";
import { InputStyled, InputUrlStyled, StyledSelectInput } from "../suppliers.styled";
import AddUserToTable from "./AddUserTable";
import { StyledLongSelect } from "../../../new-offer/create-new-offer/styled/new-offer.styled";
import { ReactComponent as ArrowDown } from "../../../../../img/icons/filled-arrow-down.svg";
import React from "react";
import { StyledSelect } from "../../styled/select";

export const organisationDetails: FormConfig = [
  {
    title: 'Name',
    name: 'name',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Address Line 1',
    name: 'addressLine1',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Address Line 2',
    name: 'addressLine2',
    component: InputStyled,
    isRequired: false,
  },
  {
    title: 'Address Line 3',
    name: 'addressLine3',
    component: InputStyled,
    isRequired: false,
  },
  {
    title: 'Town',
    name: 'town',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Country',
    name: 'country',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Telephone Number',
    name: 'telephoneNumber',
    component: InputStyled,
    isRequired: false,
  },
  {
    title: 'Contact Email',
    name: 'contactEmail',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Website',
    name: 'website',
    component: InputStyled,
    isRequired: false,
  },
  {
    title: 'VAT Number',
    name: 'VATNumber',
    component: InputStyled,
    isRequired: true,
  },
  {
    title: 'Company Number',
    name: 'companyNumber',
    component: InputStyled,
    isRequired: true,
  },
];

export const formConfig: FormConfig = [
  {
    title: 'Organisation Type',
    name: 'organisationType',
    component: StyledSelectInput,
    isRequired: true,
    props: {
      initialData: [ 'Reseller', 'Service', 'Support' ],
      suffixIcon: <ArrowDown/>,
      dropdownStyle: {zIndex: 999999},
    },
  },
];

export const addUsers: FormConfig = [
  {
    name: 'accessories',
    isRequired: false,
    component: AddUserToTable,
  }
];

export const deliveryChanges: FormConfig = [
  {
    title: 'Default Delivery Charge Amount',
    name: 'defaultDeliveryAmount',
    isRequired: false,
    component: DonationAmout,
  }
];

export const defaultPaymentTerms: FormConfig = [
  {
    title: 'Default Payment Terms',
    isRequired: false,
    name: 'defaultPaymentTerms',
    component: StyledSelectInput,
    props: {
      initialData: [ '30 day after the invoice date', 'In voice day' ],
      suffixIcon: <ArrowDown/>,
    },
  },
];

export const termsConditions: FormConfig = [
  {
    title: 'URL',
    name: 'termsConditionsURL',
    component: InputUrlStyled,
    isRequired: false,
  },
];

export const accountsReceivables: FormConfig = [
  {
    title: 'Account Name',
    name: 'accountName',
    component: InputStyled,
    isRequired: false,
  },
  {
    title: 'Account Number',
    name: 'accountNumber',
    component: InputStyled,
    isRequired: false,
  },
  {
    title: 'Sort Code',
    name: 'sortCode',
    component: InputStyled,
    isRequired: false,
  }
];