import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { ButtonDark } from '../../../../../../ui/Button';
import { PreferredRessellerForm } from '../config/configForm';
import { renderFormConfig } from '../../../../../../utils/form';

const PreferredResseller = () => {
    const formValidationResseller = useFormik({
        initialValues: {},
        onSubmit: (values) => console.log(values),
      });

    return(
        <Container onSubmit={formValidationResseller.handleSubmit}>
            {renderFormConfig(PreferredRessellerForm, formValidationResseller)}
            <StyledButtonDark type="submit">Save</StyledButtonDark>
        </Container>
    )
}

const Container = styled.form`
  margin-bottom: 170px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 88px;
`;

export default PreferredResseller