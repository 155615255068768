import styled from 'styled-components/macro';
import { DeleteOutlined } from '@ant-design/icons';
import { IAccessory } from '../../../buyer/types';
import { FC } from 'react';

interface IProps {
  accessory: IAccessory;
  onRemoveItem?: (id: string) => void;
}

const AccessoryRowItem: FC<IProps> = ({ accessory, onRemoveItem }) => {
  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image
            alt=""
            src={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${accessory.imageUrl}`}
          />
        </ImageContainer>
        <TitleRow>
          <Name>{accessory.name}</Name>
        </TitleRow>
      </Content>
      <Content>
        <TitleRow>
          <Name>{accessory.description}</Name>
        </TitleRow>
      </Content>
      <Content style={{ justifyContent: 'end', margin: 'auto', fontSize: 25 }}>
        {onRemoveItem && (
          <StyledDelete onClick={() => onRemoveItem(accessory._id)} />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  padding: 17px 10px;
  border-bottom: 1px solid #d5dce5;
  margin: 0 20px;

  display: flex;
  justify-content: space-between;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
`;

const Name = styled(Text)`
  font-weight: bold;
`;

const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff;
  margin-right: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDelete = styled(DeleteOutlined)`
  margin-left: 20px;
  color: #de2644;
  cursor: pointer;
`;

export default AccessoryRowItem;
