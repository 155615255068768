import styled from "styled-components/macro";
import { ButtonDark } from "../../../../ui/Button";
import Input from "../../../../ui/forms/Input";
import SelectInput from "../../../../ui/forms/SelectInput";
import { Select } from "antd";

export const UserTableContainer = styled.div`
  width: 90%;
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 0 25px 0;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ButtonBlock = styled.div`
  display: flex;
  gap: 30px;
`;

export const StyledButtonDark = styled(ButtonDark)`
  padding: 15px 40px;
`;

export const TableControlWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FormStyled = styled.form``;

export const ButtonsGroup = styled.div`
  margin: 45px 0;
`;

export const SubmitStyled = styled(ButtonDark)`
  padding: 13px 50px;
`;

export const InputsGroup = styled.div``;

export const HeaderBlock = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
  align-items: center;
`;

export const Header = styled.h2`
  font-size: 20px;
  margin: 0;
  font-weight: bold;
`;

export const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;
  height: 41px;
`;

export const InputUrlStyled = styled(InputStyled)`
`;

export const SupplierWrap = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
`;

export const HeaderContent = styled.div`
  display: flex;
`;

export const FormContent = styled.div`
  width: 100%;
  padding: 0 0 0 70px;
`;

export const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 30px;
  padding: 0 0 0 20px;
`;

export const StyledSelectInput = styled(SelectInput)`
  width: 100%;
  max-width: 424px;
`;

export const ViewButton = styled(ButtonDark)`
  padding: 8px 36px;
  margin: 0 0 10px 0;
`;

export const Group = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 555px;
  align-items: center;
`;

export const UrlButton = styled(ButtonDark)`
  width: 136px;
  height: 40px;
`

export const StyledSelect = styled(Select)`
  width: 200px;
  
  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;