import styled from 'styled-components/macro';
import React from 'react';
import { ReactComponent as Camera } from '../../../../../img/icons/camera.svg';

const UploadButton: React.FC = () => (
  <Wrapper>
    <Camera style={{
      position: 'absolute',
      bottom: '15px',
      left: '8px',
    }}
    />
    <Title> Browse</Title>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative
`;

const Title = styled.div`
  margin-top: 20px;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
`;

export default UploadButton;
