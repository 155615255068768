import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row, Space } from 'antd';
import {
  SubTitle,
  StyledButtonDark,
  TableWrapper,
} from '../../../../new-offer/create-new-offer/styled/new-offer.styled';
import AddOfferTable from './add-offer-table/AddOfferTable';
import { useGetOfferListQuery } from '../../../../../../api/services/superadmin/offersApi';
import { IOfferTableItem } from '../types';

const AddOfferSection: FC = () => {
  const { data: offers } = useGetOfferListQuery();
  const [offersTableData, setOffersTableData] = useState<any>([])
  const [mapedOffersTableData, setMapedOffersTableData] = useState<any>([])
  const [offerAddCandidate, setOfferAddCandidate] = useState<IOfferTableItem>({} as IOfferTableItem)

  const onAddOffer = () => {
    const newData = {
      key: Date.now(),
      sku: '',
    }
    setOffersTableData((prev: any) => [...prev, newData])
  };

  const onDeleteOffer =  useCallback((key: any) => {
    setOffersTableData((prev: any) => prev.filter((item: any) => item.key !== key))
  }, []);

  useEffect(() => {
    const mapedOffersTableData = offers?.map(_offer => ({
      offerNumber: _offer.offerNumber,
      sku: _offer.product.SKU,
      oem: _offer.product.manufacturer,
      startDate: _offer.startDate,
      endDate: _offer.stockAvailableDate,
      product: _offer.product.name,
      _id: _offer._id
    }))

    setMapedOffersTableData(mapedOffersTableData)

  }, [offers])

  const onAdd = useCallback((row: any) => {
    const newTableData = offersTableData.map((tableRow: any) => tableRow.key === row.key ? ({...tableRow, ...offerAddCandidate}) : tableRow)
    setOffersTableData(newTableData)
  }, [offersTableData]);

  const onSkuChange = useCallback((sku: string) => {
    const offerAddCandidate = mapedOffersTableData.find((_offer: any) => _offer.sku! === sku)
    if(offerAddCandidate) {
      setOfferAddCandidate(offerAddCandidate)
    }
  }, [mapedOffersTableData]);

  return (
    <Row
      style={{ marginBottom: '54px' }}
    >
      <Col>
        <Col
          style={{ marginBottom:'31px'}}
        >
          <SubTitle>2. Add Offers</SubTitle>
        </Col>
        <Space
          style={{ width: '100%' }}
        >
          <StyledButtonDark
            onClick={onAddOffer}
            style={{marginRight: 35}}
          >
            + Add Offer
          </StyledButtonDark>
        </Space>
      </Col>
      <TableWrapper>
        <AddOfferTable
          onSkuChange={onSkuChange}
          onAdd={onAdd}
          mapedData={mapedOffersTableData!}
          dataSource={offersTableData}
          onDelete={onDeleteOffer}
        >
        </AddOfferTable>
      </TableWrapper>
    </Row>
  );
};

  export default AddOfferSection;
