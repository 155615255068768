import styled from 'styled-components/macro';
import {
  Dropdown, Menu, Space, Switch,
} from 'antd';
import React, { useMemo } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import { DetailsButton } from '../../../styled/buttons';

interface UsersTableControlsProps {
  onDeleteRowClickHandler: () => void;
  onAddClickHandler: () => void;
  setShowTable: (isChecked: boolean) => void;
  isShowingTable: boolean;
  addButtonName: string;
  isSwitch?: boolean
}

const EligibleControls: React.FC<UsersTableControlsProps> = ({
  onDeleteRowClickHandler,
  onAddClickHandler,
  setShowTable,
  isShowingTable,
  addButtonName,
  isSwitch = true,
}) => {
  const dropdownContent = useMemo(() => (
    <Menu>
      <Menu.Item onClick={onDeleteRowClickHandler}>
        Delete
      </Menu.Item>
    </Menu>
  ), [onDeleteRowClickHandler]);

  return (
    <Container>
      <Space
        align="start"
        style={{
          fontSize: '12px',
          alignItems: 'center',
          columnGap: 3,
          visibility: isSwitch ? 'visible' : 'hidden',
        }}
      >
        <Switch
          className={isShowingTable ? 'checkedSwitch' : 'unCheckedSwitch'}
          checked={isShowingTable}
          onChange={setShowTable}
          size="small"
        />
        {' '}
        Make eligible on all nodes
      </Space>
      <WrapperEndAction>
        <StyledDetailsButton onClick={onAddClickHandler}>{addButtonName}</StyledDetailsButton>
        <Dropdown overlay={dropdownContent}>
          <StyledDetailsButton onClick={(e) => e.preventDefault()}>
            Actions
            {' '}
            <ArrowDown style={{ marginBottom: '2px' }} />
          </StyledDetailsButton>
        </Dropdown>
      </WrapperEndAction>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 5px;

  .ant-switch-small {
    height: 23px;
    width: 39px;
    line-height: 23px;
  }

  .checkedSwitch {
    .ant-switch-handle {
      left: calc(100% - 18px - 2px);
    }
  }

  .uncheckedSwitch {
    .ant-switch-handle {
      left: calc(100% - 18px - 10px);
    }
  }

  .ant-switch-handle {
    width: 18px;
    height: 18px;
  }
`;

const WrapperEndAction = styled.div`
`;

const StyledDetailsButton = styled(DetailsButton)`
  margin-left: 30px;
`;

export default EligibleControls;
