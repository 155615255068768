import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import InfoTable from '../../../ui/elements/InfoTable';
import { serializeStatisticsPrice } from '../../../utils/formats';
import OrdersTable from './OrdersTable';
import { IOrdersOverviewHomePage } from '../types';
import { useGetSupplierDashboardListQuery } from '../../../api/services/superadmin/supplierDashboard'

const { TabPane } = Tabs;

interface OverviewProps {
  tableData: Array<IOrdersOverviewHomePage>;
  onReviewClickHandler: (id: string, paymentMethod: string) => void;
  onViewOffersClickHandler: () => void;
  onViewOrdersClickHandler: () => void;
}

export default function Overview({
  tableData, onReviewClickHandler, onViewOffersClickHandler, onViewOrdersClickHandler,
}: OverviewProps) {
  const { data } = useGetSupplierDashboardListQuery()

  const overviewStatData = [
    {
      title: 'Total earnings',
      value: serializeStatisticsPrice(data?.totalEarnings),
      link: 'View Orders',
      onLinkClickHandler: onViewOrdersClickHandler,
    },
    {
      title: 'Units sold',
      value: `${data?.totalSold}`,
      link: 'View Orders',
      onLinkClickHandler: onViewOrdersClickHandler,
    },
    {
      title: 'Active joined offers',
      value: `${data?.activeOffers}`,
      link: 'View Offers',
      onLinkClickHandler: onViewOffersClickHandler,
    },
  ];

  return (
    <Container>
      <Title>Overview</Title>
      <StyledInfoTable data={overviewStatData} />
      <Tabs>
        <StyledTabPane tab="Orders Requiring Action">
          <OrdersTable tableData={tableData} onReviewClickHandler={onReviewClickHandler} />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #071730;
  font-weight: bold;
`;

const StyledInfoTable = styled(InfoTable)`
  margin: 40px 0;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
