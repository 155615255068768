import { useCallback, useMemo, useState } from 'react';
import { ORDER_STATUS } from '../components/superadmin/types';

export const useStatusCard = (_status?: ORDER_STATUS) => {
  const [isShowStatusHistoryOpen, setIsShowStatusHistoryOpen] = useState(false);
  const [isStatusEdit, setIsStatusEdit] = useState(false);
  const [status, setStatus] = useState<ORDER_STATUS | null>(_status || null);

  const onShowStatusHistoryClick = useCallback(() => {
    setIsShowStatusHistoryOpen(!isShowStatusHistoryOpen);
  }, [isShowStatusHistoryOpen]);

  const onStatusEditClick = useCallback(() => {
    setIsStatusEdit(true);
  }, []);

  const onStatusUpdateClick = useCallback(() => {
    setIsStatusEdit(false);
  }, []);

  const onStatusChange = useCallback((orderStatus: ORDER_STATUS) => {
    return setStatus(orderStatus);
  }, []);

  const values = useMemo(
    () => ({
      isShowStatusHistoryOpen,
      isStatusEdit,
      status,
      onShowStatusHistoryClick,
      onStatusEditClick,
      onStatusUpdateClick,
      onStatusChange,
    }),
    [isShowStatusHistoryOpen, isStatusEdit, onShowStatusHistoryClick]
  );

  return values;
};
