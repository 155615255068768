import { FC, useMemo } from 'react';
import { Space, Row, Col } from 'antd';
import { ReactComponent as MockLaptop } from '../../../../../img/mockLaptop.svg';
import {
  StyledText,
  SubTitle,
  CardRowContainer,
  CardRowIcon,
  CardRowWrapper,
  CardTitle,
  CardContainer,
} from '../styled/new-offer.styled';
import { NewOffer } from '../../../types';
import { offerPreviewMock } from './offerPreview.mock';
import dayjs from 'dayjs';

interface IOfferPreviewProps {
  title: string;
  offers: Array<NewOffer>;
}

const OfferPreviewComponent: FC<IOfferPreviewProps> = ({
  title,
  offers,
}) => {
 
  const transformData: (string | number)[] = useMemo(() => {
    if (!offers) {
      return [];
    }
    return [
      offers[0].product.SKU,
      offers[0].product.manufacturer,
      offers[0].offerNumber,
      offers[0].quantity,
      `£${offers[0].indicativeBuyerPrice}`,
      `£${offers[0].nte}`,
      dayjs(offers[0].startDate).format('DD/MM/YY'),
      dayjs(offers[0].stockAvailableDate).format('DD/MM/YY')
    ];
  }, [offers]);

  const updateData = useMemo(() => offerPreviewMock.map((item, index) => ({
    ...item,
    value: transformData[index],
  })), [transformData]);

  return (
    <Row
      style={{ marginBottom: 100 }}
    >
      <Col
        span={8}
      >
        <SubTitle
          style={{ marginBottom: '40px' }}
        >
          {title}
        </SubTitle>
        <Space
          direction="vertical"
          style={{ paddingLeft: '20px' }}
        >
          <MockLaptop />
          <SubTitle>{offers[0].product?.name}</SubTitle>
        </Space>
        <CardContainer>
          <CardTitle>{title}</CardTitle>
          {updateData.map((item) => (
            <CardRowContainer key={item.key}>
              <CardRowWrapper>
                <CardRowIcon>{item.image}</CardRowIcon>
                <StyledText
                  style={{ fontWeight: 500 }}
                >
                  {item.title}
                </StyledText>
              </CardRowWrapper>
              <StyledText style={item.title === 'Indicative Reseller Buy Price' || item.title === 'NTE' ? { fontWeight: 600 } : {}}>
                {item.value}
              </StyledText>
            </CardRowContainer>
          ))}
        </CardContainer>
      </Col>
    </Row>
  );
};

export default OfferPreviewComponent;