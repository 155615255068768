import { FC, memo } from 'react';
import { Form, Checkbox } from 'antd';

import { TextBody } from '../typography/typography.styled';

const InsuranceForm: FC = () => {
  const [form] = Form.useForm();

  return (
    <Form form={form} name="insuranceForm" wrapperCol={{ span: 14 }}>
      <Form.Item name="isInsurance" valuePropName="checked">
        <Checkbox>
          <TextBody>
            The financing partner requires a school to insure the devices. Would
            the customer like to include an insurance quote?
            <TextBody strong> Tick if yes</TextBody>
          </TextBody>
        </Checkbox>
      </Form.Item>
    </Form>
  );
};

export default memo(InsuranceForm);
