import { useCallback, useState } from 'react';

type OrderItemId = number;

export const useOrdersItems = () => {
  const [selectedItems, setSelectedItems] = useState(() => new Set([1]));

  const changeItemSelectStatus = useCallback((id: OrderItemId) => {
    return setSelectedItems((prev) => {
      const newSelectedItems = new Set(prev);

      newSelectedItems.clear();

      newSelectedItems.add(id);

      return newSelectedItems;
    });
  }, []);

  const checkItem = useCallback(
    (id: OrderItemId) => {
      if (selectedItems.has(id)) return;

      changeItemSelectStatus(id);
    },
    [selectedItems]
  );

  return {
    selectedItems,
    checkItem,
  };
};
