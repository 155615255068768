import { FC } from "react";
import { Dropdown, Menu } from "antd";
import { CustomTableControlsButton, CustomTableControlsContainer } from "../../../../ui/Table/table.styled";
import { ReactComponent as ChevronDown } from "../../../../img/icons/chevron-down.svg";

export interface SupplierTableProps {
  isPagination?: boolean;
  onDeleteSupplier: () => void;
}

const SupplierTableControls: FC<SupplierTableProps> = ({onDeleteSupplier}) => {
  const dropdownContent = (
    <Menu key="1">
      <Menu.Item onClick={onDeleteSupplier}>
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <CustomTableControlsContainer>
      <Dropdown overlay={dropdownContent}>
        <CustomTableControlsButton onClick={(e) => e.preventDefault()}>
          Actions
          <ChevronDown />
        </CustomTableControlsButton>
      </Dropdown>
    </CustomTableControlsContainer>
  );
};

export default SupplierTableControls;