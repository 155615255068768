import { superadminApi } from './superadminApi';
import { Buyers } from '../../../components/superadmin/types';

const buyersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getBuyersList: build.query<Array<Buyers>, void>({
      query: () => 'buyer',
    }),
    getSearchBuyersList: build.query<Buyers[], { school: string }>({
      query: (body) => ({
        url: 'buyer/searchBuyer',
        method: 'GET',
        params: body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetBuyersListQuery, useGetSearchBuyersListQuery } = buyersApi;
