import styled from 'styled-components/macro';

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

// Primary button color: #2682DE
// On-click button color: #2173C4
// box-shadow: 0px 4px 6px #32475C1C;
// hover: box-shadow: 0px 6px 6px #32475C1C;

function ButtonDefault(props: ButtonProps) {
  return (<button {...props} />);
}

export const Button = styled(ButtonDefault)`
  &:active {
    background: #2173C4;
    box-shadow: 0px 6px 6px #32475C1C;
  }
`;

export const ButtonDark = styled(Button)`
  background: #2682DE;
  box-shadow: 0px 4px 6px #32475C1C;
  border-radius: 2px;
  border: none;
  box-sizing: border-box;
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  color: #FCFDFF;
  cursor: pointer;
`;

export const ButtonDarkBlack = styled(ButtonDark)`
  font-weight: 600;
  padding: 11px 38px;
`;

export const ButtonLight = styled(ButtonDark)`
  border: 1px solid #2682DE;
  background: #FFFFFF;
  box-sizing: border-box;
  color: #2682DE;

  &:active {
    background: #2682DE;
    box-shadow: 0px 6px 6px #32475C1C;
    color: #fff;
  }
`;

export const ButtonDanger = styled(ButtonLight)`
  border: 1px solid #DE2644;
  color: #DE2644;

  &:active {
    background: #DE2644;
    box-shadow: 0px 6px 6px #32475C1C;
    color: #fff;
  }
`;

export const ButtonLightGray = styled(ButtonLight)`
  padding: 11px 28px;
`;
