import { Tag, TagProps } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const TagStyled: FC<TagProps> = styled(Tag)`
  font-size: 14px;
  font-weight: 700;

  border: none !important;

  padding: 10px;
`;
