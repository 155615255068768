import { useGetSupplierOffersIDQuery } from "../../../../api/services/superadmin/suppliersApi";
import { useParams } from "react-router";

const SupplierOffersView = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const {data: getSupplierOffersID, status} = useGetSupplierOffersIDQuery(offerId);
  return (
    <>
    Server status {getSupplierOffersID ? getSupplierOffersID : status}
    </>
  );
};

export default SupplierOffersView;