import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { renderFormConfig } from '../../../../../utils/form';
import { SuppliersApiType } from "../../../types";
import {
  ButtonsGroup,
  HeaderBlock,
  SubmitStyled,
  Header,
  FormStyled,
  InputsGroup, Group, UrlButton
} from "../suppliers.styled";
import { suppliersSchemas } from "../suppliers-schemas";
import { useParams } from "react-router-dom";
import { useCreateSupplierMutation, useGetSupplierQuery } from "../../../../../api/services/superadmin/suppliersApi";
import {
  accountsReceivables, addUsers, defaultPaymentTerms,
  deliveryChanges, formConfig,
  organisationDetails,
  termsConditions
} from "./form.config";
import AddServiceToTable from "./AddServiceTable";
import ImageUploader from "../../../../../ui/ImageUploader/ImageUploader";
import Currency from "../../../../../ui/Currency/Currency";

const CreateCampaignForm: FC<any> = () => {
  const {id} = useParams<{ id: string }>();
  const [ state, setState ] = useState({logo: ""});
  
  const [ createSupplier, {isSuccess} ] = useCreateSupplierMutation();
  const {data: supplier} = useGetSupplierQuery(id);
  
  const formik = useFormik<SuppliersApiType>({
    initialValues: {
      _id: "",
      logo: "",
      name: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      town: "",
      country: "",
      postcode: "",
      telephoneNumber: "",
      contactEmail: "",
      website: "",
      VATNumber: "",
      companyNumber: 0,
      organisationType: "",
      defaultDeliveryAmount: 0,
      defaultPaymentTerms: "",
      currency: "",
      termsConditionsURL: "",
      accountName: "",
      accountNumber: "",
      sortCode: "",
      deliveryPrice: 0,
      devicePrice: 0,
      availableServices: [
        {
          key: "1",
          serviceName: "",
          description: "Asset identification and tagging is an essential component of a good asset management strategy. It helps a company’s assets to become quickly identifiable and ties the asset to the overall asset plan.",
          price: 4.0,
          serviceIcon: "",
        },
        {
          key: "2",
          serviceName: "",
          description: "Our Installation Service provides for the basic installation of servers, workstations, desktop systems, notebook PCs, thin clients, storage devices, printers, networking, and software products.",
          price: 0.40,
          serviceIcon: "",
        }
      ],
      accessories: [
        {
          image: "",
          name: "",
          price: 0,
          description: "",
        }
      ],
      resellerImage: "",
    },
    validationSchema: suppliersSchemas,
    onSubmit: (values) => {
      createSupplier(values);
    },
  });
  
  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      <InputsGroup>
        <HeaderBlock>
          <Header>1. Organisation Details</Header>
        </HeaderBlock>
        <ImageUploader state={state} setState={setState} iconText="Logo"/>
        {renderFormConfig(organisationDetails, formik)}
        {renderFormConfig(formConfig, formik)}
      </InputsGroup>
      
      <InputsGroup>
        <HeaderBlock>
          <Header>2. Users</Header>
        </HeaderBlock>
        {renderFormConfig(addUsers, formik)}
      </InputsGroup>
      
      <InputsGroup>
        <HeaderBlock>
          <Header>3. Services</Header>
        </HeaderBlock>
        <AddServiceToTable
          data={formik.values.availableServices}
          setValue={formik.setFieldValue}
          name="availableServices"
        />
      </InputsGroup>
      
      <InputsGroup>
        <HeaderBlock>
          <Header>4. Delivery Charges</Header>
        </HeaderBlock>
        {renderFormConfig(deliveryChanges, formik)}
      </InputsGroup>
      
      <InputsGroup>
        <HeaderBlock>
          <Header>5. Default Payment Terms</Header>
        </HeaderBlock>
        {renderFormConfig(defaultPaymentTerms, formik)}
        <Currency
          data={formik.values.currency}
          setValue={formik.setFieldValue}
        />
      </InputsGroup>
      
      <InputsGroup>
        <HeaderBlock>
          <Header>6. Terms & Conditions</Header>
        </HeaderBlock>
        <Group>
          {renderFormConfig(termsConditions, formik)}
          <UrlButton> Add URL</UrlButton>
        </Group>
      </InputsGroup>
      
      <InputsGroup>
        <HeaderBlock>
          <Header>7. Accounts Receivables</Header>
        </HeaderBlock>
        {renderFormConfig(accountsReceivables, formik)}
      </InputsGroup>
      
      <ButtonsGroup>
        <SubmitStyled type="submit">Create Account</SubmitStyled>
      </ButtonsGroup>
    </FormStyled>
  );
};

export default CreateCampaignForm;