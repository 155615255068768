import CampaignInfo from '../components/school/campaign/campaign-folder/CampaignInfoPage';
import CreateCampaignPage from '../components/school/campaign/create-campaign/CreateCampaignPage';
import CreateSchoolPage from '../components/school/create-school/CreateSchoolPage';
import PendingPage from '../components/school/pending/pending-verification/PendingPage';
import PendingPageVerified from '../components/school/pending/pending-verified/PendingPageVerified';
import LoginSchoolPage from '../components/school/school-login/LoginSchoolPage';
import DonorPaymentPreviewPage from '../components/donor/payment/preview/DonorPaymentPreviewPage';
import DonorInviteAcceptPage from '../components/donor/invite/accept/DonorInviteAcceptPage';
import WelcopmePage from '../components/welcome/WelcomePage';
import InvitePage from '../components/school/invite-donors/InvitePage';
import FundraisingOverviewPage from '../components/school/campaign/fundraising-overview/FundraisingOverviewPage';
import PayoutPage from '../components/school/campaign/payouts/PayoutPage';
import SchoolSettings from '../components/school/school-settings/SchoolSettingsPage';
import FinishPage from '../components/donor/sign-up/finish/FinishPage';
import CreateDonorPage from '../components/donor/invite/create-donor/CreateDonorPage';
import DonorLoginPage from '../components/donor/login/DonorLoginPage';
import ThanksPage from '../components/donor/sign-up/thanks/ThanksPage';
import DonorPagesPage from '../components/donor/layout/DirectPay/DirectPayPage';
import DonorDashboard from '../components/donor/dashboard/DonorDashboardPage';
import DonorSettingsPage from '../components/donor/donor-settings/DonorSettingsPage';
import DonorBillingInformationPage from '../components/donor/billing-information/BillingInformationPage';
import DonorChangeEmailPage from '../components/donor/change-email/ChangeEmailPage';
import DonorChangePasswordPage from '../components/donor/change-password/ChangePasswordPage';
import DonorCompletePage from '../components/donor/complete/DonorCompletePage';
import PrivacyPage from '../components/donor/sign-up/privacy/PrivacyPage';
import AgreementPage from '../components/donor/sign-up/agreement/AgreementPage';
import AdminDocuments from '../components/admin/documents/DocumentsPage';
import AdminNewSchoolAccountPage from '../components/admin/new-school-account/NewSchoolAccountPage';
import AdminDonorsPage from '../components/admin/donors/DonorsPage';
import AdminSchoolsPage from '../components/admin/schools/SchoolsPage';
import AdminSchoolPage from '../components/admin/school-page/SchoolPage';
import AdminAllCollections from '../components/admin/all-collections/AllCollectionsPage';
import AdminSettingsPage from '../components/admin/settings/SettingsPage';
import AdminDonorPage from '../components/admin/donor/DonorPage';
import AdminPayoutsPage from '../components/admin/payouts/PayoutsPage';
import AdminMakePayoutPage from '../components/admin/make-payout/MakePayoutPage';
import AdminLoginPage from '../components/admin/login/AdminLoginPage';
import ProductsPage from '../components/superadmin/products/ProductsPage';
import ForgotPasswordPage from '../components/forgot-password/ForgotPasswordPage';
import ForgotPasswordCompletePage from '../components/forgot-password/ForgotPasswordCompletePage';
import AdminCreateCampaignPage from '../components/admin/create-campaign/AdminCreateCampaignPage';
import CampaignsListPage from '../components/school/campaign/campaigns-list/CampaignsListPage';
import SchemeSupportSettingsPage from '../components/school/scheme-support-settings/SchemeSupportSettingsPage';
import DeviceTradeinPage from '../components/school/device-tradein/overview-page/DeviceTradeinPage';
import DeviceTradeinDetailsPage from '../components/school/device-tradein/details-page/DeviceTradeinDetailsPage';
import RegisterDeviceTradeinPage from '../components/donor/register-device-tradein/RegisterDeviceTradeinPage';
import RegisterDeviceTradeinFormPage from '../components/donor/register-device-tradein/form-page/RegisterDeviceTradeinFormPage';
import CharitiesOverviewPage from '../components/admin/charities-folder/charities-overview/CharitiesOverviewPage';
import CreateCharityPage from '../components/admin/charities-folder/create-charity/CreateCharityPage';
import CharityPage from '../components/admin/charities-folder/charity/CharityPage';
import DonorDeviceTradeinPage from '../components/donor/device-tradein/overview-page/DonorDeviceTradeinPage';
import DonorDeviceTradeinDetailsPage from '../components/donor/device-tradein/details-page/DonorDeviceTradeinDetailsPage';
import OverviewPage from '../components/supplier/overview/OverviewPage';
import OffersPage from '../components/supplier/offers/offers-overview/OffersPage';
import ViewOfferPage from '../components/supplier/offers/view-offer/ViewOfferPage';
import EditOfferPage from '../components/supplier/offers/edit-offer/EditOfferPage';
import JoinOfferPage from '../components/supplier/offers/join-offer/JoinOfferPage';
import OrdersOverviewPage from '../components/supplier/orders/orders-overview/OrdersOverviewPage';
import OrderDetailsPage from '../components/supplier/orders/order-details/OrderDetailsPage';
import PaymentsOverviewPage from '../components/supplier/payments/payments-overview/PaymentsOverviewPage';
import BalanceTransfersPage from '../components/supplier/payments/balance-transfers/BalanceTransfersPage';
import QuotesOverviewPage from '../components/supplier/quotes/quotes-overview/QuotesOverviewPage';
import BuildQuotePage from '../components/supplier/quotes/build-quote/BuildQuotePage';
import QuoteCreatedPage from '../components/supplier/quotes/quote-created/QuoteCreatedPage';
import SupplierSettingsPage from '../components/supplier/supplier-settings/SupplierSettingsPage';
import BuyerLoginPage from '../components/buyer/buyer-login/BuyerLoginPage';
import BuyerOrdersOverviewPage from '../components/buyer/buyer-orders-overview/BuyerOrdersOverviewPage';
import BuyerOrderDetailsPage from '../components/buyer/buyer-order-details/BuyerOrderDetailsPage';
import BuyerQuotesOverviewPage from '../components/buyer/buyer-quotes-overview/BuyerQuotesOverviewPage';
import BuyerQuoteDetailsPage from '../components/buyer/buyer-quote-details/BuyerQuoteDetailsPage';
import BuyerSettingsPage from '../components/buyer/buyer-settings/BuyerSettingsPage';
import BuyerRegisterPage from '../components/buyer/buyer-register/BuyerRegisterPage';
import BuyerOverviewPage from '../components/buyer/buyer-overview/BuyerOverviewPage';
import BuyerOrderCreatePage from '../components/buyer/buyer-order-create/BuyerOrderCreatePage';
import BuyerOrderConfigurePage from '../components/buyer/buyer-order-configure/BuyerOrderConfigurePage';
import BuyerOrderCurrentPage from '../components/buyer/buyer-order-current/BuyerOrderCurrentPage';
import BuyerOrderSecureCheckoutFormPage from '../components/buyer/buyer-order-secure-checkout/form-page/BuyerOrderSecureCheckoutFormPage';
import BuyerOrderSubmittedPage from '../components/buyer/buyer-order-submitted/BuyerOrderSubmittedPage';
import BuyerOrderSecureCheckoutSubmitPage from '../components/buyer/buyer-order-secure-checkout/submit-page/BuyerOrderSecureCheckoutSubmitPage';
import BuyerVerifyAccountPage from '../components/buyer/buyer-verify-account/BuyerVerifyAccountPage';
import BuyerProductsCategoryPage from '../components/buyer/buyer-products-category/BuyerProductsCategoryPage';
import StaticPage from '../components/static-pages/StaticPage';
import { StaticPagesKeys } from '../components/static-pages/types';
import { QuotesCreatePage } from '../components/superadmin/quotes/quotes-create/QuotesCreatePage';
import OffersHomePage from '../components/superadmin/offers/home/OffersHomePage';
import OffersViewPage from '../components/superadmin/offers/offers/OffersViewPage';
import ServicesPage from '../components/superadmin/offers/services/ServicesPage';
import BuyersPage from '../components/superadmin/offers/buyers/BuyersPage';
import QuotesListPage from '../components/superadmin/quotes/quotes-list/QuotesListPage';
import QuotesCreateSuccessPage from '../components/superadmin/quotes/quotes-create-success/QuoteCreateSuccess';
import NewOfferPage from '../components/superadmin/new-offer/create-new-offer/NewOfferPage';
import InsurersPage from '../components/superadmin/insurers/InsurersPage';
import InsurerDetailsPage from '../components/superadmin/insurers/InsurerDetailsPage';
import InsurerCreatePage from '../components/superadmin/insurers/InsurerCreatePage';
import OfferGroupPage from '../components/superadmin/offers/offer-groups/OfferGroupsPage';
import OfferGroupDetailsPage from '../components/superadmin/offers/offer-groups/OfferGroupDetailsPage';
import OfferGroupEditPage from '../components/superadmin/offers/offer-groups/OfferGroupEditPage';
import ViewProductOfferPage from '../components/superadmin/offers/products/ViewProductOfferPage';
import QuotesDetailPage from '../components/superadmin/quotes/quotes-detail/QuotesDetailPage';
import OrdersListPage from '../components/superadmin/orders/orders-list/OrdersListPage';
import OrdersDetailPage from '../components/superadmin/orders/orders-detail/OrdersDetailPage';
import LessorsPage from '../components/superadmin/lessors/LessorsPage';
import LessorCreatePage from '../components/superadmin/lessors/LessorCreatePage';
import LessorDetailsPage from '../components/superadmin/lessors/LessorDetailsPage';
import OfferDetailsPage from '../components/superadmin/offers/offers/OfferDetailsPage';
import OffersEditPage from '../components/superadmin/offers/offers/OffersEditPage';
import ProductDetailsPage from '../components/superadmin/products/ProductDetailsPage';
import ProductCreatePage from '../components/superadmin/products/ProductCreatePage';
import OrdersCreatePage from '../components/superadmin/orders/orders-create/OrdersCreatePage';
import OrdersEditPage from '../components/superadmin/orders/orders-edit/OrdersEditPage';
import OfferGroupCreatePage from '../components/superadmin/offers/offer-groups/offer-groups-create/OfferGroupsCreatePage';
import SuppliersPage from "../components/superadmin/offers/suppliers/SuppliersPage";
import CreateSupplierAccount
  from "../components/superadmin/offers/suppliers/new-supplier-account/CreateSupplierAccount";
import UsersPage from "../components/superadmin/offers/users/UsersPage";
import NodesPage from "../components/superadmin/offers/nodes/NodesPage";
import PaymentsPage from "../components/superadmin/offers/payments/OffersPeymentsPage";
import OrderViewPage from "../components/superadmin/orders/orders-list/OrderViewPage";
import QuoteViewPage from "../components/superadmin/quotes/quotes-list/QuoteViewPage";
import ServiceViewPage from "../components/superadmin/offers/services/ServiceViewPage";
import SupplierOffersView from "../components/superadmin/offers/suppliers/SupplierOffersView";
import PaymentView from "../components/superadmin/offers/payments/PaymentView";

// : Record<string, RouteProps>

const ROUTER_CONFIG = {
  WELCOME_PAGE: {
    path: '/welcome',
    getPath: () => '/welcome',
    exact: true,
    children: <WelcopmePage />,
  },
  FORGOT_PASSWORD_PAGE: {
    path: '/forgot-password',
    getPath: () => '/forgot-password',
    exact: true,
    children: <ForgotPasswordPage />,
  },
  FORGOT_PASSWORD_COMPLETE_PAGE: {
    path: '/forgot-password/complete',
    getPath: () => '/forgot-password/complete',
    exact: true,
    children: <ForgotPasswordCompletePage />,
  },
  CREATE_SCHOOL_PAGE: {
    path: '/school/sign-up',
    getPath: () => '/school/sign-up',
    exact: true,
    children: <CreateSchoolPage />,
  },
  LOGIN_SCHOOL_PAGE: {
    path: '/school/login',
    getPath: () => '/school/login',
    exact: true,
    children: <LoginSchoolPage />,
  },
  SCHOOL_CAMPAIGNS_LIST: {
    path: '/school/campaigns',
    getPath: () => '/school/campaigns',
    exact: true,
    children: <CampaignsListPage />,
  },
  SCHOOL_CAMPAIGN_INFO: {
    path: '/school/campaign/info/:campaignId',
    getPath: ({ campaignId }: { campaignId: string }) =>
      `/school/campaign/info/${campaignId}`,
    exact: true,
    children: <CampaignInfo />,
  },
  SCHOOL_CAMPAIGN_CREATE: {
    path: '/school/campaign/create',
    getPath: () => '/school/campaign/create',
    exact: true,
    children: <CreateCampaignPage />,
  },
  SCHOOL_SIGNUP_PENDING: {
    path: '/school/sign-up/pending/pending-verification',
    getPath: () => '/school/sign-up/pending/pending-verification',
    exact: true,
    children: <PendingPage />,
  },
  SCHOOL_SIGNUP_PENDING_VERIFIED: {
    path: '/school/sign-up/pending/pending-verified',
    getPath: () => '/school/sign-up/pending/pending-verified',
    exact: true,
    children: <PendingPageVerified />,
  },
  DONOR_PAYMENT_PREVIEW: {
    path: '/donor/payment/preview',
    getPath: () => '/donor/payment/preview',
    exact: true,
    children: <DonorPaymentPreviewPage />,
  },
  SCHOOL_INVITE: {
    path: '/school/capaign/:campaignId/invite-donors',
    getPath: ({ campaignId }: { campaignId: string }) =>
      `/school/capaign/${campaignId}/invite-donors`,
    exact: true,
    children: <InvitePage />,
  },
  SCHOOL_CAMPAIGN_FUNRAISING: {
    path: '/school/capaign/fundraising',
    getPath: () => '/school/capaign/fundraising',
    exact: true,
    children: <FundraisingOverviewPage />,
  },
  SCHOOL_CAMPAIGN_PAYOUTS: {
    path: '/school/campaign/payouts',
    getPath: () => '/school/campaign/payouts',
    exact: true,
    children: <PayoutPage />,
  },
  SCHOOL_SETTINGS: {
    path: '/school/settings',
    getPath: (ref = '') => `/school/settings${ref}`,
    exact: true,
    children: <SchoolSettings />,
  },
  SCHOOL_SCHEME_SUPPORT_SETTINGS: {
    path: '/school/scheme-settings',
    getPath: () => '/school/scheme-settings',
    exact: true,
    children: <SchemeSupportSettingsPage />,
  },
  SCHOOL_DEVICE_TRADEIN: {
    path: '/school/device-tradein',
    getPath: () => '/school/device-tradein',
    exact: true,
    children: <DeviceTradeinPage />,
  },
  SCHOOL_DEVICE_TRADEIN_DETAILS: {
    path: '/school/device-tradein/:campaignId',
    getPath: ({ campaignId }: { campaignId: string }) =>
      `/school/device-tradein/${campaignId}`,
    exact: true,
    children: <DeviceTradeinDetailsPage />,
  },
  DONOR_CREATE_FORM: {
    path: '/donor/create/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/create/${campaignId}/${inviteCode}`,
    exact: true,
    children: <CreateDonorPage />,
  },
  DONOR_SIGNUP_FINISH: {
    path: '/donor/sign-up/finish',
    getPath: () => '/donor/sign-up/finish',
    exact: true,
    children: <FinishPage />,
  },
  DONOR_SIGNUP_THANKS: {
    path: '/donor/sign-up/thanks',
    getPath: () => '/donor/sign-up/thanks',
    exact: true,
    children: <ThanksPage />,
  },
  DONOR_SIGNUP_DIRECTPAY: {
    path: '/donor/sign-up/directpay/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/sign-up/directpay/${campaignId}/${inviteCode}`,
    exact: true,
    children: <DonorPagesPage />,
  },
  DONOR_LOGIN: {
    path: '/donor/login',
    getPath: () => '/donor/login',
    exact: true,
    children: <DonorLoginPage />,
  },
  DONOR_INVITE_ACCEPT: {
    path: '/donor/invite/accept/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/invite/accept/${campaignId}/${inviteCode}`,
    exact: true,
    children: <DonorInviteAcceptPage />,
  },
  DONOR_SIGNUP_PRIVACY: {
    path: '/donor/privacy/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/privacy/${campaignId}/${inviteCode}`,
    exact: true,
    children: <PrivacyPage />,
  },
  DONOR_SIGNUP_AGREEMENT: {
    path: '/donor/agreement/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/agreement/${campaignId}/${inviteCode}`,
    exact: true,
    children: <AgreementPage />,
  },
  DONOR_COMPLETE: {
    path: '/donor/complete',
    getPath: () => '/donor/complete',
    exact: true,
    children: <DonorCompletePage />,
  },
  DONOR_DASHBOARD: {
    path: '/donor/dashboard',
    getPath: () => '/donor/dashboard',
    exact: true,
    children: <DonorDashboard />,
  },
  DONOR_SETTINGS: {
    path: '/donor/settings',
    getPath: () => '/donor/settings',
    exact: true,
    children: <DonorSettingsPage />,
  },
  DONOR_BILLING_INFORMATION: {
    path: '/donor/billing',
    getPath: () => '/donor/billing',
    exact: true,
    children: <DonorBillingInformationPage />,
  },
  DONOR_CHANGE_EMAIL: {
    path: '/donor/:donorId/change-email',
    getPath: ({ donorId }: { donorId: string }) =>
      `/donor/${donorId}/change-email`,
    exact: true,
    children: <DonorChangeEmailPage />,
  },
  DONOR_CHANGE_PASSWORD: {
    path: '/donor/:donorId/change-password',
    getPath: ({ donorId }: { donorId: string }) =>
      `/donor/${donorId}/change-password`,
    exact: true,
    children: <DonorChangePasswordPage />,
  },
  DONOR_DEVICE_TRADEIN: {
    path: '/donor/device-tradein',
    getPath: () => '/donor/device-tradein',
    exact: true,
    children: <DonorDeviceTradeinPage />,
  },
  DONOR_DEVICE_TRADEIN_DETAILS: {
    path: '/donor/device-tradein/:id',
    getPath: ({ id }: { id: string }) => `/donor/device-tradein/${id}`,
    exact: true,
    children: <DonorDeviceTradeinDetailsPage />,
  },
  REGISTER_DEVICE_TRADEIN_PAGE: {
    path: '/register-device-tradein',
    getPath: () => '/register-device-tradein',
    exact: true,
    children: <RegisterDeviceTradeinPage />,
  },
  REGISTER_DEVICE_TRADEIN_FORM: {
    path: '/register-device-tradein-form',
    getPath: () => '/register-device-tradein-form',
    exact: true,
    children: <RegisterDeviceTradeinFormPage />,
  },
  ADMIN_LOGIN: {
    path: '/admin/login',
    getPath: () => '/admin/login',
    exact: true,
    children: <AdminLoginPage />,
  },
  ADMIN_DOCUMENTS: {
    path: '/admin/documents',
    getPath: () => '/admin/documents',
    exact: true,
    children: <AdminDocuments />,
  },
  ADMIN_NEW_SCHOOL_ACCOUNT: {
    path: '/admin/new-school-account',
    getPath: () => '/admin/new-school-account',
    exact: true,
    children: <AdminNewSchoolAccountPage />,
  },
  ADMIN_DONORS: {
    path: '/admin/donors',
    getPath: () => '/admin/donors',
    exact: true,
    children: <AdminDonorsPage />,
  },
  ADMIN_SCHOOLS: {
    path: '/admin/schools',
    getPath: () => '/admin/schools',
    exact: true,
    children: <AdminSchoolsPage />,
  },
  ADMIN_SCHOOL_PAGE: {
    path: '/admin/school/:schoolId',
    getPath: ({ schoolId }: { schoolId: string }) =>
      `/admin/school/${schoolId}`,
    exact: true,
    children: <AdminSchoolPage />,
  },
  ADMIN_ALL_COLLECTIONS: {
    path: '/admin/all-collections',
    getPath: () => '/admin/all-collections',
    exact: true,
    children: <AdminAllCollections />,
  },

  ADMIN_SETTINGS: {
    path: '/admin/settings',
    getPath: () => '/admin/settings',
    exact: true,
    children: <AdminSettingsPage />,
  },
  ADMIN_DONOR: {
    path: '/admin/donor/:donorId',
    getPath: ({ donorId }: { donorId: string }) => `/admin/donor/${donorId}`,
    exact: true,
    children: <AdminDonorPage />,
  },
  ADMIN_PAYOUTS: {
    path: '/admin/payouts',
    getPath: () => '/admin/payouts',
    exact: true,
    children: <AdminPayoutsPage />,
  },
  ADMIN_MAKE_PAYOUT: {
    path: '/admin/make-payout',
    getPath: () => '/admin/make-payout',
    exact: true,
    children: <AdminMakePayoutPage />,
  },
  ADMIN_CREATE_CAMPAIGN: {
    path: '/admin/create-campaign/:schoolId',
    getPath: ({ schoolId }: { schoolId: string }) =>
      `/admin/create-campaign/${schoolId}`,
    exact: true,
    children: <AdminCreateCampaignPage />,
  },
  ADMIN_CHARITIES_OVERVIEW: {
    path: '/admin/charities',
    getPath: () => '/admin/charities',
    exact: true,
    children: <CharitiesOverviewPage />,
  },
  ADMIN_CREATE_CHARITY: {
    path: '/admin/create-charity',
    getPath: () => '/admin/create-charity',
    exact: true,
    children: <CreateCharityPage />,
  },
  ADMIN_CHARITY_VIEW: {
    path: '/admin/charity/:charityId',
    getPath: ({ charityId }: { charityId: string }) =>
      `/admin/charity/${charityId}`,
    exact: true,
    children: <CharityPage />,
  },
  ADMIN_OFFER_GROUP: {
    path: '/admin/offer-group',
    getPath: () => '/admin/offer-group',
    exact: true,
    children: <OfferGroupPage />,
  },
  ADMIN_OFFER_GROUP_DETAILS: {
    path: '/admin/offer-group/:offerGroupId',
    getPath: ({ offerGroupId }: { offerGroupId: string }) =>
      `/admin/offer-group/${offerGroupId}`,
    exact: true,
    children: <OfferGroupDetailsPage />,
  },
  ADMIN_OFFER_GROUP_EDIT: {
    path: '/admin/offer-group/:offerGroupId/edit',
    getPath: ({ offerGroupId }: { offerGroupId: string }) =>
      `/admin/offer-group/${offerGroupId}/edit`,
    exact: true,
    children: <OfferGroupEditPage />,
  },
  SUPPLIER_OVERVIEW: {
    path: '/supplier/overview',
    getPath: () => '/supplier/overview',
    exact: true,
    children: <OverviewPage />,
  },
  SUPPLIER_OFFERS: {
    path: '/supplier/offers',
    getPath: () => '/supplier/offers',
    exact: true,
    children: <OffersPage />,
  },
  SUPPLIER_VIEW_OFFER: {
    path: '/supplier/offer/view/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/supplier/offer/view/${offerId}`,
    exact: true,
    children: <ViewOfferPage />,
  },
  SUPPLIER_EDIT_OFFER: {
    path: '/supplier/offer/edit/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/supplier/offer/edit/${offerId}`,
    exact: true,
    children: <EditOfferPage />,
  },
  SUPPLIER_JOIN_OFFER: {
    path: '/supplier/offer/join/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/supplier/offer/join/${offerId}`,
    exact: true,
    children: <JoinOfferPage />,
  },
  SUPPLIER_ORDERS: {
    path: '/supplier/orders',
    getPath: () => '/supplier/orders',
    exact: true,
    children: <OrdersOverviewPage />,
  },
  SUPPLIER_ORDER_DETAILS: {
    path: '/supplier/order/details/:orderId/:paymentType',
    getPath: ({
      orderId,
      paymentType,
    }: {
      orderId: string;
      paymentType: string;
    }) => `/supplier/order/details/${orderId}/${paymentType}`,
    exact: true,
    children: <OrderDetailsPage />,
  },
  SUPPLIER_PAYMENTS: {
    path: '/supplier/payments',
    getPath: () => '/supplier/payments',
    exact: true,
    children: <PaymentsOverviewPage />,
  },
  SUPPLIER_BALANCE_TRANSFERS: {
    path: '/supplier/balance-transfers',
    getPath: () => '/supplier/balance-transfers',
    exact: true,
    children: <BalanceTransfersPage />,
  },
  SUPPLIER_QUOTES: {
    path: '/supplier/quotes',
    getPath: () => '/supplier/quotes',
    exact: true,
    children: <QuotesOverviewPage />,
  },
  SUPPLIER_BUILD_QUOTE: {
    path: '/supplier/quote/build',
    getPath: () => '/supplier/quote/build',
    exact: true,
    children: <BuildQuotePage />,
  },
  SUPPLIER_QUOTE_CREATED: {
    path: '/supplier/quote/created',
    getPath: () => '/supplier/quote/created',
    exact: true,
    children: <QuoteCreatedPage />,
  },
  SUPPLIER_SETTINGS: {
    path: '/supplier/settings',
    getPath: () => '/supplier/settings',
    exact: true,
    children: <SupplierSettingsPage />,
  },
  BUYER_LOGIN: {
    path: '/buyer/login',
    getPath: () => '/buyer/login',
    exact: true,
    children: <BuyerLoginPage />,
  },
  BUYER_REGISTER: {
    path: '/buyer/register',
    getPath: () => '/buyer/register',
    exact: true,
    children: <BuyerRegisterPage />,
  },
  BUYER_OVERVIEW: {
    path: '/',
    getPath: () => '/',
    exact: true,
    children: <BuyerOverviewPage />,
  },
  BUYER_ORDERS: {
    path: '/buyer/orders',
    getPath: () => '/buyer/orders',
    exact: true,
    children: <BuyerOrdersOverviewPage />,
  },
  BUYER_ORDER_DETAILS: {
    path: '/buyer/order/details/:orderId',
    getPath: ({ orderId }: { orderId: string }) =>
      `/buyer/order/details/${orderId}`,
    exact: true,
    children: <BuyerOrderDetailsPage />,
  },
  BUYER_ORDER_CREATE: {
    path: '/buyer/order/create/:deviceId',
    getPath: ({ deviceId }: { deviceId: string }) =>
      `/buyer/order/create/${deviceId}`,
    exact: true,
    children: <BuyerOrderCreatePage />,
  },
  BUYER_ORDER_CONFIGURE: {
    path: '/buyer/order/configure/:orderId',
    getPath: ({ orderId }: { orderId: string }) =>
      `/buyer/order/configure/${orderId}`,
    exact: true,
    children: <BuyerOrderConfigurePage />,
  },
  BUYER_ORDER_CURRENT: {
    path: '/buyer/order/current',
    getPath: () => '/buyer/order/current',
    exact: true,
    children: <BuyerOrderCurrentPage />,
  },
  BUYER_ORDER_SECURE_CHECKOUT_FORM: {
    path: '/buyer/order/secure-checkout/:orderId/form',
    getPath: ({ orderId }: { orderId: string }) =>
      `/buyer/order/secure-checkout/${orderId}/form`,
    exact: true,
    children: <BuyerOrderSecureCheckoutFormPage />,
  },
  BUYER_ORDER_SECURE_CHECKOUT_SUBMIT: {
    path: '/buyer/order/secure-checkout/:orderId/submit',
    getPath: ({ orderId }: { orderId: string }) =>
      `/buyer/order/secure-checkout/${orderId}/submit`,
    exact: true,
    children: <BuyerOrderSecureCheckoutSubmitPage />,
  },
  BUYER_ORDER_SUBMITTED: {
    path: '/buyer/order/submitted/:orderId',
    getPath: ({ orderId }: { orderId: string }) =>
      `/buyer/order/submitted/${orderId}`,
    exact: true,
    children: <BuyerOrderSubmittedPage />,
  },
  BUYER_QUOTES: {
    path: '/buyer/quotes',
    getPath: () => '/buyer/quotes',
    exact: true,
    children: <BuyerQuotesOverviewPage />,
  },
  BUYER_QUOTE_DETAILS: {
    path: '/buyer/quote/:quoteId',
    getPath: ({ quoteId }: { quoteId: string }) => `/buyer/quote/${quoteId}`,
    exact: true,
    children: <BuyerQuoteDetailsPage />,
  },
  BUYER_SETTINGS: {
    path: '/buyer/settings',
    getPath: () => '/buyer/settings',
    exact: true,
    children: <BuyerSettingsPage />,
  },
  BUYER_VERIFY_ACCOUNT: {
    path: '/buyer/verify',
    getPath: () => '/buyer/verify',
    exact: true,
    children: <BuyerVerifyAccountPage />,
  },
  BUYER_PRODUCTS_CATEGORY: {
    path: '/buyer/products/category/:categoryId',
    getPath: ({ categoryId }: { categoryId: string }) =>
      `/buyer/products/category/${categoryId}`,
    exact: true,
    children: <BuyerProductsCategoryPage />,
  },
  STATIC_PAGE: {
    path: '/static-page/page/:pageName', // путь до страницы
    getPath: ({ pageName }: { pageName: StaticPagesKeys }) =>
      `/static-page/page/${pageName}`,
    exact: true,
    children: <StaticPage />,
  },
  SUPER_ADMIN_NEW_OFFER_PAGE: {
    path: '/super-admin/offers/create',
    getPath: () => '/super-admin/offers/create',
    exact: true,
    children: <NewOfferPage />,
  },
  SUPER_ADMIN_OFFERS_HOME: {
    path: '/super-admin/offers/home',
    getPath: () => '/super-admin/offers/home',
    exact: true,
    children: <OffersHomePage />,
  },
  SUPER_ADMIN_OFFER_GROUP: {
    path: '/super-admin/offer-group',
    getPath: () => '/super-admin/offer-group',
    exact: true,
    children: <OfferGroupPage />,
  },
  SUPER_ADMIN_OFFER_GROUP_CREATE: {
    path: '/super-admin/offer-group/create',
    getPath: () => '/super-admin/offer-group/create',
    exact: true,
    children: <OfferGroupCreatePage />,
  },
  SUPER_ADMIN_OFFER_GROUP_DETAILS: {
    path: '/super-admin/offer-group/:offerGroupId',
    getPath: ({ offerGroupId }: { offerGroupId: string }) =>
      `/super-admin/offer-group/${offerGroupId}`,
    exact: true,
    children: <OfferGroupDetailsPage />,
  },
  SUPER_ADMIN_OFFER_GROUP_EDIT: {
    path: '/super-admin/offer-group/:offerGroupId/edit',
    getPath: ({ offerGroupId }: { offerGroupId: string }) =>
      `/super-admin/offer-group/${offerGroupId}/edit`,
    exact: true,
    children: <OfferGroupEditPage />,
  },
  SUPER_ADMIN_OFFERS_OFFERS: {
    path: '/super-admin/offers/offers',
    getPath: () => '/super-admin/offers/offers',
    exact: true,
    children: <OffersViewPage />,
  },
  SUPER_ADMIN_OFFERS_DETAILS: {
    path: '/super-admin/offer/details/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/super-admin/offer/details/${offerId}`,
    exact: true,
    children: <OfferDetailsPage />,
  },
  SUPER_ADMIN_OFFERS_EDIT: {
    path: '/super-admin/offers/edit/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/super-admin/offers/edit/${offerId}`,
    exact: true,
    children: <OffersEditPage />,
  },

  SUPER_ADMIN_ORDERS_LIST: {
    path: '/super-admin/orders/list',
    getPath: () => '/super-admin/orders/list',
    exact: true,
    children: <OrdersListPage />,
  },
  SUPER_ADMIN_ORDERS_DETAIL: {
    path: '/super-admin/orders/detail',
    getPath: () => '/super-admin/orders/detail',
    exact: true,
    children: <OrdersDetailPage />,
  },
  SUPER_ADMIN_ORDER_VIEW: {
    path: '/super-admin/order/byId/:orderId',
    getPath: ({ orderId }: { orderId: string }) => `/super-admin/order/byId/${orderId}`,
    exact: true,
    children: <OrderViewPage />,
  },
  SUPER_ADMIN_ORDERS_CREATE: {
    path: '/super-admin/orders/create',
    getPath: () => '/super-admin/orders/create',
    exact: true,
    children: <OrdersCreatePage />,
  },
  SUPER_ADMIN_ORDERS_EDIT: {
    path: '/super-admin/orders/edit',
    getPath: () => '/super-admin/orders/edit',
    exact: true,
    children: <OrdersEditPage />,
  },
  SUPER_ADMIN_QUOTES_LIST: {
    path: '/super-admin/quotes/list',
    getPath: () => '/super-admin/quotes/list',
    exact: true,
    children: <QuotesListPage />,
  },
  SUPER_ADMIN_QUOTE_VIEW: {
    path: '/super-admin/quote/byId/:quoteId',
    getPath: ({ quoteId }: { quoteId: string }) => `/super-admin/quote/byId/${quoteId}`,
    exact: true,
    children: <QuoteViewPage/>,
  },
  SUPER_ADMIN_QUOTE_CREATE: {
    path: '/super-admin/quotes/create',
    getPath: () => '/super-admin/quotes/create',
    exact: true,
    children: <QuotesCreatePage />,
  },
  SUPER_ADMIN_QUOTE_DETAIL: {
    path: '/super-admin/quotes/detail',
    getPath: () => '/super-admin/quotes/detail',
    exact: true,
    children: <QuotesDetailPage />,
  },
  SUPER_ADMIN_QUOTE_CREATE_SUCCESS: {
    path: '/super-admin/quotes/create/success',
    getPath: () => '/super-admin/quotes/create/success',
    exact: true,
    children: <QuotesCreateSuccessPage />,
  },
  SUPER_ADMIN_PRODUCTS: {
    path: '/super-admin/products',
    getPath: () => '/super-admin/products',
    exact: true,
    children: <ProductsPage />,
  },
  SUPER_ADMIN_PRODUCT_DETAILS: {
    path: '/super-admin/product/:productId',
    getPath: ({ productId }: { productId: string }) =>
      `/super-admin/product/${productId}`,
    exact: true,
    children: <ProductDetailsPage />,
  },
  SUPER_ADMIN_PRODUCT_CREATE: {
    path: '/super-admin/products/create',
    getPath: () => '/super-admin/products/create',
    exact: true,
    children: <ProductCreatePage />,
  },

  SUPER_ADMIN_SERVICES: {
    path: '/super-admin/services',
    getPath: () => '/super-admin/services',
    exact: true,
    children: <ServicesPage />,
  },
  SUPER_ADMIN_SERVICE_VIEW: {
    path: '/super-admin/service/byId/:serviceId',
    getPath: ({ serviceId }: { serviceId: string }) =>
      `/super-admin/service/byId/${serviceId}`,
    exact: true,
    children: <ServiceViewPage />,
  },
  SUPER_ADMIN_BUYERS: {
    path: '/super-admin/buyers',
    getPath: () => '/super-admin/buyers',
    exact: true,
    children: <BuyersPage />,
  },
  SUPER_ADMIN_BUYER_ORDERS_VIEW: {
    path: '/super-admin/buyer/orders/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/super-admin/buyer/orders/${offerId}`,
    exact: true,
    children: <ViewProductOfferPage />,
  },
  SUPER_ADMIN_SUPPLIERS: {
    path: '/super-admin/suppliers',
    getPath: () => '/super-admin/suppliers',
    exact: true,
    children: <SuppliersPage />,
  },
  SUPER_ADMIN_SUPPLIER_OFFER_VIEW: {
    path: '/super-admin/supplier/offers/byId/:offerId',
    getPath: ({ offerId }: { offerId: string }) =>
      `/super-admin/supplier/offers/byId/${offerId}`,
    exact: true,
    children: <SupplierOffersView />,
  },
  SUPER_ADMIN_OFFERS_SUPPLIERS_NEW_ACCOUNT: {
    path: '/super-admin/offers/new-suppliers',
    getPath: () => '/super-admin/offers/new-suppliers',
    exact: true,
    children: <CreateSupplierAccount />,
  },
  SUPER_ADMIN_LESSORS: {
    path: '/super-admin/lessors',
    getPath: () => '/super-admin/lessors',
    exact: true,
    children: <LessorsPage />,
  },
  SUPER_ADMIN_LESSOR_CREATE: {
    path: '/super-admin/lessors/create',
    getPath: () => '/super-admin/lessors/create',
    exact: true,
    children: <LessorCreatePage />,
  },
  SUPER_ADMIN_LESSOR_DETAILS: {
    path: '/super-admin/lessors/:lessorId',
    getPath: ({ lessorId }: { lessorId: string }) =>
      `/super-admin/lessors/${lessorId}`,
    exact: true,
    children: <LessorDetailsPage />,
  },

  SUPER_ADMIN_INSURERS: {
    path: '/super-admin/insurers',
    getPath: () => '/super-admin/insurers',
    exact: true,
    children: <InsurersPage />,
  },
  SUPER_ADMIN_INSURER_CREATE: {
    path: '/super-admin/insurers/create',
    getPath: () => '/super-admin/insurers/create',
    exact: true,
    children: <InsurerCreatePage />,
  },
  SUPER_ADMIN_INSURER_DETAILS: {
    path: '/super-admin/insurers/:insurerId',
    getPath: ({ insurerId }: { insurerId: string }) =>
      `/super-admin/insurers/${insurerId}`,
    exact: true,
    children: <InsurerDetailsPage />,
  },
  SUPER_ADMIN_USERS: {
    path: '/super-admin/users',
    getPath: () => '/super-admin/users',
    exact: true,
    children: <UsersPage />,
  },
  SUPER_ADMIN_NODES: {
    path: '/super-admin/nodes',
    getPath: () => '/super-admin/nodes',
    exact: true,
    children: <NodesPage />,
  },
  SUPER_ADMIN_PAYMENTS: {
    path: '/super-admin/payments',
    getPath: () => '/super-admin/payments',
    exact: true,
    children: <PaymentsPage />,
  },
  SUPER_ADMIN_PAYMENT_VIEW: {
    path: '/super-admin/payment/byId/:paymentId',
    getPath: ({ paymentId }: { paymentId: string }) =>
      `/super-admin/payment/byId/${paymentId}`,
    exact: true,
    children: <PaymentView />,
  },
};

export default ROUTER_CONFIG;
