import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import CloseIcon from '../../../../../../img/close.svg';
import {
  CloseButton,
  ContainerForPopover,
  LinkAfterButton,
  StyledButtonDark,
  Text,
  Title,
} from '../../../../offers/offer-groups/offer-groups-details/styled-offerGropDetails/popover.styled';
import { renderFormConfig } from "../../../../../../utils/form";
import { AddUserConfig } from '../config/configForm'


interface InviteAddOfferModalContentProps {
    onClose: () => void;
    onSubmit: (values: any) => void;
  }
  
const AddUserBuyersModal: React.FC<InviteAddOfferModalContentProps> = ({onClose, onSubmit}) => {
    const formik = useFormik({
      initialValues: {
        addUser: 'tc@movavaleshoocl.sch.uk',
      },
      onSubmit: (values) => {
        onSubmit(values);
        onClose();
      },
      });
    
    return (
      <ContainerForPopover onSubmit={formik.handleSubmit}>
        <CloseButton alt="" src={CloseIcon} onClick={onClose}/>
        <Title>Add User to Organisation</Title>
        {renderFormConfig(AddUserConfig, formik)}
        <StyledButtonDark type="submit" style={{ padding: '14px 84px' }}>Add User</StyledButtonDark>
        <LinkAfterButton>
          <Text style={{ marginRight: '5px'}}>Or</Text>
          <InviteButton>Create new user account</InviteButton>
        </LinkAfterButton>
      </ContainerForPopover>
    );
  };
const InviteButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export default AddUserBuyersModal