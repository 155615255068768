import styled from 'styled-components/macro';
import React from 'react';
import { ReactComponent as Camera } from '../../img/icons/camera.svg';

const UploadButton: React.FC = () => (
  <Wrapper>
    <Camera style={{
      position: 'absolute',
      bottom: '-11px',
      right: '-13px',
    }}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative
`;


export default UploadButton;