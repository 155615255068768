import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import { InsuranceTableRow } from './types';
import { Dropdown, Input, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ButtonDark } from '../../../ui/Button';
import { showPriceDigits } from '../../../utils/formats';
import { TableStyled } from '../shared/table/TableStyled';
import { ReactComponent as ArrowDown } from '../../../img/icons/arrowDownBlue.svg';

interface IProps {
  insuranceTableData: Array<InsuranceTableRow>;
  onSelectedInsurances: (selectedIds: Array<string>) => void;
  selectedInsurances: Array<string>;
  onDeleteInsurance: () => void;
  onAddNewPolicy: () => void;
  onEditPolicy: (id: string) => void;
}

const InsurerPolicyTable: React.FC<IProps> = ({
  insuranceTableData,
  onSelectedInsurances,
  selectedInsurances,
  onDeleteInsurance,
  onAddNewPolicy,
  onEditPolicy,
}) => {
  const tableConfig: ColumnsType<InsuranceTableRow> = [
    {
      title: 'Policy Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      align: 'center',
    },
    {
      title: 'Policy Length',
      dataIndex: 'policyLength',
      key: 'policyLength',
    },
    {
      title: 'Payment Schedule',
      dataIndex: 'paymentSchedule',
      key: 'paymentSchedule',
    },
    {
      title: 'Instalment Amount',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => showPriceDigits(price),
    },
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: (id: string) => (
        <StyledButtonDark onClick={() => onEditPolicy(id)}>
          Edit
        </StyledButtonDark>
      ),
    },
  ];

  const dropdownContent = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={onDeleteInsurance}> Delete </Menu.Item>
      </Menu>
    ),
    [onDeleteInsurance]
  );

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          gap: 20,
          marginBottom: 10,
        }}
      >
        <InviteButton onClick={onAddNewPolicy}>
          + Add New Insurance Policy
        </InviteButton>
        <Dropdown overlay={dropdownContent}>
          <DropDownButton onClick={(e) => e.preventDefault()}>
            Actions <ArrowDown style={{ marginBottom: '2px' }} />
          </DropDownButton>
        </Dropdown>
      </div>
      <TableStyled
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectedInsurances(ids as Array<string>);
          },
          selectedRowKeys: selectedInsurances,
        }}
        columns={tableConfig}
        dataSource={insuranceTableData}
      />
    </Container>
  );
};
const Container = styled.div`
  margin-bottom: 10px;
`;

const DropDownButton = styled.span`
  cursor: pointer;
  color: #2682de;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;
  min-width: 60px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 420px;
  height: 36px;
  padding: 10px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 96% center;
`;

const InviteButton = styled.span`
  cursor: pointer;
  color: #2682de;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 5px 20px;
`;

export default InsurerPolicyTable;
