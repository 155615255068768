import { DatePicker } from 'antd';
import { ReactElement } from 'react';

interface DatePickerProps {
  setFieldValue: (field: string, value: string) => void;
  name: string;
  className?: string;
  suffixIcon?: ReactElement;
}

export default function Datepicker({
  setFieldValue,
  name,
  className,
  suffixIcon,
}: DatePickerProps) {
  return (
    <DatePicker
      onChange={(_, dateString) => setFieldValue(name, dateString)}
      className={className}
      suffixIcon={suffixIcon}
    />
  );
}
