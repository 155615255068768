import { FC } from "react";
import LayoutV1 from "../../../layout/LayoutV1";
import BodyContainer from "../../../../ui/elements/BodyContainer";
import RecorderPaymentsView from "./payments-crud/RecorderPaymentsView";

const  PaymentsPage: FC = () => {
  return (
    <LayoutV1>
      <BodyContainer title="Payments">
        <RecorderPaymentsView/>
      </BodyContainer>
    </LayoutV1>
  );
}

export default PaymentsPage;