import { Row, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { SelectValue } from 'antd/lib/select';
import { ColumnsType } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useParams } from 'react-router';
import { StyledSelect } from '../../../styled/select';
import { TitleH2, TitleH3 } from '../../../styled/text';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import {
  DarkBigButton, DarkSmallButton, DetailsButton, RedSmallButton,
} from '../../../styled/buttons';
import { StyledCheckbox } from '../../../styled/checkbox';
import { useGetOfferDetailsQuery } from '../../../../../../api/services/superadmin/offersApi';
import { LeasesType } from '../../../../../../project-types/offers/offers-offers/types';
import { TableStyled } from '../../../../shared/table/TableStyled';

const initialSelectData: string[] = ['Demo Lessor A', 'Demo Lessor B', 'Demo Lessor C'];
const { Option } = Select;

const LeaseRates: React.FC = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const [selectValue, setSelectValue] = useState<SelectValue>(initialSelectData[0]);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [isShowDefaultText, setIsShowDefaultText] = useState<boolean>(selectValue === initialSelectData[0]);
  const [data, setData] = useState<any>([]);
  const [selectedRates, setSelectedRates] = useState<string[]>([]);

  const { data: offerDetails } = useGetOfferDetailsQuery(offerId);

  const onAddLessor = () => {
    setData((prev: any) => ([...prev, { id: Date.now() }]));
  };

  const onAddClickHandler = (id: string) => {
    setSelectedRates((prevValue) => [...prevValue, id]);
  };

  const onRemoveClickHandler = (id: string) => {
    setSelectedRates((prevValue) => prevValue.filter((item) => item !== id));
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    if (selectValue === initialSelectData[0]) {
      setIsShowDefaultText(true);
    } else {
      setIsShowDefaultText(false);
    }
  }, [selectValue]);

  useEffect(() => {
    if (offerDetails) {
      setSelectedRates(offerDetails.leases.map((item) => item._id));
    }
  }, [offerDetails]);

  const onDefaultValueClickHandler = () => {
    setSelectValue(initialSelectData[0]);
  };

  const tableConfig: ColumnsType<LeasesType> = useMemo(() => [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Lease Type',
      dataIndex: 'type',
      key: 'type',
      width: '25%',
    },
    {
      title: 'Term Length',
      dataIndex: 'policyLength',
      key: 'policyLength',
    },
    {
      title: 'Payment Schedule',
      dataIndex: 'paymentSchedule',
      key: 'paymentSchedule',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Residual Value Rate',
      dataIndex: 'residualValueRate',
      key: 'residualValueRate',
      align: 'right',
    },
    {
      title: '',
      dataIndex: 'btn',
      key: 'btn',
      render: (_v, r) => ((selectedRates.includes(r._id)) ? (
        <RedSmallButton onClick={() => onRemoveClickHandler(r._id)}>
          Remove
        </RedSmallButton>
      ) : (
        <DarkSmallButton onClick={() => onAddClickHandler(r._id)}>
          Add
        </DarkSmallButton>
      )),
    },
  ], [selectedRates]);

  return (
    <Container>
      <TitleH2>Lease Rates</TitleH2>
      <Container>
        <TitleH3>Lessor 1 (Default)</TitleH3>
        <Row align="middle" style={{ marginBottom: '20px' }}>
          <StyledSelect value={selectValue} onChange={(value: any) => setSelectValue(value)} suffixIcon={<ArrowDown />}>
            {initialSelectData.map((title: string) => (<Option value={title} key={title}>{title}</Option>))}
          </StyledSelect>
          {!isShowDefaultText
            && <CustomDetailButton onClick={onDefaultValueClickHandler}>Make Default</CustomDetailButton>}
        </Row>
        {offerDetails && (
          <CustomStyledTable
            columns={tableConfig}
            dataSource={offerDetails.leases}
            pagination={false}
            rowClassName={(record) => (selectedRates.includes(record._id) ? 'darkBackground' : '')}
          />
        )}
        <StyledCheckbox checked={isChecked} onChange={handleCheckboxChange}>
          Show Lease Rates for Independent
          Schools
        </StyledCheckbox>
        {isChecked && offerDetails && (
          <CustomStyledTable
            columns={tableConfig}
            dataSource={offerDetails.leases}
            pagination={false}
            rowClassName={(record) => (selectedRates.includes(record._id) ? 'darkBackground' : '')}
          />
        )}
        <Row>
          <DarkBigButton onClick={onAddLessor}>+ Add Lessor</DarkBigButton>
        </Row>
      </Container>
      {data.map((item: any, index: number) => (
        <Container key={item.id}>
          <TitleH3>{`Lessor ${index + 2} (Default)`}</TitleH3>
          <Row align="middle" style={{ marginBottom: '20px' }}>
            <StyledSelect value={selectValue} onChange={(value: any) => setSelectValue(value)} suffixIcon={<ArrowDown />}>
              {initialSelectData.map((title: string) => (<Option value={title} key={title}>{title}</Option>))}
            </StyledSelect>
            {!isShowDefaultText
              && <CustomDetailButton onClick={onDefaultValueClickHandler}>Make Default</CustomDetailButton>}
          </Row>
          {offerDetails && (
            <CustomStyledTable
              columns={tableConfig}
              dataSource={offerDetails.leases}
              pagination={false}
              rowClassName={(record) => (selectedRates.includes(record._id) ? 'darkBackground' : '')}
            />
          )}
          <StyledCheckbox checked={isChecked} onChange={handleCheckboxChange}>
            Show Lease Rates for Independent
            Schools
          </StyledCheckbox>
          {isChecked && offerDetails && (
            <CustomStyledTable
              columns={tableConfig}
              dataSource={offerDetails.leases}
              pagination={false}
              rowClassName={(record) => (selectedRates.includes(record._id) ? 'darkBackground' : '')}
            />
          )}
          <Row>
            <DarkBigButton onClick={onAddLessor}>+ Add Lessor</DarkBigButton>
          </Row>
        </Container>
      ))}
    </Container>
  );
};

export default LeaseRates;

const Container = styled.div`
`;

const CustomStyledTable = styled(TableStyled)`
  .darkBackground {
    background-color: #F5F8FB;
  }
`;

const CustomDetailButton = styled(DetailsButton)`
  align-self: center;
  margin-left: 16px;
`;
