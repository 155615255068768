import styled from 'styled-components/macro';
import InfoTable from '../../../../../../ui/elements/InfoTable';
import { serializeStatisticsPrice } from '../../../../../../utils/formats';
import { useGetBuyersDashboardQuery } from '../../../../../../api/services/superadmin/buyersOrderApi'

const BuyersDashboard = () => {
    const { data }:any =  useGetBuyersDashboardQuery();

    const overviewStatData = [
        {
          title: 'Active Orders',
          value: `${data.activeOrders}`,
        },
        {
          title: 'Paid Orders',
          value: `${data.paidOrders}`,
        },
        {
          title: 'Total amount of purchases',
          value: serializeStatisticsPrice(data.totalPurchase)
        },
      ];

    return(
        <StyledInfoTable data={overviewStatData}/>
    )
}

const StyledInfoTable = styled(InfoTable)`
  margin: 40px 0;
`;

export default BuyersDashboard