import { FC } from 'react';
// eslint-disable-next-line object-curly-newline
import { Dropdown, Menu, Space, Typography } from 'antd';

import { ReactComponent as ChevronDown } from '../../../../../img/icons/chevron-down.svg';

const menu = (
  <Menu>
    <Menu.Item key="0">Delete</Menu.Item>
  </Menu>
);

export const QuotesDropdown: FC = () => (
  <Dropdown overlay={menu} trigger={['click']}>
    <Typography.Link onClick={(e) => e.preventDefault()}>
      <Space>
        Actions
        <ChevronDown />
      </Space>
    </Typography.Link>
  </Dropdown>
);
