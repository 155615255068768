import React from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router';
import LeaseRates from './lease-insurance-table/LeaseRates';
import InsurancePolicy from './lease-insurance-table/InsurancePolicy';
import { useGetOfferDetailsQuery } from '../../../../../api/services/superadmin/offersApi';

const LeaseInsuranceTables: React.FC = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const { data: offerDetails } = useGetOfferDetailsQuery(offerId);

  return (
    <Container>
      <LeaseRates />
      {!!offerDetails?.insurances.length && <InsurancePolicy />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default LeaseInsuranceTables;
