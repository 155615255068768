import styled from 'styled-components/macro';
import React from 'react';
import { AddOfferForm, OffersTableRow } from '../../types';
import OffersTableControls from './OffersTableControls';
import OffersTableData from './OffersTableData';
import ModalInPortal from '../../../../../../ui/Modal/ModalPortal';
import InviteAddOfferModalContent from './InviteAddOfferModalContent';

interface OffersTableProps {
  tableData: Array<OffersTableRow>;
  setSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
  onDeleteOfferGroupClickHandler: () => void;
  onSubmitAddOfferForm: (values: AddOfferForm) => void;
}

const OffersTable: React.FC<OffersTableProps> = ({
  tableData,
  selectedOffers,
  setSelectedOffers,
  onDeleteOfferGroupClickHandler,
  onSubmitAddOfferForm,
}) => {
  const InviteAddOfferModal = ModalInPortal();
  const onAddOfferClickHandler = () => InviteAddOfferModal.onShow();

  return (
    <Container>
      <Title>Offers (2)</Title>
      {InviteAddOfferModal.ModalRender && (
        <InviteAddOfferModal.ModalRender>
          <InviteAddOfferModalContent
            onClose={InviteAddOfferModal.onClose}
            onSubmit={onSubmitAddOfferForm}
          />
        </InviteAddOfferModal.ModalRender>
      )}
      <OffersTableControls
        onDeleteOfferGroupClickHandler={onDeleteOfferGroupClickHandler}
        onAddOfferClickHandler={onAddOfferClickHandler}
      />
      <OffersTableData
        tableData={tableData}
        setSelectedOffers={setSelectedOffers}
        selectedOffers={selectedOffers}
      />
    </Container>
  );
};

const Container = styled.div`
`;

const Title = styled.h1`
  margin: 0;
  font-size: 21px;
  line-height: 31px;
  color: #102F54;
  font-weight: bold;
`;

export default OffersTable;
