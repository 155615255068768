import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components/macro';
import { OffersTableRow } from '../../types';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import { TableStyled } from '../../../../shared/table/TableStyled';
import { formatDateShort } from '../../../../../../ui/DateFormatMethods';

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

const tableConfig: ColumnsType<OffersTableRow> = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Offer Number',
    dataIndex: 'offerNumber',
    key: 'offerNumber',
  },
  {
    title: 'OEM',
    dataIndex: 'oem',
    key: 'oem',
  },
  {
    title: (
      <StyledTitle>
        Start Date
        <ArrowDown style={{ marginLeft: '8px' }} />
      </StyledTitle>
    ),
    dataIndex: 'startDate',
    key: 'startDate',
    render: (_v, value) => (
      formatDateShort(new Date(value.startDate))
    ),
    sorter: (a, b) => {
      if (new Date(a.startDate) < new Date(b.startDate)) {
        return -1;
      }
      if (new Date(a.startDate) > new Date(b.startDate)) {
        return 1;
      }
      return 0;
    },
    sortDirections: ['descend']
  },
  {
    title: (
      <StyledTitle>
        End Date
        <ArrowDown style={{ marginLeft: '8px' }} />
      </StyledTitle>
    ),
    dataIndex: 'endDate',
    key: 'endDate',
    render: (_v, value) => (
      formatDateShort(new Date(value.endDate))
    ),
    sorter: (a, b) => {
      if (new Date(a.endDate) < new Date(b.endDate)) {
        return -1;
      }
      if (new Date(a.endDate) > new Date(b.endDate)) {
        return 1;
      }
      return 0;
    },
    sortDirections: ['descend']
  },
];

interface OfferGroupTableProps {
  tableData: Array<OffersTableRow>;
  setSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
}

const OffersTableData: React.FC<OfferGroupTableProps> = ({
  tableData, setSelectedOffers, selectedOffers,
}) => (
  <CustomStyledTable
    rowSelection={{
      type: 'checkbox',
      onChange: (ids) => {
        setSelectedOffers(ids as Array<string>);
      },
      selectedRowKeys: selectedOffers,
    }}
    columns={tableConfig}
    dataSource={tableData}
  />
);

const CustomStyledTable = styled(TableStyled)`
  .ant-table-column-sorter {
    display: none;
  }
`;

export default OffersTableData;
