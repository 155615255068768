import { FC } from 'react';
import { Card, CardProps } from 'antd';
import styled from 'styled-components';

export const CardStyled: FC<CardProps> = styled(Card)`
  border-top: 2px solid ${(props) => props.theme.primary};
  border-left: none;
  border-right: none;
`;

export const CardTopBlue: FC<CardProps> = styled(Card)`
  border-top: 2px solid ${(props) => props.theme.primary};
`;
