import { supplierApi } from './supplierApi';
import { QuoteCreateType, QuotesResponse } from '../../../components/supplier/types';

const quotesApi = supplierApi.injectEndpoints({
  endpoints: (build) => ({
    getQuotesList: build.query<QuotesResponse, void>({
      providesTags: ['QUOTES'],
      query: () => 'quotes',
    }),
    buildQuote: build.mutation<void, QuoteCreateType>({
      invalidatesTags: ['QUOTES'],
      query: (quote) => ({
        url: 'quote',
        method: 'POST',
        body: quote,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetQuotesListQuery,
  useBuildQuoteMutation,
} = quotesApi;
