import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import { showPriceDigits } from '../../../../utils/formats';
import { IProduct } from '../../types';

interface ProductItemProps {
  product: IProduct;
  onDeviceClick: (id: string) => void;
  minOutrightPurchasePrice: number;
  minLeasePrice: number;
  buttonText?: string;
}

export default function ProductItem({
  product, onDeviceClick, minLeasePrice, minOutrightPurchasePrice, buttonText,
}: ProductItemProps) {
  if (!product) return null;

  return (
    <ContentItem>
      <ProductImageContainer onClick={() => onDeviceClick(product._id)}>
        <ProductImage alt="" src={`https://storage.googleapis.com/sds-public-dev/product_pictures/${product.imageUrl}`} />
      </ProductImageContainer>
      <ProductDescription>
        <div>
          <Brand>{product.manufacturer}</Brand>
          <ProductName>{product.name}</ProductName>
          <Characteristics>
            {(product.headlines)}
          </Characteristics>
        </div>
        <div>
          <Divider />
          <PriceBlock>
            <PriceItem>
              <Brand>Outright Purchase</Brand>
              <Price>
                {`From ${showPriceDigits(
                  minOutrightPurchasePrice,
                )}`}

              </Price>
            </PriceItem>
            <PriceItem>
              <Brand>Operating Lease</Brand>
              <Price>
                {minLeasePrice ? `From ${showPriceDigits(
                  minLeasePrice,
                )}/qtr` : '-'}

              </Price>
            </PriceItem>
          </PriceBlock>
          <Divider />
          <StyledButtonDark onClick={() => onDeviceClick(product._id)}>
            {buttonText || 'View device'}
          </StyledButtonDark>
        </div>
      </ProductDescription>
    </ContentItem>
  );
}

const ContentItem = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fafbfc;
  padding: 10px;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const ProductImageContainer = styled.div`
  width: 100%;
  height: 170px;
  background-color: #fff;
  cursor: pointer;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductDescription = styled.div`
  flex: 1;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Brand = styled.span`
  text-transform: uppercase;
  color: rgba(16, 47, 84, 0.8);
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
`;

const ProductName = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  margin-bottom: 11px;
  margin-top: 4px;
`;

const Characteristics = styled.pre`
  white-space: pre-wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Proxima Nova';
`;

const Characteristic = styled.li`
  color: #3F5876;
  font-size: 14px;

  &::before {
    content: '• ';
    color: #3F5876;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #d5dce5;
  margin-top: 20px;
  margin-bottom: 14px;
`;

const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Price = styled.span`
  font-size: 20px;
  line-height: 20px;
  color: #de2644;
  font-weight: bold;
`;

const StyledButtonDark = styled(ButtonDark)`
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 14px;
`;
