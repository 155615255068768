import styled from 'styled-components/macro';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useEditOfferGroupMutation, useGetOfferGroupsQuery } from '../../../../../../api/services/superadmin/offerGroupsApi';
import { IOfferGroupDetailsForm } from '../types';
import OfferGroupDetailsForm from './OfferGroupDetailsForm';


const OfferGroupDetailsSection: React.FC = () => {
  const { offerGroupId } = useParams<{ offerGroupId: string }>();
  const { data: offerGroupData } = useGetOfferGroupsQuery();
  const [editOfferGroup] = useEditOfferGroupMutation();
  const onSubmitEditOfferGroupForm = (values: IOfferGroupDetailsForm) => {
    editOfferGroup({ body: values, offerGroupId });
  };

  return (
    <Container>
      <SubTitle>1. Offer Group Details</SubTitle>
      {offerGroupData && <OfferGroupDetailsForm onSubmit={onSubmitEditOfferGroupForm} offerGroupData={offerGroupData} />}
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  max-width: 453px;
  padding: 24px 0;
  position: relative;
`;

const SubTitle = styled.h3`
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #102F54;
`;

export default OfferGroupDetailsSection;
