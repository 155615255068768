import { ColumnsType } from "antd/lib/table";
import { SupplierColumnProps } from "./types";

export const tableConfig: ColumnsType<SupplierColumnProps> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Organisation Type',
    dataIndex: 'organisationType',
    key: 'organisationType',
  },
  {
    title: 'URN',
    dataIndex: 'urn',
    key: 'urn',
  },
];