import { FC } from 'react';
import HTMLEditor from '../../../../ui/forms/HTMLEditor';
import { FormConfig, renderFormConfig } from '../../../../utils/form';

interface IProps {
  formikInstance: any;
  isEditMode: boolean;
}

const ProductOverview: FC<IProps> = ({ formikInstance, isEditMode }) => {
  return (
    <HTMLEditor
      value={
        formikInstance.values.overview || formikInstance.values.overview === ''
          ? formikInstance.values.overview
          : ''
      }
      setFieldValue={(val: string) =>
        formikInstance.setFieldValue('overview', val)
      }
      disabled={!isEditMode}
    />
  );
};

export default ProductOverview;
