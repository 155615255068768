import { FC } from "react";
import { ReactComponent as ChevronDown } from "../../img/icons/chevron-down.svg";
import { Dropdown, Menu } from "antd";
import { AddButton, TableControls, CustomTableControlsButton } from "./table.styled";

export interface IAdditionalControls {
  mainActionText: string;
  subActionText: string;
  onClickRemove: () => void;
  additionalActionText?: string;
  onClickAdd?: () => void;
}

const AdditionalControls: FC<IAdditionalControls> = (
  {mainActionText, subActionText, onClickRemove, additionalActionText, onClickAdd}
) => {
  const dropdownContent = (
    <Menu key="1">
      <Menu.Item onClick={onClickRemove}>
        {subActionText}
      </Menu.Item>
    </Menu>
  );
  return (
    <TableControls>
      <AddButton onClick={onClickAdd}>
        {additionalActionText}
      </AddButton>
      <Dropdown overlay={dropdownContent}>
        <CustomTableControlsButton onClick={(e) => e.preventDefault()}>
          {mainActionText}
          <ChevronDown/>
        </CustomTableControlsButton>
      </Dropdown>
    </TableControls>
  );
};

export default AdditionalControls;