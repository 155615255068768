import styled from 'styled-components/macro';
import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { EditOfferGroupForm } from './types';
import OfferGroupEditForm from './offer-groups-details/OfferGroupEditForm';
import { useEditOfferGroupMutation, useGetOfferGroupQuery } from '../../../../api/services/superadmin/offerGroupsApi';
import LayoutV1 from '../../../layout/LayoutV1';
import Title from '../../shared/header/Title';
import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';

const OfferGroupEditPage: React.FC = () => {
  const history = useHistory();
  const { offerGroupId } = useParams<{ offerGroupId: string }>();
  const { data: offerGroupData } = useGetOfferGroupQuery(offerGroupId);
  const [editOfferGroup] = useEditOfferGroupMutation();
  const onSubmitEditOfferGroupForm = (values: EditOfferGroupForm) => {
    editOfferGroup({ body: values, offerGroupId });
  };

  const onBack = useCallback(
    () => history.goBack(),
    [history],
  );

  return (
    <LayoutV1>
      <Container>
        <GoBack onClick={onBack}>
          <BackIcon />
        </GoBack>
        <Title style={{ margin: '0 0 10px 0' }}>Edit Offer Group </Title>
        {offerGroupData && <OfferGroupEditForm onSubmit={onSubmitEditOfferGroupForm} offerGroupData={offerGroupData} />}
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div`
  position: relative;
`;

const GoBack = styled.div`
  position: absolute;
  left: -53px;
  top: 2px;
  cursor: pointer;
`;

export default OfferGroupEditPage;
