import React from 'react';
import SearchIcon from '../../img/icons/icon_search.svg';
import { SearchInputProps } from './types';
import { Search, SearchContent, StyledInput } from './search.styled';

const SearchInput: React.FC<SearchInputProps> = ({
  searchValue,
  placeholder,
  setSearchValue,
  onSubmitSearch,
}) => (
  <SearchContent>
    <StyledInput
      onKeyDown={(e) => e.key === 'Enter' && onSubmitSearch && onSubmitSearch()}
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue && setSearchValue(e.target.value)}
      iconSource={SearchIcon}
    />
    <Search />
  </SearchContent>
);

export default SearchInput;