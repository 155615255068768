import styled from "styled-components/macro";
import React, { ReactNode } from "react";

export interface BodyContainerTypes {
  title?: string
}

const BodyContainer: React.FC<BodyContainerTypes> = ({title, children}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

export default BodyContainer;

const Container = styled.article`
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #071730;
  font-weight: bold;
`;