import { ReactComponent as CubeIcon } from '../../../../../img/icons/cubeIcon.svg'
import { ReactComponent as CheckIcon } from '../../../../../img/icons/checkIcon.svg';
import { ReactComponent as MoneyIcon } from '../../../../../img/icons/moneyIcon.svg';
import { ReactComponent as TimeIcon } from '../../../../../img/icons/timeIcon.svg';
import { ReactComponent as OemIcon } from '../../../../../img/icons/oemIcon.svg';

export const offerPreviewMock = [
  {
    key: 1,
    title: 'SKU',
    value: 'KF1-00007',
    image: <CubeIcon />,
  },
  {
    key: 2,
    title: 'OEM',
    value: 'Microsoft',
    image: <OemIcon />,
  },
  {
    key: 3,
    title: 'Offer Number',
    value: 'xxxxxxxx',
    image: <CubeIcon />,
  },
  {
    key: 4,
    title: 'Available Quantity',
    value: '5,000',
    image: <CheckIcon />,
  },
  {
    key: 5,
    title: 'Indicative Reseller Buy Price',
    value: '£404.83',
    image: <MoneyIcon />,
  },
  {
    key: 6,
    title: 'NTE',
    value: '£440.83',
    image: <MoneyIcon />,
  },
  {
    key: 7,
    title: 'Start Date',
    value: '12/02/2021',
    image: <TimeIcon />,
  },
  {
    key: 8,
    title: 'Estimated Delivery Date',
    value: '12/02/2021',
    image: <TimeIcon />,
  },
];
