import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React from 'react';
import { FormConfig, renderFormConfig } from '../../../../../../utils/form';
import { createOfferGroupDetailsForm } from '../offer-group-create-schemas';
import { IOfferGroupDetailsForm } from '../types';
import Input from '../../../../../../ui/forms/Input';
import TextArea from '../../../../../../ui/forms/Textarea';
import UploadIcon from './OfferGroupDetailsUpload';
import { OfferGroupType } from '../../../../../../project-types/offers/offerGroups/types';

const StyledInput = styled(Input)`
  height: unset;
  padding: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #102F54;
`;

const StyledTextarea = styled(TextArea)`
  width: 100%;
  height: 114px;
  padding: 10px;
`;

interface OfferGroupDetailsFormProps {
  onSubmit: (values: IOfferGroupDetailsForm) => void;
  offerGroupData: OfferGroupType;
}

const formConfig: FormConfig = [
  {
    title: 'Choose an icon',
    name: 'icon',
    component: UploadIcon,
  },
  {
    title: 'Name',
    name: 'name',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Description',
    name: 'description',
    component: StyledTextarea,
  },
];

const OfferGroupDetailsForm: React.FC<OfferGroupDetailsFormProps> = ({ onSubmit, offerGroupData }) => {
  const formik = useFormik({
    initialValues: {
      icon: offerGroupData.icons,
      name: offerGroupData.name,
      description: offerGroupData.description,
    },
    validationSchema: createOfferGroupDetailsForm,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  max-width: 453px;
  padding: 24px 0;
  position: relative;
`;

export default OfferGroupDetailsForm;
