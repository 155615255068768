import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React from 'react';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/filled-arrow-down.svg';
import SelectInput from "../../../../../ui/forms/SelectInput";
import * as yup from "yup";
import CloseIcon from '../../../../../img/close.svg';
import { AddOfferForm } from "../../offer-groups/types";
import { FormConfig, renderFormConfig } from "../../../../../utils/form";
import { ButtonDark } from "../../../../../ui/Button";

const StyledSelect = styled(SelectInput)`
  width: 100%;
  max-width: 425px;
  
  .ant-select-selector {
    height: 36px !important;
  }
  
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

const selectData = [
  'test@email.com', 'test1@email.com', 'test2@email.com',
];

const formConfig: FormConfig = [
  {
    title: 'User Email',
    name: 'userEmail',
    component: StyledSelect,
    props: {
      initialData: selectData,
      suffixIcon: <ArrowDown/>,
      dropdownStyle: {
        zIndex: 200000,
        position: 'fixed',
      },
    },
    isRequired: true,
  },
];

interface IAddUserModalContent {
  onClose: () => void;
  onSubmit: (values: AddOfferForm) => void;
}

export const addServiceForm: yup.SchemaOf<AddOfferForm> = yup.object({
  numberOffer: yup.string().required(),
});

const AddUserModalContent: React.FC<IAddUserModalContent> = ({onClose, onSubmit}) => {
  const formik = useFormik({
    initialValues: {
      numberOffer: selectData[0],
    },
    validationSchema: addServiceForm,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });
  
  return (
    <ContainerForPopover onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose}/>
      <Title>Add User to Organisation</Title>
      {renderFormConfig(formConfig, formik)}
      <StyledButtonDark type="submit">Add User</StyledButtonDark>
      <LinkAfterButton>
        <Text>Or</Text>
        <InviteButton>Create new user account</InviteButton>
      </LinkAfterButton>
    </ContainerForPopover>
  );
};

export default AddUserModalContent;

export const ContainerForPopover = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  position: relative;
  
  label {
    font-size: 12px;
    font-weight: 600;
  }
`;
export const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Title = styled.h1`
  margin: 0 0 27px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
`;

export const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;

export const LinkAfterButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  max-width: 221px;
`;

export const InviteButton = styled.span`
  display: block;
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin-left: 5px;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled.span`
  display: block;
  color: #909DA8;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
`;