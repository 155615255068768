import { superadminApi } from './superadminApi';
import { OfferDetailsType } from '../../../project-types/offers/offers-offers/types';
import { EditFormType, SearchOfferType } from '../../../components/superadmin/offers/offers/types';

const offersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getOfferList: build.query<OfferDetailsType[], void>({
      providesTags: ['OFFERS'],
      query: () => 'offer',
    }),
    getSearchOfferList: build.query<OfferDetailsType[], SearchOfferType>({
      providesTags: ['OFFERS'],
      query: (body) => ({
        url: 'offer/search',
        method: 'GET',
        params: body,
      }),
    }),
    getOfferDetails: build.query<OfferDetailsType, string>({
      providesTags: ['OFFERS'],
      query: (offerId) => `offer/byId/${offerId}`,
    }),
    editOffer: build.mutation<EditFormType, { offer: EditFormType, offerId: string }>({
      invalidatesTags: ['OFFERS'],
      query: ({
        offer,
        offerId,
      }) => ({
        url: `offer/${offerId}`,
        method: 'PUT',
        body: offer,
      }),
    }),
    deleteOffer: build.mutation<any, string>({
      invalidatesTags: ['OFFERS'],
      query: (id) => ({
        url: `offer/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOfferListQuery,
  useGetOfferDetailsQuery,
  useEditOfferMutation,
  useDeleteOfferMutation,
  useGetSearchOfferListQuery,
} = offersApi;
