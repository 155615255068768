import { FC, useMemo, useCallback, useState } from 'react';
import { CustomStyledTable } from '../../styled/new-offer.styled';
import { NewOffer } from '../../../../types';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ButtonWrapper } from '../../styled/new-offer.styled';
import { ButtonDark } from '../../../../../../ui/Button';
import { InsurancePolicyTableRow } from '../../../../types';

interface IInsurancePolicyTableProps {
  tableData: Array<NewOffer>;
}

const InsurancePolicyTable: FC<IInsurancePolicyTableProps> = ({
  tableData,
}) => {
  const [selectedRates, setSelectedRates] = useState(() => new Set());

  const onAddClickHandler =  useCallback((id: string) => {
    setSelectedRates((prev: any) => new Set(prev).add(id));
  }, [selectedRates]);

  const onRemoveClickHandler =  useCallback((id: string) => {
    setSelectedRates((prev: any) => {
      const newSet: any = new Set(prev);
      newSet.delete(id);
      return newSet;
    })
  }, [selectedRates]);

  const columnsItems: ColumnsType<InsurancePolicyTableRow> = useMemo(() => [
    {
      title: 'Policy Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Policy Length',
      dataIndex: 'policyLength',
    },
    {
      title: 'Payment Schedule',
      dataIndex: 'paymentSchedule',
    },
    {
      title: 'Instalment Amount',
      dataIndex: 'price',
    },

    {
      render: (record: any) => {

        return (
        <ButtonWrapper>
          {selectedRates.has(record._id) ? (
            <Button
              type="primary"
              danger
              ghost
              onClick={() => onRemoveClickHandler(record._id)}
              style={{
                background: '#fff',
                width: '100px',
                height: '38px',
                fontWeight: 700,
              }}
            >
              Remove
            </Button>
          ) : (
            <ButtonDark
              onClick={() => onAddClickHandler(record._id)}
              style={{
                fontSize: 14,
                width: '100px',
                padding: 8,
              }}
            >
              Add
            </ButtonDark>
          )}
        </ButtonWrapper>
        );
      },
    },
  ], [onRemoveClickHandler, onAddClickHandler]);

  return(
  <CustomStyledTable
    dataSource={tableData}
    pagination={false}
    columns={columnsItems}
    rowClassName={(record) => (selectedRates.has(record._id) ? 'blueBackground' : '')}
  />
  );
};

export default InsurancePolicyTable;
