import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BuyerCreateGlobalOrderType,
  BuyerLoginFields,
  BuyerLoginRes,
  BuyerRegisterType,
  IBuyerOrderPublic,
  IBuyerProfilePublic,
  IBuyerQuotePublic,
  ICategoryPublicType,
  ICreateQuote,
  IProduct,
  IReseller,
  ISchool,
  ISellerOfferPublicType,
  ORDER_STATUS_ENUM,
  UpdateBuyerPersonalDetails,
} from '../../components/buyer/types';
import { IInsurance } from '../../components/superadmin/insurers/types';

export const buyerApi = createApi({
  reducerPath: 'buyerApi',
  tagTypes: [
    'PRODUCT',
    'QUOTES',
    'ORDERS',
    'USER',
    'GROUPED_OFFERS',
    'OFFER_GROUPS',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BUYER || 'http://localhost:5000/api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  // entityTypes: ["Buyer"],
  endpoints: (build) => ({
    signIn: build.mutation<BuyerLoginRes, BuyerLoginFields>({
      invalidatesTags: ['USER'],
      query: (credentials: BuyerLoginFields) => ({
        url: 'auth/buyer/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    signUp: build.mutation<void, BuyerRegisterType>({
      query: (buyerData: BuyerRegisterType) => ({
        url: 'buyer',
        method: 'POST',
        body: buyerData,
      }),
    }),
    getProfileInfo: build.query<IBuyerProfilePublic, void>({
      providesTags: ['USER'],
      query: () => ({
        url: 'buyer/me',
      }),
    }),
    updateProfileInfo: build.mutation<any, UpdateBuyerPersonalDetails>({
      invalidatesTags: ['USER'],
      query: (body) => ({
        url: 'buyer',
        method: 'PUT',
        body,
      }),
    }),
    schoolSearch: build.query<ISchool[], Omit<ISchool, '_id'>>({
      query: (body) => ({
        url: 'buyer/search',
        method: 'GET',
        params: body || '',
      }),
    }),
    createOrder: build.mutation<void, BuyerCreateGlobalOrderType>({
      invalidatesTags: ['ORDERS'],
      query: (body) => ({
        url: '/order',
        method: 'POST',
        body: {
          ...body,
          status: ORDER_STATUS_ENUM.PLACED,
        },
      }),
    }),
    getProducts: build.query<IProduct[], void>({
      providesTags: ['PRODUCT'],
      query: () => ({
        url: 'product',
      }),
    }),
    getSearchProducts: build.query<IProduct[], { name: string }>({
      providesTags: ['PRODUCT'],
      query: (body) => ({
        url: 'product/search',
        params: body,
      }),
    }),
    getProduct: build.query<IProduct, string>({
      providesTags: ['PRODUCT'],
      query: (id) => ({
        url: `product/${id}`,
      }),
    }),
    createProduct: build.mutation<void, IProduct>({
      invalidatesTags: ['PRODUCT'],
      query: (body) => ({
        url: 'product',
        method: 'POST',
        body: { ...body },
      }),
    }),
    getSellers: build.query<IReseller[], void>({
      query: () => ({
        url: 'seller',
      }),
    }),
    getOrdersList: build.query<Array<IBuyerOrderPublic>, void>({
      providesTags: ['ORDERS'],
      query: () => ({
        url: 'order',
      }),
    }),
    getOrder: build.query<IBuyerOrderPublic, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `order/${orderId}`,
      }),
    }),
    getQuotesList: build.query<Array<IBuyerQuotePublic>, void>({
      providesTags: ['QUOTES'],
      query: () => ({
        url: 'quote',
      }),
    }),
    getQuote: build.query<IBuyerQuotePublic, { quoteId: string }>({
      query: ({ quoteId }) => ({
        url: `quote/${quoteId}`,
      }),
    }),
    createQuote: build.mutation<any, ICreateQuote>({
      invalidatesTags: ['QUOTES'],
      query: (body) => ({
        url: 'order',
        method: 'POST',
        body: {
          ...body,
          status: ORDER_STATUS_ENUM.QUOTE,
        },
      }),
    }),
    getInsurance: build.query<IInsurance[], void>({
      query: () => ({
        url: 'insurance',
      }),
    }),
    getOffers: build.query<Array<ISellerOfferPublicType>, void>({
      query: () => ({
        url: 'seller/offer',
      }),
    }),
    getOffersByCategory: build.query<Array<ISellerOfferPublicType>, string>({
      query: (category) => ({
        providesTags: 'GROUPED_OFFERS',
        url: `seller/offer/offerGroup/${category}`,
      }),
    }),
    getOffer: build.query<ISellerOfferPublicType, string>({
      query: (id) => ({
        url: `seller/offer/${id}`,
      }),
    }),
    getCategories: build.query<Array<ICategoryPublicType>, void>({
      query: () => ({
        invalidatesTags: ['GROUPED_OFFERS'],
        providesTags: 'OFFER_GROUPS',

        url: 'offerGroup',
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useCreateOrderMutation,
  useGetProductsQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useGetSellersQuery,
  useCreateQuoteMutation,
  useGetOrdersListQuery,
  useGetOrderQuery,
  useGetQuotesListQuery,
  useGetQuoteQuery,
  useGetProfileInfoQuery,
  useUpdateProfileInfoMutation,
  useGetInsuranceQuery,
  useGetOffersQuery,
  useGetOfferQuery,
  useLazyGetOffersQuery,
  useLazyGetCategoriesQuery,
  useGetCategoriesQuery,
  useGetOffersByCategoryQuery,
  useLazyGetOffersByCategoryQuery,
  useSchoolSearchQuery,
  useGetSearchProductsQuery,
} = buyerApi;
