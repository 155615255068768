import styled from 'styled-components/macro';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { PaymentsTableRow } from '../../types';
import { formatDateShort } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';
import { FC } from 'react';
import { TableStyled } from '../../../../ui/Table/table.styled';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';

interface PaymentsTableProps {
  tableData: Array<PaymentsTableRow>;
}

const PaymentsTable: FC<PaymentsTableProps> = ({ tableData }) => {
  const { push } = useHistory();
  const onOrderClickHandler = (orderId: string, paymentType: string) => push(ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({ orderId, paymentType }));
  const tableConfig: ColumnsType<PaymentsTableRow> = [
    {
      title: 'Date Due',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (_v, value) => (
        formatDateShort(new Date(value.createdAt))
      ),
      sorter: (a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return 1;
        }
        return 0;
      },
      sortDirections: ['descend'],
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      dataIndex: 'invoiceNumber',
    },
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
      render: (v) => <BlueText onClick={onOrderClickHandler}>PO#{v}</BlueText>,
    },
    {
      title: 'Buyer',
      key: 'buyer',
      dataIndex: 'buyer',
    },
    {
      title: 'BTS Number',
      key: 'btsNumber',
      dataIndex: 'btsNumber',
    },
    {
      title: 'Amount Due',
      key: 'amountDue',
      dataIndex: 'amountDue',
      render: (_v, value) => (
        showPriceDigits(value.amountDue)
      )
    },
  ];

  return (
    <CustomTableStyled
      columns={tableConfig}
      pagination={false}
      dataSource={tableData}
    />
  );
}

const BlueText: FC<any> = styled.span`
  color: #2682DE;
  cursor: pointer;
`;

const CustomTableStyled: FC<TableProps<any>> = styled(TableStyled)`
  .ant-table-thead th {
    color: #6b7a87;
    font-weight: 600;
    font-size: 14px;
  }

  .ant-table-column-sorters {
    width: 75px;
  }

  .ant-table-column-sorter-inner {
    color: #6B7A87;
  }

  .ant-table tr {
    font-size: 14px;
    font-weight: 500;
  }
`;

export default PaymentsTable;
