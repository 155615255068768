import {SupplierUsersColumnsProps } from "../../types";

export const mockData: Array<SupplierUsersColumnsProps> = [
  {
    key: '1',
    firstName: 'John',
    secondName: 'Doe',
    email: 'johndoe@demoresellera@gmail.com',
    role: 'Admin',
    status: 'Active',
  },
  {
    key: '2',
    firstName: 'Fax',
    secondName: 'Doe',
    email: 'johndoe@demoresellera@gmail.com',
    role: 'User',
    status: 'Pasive',
  },
  {
    key: '3',
    firstName: 'Max',
    secondName: 'Doe',
    email: 'johndoe@demoresellera@gmail.com',
    role: 'Admin',
    status: 'Avtive',
  }
];

export const optionMockData = [ 'Microsoft', 'Lenovo', "Intel", 'Macbook' ];