import { Input } from 'antd';
import { useFormik } from 'formik';
import { CSSProperties, FC } from 'react';
import CloseIcon from '../../../img/close.svg';
import styled from 'styled-components';
import { ButtonDark } from '../../../ui/Button';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import { IInsurance } from './types';
import { insurancePolicyForm } from './insurers-schemas';
import SelectInput from '../../../ui/forms/SelectInput';
import { ReactComponent as ArrowDown } from '../../../img/icons/arrowDown.svg';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 420px;
`;

interface IProps {
  currentPolicyId: string;
  initialValues?: IInsurance;
  onClose: () => void;
  onCreateNewPolicy: (values: Omit<IInsurance, '_id'>) => void;
  onUpdatePolicy: (values: Omit<IInsurance, '_id'>, id: string) => void;
  isLoading: boolean;
}

const paymentScheduleOptions = ['Monthly', 'Quarterly', 'Annually', 'Upfront'];

const policyLengthOptions = ['Year', '2 Years', '3 Years', '4 Years'];

const containerStyle = { fontWeight: 'bold' } as CSSProperties;

const formConfig: FormConfig = [
  {
    title: 'Policy Name',
    name: 'name',
    component: StyledInput,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Description',
    name: 'description',
    component: StyledInput,
    containerStyle: containerStyle,
  },
  {
    title: 'Policy Length',
    name: 'policyLength',
    component: StyledSelectInput,
    isRequired: true,
    containerStyle: containerStyle,
    props: {
      initialData: policyLengthOptions,
      dropdownStyle: { zIndex: 999999 },
      suffixIcon: <ArrowDown style={{ color: '#909DA8' }} />,
    },
  },
  {
    title: 'Payment Schedule',
    name: 'paymentSchedule',
    component: StyledSelectInput,
    isRequired: true,
    containerStyle: containerStyle,
    props: {
      initialData: paymentScheduleOptions,
      dropdownStyle: { zIndex: 999999 },
      suffixIcon: <ArrowDown style={{ color: '#909DA8' }} />,
    },
  },
  {
    title: 'Instalment Amount',
    name: 'price',
    component: StyledInput,
    isRequired: true,
    containerStyle: containerStyle,
    props: {
      type: 'number',
      step: 0.01,
      min: 0.01,
    },
  },
];

const InsurerPolicyDetails: FC<IProps> = ({
  currentPolicyId,
  initialValues = {
    name: '',
    description: '',
    policyLength: policyLengthOptions[0],
    paymentSchedule: paymentScheduleOptions[0],
    price: 0,
  },
  onCreateNewPolicy,
  onUpdatePolicy,
  onClose,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: insurancePolicyForm,
    onSubmit: (values) => {
      if (currentPolicyId) {
        onUpdatePolicy(values, currentPolicyId);
      } else {
        onCreateNewPolicy(values);
      }
      onClose();
    },
  });
  return (
    <Container onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>
        {currentPolicyId ? 'Edit Insurance Policy' : 'New Insurance Policy'}
      </Title>
      {renderFormConfig(formConfig, formik)}
      {!isLoading ? (
        <StyledButtonDark type="submit">Save</StyledButtonDark>
      ) : (
        <StyledButtonDark disabled type="submit">
          Saving...
        </StyledButtonDark>
      )}
    </Container>
  );
};

const Container = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;

  position: relative;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 40px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 50px;
  align-self: flex-start;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export default InsurerPolicyDetails;
