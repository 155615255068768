import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonDark } from '../../../ui/Button';
import LayoutV1 from '../../layout/LayoutV1';
import Title from '../shared/header/Title';
import ROUTER_CONFIG from '../../../router/router-config';
import { useGetInsurersListQuery, useGetSearchInsurersListQuery } from '../../../api/services/superadmin/insurersApi';
import InsurerTable from './InsurersTable';

const InsurersPage: FC = () => {
  const { push } = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedInsurers, setSelectedInsurers] = useState<Array<string>>([]);
  const [submittedValue, setSubmittedValue] = useState<{ name: string }>({ name: '' });

  const { data: insurersData } = useGetInsurersListQuery();
  const { data: searchInsurers } = useGetSearchInsurersListQuery(submittedValue);
  const onDeleteInsurer = useCallback(() => console.log('selected'), []);

  const onInsurerRowClick = (insurerId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_INSURER_DETAILS.getPath({ insurerId }));

  const onCreateNewInsurerClick = () => push(ROUTER_CONFIG.SUPER_ADMIN_INSURER_CREATE.getPath());

  const onSubmitSearchValue = () => {
    setSubmittedValue({ name: searchValue });
  };

  const insurers = useMemo(() => {
    if (!searchValue) {
      setSubmittedValue({ name: '' });
    }
    if (submittedValue.name) {
      return searchInsurers;
    }
    return insurersData;
  }, [searchInsurers, insurersData, searchValue, submittedValue.name]);


  const tableData = useMemo(
    () => insurers?.map((insurer) => ({
      key: insurer._id,
      name: insurer.name,
      email: insurer.contactEmail,
      address: insurer.addressLine1,
      telephoneNumber: insurer.telephoneNumber,
    })) || [],
    [insurers],
  );

  return (
    <LayoutV1>
      <Container>
        <TitleHolder>
          <Title style={{ margin: '0 0 10px 0' }}>Insurers</Title>
          <StyledButton onClick={onCreateNewInsurerClick}>
            + Create New Insurer Account
          </StyledButton>
        </TitleHolder>
        <InsurerTable
          insurersTableData={tableData}
          onSelectedInsurers={setSelectedInsurers}
          selectedInsurers={selectedInsurers}
          onDeleteInsurer={onDeleteInsurer}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onInsurerRowClick={onInsurerRowClick}
          onSubmitSearchValue={onSubmitSearchValue}
        />
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div``;

const StyledButton = styled(ButtonDark)`
  padding: 11px 35px;
  font-size: 16px;
  height: fit-content;
  align-self: center;
`;

const TitleHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default InsurersPage;
