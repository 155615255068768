import { FC, useCallback, useState } from 'react';
import LayoutV1 from '../../../layout/LayoutV1';
import { mockBuyersTableData } from './buyersMockData.config';
import { useGetSearchBuyersListQuery } from '../../../../api/services/superadmin/buyersApi';
import { useHistory } from "react-router-dom";
import ROUTER_CONFIG from "../../../../router/router-config";
import { Tabs } from "antd";
import { StyledTabPane } from "../../../../ui/Table/table.styled";
import ActiveBuyersTable from "./ActiveBuyersTable";
import PendingVerifyBuyersTable from "./PendingVerifyBuyersTable";
import RejectedBuyersTable from "./RejectedBuyersTable";
import BodyContainer from "../../../../ui/elements/BodyContainer";

const BuyersPage: FC = () => {
  const {push} = useHistory();
  const [ selectedUsers, setSelectedUsers ] = useState<Array<string>>([]);
  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ submittedSearchValue, submitSearchValue ] = useState<{ school: string }>({school: ''});
  
  const {data: searchBuyers} = useGetSearchBuyersListQuery(submittedSearchValue);
  
  const onSubmitSearch = useCallback(() => {
    submitSearchValue({school: searchValue});
  }, [ searchValue ]);
  
  const onBuyerRowClick = (offerId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_BUYER_ORDERS_VIEW.getPath({offerId}));
  
  return (
    <LayoutV1>
      <BodyContainer title="Buyers">
        <Tabs defaultActiveKey="buyersDetails">
          <StyledTabPane tab="Active" key="active">
            <ActiveBuyersTable
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              onSubmitSearch={onSubmitSearch}
              tableData={mockBuyersTableData}
              onSelectUser={setSelectedUsers}
              selectedUsers={selectedUsers}
              onBuyerRowClick={onBuyerRowClick}
            />
          </StyledTabPane>
          <StyledTabPane tab="Pending Verification" key="pendingVerification">
            <PendingVerifyBuyersTable/>
          </StyledTabPane>
          <StyledTabPane tab="Rejected" key="rejected">
            <RejectedBuyersTable/>
          </StyledTabPane>
        </Tabs>
      </BodyContainer>
    </LayoutV1>
  );
}

export default BuyersPage;