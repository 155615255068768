import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetProductQuery,
  useGetProductsListQuery,
} from '../../../api/services/superadmin/productApi';
import LayoutV1 from '../../layout/LayoutV1';
import ProductDetails from './ProductDetails';

const ProductDetailsPage: FC = () => {
  const { productId } = useParams<{
    productId: string;
  }>();

  const { data: product } = useGetProductQuery(productId);
  const { data: productsList } = useGetProductsListQuery();

  return (
    <LayoutV1>
      {product && productsList && (
        <ProductDetails product={product} productsList={productsList} />
      )}
    </LayoutV1>
  );
};

export default ProductDetailsPage;
