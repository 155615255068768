import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router';
import { DetailsButton } from '../../styled/buttons';
import EligibleTable from './EligibleTable';
import {
  AddNodesForm,
  AddSupplierForm,
  AddSupplierWithPriceForm,
  EligibleNodeTableRow,
  EligibleSuppliersTableRow,
  JoinedSuppliersTableRow,
} from '../../types';
import ModalInPortal from '../../../../../ui/Modal/ModalPortal';
import AddSupplierModalContent from './eligible-table/AddSupplierModalContent';
import AddJoinedSupplierModalContent from './eligible-table/AddJoinedSupplierModalContent';
import AddNodeModalContent from './eligible-table/AddNodeModalContent';
import { joinedSuppliersTableConfig } from '../configs';
import { useGetOfferDetailsQuery } from '../../../../../api/services/superadmin/offersApi';

const eligibleSuppliersTableData: Array<EligibleSuppliersTableRow> = [
  {
    key: '1',
    supplier: 'Demo Supplier A',
  },
  {
    key: '2',
    supplier: 'Demo Supplier B',
  },
];

const joinedSuppliersTableData: Array<JoinedSuppliersTableRow> = [
  {
    key: '1',
    supplier: 'Demo Supplier A',
    customerBuyPrice: '£ 300',
  },
  {
    key: '2',
    supplier: 'Demo Supplier B',
    customerBuyPrice: '£ 400',
  },
];

const eligibleNodesTableData: Array<EligibleNodeTableRow> = [
  {
    key: '1',
    node: 'Intel',
  },
];

const EligibleWrapper: React.FC = () => {
  const [selectedEligibleSuppliersRows, setSelectedEligibleSuppliersRows] = useState<Array<string>>([]);
  const [selectedJoinedSuppliersRows, setSelectedJoinedSuppliersRows] = useState<Array<string>>([]);
  const [selectedEligibleNodesRows, setSelectedEligibleNodesRows] = useState<Array<string>>([]);

  const { offerId } = useParams<{ offerId: string }>();
  // const { data: offerDetails } = useGetOfferDetailsQuery(offerId);

  const onDeleteEligibleSuppliersClickHandler = () => console.log(selectedEligibleSuppliersRows);
  const onDeleteJoinedSuppliersClickHandler = () => console.log(selectedJoinedSuppliersRows);
  const onDeleteEligibleNodesClickHandler = () => console.log(selectedEligibleNodesRows);

  const onSubmitAddEligibleSupplierForm = (values: AddSupplierForm) => console.log(values);
  const onSubmitAddJoinedSupplierForm = (values: AddSupplierWithPriceForm) => console.log(values);
  const onSubmitAddNodeForm = (values: AddNodesForm) => console.log(values);

  const onEligibleSupplierDetailsClickHandler = (supplierId: string) => {
    console.log(supplierId);
  };
  const onNodesDetailsClickHandler = (supplierId: string) => {
    console.log(supplierId);
  };

  const AddSupplierModal = ModalInPortal();
  const onAddSupplierClickHandler = () => AddSupplierModal.onShow();

  const AddJoinedSupplierModal = ModalInPortal();
  const onAddJoinedSupplierClickHandler = () => AddJoinedSupplierModal.onShow();

  const AddNodeModal = ModalInPortal();
  const onAddNodeClickHandler = () => AddNodeModal.onShow();

  const eligibleSuppliersTableConfig: ColumnsType<EligibleSuppliersTableRow> = useMemo(() => [
    {
      title: 'Suppliers',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: '175px',
      render: (_v, r) => (
        <DetailsButton onClick={() => onEligibleSupplierDetailsClickHandler(r.key)}>
          View Supplier Details
        </DetailsButton>
      ),
    },
  ], [onEligibleSupplierDetailsClickHandler]);

  const eligibleNodeTableConfig: ColumnsType<EligibleNodeTableRow> = useMemo(() => [
    {
      title: 'Node',
      dataIndex: 'node',
      key: 'node',
    },
    {
      title: '',
      dataIndex: 'link',
      key: 'link',
      width: '175px',
      render: (_v, r) => (
        <DetailsButton onClick={() => onNodesDetailsClickHandler(r.key)}>
          View Node
          Details
        </DetailsButton>
      ),
    },
  ], [onNodesDetailsClickHandler]);

  return (
    <>
      <EligibleTable
        onDeleteRowClickHandler={onDeleteEligibleSuppliersClickHandler}
        tableData={eligibleSuppliersTableData}
        selectedRows={selectedEligibleSuppliersRows}
        setSelectedRows={setSelectedEligibleSuppliersRows}
        tableName="Eligible Suppliers"
        addButtonName="+ Add Supplier"
        tableConfig={eligibleSuppliersTableConfig}
        onAddClickHandler={onAddSupplierClickHandler}
      />
      {AddSupplierModal.ModalRender && (
        <AddSupplierModal.ModalRender>
          <AddSupplierModalContent
            onClose={AddSupplierModal.onClose}
            onSubmit={onSubmitAddEligibleSupplierForm}
          />
        </AddSupplierModal.ModalRender>
      )}
      <EligibleTable
        onDeleteRowClickHandler={onDeleteJoinedSuppliersClickHandler}
        tableData={joinedSuppliersTableData}
        selectedRows={selectedJoinedSuppliersRows}
        setSelectedRows={setSelectedJoinedSuppliersRows}
        tableName="Joined Suppliers"
        addButtonName="+ Add Supplier"
        tableConfig={joinedSuppliersTableConfig}
        onAddClickHandler={onAddJoinedSupplierClickHandler}
        isSwitch={false}
      />
      {AddJoinedSupplierModal.ModalRender && (
        <AddJoinedSupplierModal.ModalRender>
          <AddJoinedSupplierModalContent
            onClose={AddJoinedSupplierModal.onClose}
            onSubmit={onSubmitAddJoinedSupplierForm}
          />
        </AddJoinedSupplierModal.ModalRender>
      )}
      <EligibleTable
        onDeleteRowClickHandler={onDeleteEligibleNodesClickHandler}
        tableData={eligibleNodesTableData}
        selectedRows={selectedEligibleNodesRows}
        setSelectedRows={setSelectedEligibleNodesRows}
        tableName="Eligible Nodes"
        addButtonName="+ Add Node"
        tableConfig={eligibleNodeTableConfig}
        onAddClickHandler={onAddNodeClickHandler}
      />
      {AddNodeModal.ModalRender && (
        <AddNodeModal.ModalRender>
          <AddNodeModalContent
            onClose={AddNodeModal.onClose}
            onSubmit={onSubmitAddNodeForm}
          />
        </AddNodeModal.ModalRender>
      )}
    </>
  );
};

export default EligibleWrapper;
