import { FC, useState } from 'react';
import { Col, Row, Select, Form, Button } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/filled-arrow-down.svg';
import {
  SubTitle,
  InputLabel,
  StyledButtonDark,
  TableWrapper,
  LongSelectWrapper,
  StyledLongSelect,
  Container,
} from '../styled/new-offer.styled';
import InsurancePolicyTable from '../new-offer-tables/insurance-policy-table/InsurancePolicyTable';
import { NewOffer } from '../../../types';

const { Option } = Select;

interface InsurancePolicyProps {
  offers: Array<NewOffer>;
}

const InsurancePolicySection: FC<InsurancePolicyProps> = ({ offers }) => {
  const [data, setData] = useState<any>([]);

  const onAddInsurancePolicy = () => {
    setData((prev: any) => ([...prev, {id: Date.now()}]));
  };

  const onRemoveInsurancePolicy = (id: any) => {
    const newDataSource = data.filter((value: any) => value.id !== id);
    setData(newDataSource);
  };

  return (
    <Row
      style={{ marginBottom: '100px' }}
    >
      <Col
        span={16}
      >
        <SubTitle>6. Insurance Policy</SubTitle>
          <InputLabel>
            Insurer 1 (Default)
          </InputLabel>
          <LongSelectWrapper>
            <Form.Item
              name="insurance-policy"
              style={{ margin: "0px" }}
            >
              <StyledLongSelect
                defaultValue="Compucover"
                suffixIcon={<ArrowDown />}
              >
                {offers.map((item) => (
                  <Option
                    value={item.insurances?.[0]?.insurer}
                    key={item._id}
                    style={{ lineHeight: 2, color: '#102F54' }}
                  >
                    {item.insurances?.[0] ? (item.insurances?.[0]?.insurer) : 'no data'}
                  </Option>
                  ))}
              </StyledLongSelect>
            </Form.Item>
        </LongSelectWrapper>
      </Col>
      <TableWrapper>
      {offers &&
        <InsurancePolicyTable
          tableData={offers?.[0]?.insurances}
        >
        </InsurancePolicyTable>}
      </TableWrapper>
      <StyledButtonDark
        style={{marginTop:'22px'}}
        onClick={onAddInsurancePolicy}
      >
        + Add Insurer
      </StyledButtonDark>
      {data.map((item: any) => {
        return (
          <Container
            key={item.id}
          >
            <LongSelectWrapper>
              <Form.Item
                name="insurance-policy"
                style={{ margin: "0px" }}
              >
                <StyledLongSelect
                  defaultValue="Compucover"
                  suffixIcon={<ArrowDown />}
                >
                  {offers.map((item) => (
                    <Option
                      value={item.insurances?.[0]?.insurer}
                      key={item._id}
                      style={{ lineHeight: 2, color: '#102F54' }}
                    >
                      {item.insurances?.[0] ? (item.insurances?.[0]?.insurer) : 'no data'}
                    </Option>
                    ))}
                </StyledLongSelect>
              </Form.Item>
            </LongSelectWrapper>
            <TableWrapper>
            {offers &&
              <InsurancePolicyTable
                tableData={offers?.[0]?.insurances}
              >
              </InsurancePolicyTable>}
            </TableWrapper>
              <Button
                type="primary"
                danger
                ghost
                onClick={() => onRemoveInsurancePolicy(item.id)}
                style={{ background: '#fff', width: '130px', height: '42px', marginTop: 22, fontWeight: 700 }}
                >
                  Remove Insurer
              </Button>
          </Container>
        );
      })}
    </Row>
  );
};

  export default InsurancePolicySection;
