import { Tabs } from "antd";
import styled from "styled-components/macro";
import InfoTable from '../../../../../ui/elements/InfoTable'
import { serializeStatisticsPrice } from '../../../../../utils/formats';
import RecorderTabel from "./RecorderTabel";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import ROUTER_CONFIG from "../../../../../router/router-config";

const {TabPane} = Tabs;

const RecorderPayments = [
  {
    title: 'RECORDER RECEIVED',
    value: serializeStatisticsPrice(30000.00)
  },
  {
    title: 'RECORDER OUTGOING',
    value: serializeStatisticsPrice(20000.00)
  },
  {
    title: 'EXPECTED RECEIVED',
    value: serializeStatisticsPrice(30000.00),
    description: 'This Month'
  },
  {
    title: 'EXPECTED OUTGOING',
    value: serializeStatisticsPrice(20000.00),
    description: 'This Month'
  },
];

const RecorderPaymentsView: FC = () => {
    return(
      <>
      <StyledInfoTable data={RecorderPayments} />
      <Tabs defaultActiveKey="2">
        <StyledTabPane tab="Expected Payments" key="1">
        </StyledTabPane>
        <StyledTabPane tab="Recorded Payments" key="2">
            <RecorderTabel/>
        </StyledTabPane>
        <StyledTabPane tab="SW Invoices" key="3">
        </StyledTabPane>
      </Tabs>
      </>
    )
}

const StyledTabPane = styled(TabPane)`
  padding-top: 20px;
`;
const StyledInfoTable = styled(InfoTable)`
  margin: 40px 0;
  width: 80%;
`;

export default RecorderPaymentsView