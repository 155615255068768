/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import { InputProps, Input } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { TableStyled } from '../../../shared/table/TableStyled';

export interface ITableCustomProps {
  shadow?: boolean;
}

export const OverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableCreateQuote = styled(TableStyled)<ITableCustomProps>`
  .ant-table-thead th {
    color: #6b7a87;
    font-weight: 600;
    font-size: 14px;
  }

  .ant-table {
    // eslint-disable-next-line no-confusing-arrow
    filter: ${(props) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      props.shadow
        ? 'drop-shadow(0px 1px 8px rgba(161, 166, 172, 0.411))'
        : 'none'};
    border: none !important;
  }
`;

export const InputTable: FC<InputProps> = styled(Input)`
  text-align: center;
  border-radius: 5px;
  height: 37px;
  width: 110px;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.textPrimary};
  }
`;
