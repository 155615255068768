import * as yup from 'yup';
import { phoneRegExp } from '../../../utils/phoneRegExp';
import { ILease, ILessor } from './types';

export const createLessorForm: yup.SchemaOf<Omit<ILessor, '_id'>> = yup.object({
  name: yup.string().required(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string().optional(),
  addressLine3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  telephoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .optional(),
  contactEmail: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  postcode: yup.string().required(),
  defaultAdministrativeFee: yup.number().optional(),
  defaultDocumentationFee: yup.number().optional(),
});

export const leaseForm: yup.SchemaOf<
  Omit<ILease, '_id' | 'lessor'>
> = yup.object({
  description: yup.string().required(),
  type: yup.string().required(),
  policyLength: yup.string().required(),
  paymentSchedule: yup.string().required(),
  rate: yup.number().required(),
  residualValueRate: yup.number().required(),
});
