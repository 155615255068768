import LayoutV1 from '../../../layout/LayoutV1';
import { FC } from "react";
import BodyContainer from "../../../../ui/elements/BodyContainer";

const NodesPage: FC=  () => {
  return (
    <LayoutV1>
      <BodyContainer title="Nodes">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem dolores expedita natus totam velit! Aperiam deleniti distinctio, est, excepturi explicabo magnam molestiae nam odit porro quas sequi suscipit ullam voluptatem.
      </BodyContainer>
    </LayoutV1>
  );
}

export default NodesPage;