import { FC } from 'react';
import { useGetSupplierPaymentsTransferQuery } from '../../../../api/services/supplier/paymentsApi';
import SupplierLayout from '../../layout/SupplierLayout';
import BalanceTransfers from './BalanceTransfers';

const BalanceTransfersPage: FC =  () => {
  const { data: transfer } = useGetSupplierPaymentsTransferQuery();
  const onViewClickHandler = (id: string) => console.log(id);

  return (
    <SupplierLayout>
       {transfer && <BalanceTransfers
        tableData={transfer!.items.flat()}
        onViewClickHandler={onViewClickHandler}
        />}
    </SupplierLayout>
  );
}

export default BalanceTransfersPage;
