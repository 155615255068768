import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import dayjs from 'dayjs';
import Input from '../../../../ui/forms/Input';
import { RadioGroup, RadioItem } from '../../../../ui/forms/Radio';
import SelectInput from '../../../../ui/forms/SelectInput';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import { FormConfig, renderFormConfig } from '../../../../utils/form';
import { createQuoteSchema } from '../../supplier-schemas';
import { ORDER_TYPES, QuoteCreateType, QuoteServiceItem } from '../../types';
import ItemsTable from './ItemsTable';
import ServicesTable from './ServicesTable';
import Checkbox from '../../../../ui/forms/Checkbox';
import QuoteSummary from './QuoteSummary';
import InputWrap from '../../../../ui/forms/InputWrap';
import { useGetProductsQuery, useSchoolSearchQuery } from '../../../../api/services/buyerApi';
import Datepicker from '../../../../ui/forms/DatePicker';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';
import { ISchool } from '../../../buyer/types';
import { ReactComponent as Calendar } from '../../../../img/icons/calendar.svg';
import SearchInput from '../../../../ui/forms/SearchInput';
import AutocompleteInput from '../../../../ui/forms/Autocomplete';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 424px;
  padding: 11px;
  height: 36px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  max-width: 424px;
  padding: 11px;
  height: 36px;
`;

const StyledAutocomplete = styled(AutoComplete)`
  width: 100%;
  max-width: 424px;
  padding: 11px;
  height: 36px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
  max-width: 424px;

  .ant-select-selector {
    height: 36px !important;
    min-height: 36px !important;
    box-sizing: border-box;
  }

  .ant-select-selection-placeholder {
    line-height: 35px !important;
    color: #6B7A87;
  }

  .ant-select-selection-search-input, .ant-select-selection-item {
    line-height: 35px !important;
  }
`;

const OrderDetailsFormConfig: FormConfig = [
  {
    title: 'Lease Length',
    isRequired: true,
    name: 'leaseLength',
    component: StyledSelectInput,
    props: {
      initialData: ['2 Years', '3 Years'],
      size: 'large',
      suffixIcon: (<ArrowDown />),
    },
  },
  {
    title: 'Lease Provider',
    isRequired: true,
    name: 'leaseProvider',
    component: StyledSelectInput,
    props: {
      initialData: ['Wesleyan Bank'],
      size: 'large',
      suffixIcon: (<ArrowDown />),
    },
  },
  {
    title: 'Payment Schedule',
    isRequired: true,
    name: 'paymentSchedule',
    component: StyledSelectInput,
    props: {
      initialData: ['Monthly', 'Quarterly', 'Quarterly (In arrears)', 'Annually'],
      size: 'large',
      suffixIcon: (<ArrowDown />),
    },
  },
];

const initialSearchSchool = {
  address: '',
  schoolName: '',
};

interface BuildQuoteProps {
  onFormSubmit: (values: QuoteCreateType) => void;
  services: Array<QuoteServiceItem>;
}

export default function BuildQuote({
  onFormSubmit,
  services,
}: BuildQuoteProps) {
  const [searchSchool, setSearchSchool] = useState<Omit<ISchool, '_id'>>(initialSearchSchool);

  const { data: products } = useGetProductsQuery();
  const { data: schools } = useSchoolSearchQuery(initialSearchSchool);
  const {
    data: searchSchools,
    refetch,
  } = useSchoolSearchQuery(searchSchool);

  console.log(searchSchools);
  console.log(schools);
  console.log(products);

  const formik = useFormik<QuoteCreateType>({
    enableReinitialize: true,
    initialValues: {
      schoolId: '',
      recipientName: '',
      recipientEmail: '',
      deliveryCharge: '',
      orderType: ORDER_TYPES['Outright Purchase'],
      items: [
        {
          productId: '',
          quantity: 0,
          price: '',
        },
      ],
      services: [],
      includeDeviceInsurance: false,
      expirationDate: '',
      schoolName: '',
    },
    validationSchema: createQuoteSchema,
    onSubmit: (values) => {
      onFormSubmit(values);
    },
  });

  console.log(formik.values);
  console.log(searchSchool.schoolName);

  const SchoolFormConfig: FormConfig = [
    {
      title: 'Recipient Name',
      isRequired: true,
      name: 'recipientName',
      component: StyledInput,
      props: {
        disabled: !formik.values?.schoolName,
        placeholder: formik.values?.schoolName ? 'Recipient Name' : 'Please select a school or a trust first',
      },
    },
    {
      title: 'Recipient Email',
      isRequired: true,
      name: 'recipientEmail',
      component: StyledInput,
      props: {
        disabled: !formik.values?.schoolName,
        placeholder: formik.values?.schoolName ? 'Recipient Email' : 'Please select a school or a trust first',
      },
    },
  ];

  const onAddNewProduct = () => formik.setFieldValue('items', [...formik.values.items, {
    productId: '',
    quantity: 0,
    price: '',
  }]);
  const onAddNewService = (service: QuoteServiceItem) => {
    const alreadyAdded = formik.values.services ? formik.values.services.find((s) => s.type === service.type) : false;

    if (alreadyAdded) {
      const newValue = formik.values.services && formik.values.services.filter((s) => s.type !== service.type);
      formik.setFieldValue('services', newValue);
    } else {
      formik.setFieldValue('services', [...(formik.values?.services || []), service]);
    }
  };

  const isShowOrderDetailsBlock = (formik.values.orderType === ORDER_TYPES['Financing Lease'] || formik.values.orderType === ORDER_TYPES['Operating Lease']);

  useEffect(() => {
    refetch();
  }, [searchSchool.schoolName, refetch]);

  useEffect(() => {
    formik.setFieldValue('schoolName', '');
  }, [searchSchool.schoolName]);

  useEffect(() => {
    if (formik.values.schoolName) {
      formik.setFieldValue('schoolId', '');
    }
  }, [formik.values.schoolName]);

  useEffect(() => {
    if (formik.values.schoolId) {
      formik.setFieldValue('schoolName', '');
      formik.setFieldValue('recipientName', '');
      formik.setFieldValue('recipientEmail', '');
    }
  }, [formik.values.schoolId]);

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>Build Quote</Title>
      <SchoolBlock>
        <SubTitle>
          1. Select a School or a Trust & Recipient
        </SubTitle>
        <DescriptionText>
          Please select a school or a trust from the dropdown below.
          Only schools and trusts which have selected Demo Reseller A as their preferred reseller will appear in this
          dropdown.
        </DescriptionText>
        <div>
          {schools && renderFormConfig([
            {
              title: 'Select School or Trust',
              isRequired: true,
              name: 'schoolId',
              component: StyledSelectInput,
              props: {
                initialData: schools,
                suffixIcon: <ArrowDown />,
                placeholder: '(Select)',
                size: 'large',
                optionName: 'schoolName',
              },
            },
          ], formik)}
          <Divider>Or</Divider>
          <DescriptionText style={{ marginBottom: 18 }}>
            Create a quote for a school or a trust without an
            account
          </DescriptionText>
          {renderFormConfig([
            {
              title: 'Find School or Trust',
              isRequired: true,
              name: 'schoolName',
              component: AutocompleteInput,
              props: {
                options: searchSchools && searchSchools.map((data: ISchool) => ({
                  key: data._id,
                  value: data.schoolName,
                })),
                onSearch: (value: string) => {
                  setSearchSchool({
                    ...searchSchool,
                    schoolName: value,
                  });
                },
                placeholder: 'School/Trust Name',
                onSelect: (value: string, option: any) => {
                  formik.setFieldValue('schoolName', option.key);
                },
              },
            },
          ], formik)}
        </div>
        <div>
          {renderFormConfig(SchoolFormConfig, formik)}
        </div>
      </SchoolBlock>
      <OrderBlock>
        <SubTitle>2. Order Type</SubTitle>
        <DescriptionText>
          Please select if the quote is for an operating/financing lease or an outright
          purchase?
        </DescriptionText>
        <StyledRagioGroup
          value={formik.values.orderType}
          onChange={(e) => formik.setFieldValue('orderType', e.target.value)}
        >
          {Object.values(ORDER_TYPES)
            .map((order) => (
              <RadioItem key={order} value={order}>{order}</RadioItem>
            ))}
        </StyledRagioGroup>
        <LightDescriptionText>
          If lease is selected, the customer will also receive a sepearate quote from the Lessor
          including lease pricing. Indicative lease pricing can be viewed when adding the line items.
        </LightDescriptionText>
        {isShowOrderDetailsBlock && (
          <OrderDetailsBlock>
            {renderFormConfig(OrderDetailsFormConfig, formik)}
          </OrderDetailsBlock>
        )}
      </OrderBlock>
      <ItemsBlock>
        <SubTitle>3. Add Items</SubTitle>
        <DescriptionText>Press the ‘Add Item’ button below to add new line items.</DescriptionText>
        {products && (
          <ItemsTable
            data={formik.values.items}
            setValue={formik.setFieldValue}
            onAddNewItem={onAddNewProduct}
            name="items"
            initialData={products}
          />
        )}
        <InputWrap title="Delivery charge">
          <DeliveryChargeBlock>
            <StyledInput
              value={formik.values.deliveryCharge || ''}
              onChange={(e) => formik.setFieldValue('deliveryCharge', e.target.value)}
              onFocus={(e) => formik.setFieldValue('deliveryCharge', e.target.value.replace(/[^\d.]/g, ''))}
              onBlur={(e) => formik.setFieldValue('deliveryCharge', Number(e.target.value) ? `£${e.target.value}` : '')}
            />
            <StyledLightButton
              onClick={() => formik.setFieldValue('deliveryCharge', '')}
            >
              Remove
            </StyledLightButton>
          </DeliveryChargeBlock>
        </InputWrap>
      </ItemsBlock>
      <ServiceBlock>
        <SubTitle>4. Add Services</SubTitle>
        <DescriptionText>Add Services to your Quote</DescriptionText>
        <ServicesTable
          data={services}
          selectedServices={formik.values.services || []}
          onAddNewItem={onAddNewService}
        />
      </ServiceBlock>
      <InsuranceBlock>
        <SubTitle>5. Insurance</SubTitle>
        {renderFormConfig([
          {
            title: (<DescriptionText>
              The financing partner requires a school to insure the devices. Would the customer like to include an
              insurance quote?
              <BoldDescriptionText> Tick if yes</BoldDescriptionText>
            </DescriptionText>),
            name: 'includeDeviceInsurance',
            component: Checkbox,
            containerStyle: {
              flexDirection: 'row-reverse',
              gap: '20px',
            },
          },
        ], formik)}
      </InsuranceBlock>
      <ExpirationDateBlock>
        <SubTitle>6. Quote Expiration Date</SubTitle>
        <DescriptionText>
          Once this date is passed the quote is void and can’t be used to place an
          order
        </DescriptionText>
        <StyledDatePicker
          setFieldValue={(field, value) => formik.setFieldValue('expirationDate', dayjs(value))}
          name="expirationDate"
          suffixIcon={<Calendar />}
        />
      </ExpirationDateBlock>
      {formik.values.expirationDate && formik.values.items && products && (
        <QuoteSummary values={formik.values} products={products} />
      )}
      <SubmitButton type="submit">Generate Quote</SubmitButton>
    </Container>
  );
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
`;

const Divider = styled.div`
  font-size: 14px;
  color: #6A7E95;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 5px;
  align-items: center;
  margin: 22px 0;
  max-width: 424px;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #EBF2FA;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
`;

const SubTitle = styled.h2`
  margin: 0;
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const DescriptionText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const LightDescriptionText = styled(DescriptionText)`
  color: #6B7A87;
`;

const BoldDescriptionText = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: #102f54;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  max-width: 520px;
`;

const SchoolBlock = styled(ContentBlock)`
  label {
    color: #102F54;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
`;

const OrderBlock = styled(ContentBlock)``;

const StyledRagioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const OrderDetailsBlock = styled.div`
  padding: 26px;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

const ItemsBlock = styled(ContentBlock)`
  max-width: none;

  label {
    color: #102F54;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
`;

const DeliveryChargeBlock = styled.div`
  display: flex;
  gap: 13px;
`;

const StyledLightButton = styled(ButtonLight)`
  height: 36px;
  width: 150px;
`;

const ServiceBlock = styled(ContentBlock)`
  max-width: none;
`;

const InsuranceBlock = styled(ContentBlock)``;

const ExpirationDateBlock = styled(ContentBlock)``;

const StyledDatePicker = styled(Datepicker)`
  max-width: 150px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 13px 100px;
  align-self: flex-start;
`;
