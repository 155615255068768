import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import { FormConfig, renderFormConfig } from '../../../../../../utils/form';
import CloseIcon from '../../../../../../img/close.svg';
import { AddSupplierForm } from '../../../types';
import { addSupplierForm } from '../../offers-schemas';
import { CloseButton } from '../../../styled/buttons';
import {
  ContainerForPopover,
  DarkButtonForPopover,
  DetailsButtonForPopover, LinkAfterButton, TextForPopover, TitleForPopover, StyledSelect,
} from '../../../styled/popover';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import { useGetSuppliersListQuery } from '../../../../../../api/services/superadmin/suppliersApi';

interface AddSupplierModalContentProps {
  onClose: () => void;
  onSubmit: (values: AddSupplierForm) => void;
}

const AddSupplierModalContent: React.FC<AddSupplierModalContentProps> = ({ onClose, onSubmit }) => {
  const { data: suppliers } = useGetSuppliersListQuery();

  const formConfig: FormConfig = useMemo(() => (
    [
      {
        title: 'Supplier Name',
        name: 'supplierName',
        component: StyledSelect,
        props: {
          initialData: suppliers,
          suffixIcon: <ArrowDown />,
          dropdownStyle: {
            zIndex: 200000,
            position: 'fixed',
          },
          optionName: 'name',
        },
        isRequired: true,
      },
    ]
  ), [suppliers]);

  const formik = useFormik({
    initialValues: {
      supplierName: suppliers ? suppliers[0].name : '',
    },
    validationSchema: addSupplierForm,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <ContainerForPopover onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <TitleForPopover>Add Supplier</TitleForPopover>
      {suppliers && renderFormConfig(formConfig, formik)}
      <DarkButtonForPopover type="submit">Add Supplier</DarkButtonForPopover>
      <StyledLinkAfterButton>
        <TextForPopover>Or</TextForPopover>
        <DetailsButtonForPopover>Create new Supplier account</DetailsButtonForPopover>
      </StyledLinkAfterButton>
    </ContainerForPopover>
  );
};

const StyledLinkAfterButton = styled(LinkAfterButton)`
  max-width: 229px;
`;

export default AddSupplierModalContent;
