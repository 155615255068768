import { FC, useMemo, useState } from 'react';
import LayoutV1 from '../../../layout/LayoutV1';
import { CustomTableProps } from '../../../../ui/Table/types';
import {
  useDeleteSupplierMutation, useGetSuppliersListQuery
} from '../../../../api/services/superadmin/suppliersApi';
import { SupplierColumnProps } from './types';
import { ButtonBlock, StyledButtonDark, TableControlWrap, TitleBlock } from "./suppliers.styled";
import { MainHeader } from "../../../../ui/Headers";
import { Link, useHistory } from "react-router-dom";
import ROUTER_CONFIG from "../../../../router/router-config";
import SearchInput from "../../../../ui/Serach/SearchInput";
import { TableStyled } from "../../../../ui/Table/table.styled";
import BodyContainer from "../../../../ui/elements/BodyContainer";
import { tableConfig } from "./table.config";
import SupplierTableControls from "./SupplierTableControls";

interface OffersSuppliersPageProp extends CustomTableProps {
  tableData?: Array<SupplierColumnProps>;
}

const SuppliersPage: FC<OffersSuppliersPageProp> = () => {
  const {push} = useHistory();
  
  const {data: getSuppliers} = useGetSuppliersListQuery();
  const [ deleteSupplier ] = useDeleteSupplierMutation();
  
  const [ selectedUsers, setSelectedUsers ] = useState<Array<string>>([]);
  const [ searchValue, setSearchValue ] = useState<string>('');
  
  const onDeleteSupplier = async () => {
    await deleteSupplier(selectedUsers[0]);
  };
  
  const onRowClickHandler = (offerId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_SUPPLIER_OFFER_VIEW.getPath({offerId}));
  
  const arraySearch = (array: any, keyword: string) => {
    const searchTerm = keyword.toLowerCase();
    return array?.filter((value: any) => (
      value.organisationType.toLowerCase().match(new RegExp(searchTerm, 'g'))
      ||
      value.name.toLowerCase().match(new RegExp(searchTerm, 'g'))
      ||
      value.telephoneNumber.toLowerCase().match(new RegExp(searchTerm, 'g'))
    ));
  };
  
  const result = arraySearch(getSuppliers, searchValue);
  
  const tableData: Array<SupplierColumnProps> = useMemo(() =>
      result
        ? result?.map((supplier: any) => ({
          key: supplier._id,
          _id: supplier._id,
          name: supplier.name,
          organisationType: supplier.organisationType,
          urn: supplier?.telephoneNumber,
        }))
        : [],
    [ result ]
  );
  
  return (
    <LayoutV1>
      <BodyContainer>
        <TitleBlock>
          <MainHeader>Suppliers</MainHeader>
          <ButtonBlock>
            <StyledButtonDark>
              <Link style={{color: '#fff'}} to={ROUTER_CONFIG.SUPER_ADMIN_OFFERS_SUPPLIERS_NEW_ACCOUNT.getPath()}>
                + New Supplier Account
              </Link>
            </StyledButtonDark>
          </ButtonBlock>
        </TitleBlock>
        <TableControlWrap>
          <SearchInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Supplier Organisation Name"/>
          <SupplierTableControls
            onDeleteSupplier={onDeleteSupplier}
          />
        </TableControlWrap>
        <TableStyled
          rowSelection={{
            type: 'checkbox',
            onChange: (ids) => {
              setSelectedUsers(ids as Array<string>);
            },
            selectedRowKeys: selectedUsers,
          }}
          columns={tableConfig}
          dataSource={tableData}
          onRow={(rowData) => ({
            onClick: () => onRowClickHandler ? onRowClickHandler(rowData?._id) : null,
            style: {cursor: 'pointer'},
          })}
        />
      </BodyContainer>
    </LayoutV1>
  );
};

export default SuppliersPage;