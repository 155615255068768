import { FC, useMemo, useState } from 'react';
import { Select, Checkbox, Card, Row, Col, Space } from 'antd';

import {
  InputTable,
  TableCreateQuote,
} from '../../../quotes/quotes-create/styled/quotes-create.styled';

import { ReactComponent as DeleteIcon } from '../../../../../img/icons/delete-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../../img/icons/arrowDown.svg';

import { TextBody } from '../../../shared/typography/typography.styled';
import InputNumber from '../../../shared/inputs/input-number/InputNumber';
import { ButtonDark, ButtonLight } from '../../../../../ui/Button';
import { useOrdersItems } from '../../../../../hooks/superadmin/orders-items/useOrdersItems';

const { Option } = Select;

const dataMain = [
  {
    key: 1,
    unitPrice: 12,
    quantity: 10,
    insurance: false,
  },
];

const dataInsurance = [
  {
    key: 1,
    policyName: 'Policy Name A',
    description: 'POLICY 1',
    policyLength: '3 Years',
    paymentSchedule: 'Monthly',
    instalmentAmount: '£20,000.00',
    isSelected: false,
  },
  {
    key: 2,
    policyName: 'Policy Name B',
    description: 'POLICY 1',
    policyLength: '3 Years',
    paymentSchedule: 'Monthly',
    instalmentAmount: '£20,000.00',
    isSelected: true,
  },
];

const ItemsTable: FC = () => {
  const [isInsuranceDetailOpen, setIsInsuranceDetailOpen] = useState(true);

  const { checkItem, selectedItems } = useOrdersItems();

  const columnsMain = useMemo(
    () => [
      {
        title: 'Offer (Product)',
        key: 'offer',
        dataIndex: 'offer',
        width: 325,
        render: () => (
          <Select
            defaultValue="value1"
            style={{ width: '95%' }}
            suffixIcon={<ArrowDownIcon />}
          >
            <Option value="value1">Microsoft Surface Go 2</Option>
            <Option value="value2">Microsoft Surface Go</Option>
          </Select>
        ),
      },
      {
        title: 'Unit Price',
        key: 'unitPrice',
        dataIndex: 'unitPrice',
        render: () => (
          <InputTable value="£293.10" style={{ textAlign: 'center' }} />
        ),
        width: 100,
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 100,
        render: () => <InputNumber />,
      },
      {
        title: 'Insurance',
        key: 'insurance',
        dataIndex: 'insurance',
        width: 100,

        render: () => (
          <Space>
            <Checkbox>
              <TextBody style={{ fontSize: 14, color: '#2682DE' }}>
                View
              </TextBody>
            </Checkbox>

            <ArrowDownIcon cursor="pointer" onClick={onInsuranceArrowClick} />
          </Space>
        ),
      },
      {
        title: '',
        key: '',
        dataIndex: '',
        width: 50,
        render: () => <DeleteIcon />,
      },
    ],
    []
  );

  const columnsInsurance = useMemo(
    () => [
      {
        title: 'Policy Name',
        key: 'policyName',
        dataIndex: 'policyName',
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
      },
      {
        title: () => (
          <Space direction="vertical">
            <TextBody type="secondary" strong>
              Policy Length
            </TextBody>
            <Select defaultValue="val1" suffixIcon={<ArrowDownIcon />}>
              <Option value="val1">3 Years</Option>
              <Option value="val2">5 Years</Option>
            </Select>
          </Space>
        ),
        key: 'policyLength',
        dataIndex: 'policyLength',
      },
      {
        title: () => (
          <Space direction="vertical">
            <TextBody type="secondary" strong>
              Payment Schedule
            </TextBody>
            <Select defaultValue="val1" suffixIcon={<ArrowDownIcon />}>
              <Option value="val1">Monthly</Option>
            </Select>
          </Space>
        ),
        key: 'paymentSchedule',
        dataIndex: 'paymentSchedule',
      },
      {
        title: 'Instalment Amount',
        key: 'instalmentAmount',
        dataIndex: 'instalmentAmount',
      },
      {
        title: '',
        render: (record: any) => {
          const btnStyle = { width: 100, height: 33, fontSize: 14 };

          return selectedItems.has(record.key) ? (
            <ButtonLight style={btnStyle} onClick={() => checkItem(record.key)}>
              Selected
            </ButtonLight>
          ) : (
            <ButtonDark style={btnStyle} onClick={() => checkItem(record.key)}>
              Select
            </ButtonDark>
          );
        },
      },
    ],
    [selectedItems]
  );

  const onInsuranceArrowClick = () => {
    setIsInsuranceDetailOpen(!isInsuranceDetailOpen);
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <TableCreateQuote
        columns={columnsMain}
        dataSource={dataMain}
        pagination={false}
        shadow={false}
      />
      {isInsuranceDetailOpen && (
        <div style={{ padding: 20 }}>
          <Row>
            <Col span={8} style={{ margin: '20px 0' }}>
              <Space direction="vertical" size={1}>
                <TextBody strong>Insurer</TextBody>
                <Select
                  defaultValue="value1"
                  style={{ width: 425 }}
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="value1">Demo Insurer A</Option>
                  <Option value="value2">Demo Insurer B</Option>
                </Select>
              </Space>
            </Col>
          </Row>
          <Card bodyStyle={{ padding: 0 }}>
            <TableCreateQuote
              columns={columnsInsurance}
              dataSource={dataInsurance}
              pagination={false}
              shadow={false}
            />
          </Card>
        </div>
      )}
    </Card>
  );
};

export default ItemsTable;
