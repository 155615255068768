import { FC, memo } from 'react';
import { Col, Form, Select, Card, Space, Radio } from 'antd';

import { FormItemLabel, TextBody } from '../typography/typography.styled';

import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';

const { Option } = Select;

const LEASE_VALUES = [
  {
    value: 'outrightPurchase',
    name: 'Outright Purchase',
  },
  {
    value: 'operatingLease',
    name: 'Operating Lease',
  },
  {
    value: 'financingLease',
    name: 'Financing Lease',
  },
  {
    value: 'operatingLeaseIndependent Schools',
    name: 'Operating Lease for Independent Schools',
  },
  {
    value: 'financingLeaseIndependentSchools',
    name: 'Financing Lease for Independent Schools',
  },
];

const OrderTypeForm: FC = () => {
  const [form] = Form.useForm();

  const leaseRadioValueWatch = Form.useWatch('lease', form);

  return (
    <Form
      form={form}
      name="orderTypeForm"
      layout="vertical"
      wrapperCol={{ span: 12 }}
    >
      <Form.Item label="" name="lease">
        <Radio.Group defaultValue="operatingLease">
          <Space direction="vertical">
            {LEASE_VALUES.map((lease) => (
              <Radio value={lease.value} key={lease.value}>
                <TextBody>{lease.name}</TextBody>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>

      <Col span={12} style={{ margin: '30px 0' }}>
        <TextBody type="secondary">
          If lease is selected, the customer will also receive a sepearate quote
          from the Lessor including lease pricing. Indicative lease pricing can
          be viewed when adding the line items.
        </TextBody>
      </Col>

      {leaseRadioValueWatch === 'outrightPurchase' ? null : (
        <Col span={8}>
          <Card style={{ background: '#f5f5f5' }}>
            <Form.Item
              name="leaseLength"
              label={<FormItemLabel>Lease Length</FormItemLabel>}
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="leaseLength3Y"
                style={{ width: 220 }}
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="leaseLength3Y">3 Years</Option>
                <Option value="leaseLength5Y">5 Years</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="leaseProvider"
              label={<FormItemLabel>Lease Provider</FormItemLabel>}
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="leaseProvider1"
                style={{ width: 310 }}
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="leaseProvider1">Wesleyan Bank</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="paymentSchedule"
              label={<FormItemLabel>Payment Schedule</FormItemLabel>}
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="leaseProvider1"
                style={{ width: 220 }}
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="leaseProvider1">Quarterly (In arrears)</Option>
              </Select>
            </Form.Item>
          </Card>
        </Col>
      )}
    </Form>
  );
};

export default memo(OrderTypeForm);
