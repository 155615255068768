import { useGetOrderIDQuery } from "../../../../api/services/superadmin/ordersApi";

const OrderViewPage = () => {
  const {data: getOrderID,  status} = useGetOrderIDQuery('1')
  return (
    <>
      Server Status { getOrderID ? getOrderID : status}
    </>
  );
};

export default OrderViewPage;