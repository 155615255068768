import { FC, useState } from "react";
import { getBase64 } from "../../utils/getBase64";
import { Modal, Upload } from "antd";
import { UploadContainer } from "./imageUploader.styled";
import UploadIcon from "../ImageUploader/UploadIcon";
import styled from "styled-components/macro";
import { UploadImgProps } from "./types";

const ImageUploader: FC<UploadImgProps> = ({state, setState, iconText}) => {
    const [ previewVisible, setPreviewVisible ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState('');
    const [ previewTitle, setPreviewTitle ] = useState('');
    const [ fileList, setFileList ] = useState([]);
    
    const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        // eslint-disable-next-line no-param-reassign
        file.preview = await getBase64(file.originFileObj);
      }
      
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    
    const handleChange = ({fileList}: any) => {
      if (fileList && setState) {
        setFileList(fileList);
        setState({
          ...state,
          logo: fileList[0]?.originFileObj?.name
        });
      }
    };
    
    return (
      <UploadContainer>
        <Container>
          <IconTitle>{iconText}</IconTitle>
          <UploadWrap>
            <Upload
              defaultFileList={[ ...fileList ]}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              maxCount={1}>
              {fileList.length >= 20 ? null : <UploadIcon/>}
            </Upload>
            <Title>Upload</Title>
          </UploadWrap>
        </Container>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}>
          <img
            src={previewImage}
            alt="example"
            style={{
              width: 'fit-content',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Modal>
      </UploadContainer>
    );
  }
;

export default ImageUploader;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconTitle = styled.h4`
  display: block;
`;

const Title = styled.p`
  margin-top: 20px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #1ea7fd;
`;

const UploadWrap = styled.div`
  display: flex;
`;