import { ISummaryCardItem } from '../types';

export const summaryDeliveryTotalsMock: Array<ISummaryCardItem> = [
  {
    name: 'Subtotal:',
    value: '£1,118.00',
  },
  { name: 'Shipping:', value: '£0.00' },
  {
    name: 'VAT (20%):',
    value: '£1,118.00',
  },
  {
    name: 'Invoice Amount:',
    value: ' £1,118.00',
    type: 'danger',
  },
];

export const summaryLeaseTotalsMock: Array<ISummaryCardItem> = [
  {
    name: 'Subtotal (Ex-VAT)',
    subname: 'Billed Quarterly',
    value: '£1,118.00',
  },
  { name: 'VAT (20%)', subname: 'Billed Quarterly', value: '£223.00' },
  {
    name: 'Total (Inc-VAT)',
    subname: 'Billed Quarterly',
    value: '£1,118.00',
    type: 'danger',
  },
];
