import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import { OfferDetailsType } from '../../../../../project-types/offers/offers-offers/types';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/arrowDown.svg';
import { TableStyled } from '../../../shared/table/TableStyled';

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const tableConfig: ColumnsType<OfferDetailsType> = [
  {
    title: 'SKU',
    dataIndex: ['product', 'SKU'],
    key: 'SKU',
  },
  {
    title: 'Product',
    dataIndex: ['product', 'name'],
    key: 'name',
  },
  {
    title: 'Offer Number',
    dataIndex: 'offerNumber',
    key: 'offerNumber',
  },
  {
    title: 'OEM',
    dataIndex: ['product', 'manufacturer'],
    key: 'manufacturer',
  },
  {
    title: (
      <StyledTitle>
        Start Date
        <ArrowDown style={{ marginLeft: '8px' }} />
      </StyledTitle>
    ),
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    render: (rowData) => dayjs(rowData).format('DD.MM.YYYY'),
  },
  {
    title: (
      <StyledTitle>
        End Date
        <ArrowDown style={{ marginLeft: '8px' }} />
      </StyledTitle>
    ),
    dataIndex: 'stockAvailableDate',
    key: 'stockAvailableDate',
    sorter: (a, b) => new Date(a.stockAvailableDate).getTime() - new Date(b.stockAvailableDate).getTime(),
    render: (rowData) => dayjs(rowData).format('DD.MM.YYYY'),
  },
];

interface OfferTableProps {
  tableData: Array<OfferDetailsType>;
  onSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
  onOfferRowClick: (offerId: string) => void;
}

const OffersTable: React.FC<OfferTableProps> = ({
  tableData,
  onSelectedOffers,
  selectedOffers,
  onOfferRowClick,
}) => (
  <TableStyled
    rowSelection={{
      type: 'checkbox',
      onChange: (ids) => {
        onSelectedOffers(ids as Array<string>);
      },
      selectedRowKeys: selectedOffers,
    }}
    onRow={(rowData) => ({
      onClick: () => onOfferRowClick(rowData._id),
      style: { cursor: 'pointer' },
    })}
    columns={tableConfig}
    dataSource={tableData}
    rowKey={(record) => record._id}
  />
);

export default OffersTable;
