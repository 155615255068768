import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import BodyContainer from '../../../../../ui/elements/BodyContainer';
import OffersControls from './OffersControls';
import { CustomBigButton } from '../styled';
import {
  useDeleteOfferMutation,
  useGetOfferListQuery,
  useGetSearchOfferListQuery,
} from '../../../../../api/services/superadmin/offersApi';
import OffersTable from './OffersTable';
import ROUTER_CONFIG from '../../../../../router/router-config';
import { SearchOfferType } from '../types';

const OffersView: React.FC = () => {
  const { push } = useHistory();
  const [selectedOffers, setSelectedOffers] = useState<Array<string>>([]);
  const [searchSKUValue, setSearchSKUValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<SearchOfferType>({
    offerNumber: '',
    SKU: '',
  });
  const [searchOfferNumberValue, setSearchOfferNumberValue] = useState<string>('');
  const { data: offers } = useGetOfferListQuery();
  const [deleteOffer] = useDeleteOfferMutation();
  const { data: searchOffers } = useGetSearchOfferListQuery(searchValue);

  const submitSearchSKUValue = useCallback(() => {
    setSearchValue({
      SKU: searchSKUValue,
      offerNumber: '',
    });
  }, [searchSKUValue]);

  const submitSearchOfferNumberValue = useCallback(() => {
    setSearchValue({
      offerNumber: searchOfferNumberValue,
      SKU: '',
    });
  }, [searchOfferNumberValue]);

  const onDeleteOfferClickHandler = async () => {
    await deleteOffer(selectedOffers[0]);
  };

  const onOfferRowClick = (offerId: string) => {
    push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_DETAILS.getPath({ offerId }));
  };

  const onNewOfferClickHandler = () => {
    push(ROUTER_CONFIG.SUPER_ADMIN_NEW_OFFER_PAGE.getPath());
  };

  return (
    <BodyContainer title="Offers">
      <Container>
        <CustomBigButton
          onClick={onNewOfferClickHandler}
        >
          + Create New Offer
        </CustomBigButton>
        <OffersControls
          onSubmitSearchSKUValue={submitSearchSKUValue}
          oSubmitSearchOfferNumberValue={submitSearchOfferNumberValue}
          onDeleteOfferClickHandler={onDeleteOfferClickHandler}
          searchSKUValue={searchSKUValue}
          setSearchSKUValue={setSearchSKUValue}
          searchOfferNumberValue={searchOfferNumberValue}
          setSearchOfferNumberValue={setSearchOfferNumberValue}
        />
        {offers && (
          <OffersTable
            tableData={offers}
            onSelectedOffers={setSelectedOffers}
            selectedOffers={selectedOffers}
            onOfferRowClick={onOfferRowClick}
          />
        )}
      </Container>
    </BodyContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default OffersView;
