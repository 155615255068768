import { useFormik } from 'formik';
import { renderFormConfig } from '../../../../../../utils/form';
import { BuyersUpdateFormConfig } from '../config/configForm'
import { ButtonDark } from '../../../../../../ui/Button';
import {IBuyersOrganisation} from '../../../../types'
import styled from 'styled-components/macro';

interface OrganisationDetailsProps {
  onSubmit: (values: IBuyersOrganisation) => void;
}

const BuyersForm:React.FC<OrganisationDetailsProps> = ({onSubmit}) => {

    const formValidationBuyers = useFormik({
        initialValues: {
            name: 'Demo Buyer A',
            logo: '',
            URN: '122122',
            organisationType: '',
            phoneNumber: ''
        },
        onSubmit:(values) => onSubmit(values),
      });

    return(
     <Container onSubmit={formValidationBuyers.handleSubmit}>
      {renderFormConfig(BuyersUpdateFormConfig, formValidationBuyers)}
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
    )
}

const Container = styled.form`
  font-weight: bold
`;

const StyledButtonDark = styled(ButtonDark)`
  margin-top: 66px;
  padding: 14px 88px;
`;

export default BuyersForm