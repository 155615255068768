import { CardProps, Card } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const Wrapper: FC<CardProps> = styled(Card)`
  border-left: none;
  border-right: none;

  width: 100%;
`;
