import styled from 'styled-components/macro';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import ProductRowItem from '../../../../ui/elements/ProductRowItem';
import Tag from '../../../../ui/elements/Tag';
import { showPriceDigits } from '../../../../utils/formats';
import { CalculatedPricesType } from '../../calculateOrderPrice';
import {
  IBuyerQuotePublic, IReseller, ORDER_TYPES, ISellerOfferPublicType, IAccessorySelected,
} from '../../types';

interface QuoteOverviewInfoProps {
  calculations: CalculatedPricesType;
  quote: IBuyerQuotePublic;
  reseller?: IReseller;
  offer: ISellerOfferPublicType;
}

export default function QuoteOverviewInfo({
  calculations, quote, reseller, offer,
}: QuoteOverviewInfoProps) {
  const { product } = offer.offer;

  const productLeasePrice = `${showPriceDigits(offer.price * (quote.lease?.rate || 1))}/${quote.paymentSchedule}`;
  const purchasePrice = showPriceDigits(offer.price);
  const accPrice = (acc: IAccessorySelected) => (quote.orderType === ORDER_TYPES['Outright Purchase'] ? showPriceDigits(acc.price) : `${showPriceDigits(acc.price * (quote.lease?.rate || 1))}/${quote.paymentSchedule}`);

  return (
    <Container>
      <TagsBlock>
        <Tag color="#2682DE" bgColor="#E8F4FF">{`REF#:${quote._id}`}</Tag>
        <Tag color="#1B9D75" bgColor="#F0F7F5">
          QUOTE:
          {' '}
          {formatDateDDMMMYYYY(new Date())}
        </Tag>
      </TagsBlock>
      <Divider />
      <ProductRowItem
        image={`https://storage.googleapis.com/sds-public-dev/product_pictures/${product.imageUrl}`}
        productName={product.name}
        amount={quote.quantity}
        soldBy={quote.reseller.name}
        price={quote.orderType === ORDER_TYPES['Outright Purchase'] ? purchasePrice : productLeasePrice}
        onTermsClick={() => 5}
      />
      {quote.services?.map((service) => (
        <ProductRowItem
          key={service.serviceName}
          image={service.serviceIcon}
          productName={service.serviceName}
          amount={quote.quantity}
          soldBy={quote.reseller.name}
          price={`${showPriceDigits(service.price)}/unit`}
          onTermsClick={() => 5}
        />
      ))}
      {quote.accessories?.map((acc) => (
        <ProductRowItem
          key={acc.offer.product.name}
          image={`https://storage.googleapis.com/sds-public-dev/product_pictures/${acc.offer.product.imageUrl}`}
          productName={acc.offer.product.name}
          amount={acc.quantity}
          soldBy={quote.reseller.name}
          price={accPrice(acc)}
          onTermsClick={() => 5}
        />
      ))}
      <Divider />
      <DeliveryBlock>
        <BoldText>Standard Delivery</BoldText>
        <BoldText>
          {showPriceDigits(reseller?.deliveryPrice || 0)}
        </BoldText>
      </DeliveryBlock>
      <Divider />
      <PriceBlock>
        <PriceRow>
          <FlexColumnItem>
            <BoldText>Subtotal (Ex-VAT)</BoldText>
            {quote.orderType !== ORDER_TYPES['Outright Purchase'] && (
            <GreyBoldText style={{ marginTop: 3 }}>{`Billed ${quote.paymentSchedule}`}</GreyBoldText>
            )}
          </FlexColumnItem>
          <BoldText>{calculations.totalExVat}</BoldText>
        </PriceRow>
        <PriceRow>
          <FlexColumnItem>
            <BoldText>VAT (20%)</BoldText>
            {quote.orderType !== ORDER_TYPES['Outright Purchase'] && (
            <GreyBoldText style={{ marginTop: 3 }}>{`Billed ${quote.paymentSchedule}`}</GreyBoldText>
            )}
          </FlexColumnItem>
          <BoldText>{calculations.totalVat}</BoldText>
        </PriceRow>
        <PriceRow>
          <FlexColumnItem>
            <BoldText>Total (Inc-VAT)</BoldText>
            {quote.orderType !== ORDER_TYPES['Outright Purchase'] && (
            <GreyBoldText style={{ marginTop: 3 }}>{`Billed ${quote.paymentSchedule}`}</GreyBoldText>
            )}
          </FlexColumnItem>
          <RedBoldText>{calculations.totalWithVat}</RedBoldText>
        </PriceRow>
      </PriceBlock>
      {quote.orderType !== ORDER_TYPES['Outright Purchase'] && (
        <>
          <Divider />
          <LeasePrisingBlock>
            <BoldText>Indicative Lease Pricing</BoldText>
            <LeaseInfoTable>
              <LeaseInfoTableItem>
                <GreyBoldText>Lease Provider</GreyBoldText>
                <BoldText>Wesleyan Bank</BoldText>
              </LeaseInfoTableItem>
              <LeaseInfoTableItem>
                <GreyBoldText>Lease Length</GreyBoldText>
                <BoldText>{quote.lease?.policyLength}</BoldText>
              </LeaseInfoTableItem>
              <LeaseInfoTableItem>
                <GreyBoldText>Payment Schedule</GreyBoldText>
                <BoldText>{quote.lease?.paymentSchedule}</BoldText>
              </LeaseInfoTableItem>
              <LeaseInfoTableItem>
                <GreyBoldText>{`${quote.lease?.paymentSchedule} Amount`}</GreyBoldText>
                <BoldText>{calculations.leasePricing}</BoldText>
              </LeaseInfoTableItem>
            </LeaseInfoTable>
          </LeasePrisingBlock>
        </>
      )}
      <Divider />
    </Container>
  );
}

const Container = styled.div`
  padding: 12px 20px;
  border: 1px solid #F9F9F9;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const RedBoldText = styled(BoldText)`
  color: #DE2644;
`;

const GreyBoldText = styled(BoldText)`
  color: #6B7A87;
  margin-bottom: 6px;
`;

const TagsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const DeliveryBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 14px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

const LeasePrisingBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LeaseInfoTable = styled.div`
  padding: 11px 16px;
  background: #FAFBFC;
  display: flex;
  justify-content: space-between;
`;

const LeaseInfoTableItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FlexColumnItem = styled.div`
  display: flex;
  flex-direction: column;
`;
