import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import LayoutV1 from '../../../layout/LayoutV1';
import BodyContainer from '../../../../ui/elements/BodyContainer';
import { useGetOfferDetailsQuery } from '../../../../api/services/superadmin/offersApi';
import { useGetProductsQuery } from '../../../../api/services/buyerApi';
import OffersEditForm from './offers-edit/OffersEditForm';
import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';

const OffersEditPage: React.FC = () => {
  const history = useHistory();
  const { offerId } = useParams<{ offerId: string }>();
  const { data: products } = useGetProductsQuery();
  const { data: offer } = useGetOfferDetailsQuery(offerId);

  const onBack = useCallback(
    () => history.goBack(),
    [history],
  );

  return (
    <LayoutV1>
      <BodyContainer title="Edit Offer">
        <GoBack onClick={onBack}>
          <BackIcon />
        </GoBack>
        {offer && products && <OffersEditForm offer={offer} products={products} />}
      </BodyContainer>
    </LayoutV1>
  );
};

const GoBack = styled.div`
  margin-left: -53px;
  margin-top: -50px;
  cursor: pointer;
`;

export default OffersEditPage;
