import { Tabs } from 'antd';
import { useState, useEffect, useMemo, FC } from 'react';
import { EditFilled } from '@ant-design/icons';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ROUTER_CONFIG from '../../../router/router-config';
import { ButtonDark } from '../../../ui/Button';
import BackButton from '../../../ui/elements/BackButton';
import { MainHeader } from '../../../ui/Headers';
import { IProduct, ProductTypesEnum } from '../../buyer/types';
import ProductBasicInfoForm from './create-steps/ProductBasicInfoForm';
import ProductAccessories from './create-steps/ProductAccessoriesForm';
import ProductRelatedFiles from './create-steps/ProductRelatedFilesForm';
import ProductRelelatedProducts from './create-steps/ProductRelatedProductsForm';
import ProductTechSpecs from './create-steps/ProductTechSpecsForm';
import ProductOverview from './create-steps/ProductOverviewForm';
import { useUpdateProductMutation } from '../../../api/services/superadmin/productApi';
import ProductGalleryForm from './create-steps/ProdictGalleryForm';

interface IProps {
  product: IProduct;
  productsList: Array<IProduct>;
}

const { TabPane } = Tabs;

enum TABS {
  BASIC_INFORMATION = 'Basic Information',
  ATTRIBUTES = 'Attributes',
  OVERVIEW = 'Overview',
  ACCESSORIES = 'Accessories',
  RELATED_PRODUCTS = 'Related Products',
  RELATED_FILES = 'Related Files',
  GALLERY = 'Gallery',
}

const ProductDetails: FC<IProps> = ({ product, productsList }) => {
  const { push } = useHistory();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [updateProduct, { isSuccess, isLoading }] = useUpdateProductMutation();

  const changeMode = () => {
    formik.resetForm();

    setIsEditMode(!isEditMode);
  };

  const tabButtons = useMemo(
    () => (
      <>
        {isEditMode ? (
          <TabButton onClick={() => formik.handleSubmit()}>
            {!isLoading ? (
              <>
                <EditFilled />
                Save
              </>
            ) : (
              <>Saving...</>
            )}
          </TabButton>
        ) : (
          <TabButton onClick={changeMode}>
            <EditFilled />
            Edit
          </TabButton>
        )}
        <TabButton>Preview</TabButton>
      </>
    ),
    [isEditMode, isLoading]
  );

  useEffect(() => {
    if (isSuccess) {
      push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.getPath());
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: {
      ...product,
    },
    onSubmit: (values) =>
      updateProduct({ body: values, productId: product._id }),
  });

  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.getPath());

  return (
    <Wrapper>
      <Header>
        <StyledMainHeader>
          <StyledBackButton onClickHandler={onBack} />
          <>Product Details</>
        </StyledMainHeader>
      </Header>
      <Tabs
        defaultActiveKey={TABS.BASIC_INFORMATION}
        tabBarExtraContent={tabButtons}
        tabBarStyle={{color: '#6B7A87'}}
      >
        <StyledTabPane
          tab={TABS.BASIC_INFORMATION}
          key={TABS.BASIC_INFORMATION}
        >
          <ProductBasicInfoForm
            formikInstance={formik}
            isEditMode={isEditMode}
          />
        </StyledTabPane>
        <StyledTabPane tab={TABS.ATTRIBUTES} key={TABS.ATTRIBUTES}>
          <ProductTechSpecs formikInstance={formik} isEditMode={isEditMode} />
        </StyledTabPane>
        <StyledTabPane tab={TABS.OVERVIEW} key={TABS.OVERVIEW}>
          <ProductOverview formikInstance={formik} isEditMode={isEditMode} />
        </StyledTabPane>
        <StyledTabPane tab={TABS.ACCESSORIES} key={TABS.ACCESSORIES}>
          <ProductAccessories
            formikInstance={formik}
            accessoriesList={productsList.filter(
              (product) => product.category === ProductTypesEnum.ACCESSORY
            )}
            isEditMode={isEditMode}
          />
        </StyledTabPane>
        <StyledTabPane tab={TABS.RELATED_PRODUCTS} key={TABS.RELATED_PRODUCTS}>
          <ProductRelelatedProducts
            formikInstance={formik}
            productsList={productsList.filter(
              (product) => product.category === ProductTypesEnum.DEVICE
            )}
            isEditMode={isEditMode}
          />
        </StyledTabPane>
        <StyledTabPane tab={TABS.RELATED_FILES} key={TABS.RELATED_FILES}>
          <ProductRelatedFiles
            formikInstance={formik}
            isEditMode={isEditMode}
          />
        </StyledTabPane>
        <StyledTabPane tab={TABS.GALLERY} key={TABS.GALLERY}>
          <ProductGalleryForm formikInstance={formik} isEditMode={isEditMode} />
        </StyledTabPane>
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  box-sizing: border-box;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  margin-bottom: 36px;
`;

const StyledButton = styled(ButtonDark)`
  padding: 13px 60px;
  margin-top: 30px;
`;

const StyledBackButton = styled(BackButton)`
  margin-left: -15px;
  margin-right: 15px;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 35px;
`;

const TabButton = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #2682de;
  cursor: pointer;
  margin-left: 10px;
`;

export default ProductDetails;
