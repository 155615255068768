import { IProduct } from '../../../components/buyer/types';
import { superadminApi } from './superadminApi';

const productApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getProductsList: build.query<IProduct[], void>({
      providesTags: ['PRODUCTS'],
      query: () => ({
        url: 'product',
      }),
    }),
    getProduct: build.query<IProduct, string>({
      providesTags: ['PRODUCTS'],
      query: (id: string) => ({
        url: `product/byId/${id}`,
      }),
    }),
    getSearchProduct: build.query<IProduct[], {name: string}>({
      providesTags: ['PRODUCTS'],
      query: (body) => ({
        url: 'product/search',
        params: body,
      }),
    }),
    createProduct: build.mutation<void, Omit<IProduct, '_id'>>({
      invalidatesTags: ['PRODUCTS'],
      query: (body: IProduct) => ({
        url: 'product',
        method: 'POST',
        body,
      }),
    }),
    updateProduct: build.mutation<
      void,
      { body: Omit<IProduct, '_id'>; productId: string }
    >({
      invalidatesTags: ['PRODUCTS'],
      query: ({ body, productId }) => ({
        url: `product/${productId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteProduct: build.mutation<void, { productId: string }>({
      invalidatesTags: ['PRODUCTS'],
      query: ({ productId }) => ({
        url: `product/${productId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProductsListQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useGetSearchProductQuery,
} = productApi;
