import { Buyers, Quote } from '../../../components/superadmin/types';
import { superadminApi } from './superadminApi';

const quotesApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getQuotesList: build.query<Array<Quote>, void>({
      query: () => 'quote',
    }),
    getSearchQuotesList: build.query<Buyers[], { number: string }>({
      query: (body) => ({
        url: 'quote/search',
        method: 'GET',
        params: body,
      }),
    }),
    getQuoteID: build.query({
      providesTags: ['QUOTES'],
      query: (id: string) => ({
        url: `quote/byId/${id}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetQuotesListQuery,
  useGetSearchQuotesListQuery,
  useGetQuoteIDQuery,
} = quotesApi;