import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const superadminApi = createApi({
  reducerPath: 'superadminApi',
  tagTypes: [
    'PRODUCTS',
    'INSURER',
    'INSURANCE',
    'SERVICE',
    'SUPPLIER',
    'LESSORS',
    'LEASE',
    'OFFERS',
    'ORDERS',
    'QUOTES'
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://sds-backend-buyer-stage-rgrpzfjxqa-uc.a.run.app/api/', // to env
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});