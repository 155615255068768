import styled from 'styled-components';
import { ButtonDark } from '../../../../../../ui/Button';

export const Container = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  position: relative;
`;

export const LinkAfterButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  max-width: 221px;
`;

export const Title = styled.h1`
  margin: 0 0 27px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
`;

export const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const InviteButton = styled.span`
  display: block;
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled.span`
  display: block;
  color: #909DA8;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
`;

export const ContainerForPopover = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  position: relative;

  label {
    font-size: 12px;
    font-weight: 600;
  }
`;
