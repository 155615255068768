import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import InfoTable, { InfoTableItem } from '../../../../ui/elements/InfoTable';
import { showPriceDigits } from '../../../../utils/formats';
import PaymentsTable from './PaymentsTable';
import { ISupplierPaymentsSummary, PaymentsTableRow } from '../../types';
import { FC } from 'react';

const { TabPane } = Tabs;

interface PaymentsOverviewProps {
  tableData: Array<PaymentsTableRow>;
  summaryData: ISupplierPaymentsSummary;
}
const PaymentsOverview: FC<PaymentsOverviewProps>  = ({ tableData, summaryData }) => {
  const tableConfig: Array<InfoTableItem> = [
    {
      title: 'Outstanding',
      value: showPriceDigits(summaryData.outstanding.total),
      description: (summaryData.outstanding.countOfOrders + ' Order'),
    },
    {
      title: 'Overdue',
      value: showPriceDigits(summaryData.overdue.total),
      description: '-',
    },
    {
      title: 'Received',
      value: showPriceDigits(summaryData.currentMontReceived),
      description: 'This Month',
    },
  ];

  return (
    <Container>
      <Title>Payments</Title>
      <StyledInfoTable data={tableConfig} summaryData={summaryData} />
      <StyledTabs defaultActiveKey="1">
        <StyledTabPane tab="Outstanding" key="1">
          <PaymentsTable tableData={tableData} />
        </StyledTabPane>
        <StyledTabPane tab="Overdue" key="2">
          <PaymentsTable tableData={tableData} />
        </StyledTabPane>
        <StyledTabPane tab="Received" key="3">
          <PaymentsTable tableData={tableData} />
        </StyledTabPane>
        <StyledTabPane tab="SW Invoices" key="4">
          <PaymentsTable tableData={tableData} />
        </StyledTabPane>
      </StyledTabs>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #102f54;
  font-weight: bold;
`;

const StyledInfoTable: FC<any> = styled(InfoTable)`
  max-width: 630px;
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs {
    color: #6B7A87!important;
  }
  .ant-tabs-tab-btn {
    padding-bottom: 10px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #102F54;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid #D5DCE5;
  }
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 30px;
`;

export default PaymentsOverview;
