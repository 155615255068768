import { ILessor } from '../../../components/superadmin/lessors/types';
import { superadminApi } from './superadminApi';

const lessorsApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getLessorsList: build.query<ILessor[], void>({
      providesTags: ['LEASE'],
      query: () => ({
        url: 'lessor',
      }),
    }),
    getSearchLessorsList: build.query<ILessor[], { name: string }>({
      providesTags: ['LEASE'],
      query: (body) => ({
        url: 'lessor/search',
        params: body,
      }),
    }),
    getLessor: build.query<ILessor, string>({
      providesTags: ['LEASE'],
      query: (lessorId: string) => ({
        url: `lessor/byId/${lessorId}`,
      }),
    }),
    createLessor: build.mutation<void, Omit<ILessor, '_id'>>({
      invalidatesTags: ['LEASE'],
      query: (body: Omit<ILessor, '_id'>) => ({
        url: 'lessor',
        method: 'POST',
        body,
      }),
    }),
    updateLessor: build.mutation<
      void,
      { body: Omit<ILessor, '_id'>; lessorId: string }
    >({
      invalidatesTags: ['LEASE'],
      query: ({ body, lessorId }) => ({
        url: `lessor/${lessorId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useGetLessorsListQuery,
  useGetLessorQuery,
  useCreateLessorMutation,
  useUpdateLessorMutation,
  useGetSearchLessorsListQuery,
} = lessorsApi;
