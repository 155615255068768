import { IEligibleNodesTableRow } from "../../types";


export const eligibleNodesItems: Array<IEligibleNodesTableRow> = [
  {
    key: 1,
    nodes: 'Intel',
  },
  {
    key: 2,
    nodes: 'Intel',
  },
];
