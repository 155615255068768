import styled from 'styled-components/macro';
import { Modal, Upload } from 'antd';
import React, { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { getBase64 } from '../../../../../../utils/getBase64';
import  SchoolIcon  from '../../../../../../img/icons/upload-icon.svg';
import UploadButton from './OfferGroupDetailsButton';

interface UploadFileList {
  uid: string,
  name: string,
  status: string,
  url?: string
}

const initialImageList: UploadFileList[] = [
  {
    uid: '5',
    name: 'No icon',
    status: 'done',
  },
  {
    uid: '1',
    name: 'image.png',
    status: 'done',
    url: SchoolIcon,
  },
  {
    uid: '2',
    name: 'image.png',
    status: 'done',
    url: SchoolIcon,
  },
  {
    uid: '4',
    name: 'image.png',
    status: 'done',
    url: SchoolIcon,
  },
  {
    uid: '5',
    name: 'image.png',
    status: 'done',
    url: SchoolIcon,
  },
];

const UploadIcon: React.FC = () => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile<UploadFileList>[]>(
    [],
  );

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  return (
    <Container>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={1}
      >
        {fileList.length >= 20 ? null : <UploadButton />}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: 'fit-content',
            height: '100%',
            objectFit: 'cover',
          }}
          src={previewImage}
        />
      </Modal>
      {initialImageList.map((item, index) => (
        <WrapperIcon key={item.uid}>
          {index === 0 ? <NoIcon>no icon</NoIcon> : <Icon src={item.url} />}
        </WrapperIcon>
      ))}
    </Container>
  );
};

const Container = styled.span`
  display: flex;
  max-width: 452px;
  gap: 10px;

  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 56px;
    height: 56px;
    border: 1px solid #D5DCE5;
    margin: 0;
  }

  .ant-upload-list-picture-card-container {
    width: 56px;
    height: 56px;
  }

  .ant-upload-list-item {
    padding: 0;
    border: 1px solid #D5DCE5;
    height: 54px;
  }

  .ant-upload-list-item-name {
    font-size: 12px;
    font-weight: bold;
  }

  .ant-upload-list-item-actions a {
    display: none;
  }

  .ant-upload-list-item-thumbnail .anticon {
    display: none;
  }

  .ant-upload-list-item-info {
    width: 54px;
    height: 54px;
    border-radius: 3px;
  }
`;

const WrapperIcon = styled.span`
  box-sizing: border-box;
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 3px;
  border: 1px solid #D5DCE5;
`;

const Icon = styled.img`
  width: 54px;
  height: 54px;
  border: 1px solid #D5DCE5;
  cursor: pointer;
  border-radius: 3px;
`;

const NoIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  font-size: 12px;
  font-weight: 600;
  color: #102F54;
  cursor: pointer;
`;

export default UploadIcon;
