import styled from 'styled-components/macro';
import React from 'react';
import { ButtonDark, ButtonLight } from '../../../../../ui/Button';
import { showPriceDigits } from '../../../../../utils/formats';
import { IReseller, ISellerOfferPublicType, ORDER_TYPES } from '../../../types';
import ResellerService from './Services';
import { BuyerOrderStateType } from '../../../../../feature/buyerState';

interface ResellerRowItemProps {
  reseller: IReseller;
  onSelect: () => void;
  isSelected: boolean;
  currentOrder: BuyerOrderStateType;
  offer: ISellerOfferPublicType;
  onViewPricingClickHandler: (seller: IReseller) => void;
}

export default function ResellerRowItem({
  reseller,
  onSelect,
  isSelected,
  currentOrder,
  offer,
  onViewPricingClickHandler,
}: ResellerRowItemProps) {
  return (
    <Container>
      <ImageContainer>
        {(reseller as any).logo && (
          <Image
            alt=""
            src={`https://storage.googleapis.com/sds-public-dev/product_pictures/${
              (reseller as any).logo
            }`}
          />
        )}
      </ImageContainer>
      <Content>
        <TitleRow>
          <Name>{reseller.name}</Name>
          <div style={{ width: '110px', textAlign: 'end' }}>
            {currentOrder?.orderType === ORDER_TYPES['Outright Purchase'] ? (
              <Price>{showPriceDigits(offer.price)}</Price>
            ) : (
              <Price>{`${showPriceDigits(
                offer.price * (currentOrder?.lease?.rate || 1)
              )}/${currentOrder?.paymentSchedule}`}</Price>
            )}
          </div>
          {isSelected ? (
            <SelectedButton>Selected</SelectedButton>
          ) : (
            <SelectButton onClick={onSelect}>Select</SelectButton>
          )}
        </TitleRow>
        <Text>
          {`Standard Delivery - ${showPriceDigits(
            reseller.deliveryPrice || 0
          )}`}
        </Text>
        <CustomLink>Terms and conditions</CustomLink>
        {reseller.availableServices && reseller.availableServices.length ? (
          <>
            <AvailableServicesBlock>
              <GreyName>Available services</GreyName>
              <Services>
                {reseller.availableServices.map((service) => (
                  <ResellerService
                    service={service}
                    key={service.serviceName}
                  />
                ))}
              </Services>
            </AvailableServicesBlock>
            <CustomLink
              style={{ marginTop: 20, fontSize: 14 }}
              onClick={() => onViewPricingClickHandler(reseller)}
            >
              View Pricing
            </CustomLink>
          </>
        ) : null}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  padding: 17px 20px;
  border-bottom: 1px solid #D5DCE5;
  margin: 0 20px;

  display: flex;
  gap: 30px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
`;

const Name = styled(Text)`
  font-weight: bold;
  width: 185px;
}
`;

const GreyName = styled(Name)`
  color: #6B7A87;
  text-transform: uppercase;
`;

const Price = styled(Name)`
  color: #DE2644;
  font-size: 16px;
`;

const CustomLink = styled(Text)`
  font-size: 12px;
  cursor: pointer;
  color: #2682DE;
  font-weight: 600;
  margin-top: 3px;

  &:hover {
    text-decoration: underline;
  }
`;

const SelectButton = styled(ButtonDark)`
  padding: 10px;
  width: 110px;
  height: 33px;
  font-size: 14px;
  line-height: 14px;
  margin-left: 5px;
`;
const SelectedButton = styled(ButtonLight)`
  padding: 10px;
  width: 110px;
  height: 33px;
  font-size: 14px;
  line-height: 14px;
`;

const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AvailableServicesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Services = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
