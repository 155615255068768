import styled from 'styled-components/macro';
import React from 'react';
import { CustomTableProps } from '../../../../ui/Table/types';
import SearchInput from '../../../../ui/Serach/SearchInput';
import { BuyerTableColumns } from './BuyerTableColumns';
import { BuyerTableColumnProps } from './types';
import { TableStyled } from '../../../../ui/Table/table.styled';
import AdditionalControls from "../../../../ui/Table/AdditionalControls";

interface ActiveBuyersTableProps extends CustomTableProps {
  tableData: Array<BuyerTableColumnProps>;
  onSelectUser: (userIds: Array<string>) => void;
  selectedUsers: Array<string>;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSubmitSearch: () => void;
  onBuyerRowClick: (id: string) => void;
}

const ActiveBuyersTable: React.FC<ActiveBuyersTableProps> = (
  {
    tableData,
    selectedUsers,
    onSelectUser,
    searchValue,
    setSearchValue,
    onSubmitSearch,
    onBuyerRowClick,
  },
) => {
  return (
    <Container>
      <TableControlWrap>
        <SearchInput
          onSubmitSearch={onSubmitSearch}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder="Buyer Organisation Name"
        />
        <AdditionalControls
          mainActionText="Action"
          subActionText="Remove Buyer"
          onClickRemove={() => console.log('remove')}
        />
      </TableControlWrap>
      <TableStyled
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectUser(ids as Array<string>);
          },
          selectedRowKeys: selectedUsers,
        }}
        onRow={(rowData) => ({
          onClick: () => onBuyerRowClick(rowData.key),
          style: { cursor: 'pointer' },
        })}
        columns={BuyerTableColumns}
        dataSource={tableData}
      />
    </Container>
  );
};

export default ActiveBuyersTable;

const Container = styled.div`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const TableControlWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;