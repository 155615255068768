import { useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface HTMLEditorProps {
  setFieldValue: (val: string) => void;
  value: string;
  placeholder?: string;
  disabled?: boolean;
}

export default function HTMLEditor({
  value,
  setFieldValue,
  placeholder,
  disabled,
}: HTMLEditorProps) {
  const contentBlock = htmlToDraft(value);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      )
    )
  );

  const handleStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };
  return (
    <Editor
      editorState={editorState}
      placeholder={placeholder}
      readOnly={disabled}
      onEditorStateChange={handleStateChange}
      wrapperStyle={{
        border: '1px solid #D5DCE5',
        padding: 5,
        borderRadius: 5,
      }}
    />
  );
}
