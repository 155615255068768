import React from 'react';
import styled from 'styled-components/macro';
import LayoutV1 from '../../../../layout/LayoutV1';
import AddOfferSection from './add-offer-section/AddOfferSection';
import ButtonComponent from './button-component/ButtonComponent';
import EligibleNodesSection from './eligible-nodes-section/EligibleNodesSection';
import OfferGroupDetailsSection from './offer-group-details-section/OfferGroupDetailsSection';
import TitleComponent from './title-component/titleComponent';

const OfferGroupCreatePage: React.FC = () => {

  return (
    <LayoutV1>
      <Container>
        <TitleComponent />
        <OfferGroupDetailsSection />
        <AddOfferSection />
        <EligibleNodesSection />
        <ButtonComponent />
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div`
  position: relative;
`;

export default OfferGroupCreatePage;
