import { EligibleSuppliersTableRow } from '../../../../types';

export const eligibleSuppliersItems: Array<EligibleSuppliersTableRow> = [
  {
    key: 1,
    suppliers: 'suppliers',
  },
  {
    key: 2,
    suppliers: 'suppliers',
  },
];
