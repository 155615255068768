/* eslint-disable no-param-reassign */
import { showPriceDigits } from '../../utils/formats';
import {
  IReseller, ISellerOfferPublicType, ORDER_TYPES, IAccessorySelected, ILeasePublicType, PAYMENT_SCHEDULE_ENUM, IService,
} from './types';
import { IInsurance } from '../superadmin/insurers/types';

interface CalculateProps {
  quantity: number;
  orderType?: ORDER_TYPES;
  offer?: ISellerOfferPublicType;
  reseller?: IReseller;
  accessories?: Array<IAccessorySelected>;
  insurance?: IInsurance;
  lease?: ILeasePublicType;
  services?: Array<IService>;
}

export interface CalculatedPricesType {
  productsSubtotalExVat: string;
  productsVat: string;
  productsTotalWithVat: string;

  insuranceSubtotalExVat: string;
  insuranceVat: string;
  insuranceTotalWithVat: string;

  numberOfInsurancePayments: number;
  numberOfPaymentsToSilverwing: number;
  totalToSilverwing: string;

  leasePricing: string;
  totalExVat: string;
  totalVat: string;
  totalWithVat: string;
}

const getPaymentsPerYear = (paymentSchedule: string) => {
  switch (paymentSchedule) {
    case PAYMENT_SCHEDULE_ENUM.ANNUALLY:
      return 1;
    case PAYMENT_SCHEDULE_ENUM.MONTHLY:
      return 12;
    case PAYMENT_SCHEDULE_ENUM.QUARTERLY:
      return 4;
    default:
      return 1;
  }
};

const calculateOrderPrice = ({
  quantity, reseller, orderType, offer, accessories = [], insurance, lease, services,
}: CalculateProps): CalculatedPricesType => {
  const productPrice = (orderType === ORDER_TYPES['Outright Purchase'] ? offer?.price : ((offer?.price || 0) * (lease?.rate || 0))) || 0;

  const leasePricing = (offer?.price || 1) * quantity * (lease?.residualValueRate || 1);

  // products
  const devicesSubtotalPrice = reseller ? (productPrice * quantity) : 0;
  const accessoriesSubtotalPrice = accessories.length ? accessories.reduce((acc, item) => {
    if (lease) {
      acc += item.price * lease.rate * item.quantity;
    } else {
      acc += item.price * item.quantity;
    }
    return acc;
  }, 0) : 0;
  const servicesSubtotalPrice = services ? services.reduce((acc, item) => {
    acc += item.price * quantity;
    return acc;
  }, 0) : 0;

  const productsSubtotalExVat = devicesSubtotalPrice + accessoriesSubtotalPrice + servicesSubtotalPrice;
  const productsVat = productsSubtotalExVat * 0.2;
  const productsTotalWithVat = productsSubtotalExVat + productsVat;

  // insurance
  const insuranceSubtotalExVat = insurance ? insurance.price * quantity : 0;
  const insuranceVat = 0;
  const insuranceTotalWithVat = insuranceSubtotalExVat + 0;

  // total
  const totalExVat = productsSubtotalExVat + insuranceSubtotalExVat;
  const totalVat = productsVat + insuranceVat;
  const totalWithVat = totalExVat + totalVat;

  const toSilverwig = (totalWithVat * 0.045) / 12;

  return {
    productsSubtotalExVat: showPriceDigits(productsSubtotalExVat),
    productsVat: showPriceDigits(productsVat),
    productsTotalWithVat: showPriceDigits(productsTotalWithVat),
    insuranceSubtotalExVat: showPriceDigits(insuranceSubtotalExVat),
    insuranceVat: showPriceDigits(insuranceVat),
    insuranceTotalWithVat: showPriceDigits(insuranceTotalWithVat),
    numberOfPaymentsToSilverwing: getPaymentsPerYear(lease?.paymentSchedule?.toLowerCase() || '') * (lease?.policyLength ? +lease.policyLength[0] : 2),
    numberOfInsurancePayments: getPaymentsPerYear(insurance?.paymentSchedule?.toLowerCase() || '') * (insurance?.policyLength ? +insurance.policyLength[0] : 2),
    totalToSilverwing: showPriceDigits(toSilverwig),
    totalExVat: showPriceDigits(totalExVat),
    totalVat: showPriceDigits(totalVat),
    totalWithVat: showPriceDigits(totalWithVat),
    leasePricing: showPriceDigits(leasePricing),
  };
};

export default calculateOrderPrice;

// ? rows (for (product +? accessories) and insurance):
// subtotal (Ex-VAT)
// VAT (20%)
// Total (Inc-VAT)

// number of payments
// term length
// total (billed by period)

// 4.5%
