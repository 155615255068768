import { NewOffer } from '../../../components/superadmin/types';
import { superadminApi } from './superadminApi';

const newOfferApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getNewOffers: build.query<Array<NewOffer>, void>({
      query: () => 'offer',
    }),
    getNewOffer: build.query<NewOffer, string>({
      query: (offerId) => `offer/byId/${offerId}`,
    }),
    createNewOffer: build.mutation<Array<NewOffer>, { body: Array<NewOffer> }>({
      query: (body) => ({
        url: `offer`,
        method: 'POST',
        body,
      }),
    }),
    updateNewOffer: build.mutation<Array<NewOffer>, { body: Array<NewOffer>, offerId: string }>({
      query: ({ body, offerId }) => ({
        url: `offer/byId/${offerId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteNewOffer: build.mutation<Array<NewOffer>, string>({
      query: (offerId) => ({
        url: `offer/byId/${offerId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNewOffersQuery,
  useGetNewOfferQuery,
  useCreateNewOfferMutation,
  useUpdateNewOfferMutation,
  useDeleteNewOfferMutation,
} = newOfferApi;
