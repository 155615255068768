import { FC } from "react";
import { ICurrency } from "./types";
import { Select } from "antd";
import styled from "styled-components";
import InputWrap from "../forms/InputWrap";
import { ReactComponent as ArrowDown } from "../../img/icons/filled-arrow-down.svg";

const {Option} = Select;

const Currency: FC<ICurrency> = ({data, setValue}) => {
  const currencies = [ '€', '$', "₣" ];
  return (
    <InputWrap title="Default Currency">
      <StyledSelect
        defaultValue="£"
        suffixIcon={<ArrowDown/>}
        onChange={(e: any) => setValue('currency', e)}>
        {
          currencies.map((opt, idx) => (
            <Option key={idx} value={opt}>
              {opt}
            </Option>
          ))
        }
      </StyledSelect>
    </InputWrap>
  );
};

export default Currency;

export const StyledSelect = styled(Select)`
  color: #102F54;
  font-weight: 400;
  line-height: 17px;
  width: 8%;
  
  .ant-select {
    width: 102px;
  }
  
  .ant-select-arrow {
    right: -12px
  }
`;