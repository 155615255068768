import { FC } from 'react';
import { Row, Col, Space, Divider, Typography } from 'antd';

import { TextBody } from '../../../shared/typography/typography.styled';
import { Flex } from '../../../shared/utils/utils.styled';
import { CardStyled } from '../../../shared/card/card.styled';

import { ISummaryCardItem } from '../../../types';

interface ISummaryQuoteCardProps {
  deliveryTotals: Array<ISummaryCardItem>;
  leaseTotals: Array<ISummaryCardItem>;
}

const { Text } = Typography;

export const SummaryQuoteCard: FC<ISummaryQuoteCardProps> = ({
  leaseTotals,
  deliveryTotals,
}) => (
  <CardStyled style={{ marginTop: 40 }}>
    <Row>
      <Col span={4} />
      <Col span={12}>
        <Space direction="vertical" size={0}>
          <TextBody strong>Microsoft Surface Go 2</TextBody>
          <TextBody type="secondary">STV-00002</TextBody>
        </Space>
      </Col>
      <Col offset={4} span={4} style={{ textAlign: 'end' }}>
        <Space direction="vertical" size={0}>
          <TextBody strong>£10,464.00</TextBody>
          <Text type="secondary" strong>
            30 Units
          </Text>
        </Space>
      </Col>
    </Row>

    <Divider />

    <Row>
      <Col span={4} />

      <Col span={12}>
        <TextBody strong>Asset Tagging</TextBody>
      </Col>
      <Col offset={4} span={4} style={{ textAlign: 'end' }}>
        <Space direction="vertical" size={0}>
          <TextBody strong>£138.00</TextBody>
          <TextBody type="secondary" strong>
            30 Units
          </TextBody>
        </Space>
      </Col>
    </Row>

    <Divider />

    <Row>
      <Col span={6}>
        <TextBody strong>Standard Delivery</TextBody>
      </Col>
      <Col span={4} offset={14} style={{ textAlign: 'end' }}>
        <TextBody strong>£0.00</TextBody>
      </Col>
    </Row>

    <Divider />

    <Row>
      <Col span={4}>
        <Space direction="vertical">
          <TextBody type="secondary" strong>
            Delivery Address
          </TextBody>
          <TextBody strong>
            Broomhill Road Adderlane Academy Prudhoe Northumberland NE42 5HX
          </TextBody>
        </Space>
      </Col>
      <Col span={8} offset={12}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {deliveryTotals.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex justify="space-between" align="center" key={index}>
              <TextBody strong>{item.name}</TextBody>
              <TextBody strong type={item?.type}>
                {item.value}
              </TextBody>
            </Flex>
          ))}
        </Space>
      </Col>
    </Row>

    <Divider />

    <Row>
      <Col span={4}>
        <Space direction="vertical">
          <TextBody type="secondary" strong>
            Invoice Address
          </TextBody>
          <TextBody strong>
            Silverwing 243 High Street Boston Spa West Yorkshire LS23 6AL
          </TextBody>
        </Space>
      </Col>
      <Col span={6} offset={2}>
        <Space direction="vertical">
          <TextBody strong type="secondary">
            Payment Method
          </TextBody>
          <TextBody strong>Operating Lease</TextBody>
        </Space>
      </Col>
    </Row>

    <Divider />

    <Row>
      <Col span={24}>
        <TextBody type="secondary" strong>
          Indicative Lease Pricing
        </TextBody>
      </Col>
    </Row>
    <Row
      style={{
        background: '#FAFBFC',
        padding: 10,
      }}
    >
      <Col span={6}>
        <Space direction="vertical" size={0}>
          <TextBody type="secondary" strong>
            Lease Provider
          </TextBody>
          <TextBody strong>Wesleyan Bank</TextBody>
        </Space>
      </Col>
      <Col span={6}>
        <Space direction="vertical" size={0}>
          <TextBody type="secondary" strong>
            Lease Length
          </TextBody>
          <TextBody strong>3 Years</TextBody>
        </Space>
      </Col>
      <Col span={6}>
        <Space direction="vertical" size={0}>
          <TextBody type="secondary" strong>
            Payment Schedule
          </TextBody>
          <TextBody strong>Quarterly (In arrears)</TextBody>
        </Space>
      </Col>
      <Col span={6}>
        <Space direction="vertical" size={0}>
          <TextBody type="secondary" strong>
            Quarterly Amount
          </TextBody>
          <TextBody strong>£1,175.00</TextBody>
        </Space>
      </Col>
    </Row>

    <Divider />

    <Row gutter={[0, 16]}>
      {leaseTotals.map((item) => (
        <Col offset={16} span={8} key={item.value}>
          <Flex justify="space-between" align="center">
            <Space direction="vertical" size={0}>
              <TextBody strong>{item.name}</TextBody>
              <TextBody type="secondary" strong>
                {item.subname}
              </TextBody>
            </Space>
            <TextBody strong type={item?.type}>
              {item.value}
            </TextBody>
          </Flex>
        </Col>
      ))}
    </Row>
  </CardStyled>
);
