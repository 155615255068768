import styled from 'styled-components/macro';
import { Dropdown, Menu } from 'antd';
import React, { useMemo } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/arrowDownBlue.svg';
import SearchIcon from '../../../../../img/icons/icon_search.svg';
import { InviteButton, Search, StyledInput } from '../styled';

interface UsersTableControlsProps {
  onSubmitSearchSKUValue: () => void;
  oSubmitSearchOfferNumberValue: () => void;
  onDeleteOfferClickHandler: () => void;
  searchSKUValue: string;
  setSearchSKUValue: (value: string) => void;
  searchOfferNumberValue: string;
  setSearchOfferNumberValue: (value: string) => void;
}

const OffersControls: React.FC<UsersTableControlsProps> = ({
  onDeleteOfferClickHandler,
  searchSKUValue,
  setSearchSKUValue,
  searchOfferNumberValue,
  setSearchOfferNumberValue,
  onSubmitSearchSKUValue,
  oSubmitSearchOfferNumberValue,
}) => {
  const dropdownContent = useMemo(() => (
    <Menu>
      <Menu.Item onClick={onDeleteOfferClickHandler}> Delete </Menu.Item>
    </Menu>
  ), [onDeleteOfferClickHandler]);

  return (
    <Container>
      <SearchWrapper>
        <SearchContent>
          <StyledInput
            onKeyDown={(e) => e.key === 'Enter' && onSubmitSearchSKUValue()}
            placeholder="SKU"
            value={searchSKUValue}
            onChange={(e) => setSearchSKUValue(e.target.value)}
            iconSource={SearchIcon}
          />
          <Search />
        </SearchContent>
        <SearchContent>
          <StyledInput
            onKeyDown={(e) => e.key === 'Enter' && oSubmitSearchOfferNumberValue()}
            placeholder="Offer Number"
            value={searchOfferNumberValue}
            onChange={(e) => setSearchOfferNumberValue(e.target.value)}
            iconSource={SearchIcon}
          />
          <Search />
        </SearchContent>
      </SearchWrapper>
      <Dropdown overlay={dropdownContent}>
        <InviteButton onClick={(e) => e.preventDefault()}>
          Actions
          {' '}
          <ArrowDown style={{ marginBottom: '2px' }} />
        </InviteButton>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 35px;
  justify-content: space-between;
  margin-bottom: 11px;
  margin-top: 13px;
`;

export const SearchWrapper = styled.div`
  display: flex;
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default OffersControls;
