import { FC } from 'react';
import { Col, Row, Select, Form, Typography } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/filled-arrow-down.svg';
import { ReactComponent as Calendar } from '../../../../../img/icons/calendar.svg';
import {
  InputStyled,
  DatePickerStyled,
  SubTitle,
  InputLabel,
  InputWrapper,
  LongSelectWrapper,
  StyledLongSelect,
  DisabledInputStyled,
} from '../styled/new-offer.styled';
import { NewOffer } from '../../../types';

const { Text } = Typography;
const { Option } = Select;
interface IProductDetailsProps {
  offers: Array<NewOffer>;
}

const ProductDetailsSection: FC<IProductDetailsProps> = ({ offers }) => {

  return (
    <Row
      style={{ marginBottom: '54px' }}
    >
      <Col
        span={8}
      >
      <Form
        name="Product Details"
      >
          <SubTitle>2. Product Details</SubTitle>
          <InputWrapper>
              <InputLabel>
                Select Product
                <Text type="danger">*</Text>
              </InputLabel>
              <LongSelectWrapper>
                <Form.Item
                 name="Select Product"
                 rules={[{ required: true }]}
                >
                  <StyledLongSelect
                    defaultValue = "Satellite Pro"
                    suffixIcon={<ArrowDown />}
                  >
                    {offers.map((item) => (
                      <Option
                        style={{ lineHeight: 2, color: '#102F54' }}
                        value={item.product.name}
                        key={item._id}
                      >
                        {item.product.name}
                      </Option>
                    ))}
                  </StyledLongSelect>
                </Form.Item>
            </LongSelectWrapper>
          </InputWrapper>
          <InputWrapper>
              <InputLabel>
                  SKU
              </InputLabel>
              <Form.Item>
                <DisabledInputStyled
                  disabled
                  placeholder = {offers?.[0]?.product?.SKU}
                  style={{ width: '133px' }}
                />
              </Form.Item>
          </InputWrapper>
          <InputWrapper>
              <InputLabel>
              Original Equipment Manufacturer (OEM)
                <Text
                  type="danger"
                >
                  *
                </Text>
              </InputLabel>
              <LongSelectWrapper>
                <Form.Item
                  name="OEM"
                  rules={[{ required: true }]}
                >
                  <StyledLongSelect
                    defaultValue="Asus TUF Gaming F15"
                    suffixIcon={<ArrowDown />}
                    >
                    {offers.map((item) => (
                      <Option
                        style={{ lineHeight: 2, color: '#102F54' }}
                        value={item.oem}
                        key={item._id}
                      >
                        {item.oem ? (item.oem) : 'no data'}
                      </Option>
                    ))}
                  </StyledLongSelect>
                </Form.Item>
            </LongSelectWrapper>
          </InputWrapper>
          <InputWrapper>
              <InputLabel>
                Available Quantity
              </InputLabel>
              <Form.Item>
                <InputStyled
                  defaultValue = {5000}
                />
              </Form.Item>
          </InputWrapper>
          <InputWrapper>
              <InputLabel>
                Stock Availability Date
                <Text
                  type="danger"
                >
                  *
                </Text>
              </InputLabel>
              <Form.Item
               name="Stock Availability Date"
               rules={[{ required: true }]}
              >
                <DatePickerStyled
                  placeholder='Select a date'
                  suffixIcon={<Calendar />}
              />
              </Form.Item>
          </InputWrapper>
          <InputWrapper>
              <InputLabel>
                Indicative Reseller Buy Price
              </InputLabel>
              <Form.Item>
                <InputStyled
                  placeholder='€'
                />
              </Form.Item>
          </InputWrapper>
          <InputLabel>
              NTE
            <Text
              type="danger"
            >
              *
            </Text>
          </InputLabel>
          <Form.Item
            name="NTE"
            rules={[{ required: true }]}
            style={{ marginBottom: '0px' }}
          >
            <InputStyled
              placeholder='€'
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

  export default ProductDetailsSection;