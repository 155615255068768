import React, { FC } from "react";
import styled from "styled-components/macro";
import Input from "../forms/Input";

interface IPriceAmount {
  value?: any,
  onChange?: (e: any) => void,
}

const PriceAmount: FC<IPriceAmount> = ({value = 0, onChange}) => {
  return (
    <AmountWrap>
      <InputStyled
        defaultValue={value}
        type="number"
        step="0.01"
        min="0"
        onChange={onChange}
      />
    </AmountWrap>
  );
};

export default PriceAmount;

const InputStyled = styled(Input)`
  width: 90px;
  height: 35px;
  padding: 0 5px 0 25px;
`;

const AmountWrap = styled.div`
  position: relative;
  display: flex;
  
  &:before {
    content: "£";
    font-size: 14px;
    line-height: 10px;
    position: absolute;
    top: 0.9em;
    left: 14px;
  }
`;