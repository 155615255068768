import { TableStyled } from "../../../../../../ui/Table/table.styled";
import { useGetBuyersQuotesQuery } from '../../../../../../api/services/superadmin/buyersOrderApi'
import {tableQuote} from '../config/configTable'

const QuoteTable = () => {
    const { data } = useGetBuyersQuotesQuery();

    return(
        <TableStyled
        columns={tableQuote}
        dataSource={data}
        />
    )
}

export default QuoteTable