import styled from 'styled-components/macro';
import { Select } from 'antd';

export const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 425px;
  height: 36px;
  border-right: 3px;

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }

  .ant-select-arrow {
    top: 43%;
  }

  .ant-select-item-option {
    align-items: center;
  }
`;
