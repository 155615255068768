import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import React from 'react';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import SelectInput from '../../../../ui/forms/SelectInput';
import { QuoteProductItem } from '../../types';
import { IProduct } from '../../../buyer/types';
import { TableStyled } from '../../../superadmin/shared/table/TableStyled';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';

interface ItemsTableProps {
  name: string;
  setValue: (field: string, value: string | number) => void;
  data: Array<QuoteProductItem>;
  onAddNewItem: () => void;
  initialData: IProduct[];
}

export default function ItemsTable({
  name,
  setValue,
  data,
  onAddNewItem,
  initialData,
}: ItemsTableProps) {
  const tableConfig: ColumnsType<QuoteProductItem> = [
    {
      title: 'Offer (Product)',
      key: 'productId',
      dataIndex: 'productId',
      render: (_v, r, i) => (
        <StyledSelectInput
          name={`${name}[${i}].productId`}
          value={data[i].productId}
          setFieldValue={setValue}
          initialData={initialData}
          optionName="name"
          suffixIcon={<ArrowDown />}
        />
      ),
    },
    {
      title: 'Unit Price',
      key: 'price',
      dataIndex: 'price',
      render: (_v, r, i) => (
        <StyledInput
          name={`${name}[${i}].price`}
          value={data[i].price}
          onChange={(e) => setValue(`${name}[${i}].price`, e.target.value)}
          onFocus={(e) => setValue(`${name}[${i}].price`, e.target.value.replace(/[^\d.]/g, ''))}
          onBlur={(e) => setValue(`${name}[${i}].price`, Number(e.target.value) ? `£${e.target.value}` : '')}
        />
      ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (_v, r, i) => (
        <StyledInput
          type="number"
          step={1}
          min={0}
          name={`${name}[${i}].quantity`}
          value={data[i].quantity}
          onChange={(e) => setValue(`${name}[${i}].quantity`, Number(e.target.value))}
        />
      ),
    },
  ];

  return (
    <Container>
      <AddItemButton onClick={onAddNewItem}>+ Add Item</AddItemButton>
      <CustomTableStyled
        columns={tableConfig}
        dataSource={data}
        pagination={false}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AddItemButton = styled(ButtonDark)`
  padding: 10px 25px;
  align-self: flex-start;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 120px;
  height: unset;
  padding: 4px 8px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
  max-width: 424px;
`;

const CustomTableStyled = styled(TableStyled)`
  .ant-table tr {
    font-weight: 500;
  }

  .ant-table-thead th {
    font-weight: 600;
  }
`;
