import { Dayjs } from 'dayjs';

export enum CurrencyEnum {
  '€' = '€',
}

export enum ProductTypesEnum {
  DEVICE = 'DEVICE',
  ACCESSORY = 'ACCESSORY',
}

export interface EditFormType {
  offerNumber: string,
  startDate: string | Dayjs,
  endDate: string | Dayjs,
  platformFee: number,
  product: ProductTypesEnum | string,
  insurances: string [],
  leases: string[],
  offerGroup: string,
  oem: string,
  quantity: number,
  stockAvailableDate: string,
  indicativeBuyerPrice: number,
  nte: number,
  eligibleSellers: any[],
  allowForAllSellers?: number;
  currency?: CurrencyEnum | string;
}

export interface SearchOfferType {
  offerNumber: string;
  SKU: string
}
