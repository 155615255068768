import { Alert } from 'antd';
import styled from 'styled-components/macro';
import { DownOutlined } from '@ant-design/icons';
import { CSSProperties, FC } from 'react';
import InputWrap from '../ui/forms/InputWrap';

export type FormRow = {
  title?: string | JSX.Element;
  name: string;
  component: FC<any>;
  containerStyle?: CSSProperties;
  isRequired?: boolean;
  errors?: string[];
  props?: Record<string, any>;
  showDropDownIcon?: boolean;
};

export type FormConfig = Array<FormRow>;

export function renderFormConfig(formConfig: FormConfig, formik: any) {
  return formConfig.map((RowConfig, index) => (
    <InputWrap
      style={RowConfig.containerStyle || {}}
      key={index}
      isRequired={RowConfig.isRequired}
      title={RowConfig.title!}
      hasError={!!formik.errors[RowConfig.name] || !!RowConfig.errors}
    >
      <RowConfig.component
        value={formik.values[RowConfig.name]}
        key={index}
        name={RowConfig.name}
        onChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        {...RowConfig.props}
      />
      {RowConfig.showDropDownIcon && <StyledDownOutlined />}
      {RowConfig.errors?.map((error) => (
        <>
          <Alert message={error} type="error" />
        </>
      ))}
    </InputWrap>
  ));
}

const StyledDownOutlined = styled(DownOutlined)`
  position: absolute;
  top: 43px;
  right: -8px;
  width: 12px;
  height: 12px;
  opacity: 0.25;
`;
