import { RecorderTableConfig } from './config/RecorderTableConfig';
import { TableStyled } from "../../../../../ui/Table/table.styled";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import ROUTER_CONFIG from "../../../../../router/router-config";

const RecorderTabel: FC = () => {
  const {push} = useHistory();
  const onRowClickHandler = (paymentId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_PAYMENT_VIEW.getPath({paymentId}));
    return(
        <TableStyled
        columns={RecorderTableConfig}
        onRow={(rowData) => ({
          onClick: () => onRowClickHandler ? onRowClickHandler(rowData?._id) : null,
          style: { cursor: 'pointer' },
        })}
        style={{ borderTop: '1px solid #2682DE', marginRight: 2 }}
        />
    )
}

export default RecorderTabel