import { OfferGroupType } from '../../../project-types/offers/offerGroups/types';
import { EditOfferGroupForm } from '../../../components/superadmin/offers/offer-groups/types';
import { superadminApi } from './superadminApi';

const offerGroupsApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getOfferGroups: build.query<OfferGroupType, void>({
      query: () => 'offerGroup',
    }),
    getOfferGroupsList: build.query<Array<OfferGroupType>, void>({
      query: () => 'offerGroup',
    }),
    getOfferGroup: build.query<OfferGroupType, string>({
      query: (offerGroupId) => `offerGroup/byId/${offerGroupId}`,
    }),
    editOfferGroup: build.mutation<any, { body: EditOfferGroupForm, offerGroupId: string }>({
      query: ({ body, offerGroupId }) => ({
        url: `offerGroup/byId/${offerGroupId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteOfferGroup: build.query<any, string>({
      query: (offerGroupId) => ({
        url: `offerGroup/byId/${offerGroupId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetOfferGroupsQuery, useGetOfferGroupsListQuery, useGetOfferGroupQuery, useEditOfferGroupMutation, useDeleteOfferGroupQuery,
} = offerGroupsApi;
