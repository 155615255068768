import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import OrdersTable from '../../overview/OrdersTable';
import { OrdersOverviewTableRow } from '../../types';

const { TabPane } = Tabs;

interface OrdersOverviewProps {
  tableData: Array<OrdersOverviewTableRow>;
  onRowClickHandler: (id: string, paymentMethod: string) => void;
}

export default function OrdersOverview({ tableData, onRowClickHandler }: OrdersOverviewProps) {
  return (
    <Container>
      <Title>Orders</Title>
      <Tabs defaultActiveKey="1">
        <StyledTabPane tab="Action Required" key="1">
          <OrdersTable
            tableData={[]}
            onReviewClickHandler={onRowClickHandler}
            buttonText="Review"
          />
        </StyledTabPane>
        <StyledTabPane tab="Pending" key="2">
          <OrdersTable
            tableData={[]}
            onReviewClickHandler={onRowClickHandler}
            buttonText="View Details"
          />
        </StyledTabPane>
        <StyledTabPane tab="Accepted" key="3">
          <OrdersTable
            tableData={[]}
            onReviewClickHandler={onRowClickHandler}
            buttonText="View Details"
          />
        </StyledTabPane>
        <StyledTabPane tab="Rejected" key="4">
          <OrdersTable
            tableData={[]}
            onReviewClickHandler={onRowClickHandler}
            buttonText="View Details"
          />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  margin-bottom: 40px;
  font-weight: bold;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
