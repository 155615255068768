interface IconProps {
  isSelected: boolean;
}

export function HomeIcon({isSelected}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19">
      <path
        fill='#F5F5F5'
        d="M17.441 17.865H1.547V8.144l.248-.224 7.198-6.477.501-.45.502.45 7.197 6.477.248.224v9.721z"
      />
      <path
        fill='#6B7A87'
        d="M9.494 2.001L2.297 8.478v8.637h14.394V8.478L9.494 2zm0-1.5c.36 0 .718.128 1.004.385l7.197 6.476c.316.285.496.69.496 1.116v8.637a1.5 1.5 0 01-1.5 1.5H2.297a1.5 1.5 0 01-1.5-1.5V8.478c0-.425.18-.83.497-1.116L8.49.886A1.495 1.495 0 019.494.5z"
      />
    </svg>
  );
}

export function OffersIcon({isSelected}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      fill='none'
      viewBox="0 0 19 17">
      <path stroke='#6B7A87' d="M16.938 15.454H2.048V1.55h14.89v13.904z"/>
      <path
        stroke='#6B7A87'
        d="M2.298 1.3h-.5v14.404h15.39V1.3H2.298zm0-.5h14.39a1 1 0 011 1v13.404a1 1 0 01-1 1H2.298a1 1 0 01-1-1V1.8a1 1 0 011-1zM9.495 1.27v14.465M16.988 8.501H2"
      />
    </svg>
  );
}

export function OrdersIcon({isSelected}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15">
      <path fill='#F5F5F5'
            d="M17.144 14.154H1.25V1.138h15.894v13.016z"/>
      <path
        fill='#6B7A87'
        d="M2 1.888v11.516h14.394V1.888H2zm0-1.5h14.394a1.5 1.5 0 011.5 1.5v11.516a1.5 1.5 0 01-1.5 1.5H2a1.5 1.5 0 01-1.5-1.5V1.888A1.5 1.5 0 012 .388z"
      />
      <path fill='#F5F5F5'
            d="M9.197 1.887v4.318-4.318z"/>
      <path stroke='#6B7A87'
            strokeWidth="1.5" d="M9.197 1.887v4.318"/>
    </svg>
  );
}

export function PaymentsIcon({isSelected}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path fill='#F5F5F5'
            d="M16.394 1.74H2.004v11.52h14.39V1.74z"/>
      <path
        stroke='#6B7A87'
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.144.99H1.254v13.02h15.89V.99z"
      />
      <path
        stroke='#6B7A87'
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M16.685 7.5h-4.247"
      />
      <path
        fill="#fff"
        d="M12.438 7.86a.36.36 0 100-.72.36.36 0 000 .72z"
      />
    </svg>
  );
}

export function QuoteIcon({isSelected}: IconProps) {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 1.88672V13.4023H15.8943V1.88672H1.5ZM1.5 0.386719H15.8943C16.7227 0.386719 17.3943 1.05859 17.3943 1.88672V13.4023C17.3943 14.2305 16.7227 14.9023 15.8943 14.9023H1.5C0.671631 14.9023 0 14.2305 0 13.4023V1.88672C0 1.05859 0.671631 0.386719 1.5 0.386719Z"
        fill="#6B7A87"/>
      <path d="M1.5 1.88672V13.4023H15.8943V1.88672H1.5Z" fill="#F5F5F5"/>
    </svg>
  );
}

export function ProductsIcon({isSelected}: IconProps) {
  return (
    <svg width="18"
         height="19"
         viewBox="0 0 18 19"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M17 4.8C16.8 4.5 16.6 4.2 16.2 4L10 0.4C9.6 0.1 9.1 0 8.7 0C8.3 0 7.7 0.1 7.3 0.4L1 4C0.7 4.2 0.5 4.5 0.3 4.8C0.1 5.1 0 5.5 0 5.8V12.8C0 13.2 0.1 13.5 0.3 13.8C0.5 14.1 0.7 14.4 1.1 14.6L7.3 18.2C7.7 18.4 8.2 18.6 8.6 18.6H8.7C9.2 18.6 9.6 18.5 10 18.2L16.2 14.6C16.5 14.4 16.8 14.1 17 13.8C17.2 13.5 17.3 13.1 17.3 12.8V5.8C17.3 5.5 17.2 5.1 17 4.8ZM7.9 16.8L1.9 13.3C1.8 13.3 1.8 13.2 1.7 13.1C1.7 13 1.6 12.9 1.6 12.8V6.4L7.9 10.1V16.8ZM8.7 8.7L4.3 6.2L4.2 6.1L3.9 5.9L2.5 4.9L4.1 4L8.1 1.7C8.3 1.6 8.5 1.5 8.7 1.5C8.9 1.5 9.1 1.5 9.3 1.7L13.5 4.2L13.8 4.4L14.9 5L13.4 5.9L12.9 6.2L12.6 6.4L8.7 8.7ZM15.8 12.8C15.8 12.9 15.8 13 15.7 13.1C15.6 13.2 15.6 13.3 15.5 13.3L9.5 16.8V10.1L15.8 6.4V12.8Z"
            fill="#6B7A87"
      />
    </svg>
  );
}

export function ServicesIcon({isSelected}: IconProps) {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.85 2C0.85 1.36487 1.36487 0.85 2 0.85H15.85C16.4851 0.85 17 1.36487 17 2V13.15H0.85V2Z" fill="#F5F5F5"
            stroke="#6B7A87" strokeWidth="1.7"/>
      <rect x="11" y="4" width="8" height="10" fill="#F5F5F5"/>
      <path
        d="M13.35 7C13.35 6.86193 13.4619 6.75 13.6 6.75H17.9C18.0381 6.75 18.15 6.86193 18.15 7V13.25H13.6C13.4619 13.25 13.35 13.1381 13.35 13V7Z"
        fill="#F5F5F5" stroke="#6B7A87" strokeWidth="1.5"/>
    </svg>
  );
}

export function BuyersIcon({isSelected}: IconProps) {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49117 0.50001C6.81041 0.501756 5.16789 1.00176 3.77123 1.93681C2.37458 2.87186 1.28649 4.19999 0.644496 5.75331C0.00250512 7.30664 -0.164564 9.01542 0.164407 10.6637C0.493378 12.3119 1.30362 13.8257 2.49272 15.0135C3.68181 16.2014 5.19638 17.0101 6.84498 17.3373C8.49358 17.6646 10.2022 17.4957 11.7548 16.8521C13.3075 16.2085 14.6345 15.1191 15.5681 13.7214C16.5017 12.3238 17 10.6808 17 9C17.0006 7.88286 16.7808 6.77657 16.3533 5.74447C15.9258 4.71236 15.2989 3.77471 14.5086 2.98518C13.7182 2.19565 12.7799 1.56975 11.7474 1.14331C10.7148 0.716874 9.60831 0.498269 8.49117 0.50001ZM8.5 15.8047C7.15541 15.8038 5.84125 15.4044 4.72362 14.6568C3.606 13.9093 2.73507 12.8471 2.22092 11.6047C1.70677 10.3623 1.57247 8.99538 1.83501 7.67667C2.09754 6.35795 2.74511 5.14666 3.69588 4.19589C4.64666 3.24512 5.85795 2.59754 7.17666 2.33501C8.49538 2.07248 9.86232 2.20678 11.1047 2.72093C12.3471 3.23508 13.4093 4.106 14.1568 5.22363C14.9044 6.34125 15.3038 7.65541 15.3047 9C15.3057 9.89388 15.1303 10.7792 14.7887 11.6052C14.4471 12.4312 13.9459 13.1817 13.3138 13.8138C12.6817 14.4459 11.9312 14.9471 11.1052 15.2887C10.2792 15.6303 9.39388 15.8057 8.5 15.8047Z"
        fill="#6B7A87"/>
      <path
        d="M5.3125 9.77609V8.77826H6.15425C5.77464 8.26087 5.58483 7.76812 5.58483 7.3C5.58483 6.55266 5.89017 5.94082 6.50085 5.46449C7.11153 4.98816 7.80886 4.75 8.59284 4.75C9.9875 4.75 10.9448 5.24686 11.4647 6.24058L10.0659 7.05362C9.95862 6.79082 9.78944 6.5814 9.55837 6.42536C9.33556 6.26111 9.08386 6.17899 8.80328 6.17899C8.43192 6.17899 8.12245 6.28575 7.87488 6.49928C7.62731 6.7128 7.50352 6.99203 7.50352 7.33696C7.50352 7.45193 7.51177 7.5628 7.52828 7.66957C7.54478 7.76812 7.58192 7.87899 7.63968 8.00217C7.69745 8.11715 7.73871 8.19928 7.76347 8.24855C7.78823 8.28961 7.85012 8.38406 7.94915 8.53188C8.04818 8.67971 8.10182 8.76184 8.11007 8.77826H9.8926V9.77609H8.49381C8.49381 9.80072 8.49381 9.84179 8.49381 9.89928C8.50206 9.94855 8.50619 9.98551 8.50619 10.0101C8.50619 10.2894 8.43192 10.5522 8.28337 10.7986C8.13483 11.0449 7.94502 11.2379 7.71396 11.3775C7.88726 11.32 8.08944 11.2913 8.32051 11.2913C8.56808 11.2913 8.84866 11.3611 9.16226 11.5007C9.47585 11.6321 9.73167 11.6978 9.92973 11.6978C10.4166 11.6978 10.7715 11.5459 10.9943 11.242L11.6875 12.6341C11.2914 13.0447 10.6848 13.25 9.86784 13.25C9.47998 13.25 9.06323 13.1597 8.6176 12.979C8.18022 12.7983 7.85012 12.708 7.62731 12.708C7.23944 12.708 6.7608 12.864 6.19138 13.1761L5.59721 11.9812C6.49672 11.5459 6.94648 11.0326 6.94648 10.4413C6.94648 10.236 6.89697 10.0143 6.79794 9.77609H5.3125Z"
        fill="#6B7A87"/>
    </svg>
  
  );
}

export function SuppliersIcon({isSelected}: IconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.57143" y="1.07031" width="6.85714" height="6.85714" rx="1" fill="#F5F5F5" stroke="#6B7A87"
            strokeWidth="1.5"/>
      <mask id="path-2-inside-1_1677_5574" fill="white">
        <rect y="6.78516" width="16" height="9.14286" rx="1"/>
      </mask>
      <rect y="6.78516" width="16" height="9.14286" rx="1" fill="#F5F5F5" stroke="#6B7A87" strokeWidth="3"
            mask="url(#path-2-inside-1_1677_5574)"/>
      <path d="M8 6.78516V15.928" stroke="#6B7A87"/>
    </svg>
  );
}

export function LessorsIcon({isSelected}: IconProps) {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 8.2818L7.34033 0.75H13.25V15.25H0.75V8.2818Z" fill="#F5F5F5" stroke="#6B7A87" strokeWidth="1.5"/>
      <path d="M6.25 7.25L1.65283 7.25L3.36443 5.29388L6.18931 2.06545L6.25 1.99609L6.25 7.25Z" fill="#F5F5F5"
            stroke="#6B7A87" strokeWidth="1.5"/>
    </svg>
  );
}

export function InsuresIcon({isSelected}: IconProps) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.85" y="0.85" width="15.3" height="15.3" rx="1.15" fill="#F5F5F5" stroke="#6B7A87" strokeWidth="1.7"/>
      <line x1="4.58823" y1="5.20703" x2="12.4118" y2="5.20703" stroke="#6B7A87"/>
      <line x1="4.58823" y1="8.55859" x2="12.4118" y2="8.55859" stroke="#6B7A87"/>
      <line x1="4.58823" y1="11.9102" x2="12.4118" y2="11.9102" stroke="#6B7A87"/>
    </svg>
  );
}

export function UsersIcon({isSelected}: IconProps) {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 8.75H9C10.7949 8.75 12.25 10.2051 12.25 12V15.25H0.75V12C0.75 10.2051 2.20507 8.75 4 8.75Z"
            fill="#F5F5F5" stroke="#6B7A87" strokeWidth="1.5"/>
      <circle cx="6.5" cy="3.5" r="2.75" fill="#F5F5F5" stroke="#6B7A87" strokeWidth="1.5"/>
    </svg>
  
  );
}

export function NodesIcon({isSelected}: IconProps) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.52344 2.375C2.44146 2.375 2.375 2.44146 2.375 2.52344V7.57031C2.375 7.65229 2.44146 7.71875 2.52344 7.71875H7.57031C7.65229 7.71875 7.71875 7.65229 7.71875 7.57031V2.52344C7.71875 2.44146 7.65229 2.375 7.57031 2.375H2.52344ZM1.1875 2.52344C1.1875 1.78562 1.78562 1.1875 2.52344 1.1875H7.57031C8.30813 1.1875 8.90625 1.78562 8.90625 2.52344V7.57031C8.90625 8.30813 8.30813 8.90625 7.57031 8.90625H2.52344C1.78562 8.90625 1.1875 8.30813 1.1875 7.57031V2.52344Z"
            fill="#6B7A87"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.4297 2.375C11.3477 2.375 11.2812 2.44146 11.2812 2.52344V7.57031C11.2812 7.65229 11.3477 7.71875 11.4297 7.71875H16.4766C16.5585 7.71875 16.625 7.65229 16.625 7.57031V2.52344C16.625 2.44146 16.5585 2.375 16.4766 2.375H11.4297ZM10.0938 2.52344C10.0938 1.78562 10.6919 1.1875 11.4297 1.1875H16.4766C17.2144 1.1875 17.8125 1.78562 17.8125 2.52344V7.57031C17.8125 8.30813 17.2144 8.90625 16.4766 8.90625H11.4297C10.6919 8.90625 10.0938 8.30813 10.0938 7.57031V2.52344Z"
            fill="#6B7A87"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.52344 11.2812C2.44146 11.2812 2.375 11.3477 2.375 11.4297V16.4766C2.375 16.5585 2.44146 16.625 2.52344 16.625H7.57031C7.65229 16.625 7.71875 16.5585 7.71875 16.4766V11.4297C7.71875 11.3477 7.65229 11.2812 7.57031 11.2812H2.52344ZM1.1875 11.4297C1.1875 10.6919 1.78562 10.0938 2.52344 10.0938H7.57031C8.30813 10.0938 8.90625 10.6919 8.90625 11.4297V16.4766C8.90625 17.2144 8.30813 17.8125 7.57031 17.8125H2.52344C1.78562 17.8125 1.1875 17.2144 1.1875 16.4766V11.4297Z"
            fill="#6B7A87"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.4297 11.2812C11.3477 11.2812 11.2812 11.3477 11.2812 11.4297V16.4766C11.2812 16.5585 11.3477 16.625 11.4297 16.625H16.4766C16.5585 16.625 16.625 16.5585 16.625 16.4766V11.4297C16.625 11.3477 16.5585 11.2812 16.4766 11.2812H11.4297ZM10.0938 11.4297C10.0938 10.6919 10.6919 10.0938 11.4297 10.0938H16.4766C17.2144 10.0938 17.8125 10.6919 17.8125 11.4297V16.4766C17.8125 17.2144 17.2144 17.8125 16.4766 17.8125H11.4297C10.6919 17.8125 10.0938 17.2144 10.0938 16.4766V11.4297Z"
            fill="#6B7A87"/>
    </svg>
  );
}