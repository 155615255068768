import styled from "styled-components/macro";
import { ButtonDark } from "../../../../ui/Button";
import Input from "../../../../ui/forms/Input";

export const Container = styled.form`
  position: relative;
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  max-width: 310px;
  height: unset;
  padding: 12px;
`;

export const StyledButtonDark = styled(ButtonDark)`
  padding: 10px 30px;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const SubmitButton = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;


export const Title = styled.h1`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 20px;
`;