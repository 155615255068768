import { ILease } from '../../../components/superadmin/lessors/types';
import { superadminApi } from './superadminApi';

const lessorsApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getLeaseList: build.query<ILease[], void>({
      providesTags: ['LEASE'],
      query: () => ({
        url: 'lease',
      }),
    }),
    getLease: build.query<ILease, string>({
      providesTags: ['LEASE'],
      query: (lessorId: string) => ({
        url: `lease/${lessorId}`,
      }),
    }),
    createLease: build.mutation<void, Omit<ILease, '_id'>>({
      invalidatesTags: ['LEASE'],
      query: (body: Omit<ILease, '_id'>) => ({
        url: 'lease',
        method: 'POST',
        body,
      }),
    }),
    updateLease: build.mutation<
      void,
      { body: Omit<ILease, '_id'>; leaseId: string }
    >({
      invalidatesTags: ['LEASE'],
      query: ({ body, leaseId }) => ({
        url: `lease/${leaseId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteLease: build.mutation<void, { leaseId: string }>({
      invalidatesTags: ['LEASE'],
      query: ({ leaseId }) => ({
        url: `lease/${leaseId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetLeaseListQuery,
  useGetLeaseQuery,
  useCreateLeaseMutation,
  useUpdateLeaseMutation,
  useDeleteLeaseMutation,
} = lessorsApi;
