import { ColumnsType } from 'antd/lib/table';
import { JoinedSuppliersTableRow } from '../types';

export const joinedSuppliersTableConfig: ColumnsType<JoinedSuppliersTableRow> = [
  {
    title: 'Suppliers',
    dataIndex: 'supplier',
    key: 'supplier',
  },
  {
    title: 'Customer Buy Price',
    dataIndex: 'customerBuyPrice',
    key: 'customerBuyPrice',
    align: 'right',
  },
];
