import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../router/router-config';
import SupplierLayout from '../layout/SupplierLayout';
import Overview from './Overview';
import {useGetSupplierOrderListQuery } from '../../../api/services/superadmin/supplierOrders'

export default function OverviewPage() {
  const { push } = useHistory();
  const { data } = useGetSupplierOrderListQuery(); 
  const onReviewClickHandler = (orderId: string, paymentType: string) => push(ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({ orderId, paymentType }));
  const onViewOrdersClickHandler = () => push(ROUTER_CONFIG.SUPPLIER_ORDERS.getPath());
  const onViewOffersClickHandler = () => push(ROUTER_CONFIG.SUPPLIER_OFFERS.getPath());

  return (
    <SupplierLayout>
      {data && <Overview
        onReviewClickHandler={onReviewClickHandler}
        tableData={data?.items}
        onViewOrdersClickHandler={onViewOrdersClickHandler}
        onViewOffersClickHandler={onViewOffersClickHandler}
      />}
    </SupplierLayout>
  );
}
