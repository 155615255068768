import { Row, Col, Button, Space, Table, Modal } from 'antd';
import { FC } from 'react';
import { ButtonLight } from '../../../../ui/Button';

import LayoutV1 from '../../../layout/LayoutV1';

import { CardTopBlue } from '../../shared/card/card.styled';
import DocumentsCard from '../../shared/documents-card/DocumentsCard';
import InsuranceCard from '../../shared/insurance-card/InsuranceCard';
import OverviewCard from '../../shared/overview-card/OverviewCard';
import StatusCard from '../../shared/status-card/StatusCard';
import {
  Subtitle,
  TextBody,
  Title,
} from '../../shared/typography/typography.styled';
import { Flex } from '../../shared/utils/utils.styled';

import { useStatusCard } from '../../../../hooks/useStatusCard';
import { useOrders } from '../../../../hooks/superadmin/orders/useOrders';

import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';
import { ReactComponent as AlertIcon } from '../../../../img/icons/alert-circle-outline.svg';

import {
  buyerOverviewMock,
  lessorOverviewMock,
  resellerOverviewMock,
} from '../../quotes/quotes-create/quotes.create.mock';
import { SummaryQuoteCard } from '../../quotes/quotes-create/summary-card/SummaryCard';
import {
  summaryDeliveryTotalsMock,
  summaryLeaseTotalsMock,
} from '../../quotes/quotes.mock';
import { insuraceMockData } from '../orders.mock';

import { ORDER_STATUS } from '../../types';

export const columns = [
  {
    title: 'Transaction',
    key: 'transaction',
    dataIndex: 'transaction',
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
  },
];

const OrdersDetailPage: FC = () => {
  const { status, onStatusChange } = useStatusCard(
    ORDER_STATUS.OUR_OF_DELIVERY
  );

  const {
    onEditOrderClick,
    isShowOrdersEditModal,
    setIsShowOrdersEditModal,
  } = useOrders();

  return (
    <LayoutV1>
      <Row>
        <Col span={1}>
          <Flex align="center" justify="flex-start" style={{ marginTop: 5 }}>
            <BackIcon cursor="pointer" />
          </Flex>
        </Col>
        <Col span={23}>
          <Row>
            <Col span={24} style={{ marginBottom: 50 }}>
              <Title>Order PO#49318386</Title>
            </Col>
            <Col span={12} style={{}}>
              <StatusCard
                onChange={onStatusChange}
                status={ORDER_STATUS.OUR_OF_DELIVERY}
              />

              <Row style={{ margin: '50px 0' }}>
                <Col span={12}>
                  <Subtitle>Order Details</Subtitle>
                </Col>
                <Col span={12} style={{ textAlign: 'end' }}>
                  <ButtonLight
                    style={{ padding: 6, fontSize: 14, width: 110 }}
                    onClick={() => onEditOrderClick(status!)}
                  >
                    Edit Order
                  </ButtonLight>
                </Col>
              </Row>

              <SummaryQuoteCard
                deliveryTotals={summaryDeliveryTotalsMock}
                leaseTotals={summaryLeaseTotalsMock}
              />

              <InsuranceCard deliveryTotals={insuraceMockData} />

              <Row>
                <Col span={24} style={{ margin: '30px 0' }}>
                  <Subtitle>Payments</Subtitle>
                </Col>
                <Col span={24}>
                  <CardTopBlue bodyStyle={{ padding: 0 }}>
                    <Table
                      columns={columns}
                      locale={{ emptyText: 'No payments received' }}
                    />
                  </CardTopBlue>
                </Col>
              </Row>

              <Row style={{ margin: '40px 0' }}>
                <Col span={12}>
                  <Subtitle>Documents</Subtitle>
                </Col>
                <Col span={12} style={{ textAlign: 'end' }}>
                  <Button type="link">+Add Document</Button>
                </Col>

                <DocumentsCard isDelete />
              </Row>
            </Col>
            <Col span={8} offset={4}>
              <Space direction="vertical" size="large">
                <OverviewCard title="BUYER OVERVIEW" data={buyerOverviewMock} />
                <OverviewCard
                  title="RESELLER OVERVIEW"
                  data={resellerOverviewMock}
                />
                <OverviewCard
                  title="LESSOR OVERVIEW"
                  data={lessorOverviewMock}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={isShowOrdersEditModal}
        footer={null}
        onCancel={() => setIsShowOrdersEditModal(false)}
      >
        <Space>
          <AlertIcon />
          <Subtitle>Cannot make changes to the order</Subtitle>
        </Space>

        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <TextBody>
              You cannot edit orders with{' '}
              <TextBody strong>'Out for Delivery'</TextBody> and{' '}
              <TextBody strong>'Paid'</TextBody> statuses.
            </TextBody>
          </Col>
        </Row>
      </Modal>
    </LayoutV1>
  );
};

export default OrdersDetailPage;
