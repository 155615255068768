import * as yup from 'yup';
import { CREATE_PRODUCT_STEPS } from '../types';

export interface ProductBasicInformationSchema {}

export const productBasicInformationSchema: yup.SchemaOf<ProductBasicInformationSchema> = yup.object(
  {
    SKU: yup.string().required(),
    name: yup.string().required(),
    headlines: yup.string().required(),
    description: yup.string().required(),
    manufacturer: yup.string().required(),
    defaultVatRate: yup.string().required(),
    insuranceAvailable: yup.boolean().required(),
  }
);

export const productStepsSchema = {
  [CREATE_PRODUCT_STEPS.BASIC_INFORMATION]: productBasicInformationSchema,
  [CREATE_PRODUCT_STEPS.ATTRIBUTES]: null,
  [CREATE_PRODUCT_STEPS.OVERVIEW]: null,
  [CREATE_PRODUCT_STEPS.ACCESSORIES]: null,
  [CREATE_PRODUCT_STEPS.RELATED_PRODUCTS]: null,
  [CREATE_PRODUCT_STEPS.RELATED_FILES]: null,
  [CREATE_PRODUCT_STEPS.GALLERY]: null,
};
