import { Dropdown, Input, Menu, Tabs } from 'antd';
import { useMemo, FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ButtonDark } from '../../../ui/Button';
import { MainHeader } from '../../../ui/Headers';
import { IProduct, ProductTypesEnum } from '../../buyer/types';
import ProductsTable from './ProductsTable';
import SearchIcon from '../../../img/icons/icon_search.svg';
import ROUTER_CONFIG from '../../../router/router-config';
import { ReactComponent as ArrowDown } from '../../../img/icons/arrowDownBlue.svg';
import { ProductsTableRow } from '../types';

interface IProps {
  products: IProduct[];
  onCreateProduct: () => void;
  setProductType: (productType: ProductTypesEnum) => void;
  onDeleteProducts: () => void;
  searchDevicesValue: string;
  searchAccessoriesValue: string;
  setSearchDevicesValue: (value: string) => void;
  setSearchAccessoriesValue: (value: string) => void;
  onSubmitSearchDevicesValue: () => void;
  onSubmitSearchAccessoriesValue: () => void;
}

const { TabPane } = Tabs;

enum TABS {
  DEVICES = 'Devices',
  ACCESSORIES = 'Accessories',
}

const Products: FC<IProps> = ({
  products,
  onCreateProduct,
  setProductType,
  onDeleteProducts,
  searchDevicesValue,
  searchAccessoriesValue,
  setSearchDevicesValue,
  setSearchAccessoriesValue,
  onSubmitSearchDevicesValue,
  onSubmitSearchAccessoriesValue,
}) => {
  const { push } = useHistory();
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);

  const tableData: Array<ProductsTableRow> = useMemo(
    () => (products
      ? products.map((product) => ({
        key: product._id,
        _id: product._id,
        SKU: product.SKU,
        name: product.name,
        description: product.description,
        manufacturer: product.manufacturer,
      }))
      : []),
    [products],
  );

  const onRowClickHandler = (productId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_DETAILS.getPath({ productId }));

  const onTabChange = useCallback(
    (activeKey: string) => {
      setSelectedProducts([]);
      setSearchDevicesValue('');
      setSearchAccessoriesValue('');
      switch (activeKey as TABS) {
        case TABS.DEVICES: {
          setProductType(ProductTypesEnum.DEVICE);
          break;
        }
        case TABS.ACCESSORIES: {
          setProductType(ProductTypesEnum.ACCESSORY);
          break;
        }
        default: {
          setProductType(ProductTypesEnum.DEVICE);
          break;
        }
      }
    },
    [setProductType, setSearchDevicesValue, setSearchAccessoriesValue],
  );

  const dropdownContent = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={onDeleteProducts}> Delete </Menu.Item>
      </Menu>
    ),
    [onDeleteProducts],
  );

  return (
    <Wrapper>
      <Header>
        <StyledMainHeader>Products</StyledMainHeader>
        <StyledButton onClick={onCreateProduct}>+ Add New Accessory</StyledButton>
      </Header>
      <Tabs
        defaultActiveKey={TABS.DEVICES}
        onChange={onTabChange}
        tabBarStyle={{ color: '#6B7A87' }}
      >
        <StyledTabPane tab={TABS.DEVICES} key={TABS.DEVICES}>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 20,
                marginBottom: 10,
              }}
            >
              <StyledInput
                onKeyDown={(e) => e.key === 'Enter' && onSubmitSearchDevicesValue()}
                value={searchDevicesValue}
                onChange={(e) => setSearchDevicesValue(e.target.value)}
                placeholder="Product Name"
                iconSource={SearchIcon}
              />
              <Dropdown overlay={dropdownContent}>
                <DropDownButton onClick={(e) => e.preventDefault()}>
                  Actions <ArrowDown style={{ marginBottom: '2px' }} />
                </DropDownButton>
              </Dropdown>
            </div>
            <ProductsTable
              onSelectedProducts={setSelectedProducts}
              selectedProducts={selectedProducts}
              tableData={tableData}
              onRowClickHandler={onRowClickHandler}
            />
          </>
        </StyledTabPane>
        <StyledTabPane tab={TABS.ACCESSORIES} key={TABS.ACCESSORIES}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 20,
            marginBottom: 10,
          }}
          >
            <StyledInput
              value={searchAccessoriesValue}
              onChange={(e) => setSearchAccessoriesValue(e.target.value)}
              placeholder="Accessory Name"
              iconSource={SearchIcon}
              onKeyDown={(e) => e.key === 'Enter' && onSubmitSearchAccessoriesValue()}
            />
            <Dropdown overlay={dropdownContent}>
              <DropDownButton onClick={(e) => e.preventDefault()}>
                                Actions <ArrowDown style={{ marginBottom: '2px' }} />
              </DropDownButton>
            </Dropdown>
          </div>
            <ProductsTable
              onSelectedProducts={setSelectedProducts}
              selectedProducts={selectedProducts}
              tableData={tableData}
              onRowClickHandler={onRowClickHandler}
            />
        </StyledTabPane>
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  margin-bottom: 36px;
`;

const StyledButton = styled(ButtonDark)`
  padding: 13px 60px;
  margin-top: 30px;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 35px;
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  padding: 15px;
  width: 420px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: right 13px center;
`;

const DropDownButton = styled.span`
  cursor: pointer;
  color: #2682de;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;
  min-width: 60px;

  &:hover {
    text-decoration: underline;
  }
`;

export default Products;
