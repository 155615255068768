import { TableStyled } from "../../../../ui/Table/table.styled";
import styled from 'styled-components/macro';
import { ColumnsType } from 'antd/lib/table';
import { JoinedOffersTableRow } from '../../types';
import { showPriceDigits } from '../../../../utils/formats';

interface JoinedOffersTableProps {
  tableData: Array<JoinedOffersTableRow>;
  onRowClickHandler: (id: string) => void;
}

export default function JoinedOffersTable({ tableData, onRowClickHandler }: JoinedOffersTableProps) {
  const tableConfig: ColumnsType<JoinedOffersTableRow> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'OEM',
      dataIndex: 'oem',
      key: 'oem',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Available Quantity',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
      render: (_v, record) => (
        <div>
          <Text>{record.availableQuantity}</Text>
          /
          <GrayText>{record.totalQuantity}</GrayText>
        </div>
      ),
    },
    {
      title: 'Customer Buy Price',
      dataIndex: 'resellerBuyPrice',
      key: 'resellerBuyPrice',
      render: (_v, value) => (
          showPriceDigits(value.customerBuyPrice)
      )
    },
    {
      title: 'Units Sold',
      dataIndex: 'unitsSold',
      key: 'unitsSold',
    },
  ];

  return (
    <TableStyled
      onRow={(rowData) => ({
        onClick: () => onRowClickHandler(rowData.key),
        style: { cursor: 'pointer' },
      })}
      columns={tableConfig}
      dataSource={tableData}
      style={{ borderTop: '1px solid #2682DE', marginRight: 2 }}
    />
  );
}

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
`;

const GrayText = styled(Text)`
  color: #98a6b4;
`;
