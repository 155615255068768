import styled from "styled-components/macro";
import Input from "../forms/Input";

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  //gap: 450px;
  margin: 0 0 10px 0;
`;

export const Search = styled.span`
  color: #333;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 420px;
  height: 36px;
  padding: 10px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 96% center;
`;