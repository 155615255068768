import {
  Card, Col, Divider, Row, Space,
} from 'antd';
import { FC, SVGProps, useMemo } from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import SurfaceImage from '../../../../../img/test_images/surface.png';
import {
  BoldDarkText, DarkText, TitleH2, TitleH3,
} from '../../styled/text';
import { useGetOfferDetailsQuery } from '../../../../../api/services/superadmin/offersApi';
import { offerOverviewMock } from '../../mockData';

export interface IOverviewItem {
  title: string;
  value: string | number;
  image: FC<SVGProps<SVGSVGElement>>;
}

interface IOverviewCardProps {
  title: string;
}

const OverviewCard: FC<IOverviewCardProps> = ({ title }) => {
  const { offerId } = useParams<{ offerId: string }>();
  const { data: offerDetails } = useGetOfferDetailsQuery(offerId);

  const transformData: (string | number)[] | [] = useMemo(() => {
    if (!offerDetails) {
      return [];
    }
    return [offerDetails?.product.SKU, offerDetails.product.manufacturer, offerDetails.offerNumber, offerDetails.quantity, `£${offerDetails.indicativeBuyerPrice ? offerDetails.indicativeBuyerPrice : 0}`, `£${offerDetails.nte}`, dayjs(offerDetails.startDate).format('DD/MM/YY'), dayjs(offerDetails.stockAvailableDate).format('DD/MM/YY')];
  }, [offerDetails]);

  const updateData = useMemo(() => offerOverviewMock.map((item, index) => ({
    ...item,
    value: transformData[index],
  })), [transformData]);

  return (
    <>
      <Header>
        <StyledImg alt="" src={SurfaceImage} />
        <TitleH2>{offerDetails?.product?.name.trim()}</TitleH2>
      </Header>
      <StyledCard
        title={<TitleH3 style={{ marginBottom: '0' }}>{title}</TitleH3>}
        bordered={false}
      >
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          {updateData.map(({ title: titleText, image: Image, value }) => (
            <>
              <Divider style={{ margin: 0 }} />
              <Row align="middle">
                <Col span={2} style={{ textAlign: 'center' }}>
                  <Image style={{ marginRight: '11px', marginTop: '7px' }} />
                </Col>
                <Col span={14}>
                  <DarkText>{titleText}</DarkText>
                </Col>
                <Col
                  span={8}
                  style={{
                    textAlign: 'end',
                  }}
                >
                  {titleText === 'Indicative Reseller Buy Price' || titleText === 'NTE'
                    ? (
                      <BoldDarkText>
                        {value}
                      </BoldDarkText>
                    )
                    : (
                      <DarkText
                        style={{ fontWeight: 400 }}
                      >
                        {value}
                      </DarkText>
                    )}
                </Col>
              </Row>
            </>
          ))}
        </Space>
      </StyledCard>
    </>
  );
};

const StyledImg = styled.img`
  margin-bottom: 10px
`;

const Header = styled.div`
  padding-top: 10px;
  padding-left: 21px;
`;

const StyledCard = styled(Card)`
  background-color: #F5F8FB;
  padding: 20px;

  .ant-card-body {
    padding: 0
  }

  .ant-card-head {
    border-bottom: 0;
    min-height: unset;
    padding: 0;
  }

  .ant-card-head-title {
    padding-bottom: 0;
  }
`;
export default OverviewCard;
