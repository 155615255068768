import {
  Row, Col, Space, Typography,
} from 'antd';
import { FC } from 'react';

import { Wrapper } from './styled';
import { Flex } from '../utils/utils.styled';
import { TextBody } from '../typography/typography.styled';

import { ReactComponent as DeleteIcon } from '../../../../img/icons/delete-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../../img/icons/document-icon.svg';
import { ButtonLight } from '../../../../ui/Button';

const { Text } = Typography;

interface IDocumentCardProps {
  isDelete?: boolean;
}

const DocumentsCard: FC<IDocumentCardProps> = ({ isDelete }) => (
  <Wrapper bodyStyle={{ padding: '10px 0' }}>
    <Row>
      <Col span={2}>
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <DocumentIcon />
        </Flex>
      </Col>
      <Col span={10} style={{}}>
        <Space direction="vertical" size={0}>
          <TextBody strong>Purchase Order - PO#12318381</TextBody>
          <Text type="secondary" strong>
            17:53 on 06/01/2021
          </Text>
        </Space>
      </Col>
      <Col span={isDelete ? 8 : 12} style={{ textAlign: 'end' }}>
        <ButtonLight style={{
          width: 150,
          padding: 5,
          fontSize: 14,
        }}
        >
          View Document
        </ButtonLight>
      </Col>
      {isDelete && (
      <Col span={2} offset={2}>
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <DeleteIcon
            style={{
              cursor: 'pointer',
              width: 25,
              height: 25,
            }}
          />
        </Flex>
      </Col>
      )}
    </Row>
  </Wrapper>
);

export default DocumentsCard;
