import styled from 'styled-components/macro';
import React, { useState, useEffect } from 'react';
import { AddNodesForm, EligibleNodesTableRow } from '../../types';
import ModalInPortal from '../../../../../../ui/Modal/ModalPortal';
import NodesTableControls from './NodesTableControls';
import NodesTableData from './NodesTableData';
import InviteAddNodeModalContent from './InviteAddNodeModalContent';

interface OffersTableProps {
  nodesTableData: Array<EligibleNodesTableRow>;
  onSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
  onDeleteOfferGroupClickHandler: () => void;
  onSubmitAddNodeForm: (values: AddNodesForm) => void;
}

const NodesTable: React.FC<OffersTableProps> = ({
  nodesTableData,
  selectedOffers,
  onSelectedOffers,
  onDeleteOfferGroupClickHandler,
  onSubmitAddNodeForm,
}) => {
  const InviteNewNodeModal = ModalInPortal();
  const nodesDetailsModal = ModalInPortal();
  const onAddOfferClickHandler = () => InviteNewNodeModal.onShow();
  const [isEligibleNodes, setIsEligibleNodes] = useState<boolean>(false);

  useEffect(() => {
    if (!isEligibleNodes) {
      onSelectedOffers([]);
    }
  }, [isEligibleNodes, setIsEligibleNodes]);

  return (
    <Container>
      <Title>Eligible Nodes</Title>
      {InviteNewNodeModal.ModalRender && (
        <InviteNewNodeModal.ModalRender>
          <InviteAddNodeModalContent
            onClose={InviteNewNodeModal.onClose}
            onSubmit={onSubmitAddNodeForm}
          />
        </InviteNewNodeModal.ModalRender>
      )}
      <NodesTableControls
        onDeleteOfferGroupClickHandler={onDeleteOfferGroupClickHandler}
        onAddOfferClickHandler={onAddOfferClickHandler}
        isEligibleNodes={isEligibleNodes}
        setIsEligibleNodes={setIsEligibleNodes}
      />
        <NodesTableData
          tableData={nodesTableData}
          isEligibleNodes={isEligibleNodes}
          setIsEligibleNodes={setIsEligibleNodes}
          onSelectedOffers={onSelectedOffers}
          selectedOffers={selectedOffers}
        />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 12px
`;

const Title = styled.h1`
  margin: 0 0 30px 0;
  font-size: 21px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
`;

export default NodesTable;
