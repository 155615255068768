import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React from 'react';
import { FormConfig, renderFormConfig } from '../../../../../../utils/form';
import { AddNodesForm } from '../../types';
import CloseIcon from '../../../../../../img/close.svg';
import { addNodeForm } from '../../offerGroup-schemas';
import SelectInput from '../../../../../../ui/forms/SelectInput';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import {
  CloseButton, ContainerForPopover, InviteButton, LinkAfterButton, StyledButtonDark, Title, Text,
} from '../styled-offerGropDetails/popover.styled';

const StyledSelect = styled(SelectInput)`
  width: 100%;
  max-width: 425px;

  .ant-select-selector {
    height: 36px !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

interface InviteAddOfferModalContentProps {
  onSubmit: (values: AddNodesForm) => void;
  onClose: () => void;
}

const SELECTED_DATA = [
  'Intel',
  'Intel',
]

const formConfig: FormConfig = [
  {
    title: 'Node Name',
    name: 'nodeName',
    component: StyledSelect,
    props: {
      initialData: SELECTED_DATA,
      suffixIcon: <ArrowDown />,
      dropdownStyle: {
        zIndex: 200000,
        position: 'fixed',
      },
    },
    isRequired: true,
  },
];



const InviteAddNodeModalContent: React.FC<InviteAddOfferModalContentProps> = ({ onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      numberNode: SELECTED_DATA[0],
    },
    validationSchema: addNodeForm,
    onSubmit: (values: any) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <ContainerForPopover onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>Add Node</Title>
      {renderFormConfig(formConfig, formik)}
      <StyledButtonDark type="submit">Add Node</StyledButtonDark>
      <LinkAfterButton>
        <Text>Or</Text>
        <InviteButton>Create new Node</InviteButton>
      </LinkAfterButton>
    </ContainerForPopover>
  );
};

export default InviteAddNodeModalContent;
