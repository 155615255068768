import { supplierApi } from './supplierApi';
import {
  ISupplierPayments,
  ISupplierBalanceTransfers,
  ISupplierPaymentsSummary,
} from '../../../components/supplier/types';

const paymentsApi = supplierApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierPayments: build.query<ISupplierPayments, void>({
      query: () => 'payments',
    }),
    getSupplierPaymentsSummary: build.query<ISupplierPaymentsSummary, void>({
      query: () => 'payments/summary',
    }),
    getSupplierPaymentsTransfer: build.query<ISupplierBalanceTransfers, void>({
      query: () => 'payments/transfers',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSupplierPaymentsQuery,
  useGetSupplierPaymentsSummaryQuery,
  useGetSupplierPaymentsTransferQuery,
} = paymentsApi;
