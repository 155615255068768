import { useState } from 'react';
import { message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import { QuoteServiceItem } from '../../types';
import { TableStyled } from '../../../superadmin/shared/table/TableStyled';

interface ServicesTableProps {
  data: Array<QuoteServiceItem>;
  selectedServices: Array<QuoteServiceItem>;
  onAddNewItem: (service: QuoteServiceItem) => void;
}

type StateType = { [key: string]: QuoteServiceItem };

export default function ServicesTable({
  data,
  onAddNewItem,
  selectedServices,
}: ServicesTableProps) {
  const state = data.reduce((acc: StateType, item) => {
    acc[item.type] = item;

    return acc;
  }, {});
  const [editableServices, setEditableServices] = useState<StateType>(state);

  const isItemSelected = (serviceName: string) => selectedServices.find((s) => s.type === serviceName);
  const onChangePrice = (price: string, serviceName: string) => {
    setEditableServices((services) => ({
      ...services,
      [serviceName]: {
        ...services[serviceName],
        price,
      },
    }));
  };
  const onChangeQuantity = (quantity: number, serviceName: string) => {
    setEditableServices((services) => ({
      ...services,
      [serviceName]: {
        ...services[serviceName],
        quantity,
      },
    }));
  };
  const onAddNewItemHandler = (serviceName: string) => (editableServices[serviceName].quantity && editableServices[serviceName].price ? onAddNewItem({
    type: serviceName,
    quantity: editableServices[serviceName].quantity,
    price: editableServices[serviceName].price,
    description: editableServices[serviceName].description,
  }) : message.error('Enter price and quantity'));

  const tableConfig: ColumnsType<QuoteServiceItem> = [
    {
      title: 'Service Type',
      key: 'type',
      dataIndex: 'type',
      render: (textOfCell) => <span style={{ whiteSpace: 'nowrap' }}>{textOfCell}</span>,
      width: '25%',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Unit Price',
      key: 'price',
      dataIndex: 'price',
      render: (textOfCell, rowData) => (
        <StyledInput
          disabled={!!isItemSelected(rowData.type)}
          value={editableServices[rowData.type].price}
          onChange={(e) => onChangePrice(e.target.value, rowData.type)}
          onFocus={(e) => onChangePrice(e.target.value.replace(/[^\d.]/g, ''), rowData.type)}
          onBlur={(e) => onChangePrice(Number(e.target.value) ? `£${e.target.value}` : '', rowData.type)}
        />
      ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (textOfCell, rowData) => (
        <StyledInput
          type="number"
          step={1}
          min={0}
          disabled={!!isItemSelected(rowData.type)}
          value={editableServices[rowData.type].quantity}
          onChange={(e) => onChangeQuantity(Number(e.target.value), rowData.type)}
        />
      ),
    },
    {
      title: '',
      key: 'add',
      dataIndex: 'add',
      render: (textOfCell, rowData) => (isItemSelected(rowData.type)
        ? <AddedButton onClick={() => onAddNewItemHandler(rowData.type)}>Added</AddedButton>
        : <AddButton onClick={() => onAddNewItemHandler(rowData.type)}>Add</AddButton>),
    },
  ];

  return (
    <Container>
      <CustomTableStyled
        columns={tableConfig}
        dataSource={data}
        pagination={false}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 120px;
  height: unset;
  padding: 4px 8px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const AddButton = styled(ButtonDark)`
  padding: 10px 35px;
  height: 45px;
  width: 140px;
`;

const AddedButton = styled(ButtonLight)`
  padding: 10px 35px;
  height: 45px;
  width: 140px;
`;

const CustomTableStyled = styled(TableStyled)`
  .ant-table tr {
    font-weight: 500;
  }

  .ant-table-thead th {
    font-weight: 600;
    color: #6B7A87;
  }

  .ant-table-tbody > tr > td {
    color: #0B0C0C;
  }
`;
