import { FC, useMemo } from 'react';
import { CustomStyledTable } from '../../../../../new-offer/create-new-offer/styled/new-offer.styled';
import { ReactComponent as DeleteIcon } from '../../../../../../../img/icons/deleteIcon.svg';
import { ReactComponent as ArrowDown } from '../../../../../../../img/icons/filled-arrow-down.svg';
import { Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IAddOfferTableRow, IOfferTableItem } from '../../types';
import { DarkSmallButton } from '../../../../styled/buttons';
import styled from 'styled-components/macro';
import { useGetOfferListQuery } from '../../../../../../../api/services/superadmin/offersApi';
import { formatDateShort } from '../../../../../../../ui/DateFormatMethods';

const { Option } = Select;

const StyledSelect = styled(Select)`
color: #102F54;
font-weight: 400;
line-height: 17px;
.ant-select {
  width: 102px!important;
}
.ant-select-selection-item {
  padding-right: 0px!important;
  line-height: 35px!important;
  color: #102F54;
}
.ant-select-selector {
  height: 36px!important;
  width: 114px!important;
  padding: 0 0 0 11px !important;
}
`;

interface IAddOfferTableRowProps {
  dataSource: Array<IOfferTableItem>;
  mapedData: Array<IOfferTableItem>;
  onDelete?: (id: number) => void
  onSkuChange: (sku: string) => void
  onAdd: (row: any) => void
}

const AddOfferTable: FC<IAddOfferTableRowProps> = ({
  dataSource,
  mapedData,
  onDelete,
  onSkuChange,
  onAdd
}) => {
  const { data: offers } = useGetOfferListQuery();

  const columnsItems: ColumnsType<IAddOfferTableRow> =  useMemo(() => [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: ( record: any, row: any) => {
        return (
          record ||
        <>
          <StyledSelect
            style={{maxWidth: 124, marginRight: 10}}
            defaultValue="STV-00002"
            suffixIcon={<ArrowDown />}
            onChange={(value:any)=>onSkuChange(value as string)}
          >
                {offers?.map((item:any) => (
                      <Option
                        style={{ lineHeight: 2, color: '#102F54' }}
                        value={item?.product.SKU}
                        key={item._id}
                      >
                        {item?.product.SKU}
                      </Option>
                    ))}
          </StyledSelect>
          <DarkSmallButton
            style={{width: 66, height: 36, marginLeft: 10}}
            onClick={() => onAdd(row)}>
            Add
          </DarkSmallButton>
        </>
        )
      },
    },
    {
      title: 'Product',
      dataIndex: 'product',
    },
    {
      title: 'Offer Number',
      dataIndex: 'offerNumber',
    },
    {
      title: 'OEM',
      dataIndex: 'oem',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_v, value) => (
        value.startDate && formatDateShort(new Date(value.startDate))
      ),
      sorter: (a, b) => {
        if (new Date(a.startDate) < new Date(b.startDate)) {
          return -1;
        }
        if (new Date(a.startDate) > new Date(b.startDate)) {
          return 1;
        }
        return 0;
      },
      sortDirections: ['descend'],
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_v, value) => (
        value.endDate && formatDateShort(new Date(value.endDate))
      ),
      sorter: (a, b) => {
        if (new Date(a.endDate) < new Date(b.endDate)) {
          return -1;
        }
        if (new Date(a.endDate) > new Date(b.endDate)) {
          return 1;
        }
        return 0;
      },
      sortDirections: ['descend'],
    },
    {
      render: (record :any) => {
        return (
          record.product &&
          <DeleteIcon
            onClick={() => onDelete && onDelete(record.key)}
            style={{ cursor: 'pointer' }}
          />
        );
      },
    }
  ], [onSkuChange, onDelete, onAdd]);

  return (
    <CustomStyledTable
      dataSource={dataSource}
      columns={columnsItems}
      pagination={false}
    />
  );
};

export default AddOfferTable;
