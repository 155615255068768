import { SuppliersApiType } from '../../../components/superadmin/types';
import { superadminApi } from './superadminApi';

const suppliersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getSuppliersList: build.query<Array<SuppliersApiType>, void>({
      providesTags: ['SUPPLIER'],
      query: () => 'seller',
    }),
    getSearchSuppliers: build.query<Array<SuppliersApiType>, { name: string }>({
      providesTags: ['SUPPLIER'],
      query: (body) => ({
        url: 'seller/search',
        params: body,
      }),
    }),
    createSupplier: build.mutation<void, SuppliersApiType>({
      invalidatesTags: ['SUPPLIER'],
      query: (body: SuppliersApiType) => ({
        url: 'seller',
        method: 'POST',
        body,
      }),
    }),
    createOffer: build.mutation<void, SuppliersApiType>({
      invalidatesTags: ['SUPPLIER'],
      query: (body: SuppliersApiType) => ({
        url: 'seller/offer',
        method: 'POST',
        body,
      }),
    }),
    getSupplierOffer: build.query({
      providesTags: ['SUPPLIER'],
      query: () => ({
        url: 'seller/offer',
      }),
    }),
    getSupplierOffersID: build.query({
      providesTags: ['SUPPLIER'],
      query: (id: string) => ({
        url: `seller/offers/${id}`,
      }),
    }),
    deleteSupplier: build.mutation<any, string>({
      invalidatesTags: ['SUPPLIER'],
      query: (id) => ({
        url: `seller/offer/${id}`,
        method: 'DELETE',
      }),
    }),
    getSupplierOfferGroupID: build.query({
      providesTags: ['SUPPLIER'],
      query: (id: string) => ({
        url: `seller/offer/offerGroup${id}`,
      }),
    }),
    getSupplierOfferProductID: build.query({
      providesTags: ['SUPPLIER'],
      query: (id: string) => ({
        url: `seller/offer/product${id}`,
      }),
    }),
    getSupplierOfferListAccessories: build.query({
      providesTags: ['SUPPLIER'],
      query: () => ({
        url: 'seller/offer/list/accessories',
      }),
    }),
    getSupplier: build.query<SuppliersApiType, string>({
      providesTags: ['SUPPLIER'],
      query: () => ({
        url: 'seller/search',
      }),
    }),
  }),
});

export const {
  useGetSuppliersListQuery,
  useCreateSupplierMutation,
  useCreateOfferMutation,
  useGetSupplierOfferQuery,
  useGetSupplierOffersIDQuery,
  useDeleteSupplierMutation,
  useGetSupplierOfferGroupIDQuery,
  useGetSupplierOfferProductIDQuery,
  useGetSupplierOfferListAccessoriesQuery,
  useGetSupplierQuery,
  useGetSearchSuppliersQuery,
} = suppliersApi;