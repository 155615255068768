import { FC, useState } from "react";
import { IEditService, IParamsEvent } from "./types";
import ImageUploader from "../../../../ui/ImageUploader/ImageUploader";
import { Container, StyledInput, Title, CloseButton, SubmitButton } from './service.styled';
import CloseIcon from '../../../../img/close.svg';
import InputWrap from "../../../../ui/forms/InputWrap";

const EditServiceContent: FC<IEditService> = ({onClose, updateService, selectedUsers}) => {
  const [ serviceType, setServiceType ] = useState({
    iconUrl: '',
    name: '',
    logo: '',
  });
  
  const onChange = ({target: {name, value}}: IParamsEvent) => {
    setServiceType(serviceType => ({
      ...serviceType,
      [name]: value
    }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (updateService && selectedUsers) {
      updateService({
        iconUrl: serviceType?.logo || '',
        name: serviceType?.name || ''
      }, selectedUsers[0]);
    }
    onClose();
  };

  return (
    <Container onSubmit={onSubmit} >
      <CloseButton onClick={onClose} alt="close" src={CloseIcon}/>
      <Title>Edit Service Type</Title>
      <ImageUploader setState={setServiceType} iconText="Icon"/>
      <InputWrap title="Service Type Name" isRequired={true}>
        <StyledInput onChange={onChange} name="name" value={serviceType.name}/>
      </InputWrap>
      <SubmitButton type="submit">
        Save
      </SubmitButton>
    </Container>
  );
};

export default EditServiceContent;