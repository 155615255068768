import { Space, Form, Button, InputProps, Input } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { ButtonDark } from '../../../../ui/Button';
import { TextBody } from '../typography/typography.styled';

const DeliveryCharge: FC = () => {
  return (
    <Space direction="vertical" style={{ marginTop: 30 }}>
      <TextBody strong>Supplier`s delivery charge</TextBody>

      <Space align="baseline">
        <Form.Item>
          <DisabledInputStyle value="$5.25" disabled />
        </Form.Item>
        <ButtonDark
          style={{
            fontSize: 14,
            width: 80,
            height: 36,
          }}
        >
          Edit
        </ButtonDark>
        <Button
          type="primary"
          danger
          ghost
          style={{
            width: 80,
            fontWeight: 700,
            height: 36,
          }}
        >
          Remove
        </Button>
      </Space>
    </Space>
  );
};

const DisabledInputStyle: FC<InputProps> = styled(Input)`
  color: ${(props) => props.theme.textPrimary} !important;
  font-weight: 600;
  font-size: 14px;

  height: 37px;
`;

export default DeliveryCharge;
