import { useHistory } from 'react-router-dom';
import { useGetJoinedListQuery } from '../../../../api/services/superadmin/joinedOffers'
import ROUTER_CONFIG from '../../../../router/router-config';
import { showPriceDigits } from '../../../../utils/formats';
import SupplierLayout from '../../layout/SupplierLayout';
import { AvailableOffersTableRow, JoinedOffersTableRow } from '../../types';
import OffersView from './OffersView';

const OffersPage = () => {
  const history = useHistory();
  const { data } = useGetJoinedListQuery();

  const onJoinedOffersRowClick = (offerId: string) => history.push(ROUTER_CONFIG.SUPPLIER_VIEW_OFFER.getPath({ offerId }));
  const onAvailableOffersJoinClick = (offerId: string) => history.push(ROUTER_CONFIG.SUPPLIER_JOIN_OFFER.getPath({ offerId }));

  const mockAvailableTableData: Array<AvailableOffersTableRow> = new Array(2).fill(1).reduce((acc, item, index) => {
    const rowItem: AvailableOffersTableRow = {
      key: `${index}`,
      sku: `STQ-0000${index + 1}`,
      oem: 'Microsoft',
      product: 'Microsoft Surface Go 2',
      availableQuantity: '2,000',
      totalQuantity: '5,000',
      resellerBuyPrice: showPriceDigits(440),
    };
    acc.push(rowItem);
    return acc;
  }, []);

  return (
    <SupplierLayout>
      {data && <OffersView
        onJoinedOffersRowClick={onJoinedOffersRowClick}
        joinedOffersTableData={data.items}
        availableOffersTableData={mockAvailableTableData}
        onAvailableOffersJoinClick={onAvailableOffersJoinClick}
      />}
    </SupplierLayout>
  );
}

export default OffersPage