import { RefObject } from 'react';
import styled from 'styled-components/macro';
import LinkWithArrow from '../../../../ui/elements/LinkWithArrow';
import { BuyerOverviewServiceType } from '../../types';

interface GuidesAndServicesBlockProps {
  services: Array<BuyerOverviewServiceType>;
  forwardedTabsRef: RefObject<HTMLDivElement>;
}

export default function GuidesAndServicesBlock({
  services,
  forwardedTabsRef,
}: GuidesAndServicesBlockProps) {
  return (
    <Container>
      <Content>
        <ServiceItemContainer isTitle>
          <Title>Guides</Title>
          <ServiceItem bgColor="#061936">
            <div>
              <SubTitle>Intel® Buying Guide</SubTitle>
              <Text>
                Find the device that is most suitable for your education
                requirements
              </Text>
            </div>
            <StyledLink
              linkText="Learn More"
              onClick={() =>
                forwardedTabsRef.current?.scrollIntoView({ behavior: 'smooth' })
              }
            />
          </ServiceItem>
        </ServiceItemContainer>
        {services.map((service, index) => (
          <ServiceItemContainer isTitle={index === 0} key={service.serviceName}>
            {index === 0 && <Title>Services</Title>}
            <ServiceItem bgColor={service.bgColor}>
              <div>
                <SubTitle>{service.serviceName}</SubTitle>
                <Text>{service.description}</Text>
              </div>
              <StyledLink
                linkText="Learn More"
                onClick={service.onLearnClick}
              />
            </ServiceItem>
          </ServiceItemContainer>
        ))}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 60px;
  border-bottom: 1px solid #D5DCE5;
  margin-bottom: 18px;

  @media (max-width: 660px) {
    padding-bottom: 40px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  font-weight: bold;
`;

const SubTitle = styled.h3`
  font-weight: bold;
  color: inherit;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 15px;
  color: inherit;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const StyledLink = styled(LinkWithArrow)`
  span {
    color: inherit;
  }

  svg {
    color: #fff !important;
  }
`;

const ServiceItemContainer = styled.div<{ isTitle: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: ${({ isTitle }) => !isTitle && '44px'};
  // cursor: pointer;

  &:hover {
    ${StyledLink} {
      text-decoration: underline;
    }
  }

  @media (max-width: 660px) {
    padding-top: 0;
  }
`;

const ServiceItem = styled.div<{ bgColor: string }>`
  flex: 1;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor};
  padding: 18px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
