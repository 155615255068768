import { useCallback, useState } from 'react';
import { ORDER_STATUS } from '../../../components/superadmin/types';

export const useOrders = () => {
  const [isShowOrdersEditModal, setIsShowOrdersEditModal] = useState(false);

  const onEditOrderClick = useCallback((status: ORDER_STATUS) => {
    if (status === ORDER_STATUS.OUR_OF_DELIVERY || ORDER_STATUS.PAID) {
      return setIsShowOrdersEditModal(true);
    }

    return null;
  }, []);

  return {
    onEditOrderClick,
    setIsShowOrdersEditModal,
    isShowOrdersEditModal,
  };
};
