export interface OrdersOverviewTableRow {
  key: string;
  dateSubmitted: string;
  poNumber: string;
  status: string;
  buyer: string;
  paymentMethod: string;
  total: string;
}

export interface ISupplierOrder {
} // To implement

export interface ISupplierPayments {
  key: string;
  items: Array<PaymentsTableRow>;
}

export interface ISupplierBalanceTransfers {
  key: string;
  items: Array<BalanceTransfersTableRow>;
}

export interface PaymentsTableRow {
  key: string;
  createdAt: string | number | Date;
  invoiceNumber: number;
  order: string;
  buyer: string;
  btsNumber: string;
  amountDue: number;
}

export interface ISupplierPaymentsSummary {
  key: string;
  outstanding: any;
  overdue: any;
  currentMontReceived: number;
}

export interface IOrdersOverviewHomePage {
  key: string;
  createdAt: string;
  poNumber: string;
  status: string;
  buyer: string;
  paymentMethod: string;
  total: number;
}

export interface JoinedOffersTableRow {
  key: string;
  sku: string;
  oem: string;
  product: string;
  availableQuantity: string;
  totalQuantity: string;
  customerBuyPrice: number;
}

export interface AvailableOffersTableRow {
  key: string;
  sku: string;
  oem: string;
  product: string;
  availableQuantity: string;
  totalQuantity: string;
  resellerBuyPrice: string;
}

export interface BalanceTransfersTableRow {
  key: string;
  createdAt: string;
  btsNumber: string;
  balanceSettled: string;
}

export interface SupplierAdminUsersTableRow {
  key: string;
  user: string;
  email: string;
  role: string;
  status: string;
}

export interface SupplierAdminServicesTableRow {
  key: string;
  serviceType: string;
  description: string;
  unitPrice: string;
}

export interface SupplierEditServiceType {
  description: string;
  unitPrice: string;
}

export interface SupplierCreateServiceType extends SupplierEditServiceType {
  serviceType: string;
}

export interface UpdateSupplierOrganisationDetails {
  name: string;
  adress1: string;
  adress2?: string;
  adress3?: string;
  town: string;
  country: string;
  postcode: string;
  phoneNumber: string;
  email: string;
  vatNumber: string;
  companyNumber: string;
}

export interface UpdateSupplierDeliveryChargesDetails {
  defaultDeliveryChargeAmount: string;
}

export interface UpdateSupplierPersonalDetails {
  firstName: string;
  lastName: string;
  email: string;
}

export type SupplierOrderStatus = 'Quote' | 'Placed' | 'Accepted' | 'Rejected';

export interface ISupplierOffersResp {
  exitsMore: boolean;
  items: Array<JoinedOffersTableRow>;
}

export interface ISupplierOverviewOrdersResp {
  exitsMore: boolean;
  items: Array<IOrdersOverviewHomePage>;
}

export interface ISupplierDashboard {
  totalEarnings: number,
  totalSold: number,
  activeOffers: number
}

export interface ISupplierOffersById{
  key: string;
  sku: string;
  product: string;
  availableQuantity: string;
  totalQuantity: string;
  customerBuyPrice: number;
  unitsSold: string;
  dealId: string;
  indicativeResellerBuyPrice: number;
  nte: number;
  startDate: Date;
  estimatedDeliveryDate: Date;
}

export interface QuotesTableRow {
  key: string;
  createdAt: string;
  reference: string;
  customer: string;
  total: string;
}

export interface QuotesResponse {
  existsMore: boolean;
  items: QuotesTableRow[];
  total: number;
}

export enum ORDER_TYPES {
  'Outright Purchase' = 'Outright Purchase',
  'Operating Lease' = 'Operating Lease',
  'Financing Lease' = 'Financing Lease',
}

export interface QuoteProductItem {
  productId: string;
  price: number | string;
  quantity: number;
}

export interface QuoteServiceItem {
  type: string;
  price: number | string;
  quantity: number;
  description: string;
}

export interface QuoteCreateType {
  schoolId?: string;
  recipientName?: string;
  recipientEmail?: string;
  orderType: ORDER_TYPES;
  items: Array<QuoteProductItem>;
  services: Array<QuoteServiceItem>;
  deliveryCharge: number | string;
  includeDeviceInsurance: boolean;
  expirationDate: string;
  leaseLength?: string;
  leaseProvider?: string;
  paymentSchedule?: string;
  schoolName?: string;
}
