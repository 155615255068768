import { superadminApi } from "./superadminApi";
import { ISupplierOverviewOrdersResp } from "../../../components/supplier/types";

const supplierOrdersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierOrderList: build.query<ISupplierOverviewOrdersResp, void>({
      query: () => 'supplier/orders',
    }),
  }),
  overrideExisting: false,
});

export const { useGetSupplierOrderListQuery } = supplierOrdersApi;