import { Radio } from 'antd';
import styled from 'styled-components/macro';

interface IOptionItem {
    label: string;
    value: string;
}

interface IBuyersFilterProps {
    options: Array<IOptionItem>;
    status: string;
    setStatus: (status: string) => void;
}

const BuyersFilter:React.FC<IBuyersFilterProps> = ({options, status, setStatus}) => {
    return(
        <Container>  
            <Text>Status: </Text>
            <StyledRadio options={options} defaultValue={status} onChange={(e) => setStatus(e.target.value)}/>
        </Container>
    )
}
const StyledRadio = styled(Radio.Group)`
  .ant-radio-inner {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
`

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  color: #102F54;
  margin-right: 15px;
  margin-left: 10px
`;
export default BuyersFilter