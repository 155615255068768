import { Modal } from "antd";
import { ButtonLight } from '../Button';
import CloseImg from '../../img/closeOutlined.svg';
import styled from "styled-components";
import Bird from '../../img/birdLogo.svg'
import * as React from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { useRef } from "react";
import { TableStyled } from '../../components/superadmin/shared/table/TableStyled';
import { showPriceDigits } from '../../utils/formats';

interface IModalPdf{
    handleVisible: boolean;
    handleClose: () => void;
}

const tableConfig = [
    {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
    },
    {
        title: 'DESCRIPTION',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'QTY',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
    },
]

const data = [
    {
        sku: 'STZ-00002',
        description: 'Microsoft Surface Go 2',
        qty: 50,
        amount: '£330.00'
    }
]

const number = 122221

const ModalPdf:React.FC<IModalPdf> = ({handleVisible, handleClose}) => {
    const exportArea:any = useRef(null)

    return(
        <PdfModal visible={handleVisible} footer={null} width='70%' closable={false}>
            <Header>
                <Title>View Document</Title>
                <RightSide>
                    <ViewDocButton onClick={() => savePDF(exportArea.current, {paperSize: "A3"})}>Save as PDF</ViewDocButton>
                    <img src={CloseImg} onClick={() => handleClose()}/>
                </RightSide>
            </Header>
            <PDFExport>
                <RightSide ref={exportArea}>
                <Container style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '31px'}}>
                    <ContDiv>
                        <ContText style={{color: '#6B7A87'}}>MONA VALE SCHOOL</ContText>
                        <ShellDescription>
                            <Description style={{ fontWeight: 400, color: '#102F54' }}>Silverwing is acting as an undisclosed sales agent of <Description style={{ fontWeight: 700 }}>Demo Reseller A for sales to</Description> Mona Vale School</Description>
                        </ShellDescription>
                        <ContText style={{color: '#6B7A87' , marginTop: '25px'}}>to</ContText>
                        <DeliveryText style={{ fontWeight: 600}}>Silverwing</DeliveryText>
                        <DeliveryText>243 High Street</DeliveryText>
                        <DeliveryText>Boston Spa</DeliveryText>
                        <DeliveryText>West Yorkshire</DeliveryText>
                        <DeliveryText>LS23 6AL</DeliveryText>
                    </ContDiv>
                    <ContDiv>
                        <ContText>QUOTATION</ContText>
                        <RightSide>Quote No: {number}</RightSide>
                        <RightSide style={{ alignSelf: 'flex-end'}}>Date: {number}</RightSide>
                        <ContText style={{color: '#6B7A87', marginTop: '61px'}}>from</ContText>
                        <DeliveryText style={{ fontWeight: 600}}>Demo Reseller</DeliveryText>
                        <DeliveryText>204 Mill Street</DeliveryText>
                        <DeliveryText>Tower Bridge</DeliveryText>
                        <DeliveryText>London</DeliveryText>
                        <DeliveryText>SE1 2DZ</DeliveryText>
                    </ContDiv>
                </Container>
                <Container>
                    <CustomTableStyled
                        columns={tableConfig}
                        dataSource={data}
                        pagination={false}
                        footer={() => ''}
                    />
                    <ContDiv style={{ paddingTop: 0, display: 'flex', justifyContent: 'space-between'}}>
                        <ContDiv style={{ padding: 0}}>
                            <ContText style={{ fontSize: '14px', fontWeight: 600, color: '#6B7A87', paddingTop: '21px'}}>customer details</ContText>
                            <ContText style={{ fontWeight: 400, color: '#102F54', fontSize: '14px' ,textTransform: 'capitalize'}}>Silverwing243 High Street</ContText>
                        </ContDiv>
                        <ContDiv style={{ padding: 0, margin: 0, width: '35%'}}>
                            <TotalBlock style={{ borderTop: 0 }}>
                                <TitleTotal>SUBTOTAL</TitleTotal>
                                <TotalPrice>{showPriceDigits(1222)}</TotalPrice>
                            </TotalBlock>
                            <TotalBlock style={{ borderTop: 0 }}>
                                <TitleTotal>VAT(20%)</TitleTotal>
                                <TotalPrice>{showPriceDigits(1222)}</TotalPrice>
                            </TotalBlock>
                            <TotalBlock style={{ borderTop: 0 }}>
                                <TitleTotal>TOTAL</TitleTotal>
                                <TotalPrice>{showPriceDigits(1222)}</TotalPrice>
                            </TotalBlock>
                        </ContDiv>
                    </ContDiv>
                    <ContDiv style={{ display: 'flex', justifyContent: 'space-between', marginTop: '250px'}}>
                        <img src={Bird}/>
                        <CompanyInfo>{`Company No: ${number}`}</CompanyInfo>
                        <CompanyInfo>{`VAT No: GB273673576`}</CompanyInfo>
                        <CompanyInfo>{`Email: finance@silverwing.co`}</CompanyInfo>
                    </ContDiv>
                </Container>
                </RightSide>
            </PDFExport>
        </PdfModal>
    )
}

const CompanyInfo = styled.p`
    color: #6B7A87;
    font-weight: 600;
    font-size: 14px;
    margin: 0
`

const TotalPrice = styled.p`
    margin-top: 7px;
`

const TitleTotal = styled.h1`
    color: #6B7A87;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 9px;
    margin-top: 0;
    padding-top: 7px
`

const TotalBlock = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #D5DCE5;
    padding-left: 16px;
    padding-right: 10px
`

const DeliveryText = styled.p`
    margin: 0
`

const CustomTableStyled = styled(TableStyled)`
    .ant-table {
        border: 1px solid #D5DCE5 !important;
    }
    .ant-table-thead{
        font-weight: 600;
    }
    .ant-table-cell{
        font-weight: 400;
        font-size: 14px;
    }
    .ant-table-thead th {
        border-top: none;
        border-bottom: 1px solid #D5DCE5 !important;
    }
    .ant-table-tbody > tr > td{
        border-bottom: 1px solid #D5DCE5 !important;
    }

    padding-left: 50px;
    padding-right: 50px;
`;


const ShellDescription = styled.div`
    background-color: #F9F9F9;
    padding: 21px;
    width: 355px
`

const Description = styled.span`
`

const ContDiv = styled.div`
    padding: 50px;
`

const ContText = styled.p`
    font-weight: 600;
    font-size: 21px;
    text-transform: uppercase;
`

const Container = styled.div`
    background-color: white;
`

const Title = styled.h1`
  font-size: 21px;
  font-weight: 600;
`;

const Header = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
`

const RightSide = styled.div`
`


const PdfModal = styled(Modal)`
    .ant-modal-header{
        border: none;
    }
    .ant-modal-body{
        padding: 41px;
        background: #F0F2F5;
    }
`

const ViewDocButton = styled(ButtonLight)`
  width: 133px;
  height: 32px;
  margin-right: 28px;
  font-size: 14px;
`;

export default ModalPdf