import { FC } from 'react';
import { Row, Col, Space, Form, Card } from 'antd';

import {
  Subtitle,
  TextBody,
} from '../../../../shared/typography/typography.styled';
import DeliveryCharge from '../../../../shared/delivery-charge/DeliveryCharge';
import { TableCreateQuote } from '../../styled/quotes-create.styled';
import { ButtonDark } from '../../../../../../ui/Button';

import { columnsItems, dataItems } from '../../quotes.create.mock';

const Section4: FC = () => {
  return (
    <Row style={{ margin: '20px 0' }}>
      <Col span={8}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Subtitle>4. Add Items</Subtitle>
          <TextBody>
            Press the ‘Add Item’ button below to add new line items.
          </TextBody>
          <ButtonDark
            style={{
              width: 120,
              padding: 7,
              fontSize: 14,
              margin: '15px 0',
            }}
          >
            + Add Item
          </ButtonDark>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: 10 }}>
        <Form name="itemsForm">
          <Card bodyStyle={{ padding: 0 }}>
            <TableCreateQuote
              shadow
              columns={columnsItems}
              dataSource={dataItems}
              pagination={false}
            />
          </Card>
        </Form>
      </Col>
      <Col span={12}>
        <DeliveryCharge />
      </Col>
    </Row>
  );
};

export default Section4;
