import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useCreateProductMutation,
  useGetProductsListQuery,
} from '../../../api/services/superadmin/productApi';
import ROUTER_CONFIG from '../../../router/router-config';
import LayoutV1 from '../../layout/LayoutV1';
import ProductCreate from './ProductCreate';

const ProductCreatePage: FC = () => {
  const { push } = useHistory();

  const { data: productsList } = useGetProductsListQuery();
  const [createProduct, { isSuccess, isLoading }] = useCreateProductMutation();

  const onFormSubmit = (values: any) => {
    createProduct(values);
  };

  useEffect(() => {
    if (isSuccess) {
      push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.getPath());
    }
  }, [isSuccess]);

  return (
    <LayoutV1>
      <ProductCreate
        onFormSubmit={onFormSubmit}
        productsList={productsList || []}
        isLoading={isLoading}
      />
    </LayoutV1>
  );
};

export default ProductCreatePage;
