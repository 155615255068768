import { ReactComponent as CubeIcon } from '../../../img/icons/cubeIcon.svg';
import { ReactComponent as EuroIcon } from '../../../img/icons/euroIcon.svg';
import { ReactComponent as DocumentCheckIcon } from '../../../img/icons/documentCheck.svg';
import { ReactComponent as ClockIcon } from '../../../img/icons/clockIcon.svg';
import { ReactComponent as CheckIcon } from '../../../img/icons/checkIcon.svg';

export const offerOverviewMock = [
  {
    title: 'SKU',
    value: 'KF1-00007',
    image: CubeIcon,
  },
  {
    title: 'OEM',
    value: 'Microsoft',
    image: DocumentCheckIcon,
  },
  {
    title: 'Offer Number',
    value: 'xxxxxxxxx',
    image: CubeIcon,
  },
  {
    title: 'Available Quantity',
    value: '5,000',
    image: CheckIcon,
  },
  {
    title: 'Indicative Reseller Buy Price',
    value: '£404.83',
    image: EuroIcon,
  },
  {
    title: 'NTE',
    value: '£440.83',
    image: EuroIcon,
  },
  {
    title: 'Start Date',
    value: '12/02/2021',
    image: ClockIcon,
  },
  {
    title: 'Estimated Delivery Date',
    value: '12/02/2021',
    image: ClockIcon,
  },
];
