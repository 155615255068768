import { ISummaryCardItem } from '../types';

export const insuraceMockData: Array<ISummaryCardItem> = [
  {
    name: 'Subtotal',
    subname: 'Billed Quarterly',
    value: '£475.00',
  },
  { name: 'VAT (0%)', subname: 'Billed Quarterly', value: '£0.00' },
  {
    name: 'Total',
    subname: 'Billed Quarterly',
    value: '£475.00',
    type: 'danger',
  },
];
