import styled from 'styled-components/macro';
import { LayoutTypes } from './LayoutTypes';
import SilverWingLogo from "./SilverwingLogo";
import ROUTER_CONFIG from "../../router/router-config";
import AccountMenu from "../supplier/layout/SupplierAccountMenu";
import SideMenu from "../../ui/SideMenu/SideMenu";
import Header from "./Header";
import React from "react";

const LayoutV1:React.FC<LayoutTypes> = ({children, className}) => {
  return (
    <Container className={className}>
      <StyledHeader logo={<SilverWingLogo/>} loginLinkTo={ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.path}>
        <AccountMenu accountName="Account"/>
      </StyledHeader>
      <SidebarWrapper>
        <SideMenu/>
      </SidebarWrapper>
      <ContentWrapper className="supplier_content_wrapper">
        {children}
        <FooterText>
          © 2021 Silverwing, A trading name of CASQOL Ltd. All Rights Reserved. Terms, Privacy and Cookies
        </FooterText>
      </ContentWrapper>
    </Container>
  );
}

export default LayoutV1;

const Container = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: minmax(180px, auto) 1fr;
  grid-template-rows: minmax(66px, auto) 1fr;
  grid-template-areas:
  'header header'
  'sidebar main'
  'sidebar main';
`;

const StyledHeader = styled(Header)`
  grid-area: header;
  grid-column: 1 / -1;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  height: 66px;
  .header__wrapper {
    padding: 15px 90px 15px 24px;
  }
`;

const SidebarWrapper = styled.div`
  grid-area: sidebar;
`;

const ContentWrapper = styled.section`
  grid-area: main;
  padding: 38px 80px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterText = styled.footer`
  font-size: 14px;
  line-height: 17px;
  color: #909DA8;
  margin-top: 66px;
`;
