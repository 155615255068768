import { Quote } from '../../../components/superadmin/types';
import { superadminApi } from './superadminApi';

const ordersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getOrdersList: build.query<Array<Quote>, void>({
      providesTags: [ 'ORDERS' ],
      query: () => ({
        url: 'order',
      })
    }),
    getOrderID: build.query({
      providesTags: [ 'ORDERS' ],
      query: (id: string) => ({
        url: `order/byId/${id}`,
      }),
    }),
  }),
});

export const {useGetOrdersListQuery, useGetOrderIDQuery} = ordersApi;