import styled from 'styled-components/macro';
import BackIcon from '../../img/icons/backIcon.svg';
import React from "react";
import { Link } from "react-router-dom";

interface LinkBackButtonType {
  onClick?: () => void;
  className?: string;
  path?: any;
}

const LinkBackButton: React.FC<LinkBackButtonType> = ({onClick, path, className}) => {
  return (
    <Link to={path}>
      <Button className={className} alt="LinkBackIcon" src={BackIcon} onClick={onClick}/>
    </Link>
  );
};

const Button = styled.img`
  width: 34px;
  height: 34px;
  cursor: pointer;
  margin: 7px 0 0 0;
`;

export default LinkBackButton;