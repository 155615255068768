import React from 'react';
import styled from 'styled-components/macro';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  BoldDarkText, DarkText, LightText, StyledTitleH2,
} from '../../styled/text';
import { Divider } from '../../styled/common';
import { LightButton } from '../../styled/buttons';
import { useGetOfferDetailsQuery } from '../../../../../api/services/superadmin/offersApi';
import ROUTER_CONFIG from '../../../../../router/router-config';

const OfferDescription: React.FC = () => {
  const { push } = useHistory();
  const { offerId } = useParams<{ offerId: string }>();
  const { data: offerDetails } = useGetOfferDetailsQuery(offerId);

  const onEditOfferClickHandler = () => {
    push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_EDIT.getPath({ offerId }));
  };

  return (
    <Container>
      <DescriptionHeader>
        <StyledTitleH2>
          {`Offer Number: ${offerDetails?.offerNumber}`}
        </StyledTitleH2>
        <LightButton onClick={onEditOfferClickHandler}>Edit Offer</LightButton>
      </DescriptionHeader>
      <DescriptionTable>
        <Row align="middle" style={{ padding: '20px 0' }}>
          <Col span={12}>
            <Row align="middle">
              <Col style={{ marginRight: '12px' }}>
                <Image src="https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg" alt="Some image" />
              </Col>
              <Col>
                <DarkText style={{ marginBottom: '3px' }}>{offerDetails?.product?.name}</DarkText>
                <LightText>{offerDetails?.product?.SKU}</LightText>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <DarkText
              style={{ textAlign: 'end', marginBottom: '3px' }}
            >
              {`${offerDetails?.quantity ?? 0} Units`}
            </DarkText>
            <LightText
              style={{ textAlign: 'end' }}
            >
              {`Available on ${dayjs(offerDetails?.stockAvailableDate).format('DD/MM/YY')}`}
            </LightText>
          </Col>
        </Row>
        <Divider />
        <Row style={{ padding: '20px 0' }}>
          <Col span={12}>
            <Row style={{ marginBottom: '7px' }}>
              <LightText>Start Date:&nbsp;</LightText>
              <BoldDarkText>{dayjs(offerDetails?.startDate).format('DD/MM/YY')}</BoldDarkText>
            </Row>
            <Row>
              <LightText>End Date:&nbsp;</LightText>
              <BoldDarkText>{dayjs(offerDetails?.stockAvailableDate).format('DD/MM/YY')}</BoldDarkText>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={18}>
                <DarkText style={{ marginBottom: '7px' }}>Indicative Reseller Buy Price</DarkText>
                <DarkText>NTE</DarkText>
              </Col>
              <Col span={6}>
                <BoldDarkText style={{
                  textAlign: 'end',
                  marginBottom: '7px',
                }}
                >
                  {`£${offerDetails?.indicativeBuyerPrice ?? 0}`}
                </BoldDarkText>
                <BoldDarkText style={{ textAlign: 'end' }}>{`£${offerDetails?.nte ?? 0}`}</BoldDarkText>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row style={{ padding: '20px 0' }} justify="space-between">
          <DarkText>Platform Fee</DarkText>
          <BoldDarkText style={{ textAlign: 'end' }}>
            {`${offerDetails?.platformFee ?? 0}%`}
          </BoldDarkText>
        </Row>
        <Divider />
      </DescriptionTable>
    </Container>
  );
};

const Container = styled.div`
`;

const DescriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DescriptionTable = styled.div`
  padding: 8px 17px 2px 17px;
  border-top: 2.25px solid #2682DE;
  border-left: 1px solid #D5DCE5;
  border-right: 1px solid #D5DCE5;
  margin-top: 27px;
`;

const Image = styled.img`
  max-width: 77px;
  max-height: 37px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default OfferDescription;
