import styled from 'styled-components/macro';
import { ButtonDanger, ButtonDark, ButtonLight } from '../../../../ui/Button';

export const RedBigButton = styled(ButtonDanger)`
  font-size: 16px;
  padding: 10px 25px;
  margin-top: 148px;
  font-weight: 600;
`;

export const RedSmallButton = styled(ButtonDanger)`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  width: 100px;
  height: 33px;
`;

export const DarkBigButton = styled(ButtonDark)`
  font-size: 14px;
  font-weight: 700;
  width: 143px;
  height: 36px;
  margin-top: 25px;
  margin-bottom: 20px;
`;

export const DarkSmallButton = styled(ButtonDark)`
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  height: 33px;
`;

export const LightButton = styled(ButtonLight)`
  padding: 4px 31px;
  font-size: 14px;
  height: fit-content;
`;

export const DetailsButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;
