import { Col, Row, Space, Form } from 'antd';
import { FC } from 'react';

import { ButtonDark } from '../../../../ui/Button';
import LayoutV1 from '../../../layout/LayoutV1';
import {
  buyerOverviewMock,
  lessorOverviewMock,
  resellerOverviewMock,
} from '../../quotes/quotes-create/quotes.create.mock';

import Services from '../../quotes/quotes-create/sections/section-5/Section5';
import { SummaryQuoteCard } from '../../quotes/quotes-create/summary-card/SummaryCard';

import InsuranceCard from '../../shared/insurance-card/InsuranceCard';
import OverviewCard from '../../shared/overview-card/OverviewCard';
import OrganisationTypeForm from '../../shared/forms/OrganisationTypeForm';
import ResselerForm from '../../shared/forms/ResellerForm';
import OrderTypeForm from '../../shared/forms/OrderTypeForm';
import ItemsTable from './items-table/ItemsTable';
import { Flex } from '../../shared/utils/utils.styled';
import DeliveryCharge from '../../shared/delivery-charge/DeliveryCharge';

import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';

import {
  Subtitle,
  TextBody,
  Title,
} from '../../shared/typography/typography.styled';

import {
  summaryDeliveryTotalsMock,
  summaryLeaseTotalsMock,
} from '../../quotes/quotes.mock';
import { insuraceMockData } from '../orders.mock';

const OrdersCreatePage: FC = () => {
  const [orgTypeForm] = Form.useForm();
  const [resellerForm] = Form.useForm();

  const orgTypeWatch = Form.useWatch('orgType', orgTypeForm);

  return (
    <LayoutV1>
      <Row>
        <Col span={1}>
          <Flex align="center" justify="flex-start" style={{ marginTop: 5 }}>
            <BackIcon cursor="pointer" />
          </Flex>
        </Col>
        <Col span={23}>
          <Row>
            <Col span={8}>
              <Title>Create New Order</Title>
            </Col>
          </Row>

          <OrganisationTypeForm
            form={orgTypeForm}
            isOrganisationTypeSelected={!!orgTypeWatch}
          />

          <Row style={{ marginTop: 55 }}>
            <Col span={8}>
              <Subtitle>2. Select a Reseller</Subtitle>
            </Col>
          </Row>

          <ResselerForm
            form={resellerForm}
            isOrganisationTypeSelected={!!orgTypeWatch}
          />

          <Row style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Space direction="vertical">
                <Subtitle>3. Order Type</Subtitle>
                <TextBody>
                  Please select if the quote is for an lease or an outright
                  purchase?
                </TextBody>
              </Space>
            </Col>
          </Row>

          <OrderTypeForm />

          <Row style={{ marginTop: 75 }}>
            <Col>
              <Space direction="vertical">
                <Subtitle>4. Add Items</Subtitle>
                <TextBody>
                  Press the ‘Add Item’ button below to add new line items.
                </TextBody>
                <ButtonDark
                  style={{
                    width: 120,
                    padding: 7,
                    fontSize: 14,
                    margin: '15px 0',
                  }}
                >
                  + Add Item
                </ButtonDark>
              </Space>
            </Col>
          </Row>

          <ItemsTable />

          <Col span={12}>
            <DeliveryCharge />
          </Col>

          <Row style={{ marginTop: 90 }}>
            <Col span={8}>
              <Subtitle>5. Services</Subtitle>
            </Col>
          </Row>

          <Services shadow={false} />

          <Row style={{ marginTop: 40 }}>
            <Col span={12}>
              <Subtitle>Order Summary</Subtitle>

              <SummaryQuoteCard
                deliveryTotals={summaryDeliveryTotalsMock}
                leaseTotals={summaryLeaseTotalsMock}
              />
              <InsuranceCard deliveryTotals={insuraceMockData} />
            </Col>
            <Col span={8} offset={4}>
              <Space
                direction="vertical"
                size="large"
                style={{ width: '100%' }}
              >
                <OverviewCard title="BUYER OVERVIEW" data={buyerOverviewMock} />
                <OverviewCard
                  title="RESELLER OVERVIEW"
                  data={resellerOverviewMock}
                />
                <OverviewCard
                  title="LESSOR OVERVIEW"
                  data={lessorOverviewMock}
                />
              </Space>
            </Col>
          </Row>
          <ButtonDark
            disabled
            type="submit"
            style={{ marginTop: 200, width: 300, padding: 10 }}
          >
            Generate Quote
          </ButtonDark>
        </Col>
      </Row>
    </LayoutV1>
  );
};

export default OrdersCreatePage;
