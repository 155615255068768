import { Col, Row, Typography, Space, Button } from 'antd';
import { FC } from 'react';

import LayoutV1 from '../../../layout/LayoutV1';

import DocumentsCard from '../../shared/documents-card/DocumentsCard';
import OverviewCard from '../../shared/overview-card/OverviewCard';
import StatusCard from '../../shared/status-card/StatusCard';
import { SummaryQuoteCard } from '../quotes-create/summary-card/SummaryCard';
import {
  Subtitle,
  TextBody,
  Title,
} from '../../shared/typography/typography.styled';
import { Flex } from '../../shared/utils/utils.styled';
import { ButtonDanger, ButtonLight } from '../../../../ui/Button';

import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';

import {
  buyerOverviewMock,
  lessorOverviewMock,
  resellerOverviewMock,
} from '../quotes-create/quotes.create.mock';
import {
  summaryDeliveryTotalsMock,
  summaryLeaseTotalsMock,
} from '../quotes.mock';
import { ORDER_STATUS } from '../../types';

const { Text } = Typography;

const QuotesDetailPage: FC = () => {
  return (
    <LayoutV1>
      <Row style={{ width: '100%' }}>
        <Col span={1}>
          <Flex align="center" justify="flex-start" style={{ marginTop: 25 }}>
            <BackIcon cursor="pointer" />
          </Flex>
        </Col>
        <Col span={23}>
          <Row>
            <Col span={24} style={{ margin: '20px 0' }}>
              <Title>Quote #REF#:FE2D04F9-8EE</Title>
            </Col>
            <Col span={14}>
              <StatusCard status={ORDER_STATUS.OUR_OF_DELIVERY} />
              <Row style={{ margin: '50px 0' }}>
                <Col span={8}>
                  <Subtitle>Quote Details</Subtitle>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                  <ButtonLight
                    style={{
                      width: 120,
                      padding: 5,
                      fontSize: 14,
                    }}
                  >
                    Edit Quote
                  </ButtonLight>
                </Col>
              </Row>

              <SummaryQuoteCard
                deliveryTotals={summaryDeliveryTotalsMock}
                leaseTotals={summaryLeaseTotalsMock}
              />

              <Row>
                <Col span={24} style={{ marginTop: 10 }}>
                  <Text type="secondary" strong>
                    Insurance
                  </Text>
                </Col>
                <Col span={24}>
                  <Space size={0} direction="vertical" style={{ margin: 10 }}>
                    <TextBody strong>
                      Contact the Buyer to provide Insurance Policy information
                    </TextBody>
                    <Text strong>em@movavaleshoocl.sch.uk</Text>
                  </Space>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col span={24}>
                  <Subtitle>Documents</Subtitle>
                </Col>
                <Col span={6} offset={18} style={{ textAlign: 'right' }}>
                  <Button type="link">+ Add Document</Button>
                </Col>
                <DocumentsCard isDelete />
              </Row>

              <Row style={{ margin: '70px 0' }}>
                <Col span={24}>
                  <ButtonDanger
                    style={{
                      width: 150,
                      padding: 10,
                    }}
                  >
                    Delete Quote
                  </ButtonDanger>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={2}>
              <Space direction="vertical">
                <OverviewCard title="BUYER OVERVIEW" data={buyerOverviewMock} />
                <OverviewCard
                  title="RESELLER OVERVIEW"
                  data={resellerOverviewMock}
                />
                <OverviewCard
                  title="LESSOR OVERVIEW"
                  data={lessorOverviewMock}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutV1>
  );
};

export default QuotesDetailPage;
