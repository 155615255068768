import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import {BuyersFilter, GoBackContainer, OrderTable, QuoteTable, UserTable, AddressTable, PreferredResseller, BillingForm, BuyersForm, BuyersDashboard} from './config/configImport'
import LayoutV1 from '../../../../layout/LayoutV1';
import { options } from './config/configTable';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import ROUTER_CONFIG from "../../../../../router/router-config";
import { message } from 'antd';
import { useEffect } from 'react';
import { useUpdateOrganisationDetailsMutation, useGetBuyersDashboardQuery } from '../../../../../api/services/superadmin/buyersOrderApi'

const {TabPane} = Tabs;

const BuyersTable = () => {
  const [ status, setStatus ] = useState('All');
  const [ selected, setSelected ] = useState<Array<string>>([]);
  const {push} = useHistory();

  const onRowClickHandler = (offerId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_BUYER_ORDERS_VIEW.getPath({offerId}));
  
  const [updateProfile, {isSuccess, isError}] = useUpdateOrganisationDetailsMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Profile successfully updated');
    }

    if (isError) {
      message.error('An error occurred while updating the profile');
    }
  }, [isSuccess, isError]);

  return (
    <LayoutV1>
      <GoBackContainer/>
      <BuyersDashboard/>
      <Tabs defaultActiveKey="1" tabBarStyle={{color: '#6B7A87'}}>
        <StyledTabPane tab="Orders" key="1">
          <BuyersFilter options={options} status={status} setStatus={setStatus}/>
          <OrderTable status={status} onRowClickHandler={onRowClickHandler}/>
        </StyledTabPane>
        <StyledTabPane tab="Quotes" key="2">
          <QuoteTable/>
        </StyledTabPane>
      </Tabs>
      <Tabs defaultActiveKey="1" tabBarStyle={{color: '#6B7A87', marginTop: '136px'}}>
        <StyledTabPane tab="Organisation Details" key="1">
          <BuyersForm onSubmit={updateProfile}/>
        </StyledTabPane>
        <StyledTabPane tab="Users" key="2">
          <UserTable onSelectUser={setSelected} selectedUser={selected} onRowClickHandler={onRowClickHandler}/>
        </StyledTabPane>
        <StyledTabPane tab="Preferred Reseller" key="3">
          <PreferredResseller/>
        </StyledTabPane>
        <StyledTabPane tab="Addresses" key="4">
          <AddressTable onSelectedAddress={setSelected} selectedAddress={selected} onRowClickHandler={onRowClickHandler}/>
        </StyledTabPane>
        <StyledTabPane tab="Billing" key="5">
          <BillingForm/>
        </StyledTabPane>
      </Tabs>
    </LayoutV1>
  );
};

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;

export default BuyersTable;