import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Container, StyledButtonDark, Title } from '../styled-form';
import { useEditOfferMutation } from '../../../../../api/services/superadmin/offersApi';
import { EditFormType } from '../types';
import { OfferDetailsType } from '../../../../../project-types/offers/offers-offers/types';
import OfferForm from './OfferForm';
import ProductForm from './ProductForm';
import { editOfferForm } from '../validation-schemas';
import { currency } from '../dataForForm';
import { IProduct } from '../../../../buyer/types';

interface OffersEditFormTypes {
  offer: OfferDetailsType
  products: IProduct[]
}

const OffersEditForm: React.FC<OffersEditFormTypes> = ({ offer, products }) => {
  const { offerId } = useParams<{ offerId: string }>();
  const [editOffer] = useEditOfferMutation();

  const editFormData: EditFormType = useMemo(() => ({
    offerNumber: offer.offerNumber ?? '',
    startDate: offer.startDate ?? '',
    endDate: offer.stockAvailableDate ?? '',
    platformFee: offer.platformFee ?? 0,
    product: offer.product._id ?? '',
    insurances: offer.insurances.map((item) => item._id),
    leases: offer.leases.map((item) => item._id),
    offerGroup: offer.offerGroup._id ?? '',
    oem: offer.product.manufacturer ?? '',
    quantity: offer.quantity ?? 0,
    stockAvailableDate: offer.stockAvailableDate ?? '',
    indicativeBuyerPrice: offer.indicativeBuyerPrice ?? 0,
    nte: offer.nte ?? 0,
    eligibleSellers: offer.eligibleSellers,
    allowForAllSellers: 0,
  }), [offer]);

  const onSubmitForm = async (values: Partial<EditFormType>) => {
    await editOffer({
      offer: {
        ...editFormData,
        ...values,
        startDate: values.startDate ? values.startDate : editFormData.startDate,
        endDate: values.endDate ? values.endDate : editFormData.endDate,
        stockAvailableDate: values.stockAvailableDate ? values.stockAvailableDate : editFormData.stockAvailableDate,
      },
      offerId,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      offerNumber: offer.offerNumber ?? '',
      startDate: '',
      endDate: '',
      platformFee: offer.platformFee ?? 0,
      currency: currency[0],
      product: offer.product._id ?? '',
      oem: offer.product.manufacturer ?? '',
      quantity: offer.quantity ?? 0,
      stockAvailableDate: '',
      indicativeBuyerPrice: offer.indicativeBuyerPrice ?? 0,
      nte: offer.nte ?? 0,
    },
    validationSchema: editOfferForm,
    onSubmit: async (values) => {
      await onSubmitForm({
        ...values,
        platformFee: Number(values.platformFee),
        quantity: Number(values.quantity),
        indicativeBuyerPrice: Number(values.indicativeBuyerPrice),
        nte: Number(values.nte),
      });
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>Offer Details</Title>
      <OfferForm formik={formik} />
      <Title>Product Details</Title>
      <ProductForm formik={formik} products={products} />
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
};

export default OffersEditForm;
