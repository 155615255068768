import { Card, Space, Divider, Typography, Row, Col } from 'antd';
import { FC, ReactNode, memo } from 'react';
import { useTheme } from 'styled-components';

interface IOverviewItem {
  title: string;
  value: string | number;
  image: ReactNode;
}

interface IOverviewCardProps {
  title: string;
  data: Array<IOverviewItem>;
}

const { Text } = Typography;

const OverviewCard: FC<IOverviewCardProps> = ({ title, data }) => {
  const theme = useTheme();

  return (
    <Card
      title={<Text strong>{title}</Text>}
      bordered={false}
      style={{ backgroundColor: '#F5F8FB' }}
    >
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        {data.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Row>
              <Col span={2} style={{ textAlign: 'center' }}>
                {item.image}
              </Col>
              <Col span={10}>
                <Text strong>{item.title}</Text>
              </Col>
              <Col
                span={10}
                style={{
                  textAlign: 'end',
                }}
              >
                <Text strong style={{ color: theme.textBlue }}>
                  {item.value}
                </Text>
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
          </div>
        ))}
      </Space>
    </Card>
  );
};

export default memo(OverviewCard);
