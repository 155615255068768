import styled from 'styled-components/macro';
import { CheckOutlined, RightOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { CREATE_PRODUCT_STEPS } from '../../types';
import { ProductTypesEnum } from '../../../buyer/types';

interface IProps {
  currentStep: CREATE_PRODUCT_STEPS;
  onStepClick: (step: CREATE_PRODUCT_STEPS) => void;
  completedSteps: Array<CREATE_PRODUCT_STEPS>;
  productType: ProductTypesEnum;
}

const CreateProductSteps: FC<IProps> = ({
  currentStep,
  onStepClick,
  completedSteps,
  productType,
}) => {
  let index = 0;
  return (
    <Container>
      {(Object.keys(CREATE_PRODUCT_STEPS) as Array<
        keyof typeof CREATE_PRODUCT_STEPS
      >).map((step) => {
        if (
          productType === ProductTypesEnum.ACCESSORY &&
          step === 'ACCESSORIES'
        ) {
          return null;
        }
        index += 1;
        return (
          <StepItem
            isComplete={completedSteps.includes(CREATE_PRODUCT_STEPS[step])}
            isCurrent={currentStep === CREATE_PRODUCT_STEPS[step]}
            key={step}
          >
            <NumberInSquare>
              {completedSteps.includes(CREATE_PRODUCT_STEPS[step]) ? (
                <StyledCheck />
              ) : (
                `${index}`
              )}
            </NumberInSquare>
            <StepText onClick={() => onStepClick(CREATE_PRODUCT_STEPS[step])}>
              {CREATE_PRODUCT_STEPS[step]}
            </StepText>
            {Object.keys(CREATE_PRODUCT_STEPS).length !== index && (
              <StyledRightOutlined />
            )}
          </StepItem>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const NumberInSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;
  margin-right: 14px;

  border-radius: 3px;
  background-color: #e5eaef;
  font: inherit;
`;

const StepItem = styled.div<{ isCurrent: boolean; isComplete: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isCurrent }) => (isCurrent ? '#2682DE' : '#6B7A87')};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  ${NumberInSquare} {
    background-color: ${({ isCurrent }) => isCurrent && '#E8F4FF'};
    background-color: ${({ isComplete }) => isComplete && '#DCF7EF'};
  }
`;

const StepText = styled.span<{ isCheckout?: boolean }>`
  font: inherit;
  cursor: ${({ isCheckout }) => !isCheckout && 'pointer'};

  &:hover {
    text-decoration: ${({ isCheckout }) => !isCheckout && 'underline'};
  }
`;

const StyledCheck = styled(CheckOutlined)`
  color: #10c08a;
  font-size: 11px;
`;

const StyledRightOutlined = styled(RightOutlined)`
  margin-left: 5px;
  font-size: 11px;
`;

export default CreateProductSteps;
