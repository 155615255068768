import { Key } from 'react';
import { Form, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ButtonDark } from '../../../../ui/Button';
import InputNumber from '../../shared/inputs/input-number/InputNumber';

import { ReactComponent as PhoneIcon } from '../../../../img/icons/phoneIcon.svg';
import { ReactComponent as CubeIcon } from '../../../../img/icons/cubeIcon.svg';
import { ReactComponent as CertificateIcon } from '../../../../img/icons/certificateIcon.svg';
import { ReactComponent as DocumentCheckIcon } from '../../../../img/icons/documentCheck.svg';
import { ReactComponent as MailIcon } from '../../../../img/icons/mail-outline.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';

import { InputTable } from './styled/quotes-create.styled';

const { Option } = Select;

interface DataType {
  key: Key;
  offer: string;
  unitPrice: number;
  quantity: string;
}

export interface ServicesDataType {
  key: Key;
  serviceType: string;
  serviceId: number;
  description: string;
  unitPrice: string;
  quantity: number;
}

export const buyerOverviewMock = [
  {
    title: 'Organisation Name',
    value: 'Coombe Wood School',
    image: <CertificateIcon />,
  },
  {
    title: 'Organisation Type',
    value: 'School',
    image: <DocumentCheckIcon />,
  },
  {
    title: 'School Type',
    value: 'Maintained',
    image: <CubeIcon />,
  },
  {
    title: 'School URN',
    value: '145875',
    image: <DocumentCheckIcon />,
  },
  {
    title: 'Contact Name',
    value: 'John Smith',
    image: <DocumentCheckIcon />,
  },
  {
    title: 'Contact Email',
    value: 'j.smith@coombewood school.sch.uk',
    image: <DocumentCheckIcon />,
  },
];

export const resellerOverviewMock = [
  {
    title: 'Organisation Name',
    value: 'Coombe Wood School',
    image: <CubeIcon />,
  },
  {
    title: 'Contact Email',
    value: 'help@mail.com',
    image: <MailIcon />,
  },
  {
    title: 'Contact Telephone',
    value: '0239 209 30',
    image: <PhoneIcon />,
  },
];

export const lessorOverviewMock = [
  {
    title: 'Organisation Name',
    value: 'Coombe Wood School',
    image: <CubeIcon />,
  },
  {
    title: 'Contact Email',
    value: 'help@mail.com',
    image: <MailIcon />,
  },
  {
    title: 'Contact Telephone',
    value: '0239 209 30',
    image: <PhoneIcon />,
  },
];

export const columnsItems: ColumnsType<DataType> = [
  {
    title: 'Offer (Product)',
    dataIndex: 'offer',

    render: () => (
      <Form.Item name="offer">
        <Select
          style={{ width: 300 }}
          defaultValue="sfl1"
          suffixIcon={<ArrowDownIcon />}
        >
          <Option value="sfl1">Surface Laptop SE - 4GB - 64GB</Option>
          <Option value="sfl2">Surface Laptop SE - 8GB - 128GB</Option>
        </Select>
      </Form.Item>
    ),
  },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',

    render: () => (
      <Form.Item style={{ width: 120 }} name="unitPrice">
        <InputTable value="$321.21" placeholder="$321.21" />
      </Form.Item>
    ),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',

    render: () => (
      <Form.Item style={{ width: 120 }} name="quantity">
        <InputNumber />
      </Form.Item>
    ),
  },
];

export const columnsServices = [
  {
    title: 'Service Type',
    dataIndex: 'serviceType',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 410,
  },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    // eslint-disable-next-line arrow-body-style
    render: () => {
      return (
        <Form.Item style={{ width: 120 }} name="unitPrice">
          <InputTable placeholder="$123.1" value="$123.1" />
        </Form.Item>
      );
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    // eslint-disable-next-line arrow-body-style
    render: () => {
      return (
        <Form.Item style={{ width: 120 }} name="quantity">
          <InputNumber />
        </Form.Item>
      );
    },
  },
  {
    title: '',
    key: 'action',
    render: () => (
      <ButtonDark style={{ width: 110, padding: 7, fontSize: 14 }}>
        Add
      </ButtonDark>
    ),
  },
];

export const dataItems: Array<DataType> = [
  {
    key: 1,
    offer: 'Edward King 1',
    quantity: '32',
    unitPrice: 123,
  },
];

export const dataServices: Array<ServicesDataType> = [
  {
    key: 1,
    serviceType: 'Asset Tagging',
    serviceId: 12,
    description:
      'Asset identification and tagging is an essential component of a good asset management strategy. It helps a company’s assets to become quickly identifiable and ties the asset to the overall asset plan.',
    unitPrice: '$123.12',
    quantity: 50,
  },
  {
    key: 2,
    serviceType: 'Installation',
    serviceId: 22,
    description:
      'Asset identification and tagging is an essential component of a good asset management strategy. It helps a company’s assets to become quickly identifiable and ties the asset to the overall asset plan.',
    unitPrice: '$123.12',
    quantity: 50,
  },
];
