import { useGetQuoteIDQuery } from "../../../../api/services/superadmin/quotesApi";

const QuoteViewPage = () => {
  const {data: getQuoteID, status} = useGetQuoteIDQuery('62bdb1a27a5d27e2d952f983');
  return (
    <>
      Server status {getQuoteID ? JSON.stringify(getQuoteID, null, 2) : status}
    </>
  );
};

export default QuoteViewPage;