import { FC } from 'react';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';

import { TextBody } from '../typography/typography.styled';

import { ISummaryCardItem } from '../../types';
import { Flex } from '../utils/utils.styled';
import { TagStyled } from '../tag/tag,styled';

const { Link } = Typography;

interface IInsuraceCardProps {
  deliveryTotals: Array<ISummaryCardItem>;
}

const InsuranceCard: FC<IInsuraceCardProps> = ({ deliveryTotals }) => {
  return (
    <Card bodyStyle={{ padding: '20px 0' }} style={{ border: 'none' }}>
      <Row>
        <Col span={8}>
          <TextBody type="secondary" strong>
            Insurance
          </TextBody>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: 'end' }}>
          <TagStyled color="blue" style={{ margin: 0 }}>
            PO#INS49318386
          </TagStyled>
        </Col>

        <Col span={12} style={{ margin: '20px 0' }}>
          <Space direction="vertical" size={3}>
            <TextBody strong>Accidental Damage & Theft Insurance</TextBody>
            <TextBody>50 Units</TextBody>
          </Space>
        </Col>

        <Col span={12} style={{ textAlign: 'end', margin: '20px 0' }}>
          <Space direction="vertical" size={0}>
            <TextBody strong>£9.50/quarter</TextBody>
            <TextBody>3 Years</TextBody>
          </Space>
        </Col>

        <Col span={24}>
          <Space direction="vertical" size={0}>
            <TextBody>Presented by: Demo Insurance Ltd.</TextBody>
            <Link href="/" target="_blank">
              Terms and conditions
            </Link>
          </Space>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={10}>
          <Space direction="vertical" size={0}>
            <TextBody type="secondary" strong>
              Expected Delivery
            </TextBody>
            <TextBody strong>
              Policy documents will be sent by email and available within 72
              hours after your order has been accepted.
            </TextBody>
          </Space>
        </Col>

        <Col span={8} offset={6}>
          <Space direction="vertical" style={{ width: '100%' }}>
            {deliveryTotals.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Flex justify="space-between" align="center" key={index}>
                <Space direction="vertical" size={0}>
                  <TextBody strong>{item.name}</TextBody>
                  <TextBody type="secondary" strong>
                    {item.subname}
                  </TextBody>
                </Space>
                <TextBody strong type={item.type}>
                  {item.value}
                </TextBody>
              </Flex>
            ))}
          </Space>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={6}>
          <Space direction="vertical">
            <TextBody type="secondary" strong>
              Invoice Address
            </TextBody>
            <TextBody strong>
              Mona Vale School 32 Vale Ave South Croydon Croydon CR9 7HY
            </TextBody>
          </Space>
        </Col>

        <Col span={16} offset={2}>
          <Space direction="vertical">
            <TextBody type="secondary" strong>
              Payment Method
            </TextBody>
            <TextBody strong>Direct Debit</TextBody>
            <TextBody type="secondary">
              When your order is accepted we will contact your accounts payable
              team to set up your direct debit payments.
            </TextBody>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default InsuranceCard;
