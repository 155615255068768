import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React from 'react';
import { FormConfig, renderFormConfig } from '../../../../../utils/form';
import { ButtonDark } from '../../../../../ui/Button';
import { editOfferGroupForm } from '../offerGroup-schemas';
import { EditOfferGroupForm } from '../types';
import Input from '../../../../../ui/forms/Input';
import TextArea from '../../../../../ui/forms/Textarea';
import UploadIcon from './UploadIcon';
import { OfferGroupType } from '../../../../../project-types/offers/offerGroups/types';

const StyledInput = styled(Input)`
  height: unset;
  padding: 8px;
  width: 100%;
`;

const StyledTextarea = styled(TextArea)`
  width: 100%;
  height: 114px;
  padding: 10px;
`;

interface OfferGroupEditFormProps {
  onSubmit: (values: EditOfferGroupForm) => void;
  offerGroupData: OfferGroupType;
}

const formConfig: FormConfig = [
  {
    title: 'Choose an icon',
    name: 'icon',
    component: UploadIcon,
  },
  {
    title: 'Name',
    name: 'name',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Description',
    name: 'description',
    component: StyledTextarea,
  },
];

const OfferGroupEditForm: React.FC<OfferGroupEditFormProps> = ({ onSubmit, offerGroupData }) => {
  const formik = useFormik({
    initialValues: {
      icon: offerGroupData.icons,
      name: offerGroupData.name,
      description: offerGroupData.description,
    },
    validationSchema: editOfferGroupForm,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  max-width: 453px;
  padding: 24px 0;
  position: relative;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;

export default OfferGroupEditForm;
