import LayoutV1 from '../../../layout/LayoutV1';
import OffersView from './offers-offers/OffersView';

export default function OffersViewPage() {
  return (
    <LayoutV1>
      <OffersView />
    </LayoutV1>
  );
}
