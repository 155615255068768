import { superadminApi } from "../superadmin/superadminApi";
import { ISupplierOffersById } from "../../../components/supplier/types";

const supplierOfferDetailView = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getOfferDetailView: build.query<Array<ISupplierOffersById>, string>({
      query: (offerId) => `supplier/offers/byId/${offerId}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetOfferDetailViewQuery } = supplierOfferDetailView;