/* eslint-disable @typescript-eslint/no-shadow */
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import BuyerLayout from '../layout/BuyerLayout';
import {
  CONFIGURE_ORDER_STEPS,
  IAccessorySelected,
  IReseller,
  ISellerOfferPublicType,
  IService,
} from '../types';
import BuyerOrderConfigureView from './BuyerOrderConfigureView';
import ROUTER_CONFIG from '../../../router/router-config';
import {
  changeOrderServices,
  changeItemsQuantity,
  selectBuyerOrder,
  setOfferId,
  setOrderAccessories,
  setOrderCalculations,
  setOrderInsurance,
  setOrderReseller,
  setOrderServices,
} from '../../../feature/buyerState';
import {
  useGetOffersQuery,
} from '../../../api/services/buyerApi';
import calculateOrderPrice from '../calculateOrderPrice';
import { groupRelatedAccessoriesByProductId, groupSellerOffersByProductId } from '../groupSellerOffersByProductId';
import { IInsurance } from '../../superadmin/insurers/types';

interface LocationState {
  defaultStep: CONFIGURE_ORDER_STEPS;
}

export default function BuyerOrderConfigurePage() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const currentOrder = useSelector(selectBuyerOrder);
  const { orderId } = useParams<{ orderId: string }>();

  const { data: offers } = useGetOffersQuery();

  const groupedOffers = groupSellerOffersByProductId(offers)[orderId];
  const groupedRelatedAccessories = groupRelatedAccessoriesByProductId(groupedOffers, offers);

  const currentOffer = groupedOffers.offers.find((offer) => offer.seller._id === currentOrder?.reseller?._id);

  const insuranceOptions = groupedOffers.offers.reduce((acc: Array<IInsurance>, offer) => {
    // eslint-disable-next-line no-param-reassign
    acc = [...acc, ...(offer.offer?.insurances || [])];

    return acc;
  }, []).filter((value, index, self) => index === self.findIndex((t) => (
    t._id === value._id
  )));

  const accessories: Array<IAccessorySelected> | undefined = useMemo(() => {
    if (!currentOrder?.reseller || !currentOffer) return;

    const { accessories: selectedAccessories } = currentOrder;
    // const { offer: { product: { accessories: offerAccessories } } } = currentOffer;
    const offerAccessories = Object.values(groupedRelatedAccessories).reduce((acc: Array<ISellerOfferPublicType>, offer) => {
      // eslint-disable-next-line no-param-reassign
      acc = [...acc, ...offer.offers];

      return acc;
    }, []).filter((value, index, self) => index === self.findIndex((t) => (
      t._id === value._id
    )));

    const offerAccessoriesWithQuantity = offerAccessories?.map((acc) => ({ ...acc, quantity: currentOrder?.quantity || 0 }));

    // eslint-disable-next-line consistent-return
    return offerAccessoriesWithQuantity?.map((accessory) => {
      if (selectedAccessories?.find((acc) => acc._id === accessory._id)) {
        return { ...accessory, quantity: selectedAccessories?.find((acc) => acc._id === accessory._id)?.quantity || 0 };
      }

      return accessory;
    });
  }, [currentOffer, currentOrder, groupedRelatedAccessories]);

  const onComplete = () => {
    if (currentOffer) {
      dispatch(setOfferId(currentOffer._id));
    }

    const calculations = calculateOrderPrice({
      quantity: currentOrder?.quantity || 0,
      reseller: currentOrder?.reseller,
      accessories: currentOrder?.accessories,
      insurance: currentOrder?.insurance,
      offer: currentOffer,
      orderType: currentOrder?.orderType,
      lease: currentOrder?.lease,
    });

    dispatch(setOrderCalculations(calculations));

    push(ROUTER_CONFIG.BUYER_ORDER_CURRENT.getPath());
  };

  const onChangeReseller = (reseller: IReseller) => {
    dispatch(setOrderServices([]));
    dispatch(setOrderReseller(reseller));
  };

  const onChangeInsurance = (insurance?: IInsurance) => {
    dispatch(setOrderInsurance(insurance));
  };

  const onChangeAccessories = (
    accs: Array<IAccessorySelected>,
  ) => {
    dispatch(setOrderAccessories(accs));
  };

  const onChangeAccessoriesQuantity = (
    accessory: IAccessorySelected,
    quantity: number,
  ) => {
    dispatch(changeItemsQuantity({ type: 'accessory', quantity, accessoryId: accessory._id }));
  };

  const onChangeServices = (service: IService, resellerId: string) => {
    dispatch(changeOrderServices({ service, resellerId }));
  };

  return (
    <BuyerLayout>
      {offers && (
        <BuyerOrderConfigureView
          insuranceOptions={insuranceOptions}
          orderAccessories={accessories || []}
          onChangeAccessoriesQuantity={onChangeAccessoriesQuantity}
          onChangeReseller={onChangeReseller}
          onChangeInsurance={onChangeInsurance}
          onChangeAccessories={onChangeAccessories}
          onComplete={onComplete}
          groupedOffer={groupedOffers}
          currentOrder={currentOrder}
          defaultStep={location.state?.defaultStep}
          onChangeServices={onChangeServices}
        />
      )}
    </BuyerLayout>
  );
}
