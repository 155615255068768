import styled from 'styled-components/macro';
import { Divider } from 'antd';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';

import DocumentIcon from '../../../../img/icons/document_icon.svg';
import ShareLinkIcon from '../../../../img/icons/share_link.svg';

export default function QuoteCreated() {
  return (
    <Container>
      <Title>Quote Created Successfully</Title>
      <Divider style={{ margin: '0' }} />
      <DocumentBlock>
        <DocumentInfo>
          <img alt="" src={DocumentIcon} />
          <DocumentDescription>
            <Text>Quote - Q#12318381</Text>
            <GrayText>
              17:53 on
              {' '}
              {formatDateDDMMMYYYY(new Date('06 jan 2021'))}
            </GrayText>
          </DocumentDescription>
        </DocumentInfo>
        <StyledButtonLight>View Document</StyledButtonLight>
      </DocumentBlock>
      <Divider style={{ margin: '0 0 5px 0' }} />
      <SharebleLink>
        <img alt="" src={ShareLinkIcon} />
        <GrayText>Shareable Link</GrayText>
      </SharebleLink>
      <LinkText>www.devicesforeducation.com/Buyer/ViewQuote?Quote=247UBFUBFUBFUBFUBF</LinkText>
      <StyledButtonDark>Email Quote Link To Customer</StyledButtonDark>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 605px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
  margin-bottom: 58px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
  font-weight: bold;
`;

const GrayText = styled(Text)`
  color: #6B7A87;
`;

const DocumentBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const DocumentInfo = styled.div`
  display: flex;
  gap: 17px;
`;

const DocumentDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonLight = styled(ButtonLight)`
  width: 156px;
  height: 33px;
  font-size: 14px;
`;

const SharebleLink = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 20px 0;
`;

const LinkText = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #102f54;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 13px;
  margin-top: 40px;
`;
