import { FC, memo } from 'react';
import { Form, Select, Input } from 'antd';

import { FormItemLabel } from '../typography/typography.styled';

import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';
import { IFormComponentProps } from '../../types';

const { Option } = Select;

interface IResellerFormProps extends IFormComponentProps {
  isOrganisationTypeSelected: boolean;
}

const ResellerForm: FC<IResellerFormProps> = ({
  form,
  isOrganisationTypeSelected,
}) => {
  return (
    <Form
      form={form}
      name="resellerForm"
      layout="vertical"
      wrapperCol={{ span: 8 }}
    >
      <Form.Item
        name="reseller"
        label={<FormItemLabel>Reseller Name</FormItemLabel>}
        rules={[{ required: true }]}
      >
        <Select placeholder="(Select)" suffixIcon={<ArrowDownIcon />}>
          <Option value="reseller1">Resseller 1</Option>
          <Option value="reseller2">Resseller 2</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="recipientNameResseler"
        label={<FormItemLabel>Recipient Name</FormItemLabel>}
        rules={[{ required: true }]}
      >
        <Input
          disabled={!isOrganisationTypeSelected}
          placeholder="Please select a school or a trust first"
        />
      </Form.Item>

      <Form.Item
        name="recipientEmailResseler"
        label={<FormItemLabel>Recipient Email</FormItemLabel>}
        rules={[{ required: true }]}
      >
        <Input
          disabled={!isOrganisationTypeSelected}
          placeholder="Please select a school or a trust first"
        />
      </Form.Item>
    </Form>
  );
};

export default memo(ResellerForm);
