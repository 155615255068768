import { IRecorderTableConfig } from '../../../../types';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import styled from 'styled-components/macro';

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const time = 3
const three = 3

export const RecorderTableConfig: ColumnsType<IRecorderTableConfig> = [
    {
      title: (
        <StyledTitle style={{ marginLeft: '35px'}}>
          Time Recorder
          <ArrowDown style={{ marginLeft: '4px' }} />
        </StyledTitle>
      ),
      key: 'timeRecorder',
      dataIndex: 'timeRecorder',
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
    },
    {
       title: 'Organisation',
       key: 'organisation',
       dataIndex: 'organisation',
    },
    {
      title: 'Association',
      key: 'association',
      dataIndex: 'association',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Recorded by',
      key: 'recorderName',
      dataIndex: 'recorderName',
    }
  ];