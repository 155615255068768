import { FC, useMemo, useState } from "react";
import ModalInPortal from "../../../../../ui/Modal/ModalPortal";
import AddServiceModalContent from "./AddServiceModalContent";
import PriceAmount from "../../../../../ui/PriceAmount/PriceAmount";
import { ColumnsType } from "antd/lib/table";
import { AddServiceTypes, ISuppliersItem } from "../types";
import { Select } from "antd";
import { Container, StyledSelect, ViewButton } from "../suppliers.styled";
import { TableStyled } from "../../../../../ui/Table/table.styled";
import { ReactComponent as DeleteIcon } from "../../../../../img/icons/deleteIcon.svg";
import { optionMockData } from "./moks/mockData";
import { ReactComponent as ArrowDown } from "../../../../../img/icons/filled-arrow-down.svg";

const {Option} = Select;

const AddServiceToTable: FC<AddServiceTypes> = ({name, data, setValue}) => {
  const [ service, setService ] = useState(data);
  
  const InviteAddOfferModal = ModalInPortal();
  const onAddOfferClickHandler = () => InviteAddOfferModal.onShow();
  
  const onSubmitAddOfferForm = (values: any) => console.log(values);
  const onDeleteService = (key: string | undefined) => {
    setService(service?.filter((item: any) => item?.key !== key));
  };

  const tableConfig: ColumnsType<ISuppliersItem> = useMemo(() => [
    {
      title: 'Service Type',
      dataIndex: 'serviceName',
      key: 'serviceName',
      render: (value, record, i) => {
        return (
          <StyledSelect
            key={i}
            defaultValue={value}
            suffixIcon={<ArrowDown />}
            onChange={(e: any) => setValue(`${name}[${i}].serviceName`, e)}>
            {
              optionMockData.map((opt, idx) => (
                <Option key={`${idx}-${i}`} value={opt}>
                  {opt}
                </Option>
              ))
            }
          </StyledSelect>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Unit Price',
      dataIndex: 'price',
      key: 'price',
      render: (v, r, i) => {
        return (
          <PriceAmount
            key={i}
            onChange={e => setValue(`${name}[${i}].price`, e.target.value)}
          />
        );
      },
    },
    {
      dataIndex: "serviceIcon",
      key: "serviceIcon",
      render: (value, record, index) => {
        return (
          <DeleteIcon
            key={record.key}
            onClick={() => onDeleteService(record.key)}
            style={{cursor: 'pointer'}}
          />
        );
      },
    },
  ], [ setValue ]);
  
  return (
    <Container>
      <ViewButton onClick={onAddOfferClickHandler}>
        + Add Service
      </ViewButton>
      {InviteAddOfferModal.ModalRender && (
        <InviteAddOfferModal.ModalRender>
          <AddServiceModalContent
            onClose={InviteAddOfferModal.onClose}
            onSubmit={onSubmitAddOfferForm}
          />
        </InviteAddOfferModal.ModalRender>
      )}
      <TableStyled
        columns={tableConfig}
        dataSource={service}
        pagination={false}
      />
    </Container>
  );
};

export default AddServiceToTable;