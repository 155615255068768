import { FC, useMemo } from "react";
import { ColumnsType } from "antd/lib/table";
import { IServiceConfig, IServiceTable } from "./types";
import { TableStyled } from "../../../../ui/Table/table.styled";
import { StyledButtonDark } from "./service.styled";

const ServiceTable: FC<IServiceTable> = ({tableData, selectedUsers, setSelectedUsers, editService}) => {
  const tableConfig: ColumnsType<IServiceConfig> = useMemo(() => [
    {
      title: 'Icon',
      dataIndex: 'iconUrl',
      key: 'iconUrl',
      width: '20%',
      render: (value, record) =>
        <img key={record.id} src={record?.iconUrl} alt={record?.name}
             style={{width: '20px', height: '20px'}}
        />
    },
    {
      title: 'Service Type',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      width: "12%",
      dataIndex: 'editButton',
      key: 'editButton',
      render: (_v, r) => <StyledButtonDark
        onClick={() => editService ? editService(r.key) : null}>
        Edit
      </StyledButtonDark>,
    },
  ], [ editService ]);
  return (
    <TableStyled
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => {
          setSelectedUsers(ids as Array<string>);
        },
        selectedRowKeys: selectedUsers,
      }}
      columns={tableConfig}
      dataSource={tableData}
      pagination={false}
    />
  );
};

export default ServiceTable;