import { EligibleNodesTableRow } from '../../../../types';

export const eligibleNodesItems: Array<EligibleNodesTableRow> = [
  {
    key: 1,
    nodes: 'Intel',
  },
  {
    key: 2,
    nodes: 'Intel',
  },
];
