import styled from 'styled-components/macro';
import React, {CSSProperties, ReactNode} from 'react';

interface TitleProps {
  children: ReactNode,
  style?: CSSProperties
}

export const CustomTitle: React.FC<TitleProps> = ({ children, style }) => (
  <Title style={style}>{children}</Title>
);

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
`;

export default CustomTitle;
