import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import { EligibleNodesTableRow } from '../../types';
import { TableStyled } from '../../../../shared/table/TableStyled';
import ROUTER_CONFIG from '../../../../../../router/router-config';
import { useHistory } from 'react-router-dom';
import { CustomStyledTable } from '../../../../new-offer/create-new-offer/styled/new-offer.styled';

interface NodesTableDataProps {
  tableData: Array<EligibleNodesTableRow>;
  onSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
  setIsEligibleNodes: (values: boolean) => void;
  isEligibleNodes: boolean;
}

const NodesTableData: React.FC<NodesTableDataProps> = ({
  tableData,
  onSelectedOffers,
  selectedOffers,
  setIsEligibleNodes,
  isEligibleNodes
}) => {
  const { push } = useHistory();
  const onNodeDetails = () => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.getPath());
  const tableConfig: ColumnsType<EligibleNodesTableRow> = useMemo(() => [
    {
      title: 'Node',
      dataIndex: 'node',
      key: 'node',
    },
    {
      title: '',
      dataIndex: 'btn',
      key: 'btn',
      width: '175px',
      render: (_v, r) => (
        <DetailsButton onClick={() => onNodeDetails()}>
          View Node
          Details
        </DetailsButton>
      ),
    },
  ], [onNodeDetails]);

  return (
    <CustomStyledTable
      rowSelection={{
        type: 'checkbox',
        onChange: (ids: any) => {
          onSelectedOffers(ids as Array<string>);
          setIsEligibleNodes(false);
        },
        selectedRowKeys: isEligibleNodes ? tableData.map((item) => item.key) : selectedOffers,
      }}
      columns={tableConfig}
      pagination={false}
      dataSource={tableData}
    />
  );
};

const DetailsButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export default NodesTableData;
