import styled from 'styled-components/macro';
import React from 'react';
import { OfferGroupsTableRow } from '../types';
import OfferGroupTable from './OfferGroupTable';
import OfferGroupsControls from './OfferGroupsControls';

interface OfferGroupsContentProps {
  offerGroupTableData: Array<OfferGroupsTableRow>;
  onSelectedOffers: (userIds: Array<string>) => void;
  selectedOffers: Array<string>;
  onDeleteOfferGroupClickHandler: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onOfferGroupsRowClick: (offerGroupId: string) => void
}

const OfferGroupsContent: React.FC<OfferGroupsContentProps> = ({
  offerGroupTableData,
  selectedOffers,
  onSelectedOffers,
  onDeleteOfferGroupClickHandler,
  searchValue,
  setSearchValue,
  onOfferGroupsRowClick,
}) => (
  <Container>
    <OfferGroupsControls
      onDeleteOfferGroupClickHandler={onDeleteOfferGroupClickHandler}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
    <OfferGroupTable
      tableData={offerGroupTableData}
      onSelectedOffers={onSelectedOffers}
      selectedOffers={selectedOffers}
      onOfferGroupsRowClick={onOfferGroupsRowClick}
    />
  </Container>
);

const Container = styled.div``;

export default OfferGroupsContent;
