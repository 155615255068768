import styled from 'styled-components';
import { DatePicker, Input } from 'antd';
import { ButtonDark } from '../../../../ui/Button';
import SelectInput from '../../../../ui/forms/SelectInput';

export const Container = styled.form`
  position: relative;

  label {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const Title = styled.h1`
  margin: 40px 0 20px 0;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #102F54;
`;

export const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
  width: 208px;
  height: 44px;
`;

export const StyledSelect = styled(SelectInput)`
  width: 100%;
  max-width: 425px;
  border-radius: 3px;

  .ant-select-selector {
    height: 36px !important;
    box-sizing: border-box;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center !important;
  }

  .ant-select-item-option {
    align-items: center !important;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  max-width: 200px;
  height: 36px !important;
  border-radius: 3px;
`;

export const StyledSmallInput = styled(StyledInput)`
  width: 100%;
  max-width: 102px;
`;

export const StyledDataPicker = styled(DatePicker)`
  width: 100%;
  max-width: 200px;
  height: 36px !important;
  border-radius: 3px;
`;

export const StyledSmallSelect = styled(StyledSelect)`
  width: 100%;
  max-width: 102px;
`;
