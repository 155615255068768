import { FormInstance } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import { Key } from 'react';
import { IProduct } from '../buyer/types';
import { ISuppliersItem } from './offers/suppliers/types';

export enum CREATE_PRODUCT_STEPS {
  BASIC_INFORMATION = 'Basic Information',
  ATTRIBUTES = 'Attributes',
  OVERVIEW = 'Overview',
  ACCESSORIES = 'Accessories',
  RELATED_PRODUCTS = 'Related Products',
  RELATED_FILES = 'Related Files',
  GALLERY = 'Gallery',
}

export enum CREATE_ACCESSORY_STEPS {
  BASIC_INFORMATION = 'Basic Information',
  ATTRIBUTES = 'Attributes',
  OVERVIEW = 'Overview',
  RELATED_PRODUCTS = 'Related Products',
  RELATED_FILES = 'Related Files',
  GALLERY = 'Gallery',
}

export enum TECH_SPEC_OPTIONS {
  PRODUCT_DESCRIPTION = 'Product Description',
  PRODUCT_TYPE = 'Product Type',
  OPERATING_SYSTEM = 'Operating System',
  PROCESSOR = 'Processor',
  MEMORY = 'Memory',
  STORAGE = 'Storage',
  DISPLAY = 'Display',
  GRAPHICS = 'Graphics',
  KEYBOARD = 'Keyboard',
  INTEGRATED_WEBCAM = 'Integrated Webcam',
  NETWORKING = 'Networking',
  BATTERY = 'Battery',
  SECURITY = 'Security',
  COLOUR = 'Colour',
  DIMENSIONS = 'Dimensions (WxDxH)',
  WEIGHT = 'Weight',
  LOCALISATION = 'Localisation',
  ENVIRONMENTAL_STANDARDS = 'Environmental Standards',
}

export enum ORDER_STATUS {
  OUR_OF_DELIVERY = 'Out of delivery',
  PLACED = 'Placed',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  PAID = 'Paid',
}

export interface QuotesTableRow {
  key: string;
  date: string;
  reference: string;
  status: string;
  buyer: string;
  supplier: string;
  orderType: string;
  total: string;
}

export interface UsersTableRow{
  user: string;
  status: string;
  orderType: string;
}

export interface IRecorderTableConfig {
  timeRecorder: string;
  type: string;
  category: string;
  organisation: string;
  association: string;
  amout: string;
  recorderName: string;
}

export interface Quote {
  _id: string;
  user: string;
  orderType: string;
  includeDeviceInsurance: boolean;
  termLength: string;
  paymentSchedule: string;
  productId: string;
  status: string;
  quantity: number;
  reseller: string;
  services: Array<any>; // to fix
  sellerOfferId: string;
}

export interface ProductsTableRow {
  key: string;
  _id: string;
  SKU: string;
  name: string;
  description: string;
  manufacturer: string;
}

export interface SuppliersTableRow {
  key: string;
  name: string;
  organisationType: string;
  urn: string;
}

export interface Suppliers {
  _id: string;
  name: string;
  organisationType: string;
  urn: string;
}

export interface NewOffer {
  _id: string;
  insurances: Array<any>;
  leases: Array<any>;
  nte: number;
  oem: string;
  indicativeBuyerPrice: number;
  offerNumber: string;
  platformFee: number;
  product: IProduct;
  name: string;
  quantity: number;
  startDate: Date;
  stockAvailableDate: Date;
}

export interface EligibleSuppliersTableRow {
  key: Key;
  suppliers?: string;
}

export interface IBuyersOrganisation{
  name: string,
  logo: string,
  URN: string,
  organisationType: string,
  phoneNumber: string
}
export interface EligibleNodesTableRow {
  key: Key;
  nodes?: string;
}

export interface LeaseRatesTableRow {
  _id: string;
  description: string;
  type: string;
  termLength: string;
  paymentSchedule: string;
  rate: number;
  residualValueRate: string;
}

export interface InsurancePolicyTableRow {
  key: Key;
  description?: string;
  policyName?: string;
  policyLength?: string;
  paymentSchedule?: string;
  instalmentAmount?: string;
}

export interface Buyers {
  active: boolean;
  _id: string;
  school: string;
  schoolAddress: string;
  firstName: string;
  lastName: string;
  workEmail: string;
  phoneNumber: string;
  jobTitle: string;
  preferredReseller: string;
  devicesToPurchaseCount: number;
  agree: boolean;
}

export interface SuppliersApiType {
  _id?: string;
  logo: string;
  name: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  town: string;
  country: string;
  postcode: string;
  telephoneNumber: string;
  contactEmail: string;
  website: string;
  VATNumber: string;
  companyNumber: number;
  organisationType: string;
  defaultDeliveryAmount: number;
  defaultPaymentTerms: string;
  currency: string;
  termsConditionsURL: string;
  accountName: string;
  accountNumber: string;
  sortCode: string;
  deliveryPrice: number;
  devicePrice: number;
  availableServices: Array<ISuppliersItem>;
  accessories: Array<Accessories>;
  resellerImage: string;
}

export interface IBuyersOrders {
  _id: string;
  createdAt: string;
  poNumber: string;
  product: string;
  status: string;
  orderType: string;
  total: number;
}
export interface IBuyersQuotes {
  _id: string;
  createdAt: string;
  expiredAt: string;
  reference: string;
  seller: string;
  orderType: string;
  total: number;
}
export interface IBuyersDashboard {
  activeOrders: number;
  paidOrders: number;
  totalPurchase: number;
}
interface Accessories {
  image: string;
  name: string;
  price: number;
  description: string;
}

export interface IService {
  _id?: string;
  name: string;
  iconUrl: string;
  logo?: string;
  body?: any;
  serviceId?: string;
}

export interface EditService {
  _id?: string;
  name: string;
  iconUrl: string;
  logo?: string;
  body?: any;
  serviceId?: string;
}

export interface ISummaryCardItem {
  name: string;
  subname?: string;
  type?: BaseType;
  value: string | number;
}

export interface IFormComponentProps {
  form: FormInstance;
}