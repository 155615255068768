import styled from 'styled-components/macro';
import React from 'react';
import SearchIcon from '../../../../../img/icons/icon_search.svg';
import Input from '../../../../../ui/forms/Input';

interface IOfferGroupsSearch {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

const OfferGroupsSearch: React.FC<IOfferGroupsSearch> = ({
  searchValue,
  setSearchValue,
}) => (
  <SearchContent>
    <StyledInput
      placeholder="Offer Group Name"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      iconSource={SearchIcon}
    />
    <Search />
  </SearchContent>
);

const SearchContent = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const Search = styled.span`
  color: #333;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 420px;
  height: 36px;
  padding: 10px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 96% center;
`;

export default OfferGroupsSearch;
