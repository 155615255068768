import styled from 'styled-components/macro';
import { Dropdown, Menu } from 'antd';
import React, { useMemo } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDownBlue.svg';

interface UsersTableControlsProps {
  onDeleteOfferGroupClickHandler: () => void;
  onAddOfferClickHandler: () => void;
}

const OffersTableControls: React.FC<UsersTableControlsProps> = ({
  onDeleteOfferGroupClickHandler,
  onAddOfferClickHandler,
}) => {
  const dropdownContent = useMemo(() => (
    <Menu>
      <Menu.Item onClick={onDeleteOfferGroupClickHandler}>
        Delete
      </Menu.Item>
    </Menu>
  ), [onDeleteOfferGroupClickHandler]);

  return (
    <Container>
      <InviteButton onClick={onAddOfferClickHandler}>+ Add Offer</InviteButton>
      <Dropdown overlay={dropdownContent}>
        <InviteButton onClick={(e) => e.preventDefault()}>
          Actions
          {' '}
          <ArrowDown style={{ marginBottom: '2px' }} />
        </InviteButton>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 30px;
  justify-content: end;
  margin-bottom: 10px;
  padding-right: 10px;
`;

const InviteButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export default OffersTableControls;
