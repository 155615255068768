import styled from 'styled-components/macro';
import { showPriceDigits } from '../../../../../utils/formats';
import { IAccessorySelected, ORDER_TYPES } from '../../../types';
import Input from '../../../../../ui/forms/Input';
import { ButtonDark, ButtonLight } from '../../../../../ui/Button';
import { BuyerOrderStateType } from '../../../../../feature/buyerState';

interface SelectAccessoriesStepProps {
  accessories: Array<IAccessorySelected>;
  selectedAccessories: Array<IAccessorySelected>;
  onSelectAccessory: (accesory: IAccessorySelected) => void;
  onChangeAccessoryQuantity: (accessory: IAccessorySelected, quantity: number) => void;
  currentOrder: BuyerOrderStateType;
}

export default function SelectAccessoriesStep({
  accessories, selectedAccessories, onSelectAccessory, onChangeAccessoryQuantity, currentOrder,
}: SelectAccessoriesStepProps) {
  const checkIfSelected = (accessory: IAccessorySelected) => selectedAccessories.find((el) => el._id === accessory._id);

  const getPrice = (accessory: IAccessorySelected) => {
    if (currentOrder?.orderType !== ORDER_TYPES['Outright Purchase']) {
      return `${showPriceDigits(accessory.price * (currentOrder?.lease?.rate || 1))}/${currentOrder?.paymentSchedule}`;
    }

    return showPriceDigits(accessory.price);
  };

  return (
    <>
      <TitleBlock>
        <StepTitle>Add Accessories</StepTitle>
        <GreyText>All prices are Ex-VAT</GreyText>
      </TitleBlock>
      <Table>
        {accessories.length ? accessories.slice(0, 1).map((accessory) => (
          <AccessoryItem key={accessory.offer.product.name}>
            <ImageContainer>
              <Image alt="" src={`https://storage.googleapis.com/sds-public-dev/product_pictures/${accessory.offer.product.imageUrl}`} />
            </ImageContainer>
            <BoldText>{accessory.offer.product.name}</BoldText>
            <Price>{getPrice(accessory)}</Price>
            <Text>{accessory.offer.product.description}</Text>
            <ControlsBlock>
              <BoldText>Qty</BoldText>
              <Controls>
                <StyledInput
                  value={accessory.quantity}
                  onChange={(e) => onChangeAccessoryQuantity(accessory, +e.target.value)}
                  type="number"
                  step={1}
                  min={1}
                  disabled={!checkIfSelected(accessory)}
                />
                {checkIfSelected(accessory) ? (
                  <AddedButton onClick={() => onSelectAccessory(accessory)}>Added</AddedButton>
                ) : (
                  <AddButton onClick={() => onSelectAccessory(accessory)}>Add to order</AddButton>
                )}
              </Controls>
            </ControlsBlock>
          </AccessoryItem>
        )) : (
          <div>No available accessories</div>
        )}
      </Table>
    </>
  );
}

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const GreyText = styled(Text)`
  color: #6B7A87;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const Price = styled(BoldText)`
  color: #DE2644;
`;

const StepTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  margin-bottom: 22px;
`;

const Table = styled.div`
  display: grid;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #D5DCE5;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

//

const AccessoryItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ImageContainer = styled.div`
  width: 155;
  height: 155px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ControlsBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Controls = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  /* max-width: 80px; */
  height: unset;
  padding: 10.5px 11.5px;

  &:disabled {
    background-color: #F5F5F5;
  }
`;

const AddButton = styled(ButtonDark)`
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  width: 110px;
  flex-shrink: 0;
`;

const AddedButton = styled(ButtonLight)`
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  width: 110px;
  flex-shrink: 0;
`;
