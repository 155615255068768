import styled from 'styled-components/macro';
import { RightCircleOutlined } from '@ant-design/icons';
import CubeIcon from '../../../img/icons/cubeIcon.svg';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import OrderOptions from './order-options/OrderOptions';
import {
  BuyerCreateLeaseOrderType,
  BuyerCreateOutrightPurchaseOrderType,
  GroupedOfferItemType,
  GroupedOffersByProductType,
  ILeasePublicType,
  OmitProductId,
} from '../types';
import ProductOverview from './ProductOverview';

interface BuyerOrderCreateViewProps {
  groupedOffer: GroupedOfferItemType;
  onStartLeaseOrderClickHandler: (
    values: OmitProductId<BuyerCreateLeaseOrderType>
  ) => void;
  onStartOutrightPurchaseOrderClickHandler: (
    values: OmitProductId<BuyerCreateOutrightPurchaseOrderType>
  ) => void;
  leases: Array<ILeasePublicType>;
  groupedRelatedAccessoryOffers: GroupedOffersByProductType;
}

export default function BuyerOrderCreateView({
  groupedOffer,
  onStartLeaseOrderClickHandler,
  onStartOutrightPurchaseOrderClickHandler,
  leases,
  groupedRelatedAccessoryOffers,
}: BuyerOrderCreateViewProps) {
  const { product: device, offers } = groupedOffer;

  return (
    <Container>
      <ProductInfoBlock>
        <ProductInfoContent>
          <ProductImageContainer>
            <ProductImage
              alt=""
              src={`https://storage.googleapis.com/sds-public-dev/product_pictures/${device.imageUrl}`}
            />
          </ProductImageContainer>
          <ProductName>{device.name}</ProductName>
          <ProductTagsBlock>
            <ProductTagBlock>
              <TagName>
                <img alt="" src={CubeIcon} />
                <Text>SKU</Text>
              </TagName>
              <Text>{device.SKU}</Text>
            </ProductTagBlock>
            <ProductTagBlock>
              <TagName>
                <RightCircleOutlined
                  style={{ fontSize: 12, color: '#619DD8' }}
                />
                <Text>Date Available</Text>
              </TagName>
              {/* todo add availability to model */}
              <Text>{formatDateDDMMMYYYY(new Date(offers[0].offer.stockAvailableDate))}</Text>
            </ProductTagBlock>
          </ProductTagsBlock>
          <OverviewInfoBlock>
            <BlockTitle>Overview</BlockTitle>
            <Characteristics>
              <pre style={{ fontFamily: 'Proxima Nova' }}>
                {device.headlines}
              </pre>
            </Characteristics>
            <div style={{ margin: '20px 0' }} />
            <BlockTitle>Description</BlockTitle>
            <div dangerouslySetInnerHTML={{ __html: device.description }} />
          </OverviewInfoBlock>
        </ProductInfoContent>
        <StyledOrderOptions
          groupedOffer={groupedOffer}
          onStartLeaseOrderClickHandler={onStartLeaseOrderClickHandler}
          onStartOutrightPurchaseOrderClickHandler={
            onStartOutrightPurchaseOrderClickHandler
          }
          leases={leases}
        />
      </ProductInfoBlock>
      <ProductOverview product={groupedOffer.product} groupedRelatedAccessoryOffers={groupedRelatedAccessoryOffers} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
`;

const ProductInfoBlock = styled.div`
  display: flex;
  gap: 100px;
`;

const ProductInfoContent = styled.div`
  flex: 1;
`;

const ProductImageContainer = styled.div`
  width: 100%;
  height: 320px;
  background-color: #fff;
  margin-bottom: 20px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductName = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
`;

const ProductTagsBlock = styled.div`
  flex: 1;
  display: flex;
  gap: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid #D5DCE5;
  margin-bottom: 35px;
`;

const ProductTagBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const TagName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const OverviewInfoBlock = styled.div`
  margin-bottom: 44px;
`;

const BlockTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
  margin-bottom: 14px;
`;

const Characteristics = styled.ul`
  margin: 0;
  padding: 0;
  list-style-position: inside;
`;

// const ConsiderationsBlock = styled.div``;

const StyledOrderOptions = styled(OrderOptions)`
  flex: 0.8;
  max-width: 480px;
  align-self: flex-start;
`;
