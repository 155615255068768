import * as yup from 'yup';
import {
  AddNodesForm,
  AddOfferForm, EditOfferGroupForm,
} from './types';

export const addOfferForm: yup.SchemaOf<AddOfferForm> = yup.object({
  numberOffer: yup.string().required(),
});

export const addNodeForm: yup.SchemaOf<AddNodesForm> = yup.object({
  numberNode: yup.string().required(),
});

export const editOfferGroupForm: yup.SchemaOf<Partial<EditOfferGroupForm>> = yup.object({
  name: yup.string().required(),
  icon: yup.string().optional(),
  description: yup.string().optional(),
});
