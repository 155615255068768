import { superadminApi } from "./superadminApi";
import { ISupplierDashboard } from "../../../components/supplier/types";

const supplierDashboardApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierDashboardList: build.query<ISupplierDashboard, void>({
      query: () => 'supplier/dashboard',
    }),
  }),
  overrideExisting: false,
});

export const { useGetSupplierDashboardListQuery } = supplierDashboardApi;