import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  AddNodesForm, AddOfferForm, EligibleNodesTableRow,
} from './types';
import ROUTER_CONFIG from '../../../../router/router-config';
import { ButtonDanger } from '../../../../ui/Button';
import OfferGroupDetailsDescription from './offer-groups-details/OfferGroupDetailsDescription';
import OffersTable from './offer-groups-details/offers-table/OffersTable';
import NodesTable from './offer-groups-details/nodes-table/NodesTable';
import { useGetOfferListQuery } from '../../../../api/services/superadmin/offersApi';
import LayoutV1 from '../../../layout/LayoutV1';
import Title from '../../shared/header/Title';
import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';


const eligibleNodesTableData: Array<EligibleNodesTableRow> = [
  {
    key: '1',
    node: 'Intel',
  },
  {
    key: '2',
    node: 'Intel',
  },
];

const OfferGroupDetailsPage: React.FC = () => {
  const { data: offers } = useGetOfferListQuery();
  const history = useHistory();
  const { offerGroupId } = useParams<{ offerGroupId: string }>();
  const [selectedOffers, setSelectedOffers] = useState<Array<string>>([]);
  const [selectedNodeOffers, setSelectedNodeOffers] = useState<Array<string>>([]);

  const tableData = offers?.map((offers: any) => ({
    key: offers._id,
    sku: offers.product.SKU,
    product: offers.product.name,
    offerNumber: offers.offerNumber,
    oem: offers.product.manufacturer,
    startDate: offers.startDate,
    endDate: offers.stockAvailableDate,
  }));

  const onDeleteOfferGroupClickHandler = () => console.log(selectedOffers);
  const onSubmitAddOfferForm = (values: AddOfferForm) => console.log(values);
  const onSubmitAddNodeForm = (values: AddNodesForm) => console.log(values);
  const onEditOfferGroupClick = () => history.push(ROUTER_CONFIG.SUPER_ADMIN_OFFER_GROUP_EDIT.getPath({ offerGroupId }));

  const onBack = useCallback(
    () => history.goBack(),
    [history],
  );

  return (
    <LayoutV1>
      <Container>
        <GoBack onClick={onBack}>
          <BackIcon />
        </GoBack>
        <Title style={{ margin: '0 0 60px 0' }}>Offer Group Details</Title>
        <OfferGroupDetailsDescription onEditOfferGroupClick={onEditOfferGroupClick} />
        {tableData &&
        <OffersTable
          {...{tableData, setSelectedOffers, selectedOffers, onDeleteOfferGroupClickHandler, onSubmitAddOfferForm}}
        />}
        <NodesTable
          nodesTableData={eligibleNodesTableData}
          onSelectedOffers={setSelectedNodeOffers}
          selectedOffers={selectedNodeOffers}
          onDeleteOfferGroupClickHandler={onDeleteOfferGroupClickHandler}
          onSubmitAddNodeForm={onSubmitAddNodeForm}
        />
        <OfferGroupDetailsButton>Delete Offer Group</OfferGroupDetailsButton>
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div`
  position: relative;
`;

const OfferGroupDetailsButton = styled(ButtonDanger)`
  font-size: 16px;
  padding: 10px 25px;
  margin-top: 84px;
`;

const GoBack = styled.div`
  position: absolute;
  left: -53px;
  top: 2px;
  cursor: pointer;
`;

export default OfferGroupDetailsPage;
