import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayout from '../../layout/SupplierLayout';
import QuotesOverview from './QuotesOverview';
import { useGetQuotesListQuery } from '../../../../api/services/supplier/quotesApi';
import { useState } from 'react';
import ModalPdf from '../../../../ui/Modal/ModalPdf';

export default function QuotesOverviewPage() {
  const { push } = useHistory();
  const { data: quotes } = useGetQuotesListQuery();
  const [ isModalVisible, setModalVisible] = useState(false);

  const onViewDocClickHandler = (id: string) => setModalVisible(!isModalVisible);
  const onBuildQuoteClickHandler = () => push(ROUTER_CONFIG.SUPPLIER_BUILD_QUOTE.getPath());

  return (
    <SupplierLayout>
      {quotes && (
        <QuotesOverview
          tableData={quotes.items}
          onViewDocClickHandler={onViewDocClickHandler}
          onBuildQuoteClickHandler={onBuildQuoteClickHandler}
        />
      )}
      <ModalPdf handleVisible={isModalVisible} handleClose={() => setModalVisible(false)}/>
    </SupplierLayout>
  );
}
