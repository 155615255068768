import { useCallback, useState } from 'react';

type ServiceId = number;

export const useServices = () => {
  const [services, setServices] = useState(() => new Set([22])); // temp value

  const onAddService = useCallback((id: ServiceId) => {
    return setServices((prevServices) => new Set(prevServices).add(id));
  }, []);

  const onRemoveService = useCallback((id: ServiceId) => {
    return setServices((prevServices) => {
      const newServices = new Set(prevServices);
      newServices.delete(id);

      return newServices;
    });
  }, []);

  return {
    onRemoveService,
    onAddService,
    services,
  };
};
