import { FC, useRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '../../../../../img/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../../img/icons/arrow-up.svg';

const InputNumber: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onButtonPlus = () => inputRef.current?.stepUp();

  const onButtonMinus = () => inputRef.current?.stepDown();

  return (
    <InputNumberStyled>
      <input ref={inputRef} type="number" min={0} value={0} />
      <div>
        <button type="button" className="btn__plus" onClick={onButtonPlus}>
          <ArrowUpIcon />
        </button>
        <button type="button" className="btn__minus" onClick={onButtonMinus}>
          <ArrowDownIcon />
        </button>
      </div>
    </InputNumberStyled>
  );
};

const InputNumberStyled = styled.div`
  display: flex;
  height: 38px;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    border: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;

    border: 1px solid #d5dce5;
    /* border-radius: 5px; */

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    width: 80px;

    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.textPrimary};
  }

  div {
    display: flex;
    flex-direction: column;
  }

  button {
    border: none;
    width: 30px;
    height: 19px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn__minus {
    background: #c6c9d5;
    border-bottom-right-radius: 5px;
  }

  .btn__plus {
    background: #d5dce5;
    border-top-right-radius: 5px;
  }
`;

export default InputNumber;
