import { Input } from 'antd';
import { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';
import SelectInput from '../../../../ui/forms/SelectInput';
import TextArea from '../../../../ui/forms/Textarea';
import { FormConfig, renderFormConfig } from '../../../../utils/form';
import {
  IProduct,
  ProductTypesEnum,
  SUBCATEGORY_TYPES_ENUM,
} from '../../../buyer/types';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';
import { RadioGroup, RadioItem } from '../../../../ui/forms/Radio';

interface RadioProps {
  setFieldValue: (field: string, value: string) => void;
  name: string;
  value: boolean;
}

const YesOrNoRadioGroup: FC<RadioProps> = ({ setFieldValue, name, value }) => (
  <RadioGroup
    onChange={(v) => setFieldValue(name, v.target.value)}
    value={value}
  >
    <RadioItem value={true}>Yes </RadioItem>
    <RadioItem value={false}>No </RadioItem>
  </RadioGroup>
);

interface IProps {
  formikInstance: any;
  isEditMode: boolean;
}

const StyledInput = styled(Input)`
  height: unset;
  padding: 8px;
  width: 100%;
  max-width: 550px;
`;

const StyledTextArea = styled(TextArea)`
  height: 175px;
  padding: 8px;
  width: 100%;
  max-width: 550px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 175px;

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

interface Fields {
  key: keyof IProduct;
  title: string;
  render?: (value: any) => string | JSX.Element;
}

const fields: Array<Fields> = [
  {
    title: 'SKU',
    key: 'SKU',
  },
  {
    title: 'Product Name',
    key: 'name',
  },
  {
    title: 'Headlines',
    key: 'headlines',
  },
  {
    title: 'Product Description',
    key: 'description',
  },
  {
    title: 'Category',
    key: 'category',
  },
  {
    title: 'Subcategory',
    key: 'subcategory',
  },
  {
    title: 'Manufacturer',
    key: 'manufacturer',
  },
  {
    title: 'Default VAT Rate',
    key: 'defaultVatRate',
    render: (text: string) => `${text} %`,
  },
  {
    title: 'Eligible for Insurance',
    key: 'insuranceAvailable',
    render: (condition: boolean) => `${condition ? 'Yes' : 'No'}`,
  },
];

const containerStyle = { flexDirection: 'row' } as CSSProperties;

const formConfig: FormConfig = [
  {
    title: 'SKU',
    name: 'SKU',
    component: StyledInput,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Product Name',
    name: 'name',
    component: StyledInput,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Headlines',
    name: 'headlines',
    component: StyledTextArea,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Product Description',
    name: 'description',
    component: StyledTextArea,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Category',
    name: 'category',
    component: StyledSelectInput,
    props: {
      initialData: [ProductTypesEnum.DEVICE, ProductTypesEnum.ACCESSORY],
      suffixIcon: <ArrowDown />,
    },
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Subcategory',
    name: 'subcategory',
    component: StyledSelectInput,
    props: {
      initialData: [SUBCATEGORY_TYPES_ENUM.NOTEBOOK],
      suffixIcon: <ArrowDown />,
    },
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Manufacturer',
    name: 'manufacturer',
    component: StyledInput,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Default VAT Rate',
    name: 'defaultVatRate',
    component: StyledInput,
    isRequired: true,
    containerStyle: containerStyle,
  },
  {
    title: 'Eligible for Insurance',
    name: 'insuranceAvailable',
    component: YesOrNoRadioGroup,
    isRequired: true,
    containerStyle: containerStyle,
  },
];

const ProductBasicInfo: FC<IProps> = ({ formikInstance, isEditMode }) => {
  const product = formikInstance.values as IProduct;

  return (
    <>
      {isEditMode ? (
        renderFormConfig(formConfig, formikInstance)
      ) : (
        <>
          {fields.map((field) => (
            <SummaryInfoItem>
              <Label>{field.title}</Label>
              <Text>
                {field.render
                  ? field.render(product[field.key])
                  : product[field.key]}
              </Text>
            </SummaryInfoItem>
          ))}
        </>
      )}
    </>
  );
};

const SummaryInfoItem = styled.div`
  display: flex;
  margin-bottom: 45px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const Label = styled(Text)`
  font-weight: bold;
  color: #6b7a87;
  margin-bottom: 6px;
  min-width: 170px;
`;

export default ProductBasicInfo;
