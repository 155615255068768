import styled from 'styled-components/macro';
import {
  Dropdown, Menu, Space, Switch,
} from 'antd';
import React, { useMemo } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDownBlue.svg';

interface UsersTableControlsProps {
  onDeleteOfferGroupClickHandler: () => void;
  onAddOfferClickHandler: () => void;
  setIsEligibleNodes: any;
  isEligibleNodes: boolean;
}

const NodesTableControls: React.FC<UsersTableControlsProps> = ({
  onDeleteOfferGroupClickHandler,
  onAddOfferClickHandler,
  setIsEligibleNodes,
  isEligibleNodes,
}) => {
  const dropdownContent = useMemo(() => (
    <Menu>
      <Menu.Item onClick={onDeleteOfferGroupClickHandler}>
        Delete
      </Menu.Item>
    </Menu>
  ), [onDeleteOfferGroupClickHandler]);

  return (
    <Container>
      <Space
        align="start"
        style={{
          fontSize: '12px',
          alignItems: 'center',
          columnGap: 3,
        }}
      >
        <Switch size="small" onChange={() => setIsEligibleNodes(!isEligibleNodes)}/>
        {' '}
        Make eligible on all nodes
      </Space>
        <WrapperEndAction>
          <DetailsButton onClick={onAddOfferClickHandler}>+ Add Node</DetailsButton>
          <Dropdown overlay={dropdownContent}>
            <DetailsButton onClick={(e) => e.preventDefault()}>
              Actions
              {' '}
              <ArrowDown style={{ marginBottom: '2px' }} />
            </DetailsButton>
          </Dropdown>
        </WrapperEndAction>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 5px;

  .ant-switch-small {
    height: 23px;
    width: 39px;
    line-height: 23px;
  }

  .ant-switch-handle {
    width: 18px;
    height: 18px;
  }

  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
`;

const WrapperEndAction = styled.div`
`;

const DetailsButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin-left: 30px;

  &:hover {
    text-decoration: underline;
  }
`;

export default NodesTableControls;
