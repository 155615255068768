import { Col, Row, Space } from 'antd';
import { FC } from 'react';

import LayoutV1 from '../../../layout/LayoutV1';
import DocumentsCard from '../../shared/documents-card/DocumentsCard';
import { Flex } from '../../shared/utils/utils.styled';
import { TextBody, Title } from '../../shared/typography/typography.styled';
import { ButtonDark } from '../../../../ui/Button';

import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';
import { ReactComponent as ShareLinkIcon } from '../../../../img/icons/share_link.svg';

const QuoteCreateSuccess: FC = () => {
  return (
    <LayoutV1>
      <Row>
        <Col span={1}>
          <Flex align="center" justify="flex-start" style={{ marginTop: 5 }}>
            <BackIcon cursor="pointer" />
          </Flex>
        </Col>
        <Col span={23}>
          <Row>
            <Col span={24}>
              <Title>Quote Created Successfully</Title>
            </Col>

            <Col span={12} style={{ marginTop: 50 }}>
              <DocumentsCard />
            </Col>

            <Col span={24} style={{ marginTop: 30 }}>
              <Space>
                <ShareLinkIcon />
                <TextBody>Shareable Link</TextBody>
              </Space>
            </Col>

            <Col
              span={24}
              style={{ fontWeight: 500, fontSize: 16, padding: 25 }}
            >
              <TextBody>
                www.devicesforeducation.com/Buyer/ViewQuote?Quote=247UBFUBFUBFUBFUBF||
              </TextBody>
            </Col>

            <Col span={12} style={{ marginTop: 55 }}>
              <ButtonDark style={{ width: '100%', padding: 20 }}>
                Email Quote Link To Buyer
              </ButtonDark>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutV1>
  );
};

export default QuoteCreateSuccess;
