import * as yup from 'yup';
import { phoneRegExp } from "../../../../utils/phoneRegExp";

export const suppliersSchemas = yup.object({
  _id: yup.string().required(),
  logo: yup.string().optional(),
  name: yup.string().required(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string().optional(),
  addressLine3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  postcode: yup.string().required(),
  telephoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .optional(),
  contactEmail: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  website: yup.string().optional(),
  VATNumber: yup.string().required(),
  companyNumber: yup.string().required(),
  organisationType: yup.string().required(),
  defaultDeliveryAmount: yup.string().optional(),
  currency: yup.string().optional(),
  defaultPaymentTerms: yup.string().optional(),
  url: yup.string().optional(),
  accountName: yup.string().optional(),
  accountNumber: yup.string().optional(),
  sortCode: yup.string().optional(),
  deliveryPrice: yup.number().optional(),
  devicePrice: yup.number().optional(),
  availableServices: yup.array().of(
    yup.object().shape({
      serviceName: yup.string().optional(),
      description: yup.string().optional(),
      price: yup.number().optional(),
      serviceIcon: yup.string().optional(),
    }),
  ).optional(),
  accessories: yup.array().of(
    yup.object().shape({
      image: yup.string().optional(),
      name: yup.string().optional(),
      price: yup.number().optional(),
      description: yup.string().optional(),
    }),
  ).optional(),
  resellerImage: yup.string().optional(),
});