import styled from 'styled-components';
import { TableStyled } from '../../../../shared/table/TableStyled';
import { userConfig } from '../config/configTable';
import UserTableControl from './UserTableControl';
import { FC } from "react";

interface IUserTable {
  onSelectUser: (userIds: Array<string>) => void;
  selectedUser: Array<string>;
  onRowClickHandler?: (id: string) => void;
}

const mockData = [
  {
    key: '1',
    firstName: 'Robert',
    secondName: 'Noice',
    email: 'robNoice@intel.com',
    role: 'Engineer',
    status: 'Creating Intel'
  },
  {
    key: '2',
    firstName: 'Robert',
    secondName: 'Noice',
    email: 'robNoice@intel.com',
    role: 'Engineer',
    status: 'Creating Intel'
  }
];

const UserTable: FC<IUserTable> = ({onSelectUser, selectedUser, onRowClickHandler}) => {
  return (
    <Container>
      <UserTableControl/>
      <CustomStyledTable
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectUser(ids as Array<string>);
          },
          selectedRowKeys: selectedUser,
        }}
        columns={userConfig}
        dataSource={mockData}
        pagination={false}
        onRow={(rowData) => ({
          onClick: () => onRowClickHandler ? onRowClickHandler(rowData?._id) : null,
          style: { cursor: 'pointer' },
        })}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: -25px;
  margin-bottom: 347px; 
`;

const CustomStyledTable = styled(TableStyled)`
  .ant-table-thead > tr > th{
    font-weight: 600;
  }
  .ant-table-tbody > tr{
    font-weight: 500;
  }
  .ant-table-column-sorter {
    display: none;
  }
`;

export default UserTable;