import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as BackIcon } from '../../../../../../img/icons/backIcon.svg';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../../../router/router-config';

const TitleComponent: React.FC = () => {
  const { push } = useHistory();
  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_OFFER_GROUP.getPath());

  return (
    <TitleWrapper>
    <BackWrapper onClick={onBack}>
      <BackIcon />
    </BackWrapper>
    <Title>Create New Offer</Title>
    </TitleWrapper>

  );
};

export const Title = styled.h1`
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 37px;
  color: #102f54;
  font-weight: bold;
`;

export const TitleWrapper = styled.div`
  position: relative;
`;

export const BackWrapper = styled.div`
  position: absolute;
  left: -53px;
  top: 2px;
  cursor: pointer;
`;


export default TitleComponent;

