import { FC } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../../../router/router-config';
import { StyledButtonDark } from '../../../../new-offer/create-new-offer/styled/new-offer.styled';

const ButtonComponent: FC = () => {
  const { push } = useHistory();
  const onOffers = () => push(ROUTER_CONFIG.SUPER_ADMIN_OFFER_GROUP.getPath());

  return (
    <Row
      style={{ marginBottom: '100px' }}
    >
      <Col
        span={16}
      >
        <StyledButtonDark
          type='submit'
          onClick={() => onOffers()}
          style={{ width: '313px' }}
        >
          Create Offer Group
        </StyledButtonDark>
      </Col>
    </Row>
  );
};

  export default ButtonComponent;
