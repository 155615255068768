import styled from "styled-components/macro";

export const UploadContainer = styled.span`
  display: flex;
  max-width: 452px;
  gap: 10px;
  margin: 0 0 20px 0;
  
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 56px;
    height: 56px;
    border: 1px solid #D5DCE5;
  }

  .ant-upload-list-picture-card-container {
    width: 56px;
    height: 56px;
  }

  .ant-upload-list-item {
    padding: 0;
    border: 1px solid #D5DCE5;
    height: 54px;
  }

  .ant-upload-list-item-name {
    font-size: 12px;
    font-weight: bold;
  }

  .ant-upload-list-item-actions a {
    display: none;
  }

  .ant-upload-list-item-thumbnail .anticon {
    display: none;
  }

  .ant-upload-list-item-info {
    width: 54px;
    height: 54px;
    border-radius: 3px;
  }
`;