import { FC } from 'react';
import { useGetNewOffersQuery } from '../../../../api/services/superadmin/newOfferApi';
import OfferDetailsSection from './offer-details-section/OfferDetailsSection';
import ProductDetailsSection from './product-details-section/ProductDetailsSection';
import EligibleSuppliersSection from './eligible-suppliers-section/EligibleSuppliersSection';
import EligibleNodesSection from './eligible-nodes-section/EligibleNodesSection';
import LeaseRatesSection from './lease-rates-section/LeaseRatesSection';
import InsurancePolicySection from './insurance-policy-section/InsurancePolicySection';
import ButtonComponent from './button-component/ButtonComponent';
import TitleComponent from './title-component/TitleComponent';
import OfferPreviewComponent from './offer-preview-component/OfferPreviewComponent';
import LayoutV1 from '../../../layout/LayoutV1';
import { offerDetailsMock } from './offer-details-section/offerDetails.mock';

const NewOfferPage: FC = () => {
  const { data: offers } = useGetNewOffersQuery();

  return (
    <LayoutV1>
      <TitleComponent />
      <OfferDetailsSection
        mockedData={offerDetailsMock}
      />
      {offers &&
      <ProductDetailsSection
        offers={offers}
      />}
      <EligibleSuppliersSection/>
      <EligibleNodesSection />
       {offers &&
       <LeaseRatesSection
        offers={offers}
       />}
        {offers &&
      <InsurancePolicySection
         offers={offers}
      />}
        {offers &&
        <OfferPreviewComponent
          title="Offer Preview"
          offers={offers}
        />}
      <ButtonComponent />
    </LayoutV1>
  );
};

export default NewOfferPage;
