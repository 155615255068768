import "antd/dist/antd.css";
import {  Upload, UploadProps } from "antd";
import styled from 'styled-components/macro';
import type { UploadFile } from 'antd/es/upload/interface';
import { FC } from 'react';
import example from '../../img/buyer-overview/exampleLogoUpload.svg'

const fileList: UploadFile[] = [
  {
    uid: "-1",
    name: "xxx.png",
    status: "done",
    url: example
  }
];

export const UploadImg: FC<UploadProps> = styled(Upload)`
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: 0;
  }

  .ant-upload.ant-upload-select-picture-card {
    background-color: transparent;
    border: 0;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload{
    color: #2682DE;
  }
`;

const UploadProfileImg = () => {
    return(
        <>
            <UploadImg
            listType="picture-card"
            defaultFileList={[...fileList]}
            >
              {fileList.length < 5 && "Upload"}
            </UploadImg>
        </>
    )
}

export default UploadProfileImg