import React from "react";
import CreateAccountForm from "./CreateAccountForm";
import LayoutV1 from "../../../../layout/LayoutV1";
import { FormContent, HeaderContent, MainHeader, SupplierWrap } from "../suppliers.styled";
import LinkBackButton from "../../../../../ui/elements/LinkBackButton";
import ROUTER_CONFIG from "../../../../../router/router-config";

const CreateSupplierAccount: React.FC = () => {
  return (
    <LayoutV1>
      <SupplierWrap>
        <HeaderContent>
          <LinkBackButton path={ROUTER_CONFIG.SUPER_ADMIN_SUPPLIERS.getPath()}/>
          <MainHeader> New Supplier Account</MainHeader>
        </HeaderContent>
        <FormContent>
          <CreateAccountForm/>
        </FormContent>
      </SupplierWrap>
    </LayoutV1>
  );
};

export default CreateSupplierAccount;