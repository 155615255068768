import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import AppRouter from './routers';
import { persistor, store } from './store';
import { theme } from './theme/theme.provider';

import './App.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
