import { superadminApi } from "./superadminApi";
import { EditService, IService } from "../../../components/superadmin/types";

const servicesApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getServices: build.query<Array<IService>, void>({
      providesTags: [ 'SERVICE' ],
      query: () => 'service',
    }),
    createService: build.mutation<void, IService>({
      invalidatesTags: [ 'SERVICE' ],
      query: (body: IService) => ({
        url: 'service',
        method: 'POST',
        body,
      }),
    }),
    updateService: build.mutation<EditService, { body: any, serviceId: string }>({
      invalidatesTags: [ 'SERVICE' ],
      query: ({body, serviceId}) => ({
        url: `service/${serviceId}`,
        method: 'PUT',
        body,
      }),
    }),
    getServiceID: build.query({
      providesTags: ['SERVICE'],
      query: (id: string) => ({
        url: `service/byId/${id}`,
      }),
    }),
    deleteService: build.mutation<any, string>({
      invalidatesTags: ['SERVICE'],
      query: (id) => ({
        url: `service/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useGetServiceIDQuery,
  useDeleteServiceMutation,
} = servicesApi;
