import * as yup from 'yup';
import { phoneRegExp } from '../../../utils/phoneRegExp';
import { CreateInsurerForm, IInsurance } from './types';

export const createInsurerForm: yup.SchemaOf<CreateInsurerForm> = yup.object({
  name: yup.string().required(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string().optional(),
  addressLine3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  telephoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .optional(),
  contactEmail: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  postcode: yup.string().required(),
});

export const insurancePolicyForm: yup.SchemaOf<
  Omit<IInsurance, '_id' | 'insurer'>
> = yup.object({
  name: yup.string().required(),
  description: yup.string().optional(),
  policyLength: yup.string().required(),
  paymentSchedule: yup.string().required(),
  price: yup.number().required(),
});
