import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import { Dropdown, Input, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import SearchIcon from '../../../img/icons/icon_search.svg';
import { LessorTableRow } from './types';
import { TableStyled } from '../shared/table/TableStyled';
import { ReactComponent as ArrowDown } from '../../../img/icons/arrowDownBlue.svg';

interface IProps {
  lessorsTableData: Array<LessorTableRow>;
  onSelectedLessors: (selectedIds: Array<string>) => void;
  selectedLessors: Array<string>;
  onDeleteLessor: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onLessorRowClick: (id: string) => void;
  onSubmitSearchValue: () => void;
}

const tableConfig: ColumnsType<LessorTableRow> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '20%',
    align: 'center',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Telephone',
    dataIndex: 'telephoneNumber',
    key: 'telephoneNumber',
  },
];

const LessorsTable: React.FC<IProps> = ({
  lessorsTableData,
  onSelectedLessors,
  selectedLessors,
  onDeleteLessor,
  searchValue,
  setSearchValue,
  onLessorRowClick,
  onSubmitSearchValue,
}) => {
  const dropdownContent = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={onDeleteLessor}> Delete </Menu.Item>
      </Menu>
    ),
    [onDeleteLessor]
  );

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
          marginTop: 13,
          gap: 35,
        }}
      >
        <SearchContent>
          <StyledInput
            placeholder="Lessor Name"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            iconSource={SearchIcon}
            onKeyDown={(e) => e.key === 'Enter' && onSubmitSearchValue()}
          />
          <Search />
        </SearchContent>
        <Dropdown overlay={dropdownContent}>
          <DropDownButton onClick={(e) => e.preventDefault()}>
            Actions <ArrowDown style={{ marginBottom: '2px' }} />
          </DropDownButton>
        </Dropdown>
      </div>
      <TableStyled
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectedLessors(ids as Array<string>);
          },
          selectedRowKeys: selectedLessors,
        }}
        onRow={(rowData) => ({
          onClick: () => onLessorRowClick(rowData.key),
          style: { cursor: 'pointer' },
        })}
        columns={tableConfig}
        dataSource={lessorsTableData}
      />
    </Container>
  );
};
const Container = styled.div`
  margin-bottom: 10px;
`;

const DropDownButton = styled.span`
  cursor: pointer;
  color: #2682de;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;
  min-width: 60px;

  &:hover {
    text-decoration: underline;
  }
`;

const SearchContent = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const Search = styled.span`
  color: #333;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 420px;
  height: 36px;
  padding: 10px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 96% center;
`;

export default LessorsTable;
