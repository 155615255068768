import styled from 'styled-components/macro';
import React from 'react';
import { ReactComponent as Camera } from '../../../../../../img/icons/camera.svg';

const UploadButton: React.FC = () => (
  <Wrapper>
    <Camera style={{
      position: 'absolute',
      bottom: '35%',
      right: '5',
    }}
    />

    <Title>upload</Title>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative
`;

const Title = styled.div`
  margin-top: 25px;
  font-size: 12px;
  font-weight: 600;
  color: #102F54;
`;

export default UploadButton;
