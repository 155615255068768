import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonDark } from '../../../ui/Button';
import LayoutV1 from '../../layout/LayoutV1';
import Title from '../shared/header/Title';
import ROUTER_CONFIG from '../../../router/router-config';
import { useGetLessorsListQuery, useGetSearchLessorsListQuery } from '../../../api/services/superadmin/lessorsApi';
import LessorsTable from './LessorsTable';

const LessorsPage: FC = () => {
  const { push } = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const [submittedValue, setSubmittedValue] = useState<{ name: string }>({ name: '' });
  const [selectedLessors, setSelectedLessors] = useState<Array<string>>([]);

  const { data: lessorsData } = useGetLessorsListQuery();
  const { data: searchLessors } = useGetSearchLessorsListQuery(submittedValue);

  const onSubmitSearchValue = () => {
    setSubmittedValue({ name: searchValue });
  };

  const onDeleteLessor = useCallback(() => console.log('selected'), []);

  const onLessorRowClick = (lessorId: string) => push(ROUTER_CONFIG.SUPER_ADMIN_LESSOR_DETAILS.getPath({ lessorId }));

  const onCreateNewLesserClick = () => push(ROUTER_CONFIG.SUPER_ADMIN_LESSOR_CREATE.getPath());

  const lessors = useMemo(() => {
    if (!searchValue) {
      setSubmittedValue({ name: '' });
    }
    if (submittedValue.name) {
      return searchLessors;
    }
    return lessorsData;
  }, [searchLessors, lessorsData, searchValue, submittedValue.name]);

  const tableData = useMemo(
    () => lessors?.map((lessor) => ({
      key: lessor._id,
      name: lessor.name,
      email: lessor.contactEmail,
      address: lessor.addressLine1,
      telephoneNumber: lessor.telephoneNumber,
    })) || [],
    [lessors],
  );

  return (
    <LayoutV1>
      <Container>
        <TitleHolder>
          <Title style={{ margin: '0 0 10px 0' }}>Lessors</Title>
          <StyledButton onClick={onCreateNewLesserClick}>
            + Create New Lessor Account
          </StyledButton>
        </TitleHolder>
        <LessorsTable
          lessorsTableData={tableData}
          onSelectedLessors={setSelectedLessors}
          selectedLessors={selectedLessors}
          onDeleteLessor={onDeleteLessor}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onLessorRowClick={onLessorRowClick}
          onSubmitSearchValue={onSubmitSearchValue}
        />
      </Container>
    </LayoutV1>
  );
};

const Container = styled.div``;

const StyledButton = styled(ButtonDark)`
  padding: 11px 35px;
  font-size: 16px;
  height: fit-content;
  align-self: center;
`;

const TitleHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default LessorsPage;