import { ColumnsType } from 'antd/lib/table';

import { showPriceDigits } from '../../../../utils/formats';
import { TextBody } from '../../shared/typography/typography.styled';
import { QuotesTableRow } from '../../types';

import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';

export const columns: ColumnsType<QuotesTableRow> = [
  {
    title: (
      <TextBody type="secondary">
        Date Submitted <ArrowDownIcon />
      </TextBody>
    ),
    key: 'date',
    dataIndex: 'date',
    sorter: (a: any, b: any) => {
      if (new Date(a.date) < new Date(b.date)) {
        return -1;
      }
      if (new Date(a.date) > new Date(b.date)) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Reference',
    key: 'reference',
    dataIndex: 'reference',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Buyer',
    key: 'buyer',
    dataIndex: 'buyer',
  },
  {
    title: 'Supplier',
    key: 'supplier',
    dataIndex: 'supplier',
  },
  {
    title: 'Order Type',
    key: 'orderType',
    dataIndex: 'orderType',
  },
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
  },
];

export const mockTableData: Array<QuotesTableRow> = [
  {
    key: '1',
    date: '06/06/2021',
    reference: 'EFR1-2412',
    status: 'quoted',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '2',
    date: '03/06/2021',
    reference: 'EFR1-2412',
    status: 'pending',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '3',
    date: '01/06/2021',
    reference: 'EFR1-2412',
    status: 'rejected',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '4',
    date: '02/04/2021',
    reference: 'EFR1-2412',
    status: 'quoted',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
  {
    key: '5',
    date: '01/03/2021',
    reference: 'EFR1-2412',
    status: 'quoted',
    buyer: 'Coomble Wood School',
    supplier: 'Demo Supplier A',
    orderType: 'Supplier Credit',
    total: showPriceDigits(16500),
  },
];
