import { FC } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line object-curly-newline
import { Input, Table, TableProps, InputProps, DatePicker, Select, Typography, Switch, Checkbox } from 'antd';
import { ButtonDark } from '../../../../../ui/Button';

export const Title = styled.h1`
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 37px;
  color: #102f54;
  font-weight: bold;
`;

export const Container = styled.div`
    width: 100%;
    margin-top: 40px;
`;

export const SubTitle = styled.h3`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #102F54;
`;

export const InputLabel = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #102F54;
  margin-bottom: 6px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 22px;
`;

export const TitleWrapper = styled.div`
  position: relative;
`;

export const BackWrapper = styled.div`
  position: absolute;
  left: -53px;
  top: 2px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectWrapper = styled.div`
  width: 102px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const LongSelectWrapper = styled.div`
  width: 425px;
`;

export const StyledSelect = styled(Select)`
  color: #102F54;
  font-weight: 400;
  line-height: 17px;
  .ant-select {
    width: 102px!important;
  }
  .ant-select-selection-item {
    padding-right: 0px!important;
    line-height: 35px!important;
    color: #102F54;
  }
  .ant-select-selector {
    height: 36px!important;
    width: 91px!important;
    padding: 0 0 0 11px !important;
  }
`;

export const StyledLink:FC<any> = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2682DE;
  cursor: pointer;
`;

export const StyledText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

export const StyledSwitch = styled(Switch)`
  margin-right: 7px;
  min-width: 39px;
  height: 23px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 11px;
    height: 11px;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-inner::after {
    width: 4px;
    height: 5px;
  }
`;

export const StyledLongSelect = styled(Select)`
  color: #102F54;
  font-weight: 400;
  .ant-select {
    width: 425px!important;
  }
  .ant-select-selection-item {
    padding-right: 0px!important;
    line-height: 35px!important;
  }
  .ant-select-selector {
    height: 36px!important;
    width: 425px!important;
    padding-right: 0px!important;
  }
`;

export const StyledButtonDark:FC<any> = styled(ButtonDark)`
  padding: 10px 25px;
  font-size: 14px;
`;

export const TableStyledWrapper = styled.div`
  .ant-table-row {
    background: #F5F8FB;
  }
`;

export const TableStyled: FC<TableProps<any>> = styled(Table)`
  .ant-table-tbody > tr > td {
    border-bottom: 2px solid #d5dce5;
  }
  .ant-table-thead th {
    color: #6b7a87;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 2px solid #d5dce5;
    border-top: 2px solid #2682de;
  }
  .ant-table-thead tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: relative;
  }
  .ant-table tr {
    color: #102f54;
    font-size: 14px;
    font-weight: 600;
  }
  .ant-table {
    border-right: 2px solid #d5dce5 !important;
    border-left: 2px solid #d5dce5 !important;
  }
`;

export const CustomStyledTable = styled(TableStyled)`
  .blueBackground {
    background-color: #F5F8FB;
  }
  .ant-table-column-sorters {
    width: 80px;
  }

  .ant-table-column-sorter-inner {
    color: #6B7A87;
  }
`;

export const InputStyled: FC<InputProps> = styled(Input)`
  width: 200px;
  height: 36px;
  border: 1px solid #D5DCE5;
  border-radius: 3px;
  background: #FFFFFF;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #909DA8;
  }
`;

export const DisabledInputStyled: FC<InputProps> = styled(InputStyled)`
  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #102F54!important;
  }
`;

export const DatePickerStyled: FC<any> = styled(DatePicker)`
  width: 200px;
  height: 36px;
  border: 1px solid #D5DCE5;
  border-radius: 3px;
  background: #FFFFFF;
  .ant-picker-input > input {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #909DA8;
      font-weight: 400;
      font-size: 13px;
      line-height: 12px;
    }
  }
`;

export const FeeInputStyled: FC<any> = styled(Input)`
  width: 102px;
  height: 36px;
  border: 1px solid #D5DCE5;
  border-radius: 3px;
  background: #FFFFFF;
}
::placeholder,
::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  color: #909DA8;
}
`;

export const CardTitle = styled.span`
  margin-top: 20px;
  font-size: 12px;
  line-height: 15px;
  color: #102F54;
  text-transform: uppercase;
  font-weight: bold;
`;

export const CardRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16.5px;
  border-top: 1px solid #D5DCE5;
`;

export const CardRowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CardRowIcon = styled.div`
  max-width: 15px;
  max-height: 15px;
`;

export const CardContainer = styled.div`
  padding: 20px;
  background-color: #F5F8FB;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
