import { FC, useMemo, useState } from 'react';
import { Button, Col, Row, Table, Input, Space, Modal } from 'antd';

import LayoutV1 from '../../../layout/LayoutV1';
import {
  Subtitle,
  TextBody,
  Title,
} from '../../shared/typography/typography.styled';
import { ButtonDanger, ButtonDark } from '../../../../ui/Button';

import ItemsTable from '../orders-create/items-table/ItemsTable';
import { SummaryQuoteCard } from '../../quotes/quotes-create/summary-card/SummaryCard';

import InsuranceCard from '../../shared/insurance-card/InsuranceCard';
import Services from '../../quotes/quotes-create/sections/section-5/Section5';
import { TagStyled } from '../../shared/tag/tag,styled';
import { CardTopBlue } from '../../shared/card/card.styled';

import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';

import {
  summaryDeliveryTotalsMock,
  summaryLeaseTotalsMock,
} from '../../quotes/quotes.mock';
import AddressEditContent from '../../shared/address-edit-content/AddresEditContent';
import { insuraceMockData } from '../orders.mock';
import { Flex } from '../../shared/utils/utils.styled';

const dataDelivery = [
  {
    key: '1',
    address:
      'Broomhill Road, Adderlane Academy, Prudhoe, Northumberland, NE42 5HX',
  },
];

const OrdersEditPage: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deliveryColumns = useMemo(() => {
    return [
      {
        title: <TextBody>Address</TextBody>,
        key: 'address',
        dataIndex: 'address',
      },
      {
        title: '',
        key: '',
        dataIndex: '',
        width: 95,
        render: () => (
          <ButtonDark
            style={{ width: 95, fontSize: 14, padding: 7 }}
            onClick={showModal}
          >
            Edit
          </ButtonDark>
        ),
      },
    ];
  }, []);

  return (
    <>
      <LayoutV1>
        <Row>
          <Col span={1}>
            <BackIcon cursor="pointer" />
          </Col>
          <Col span={23}>
            <Row>
              <Col span={24} style={{ marginBottom: 50 }}>
                <Title>Edit Order</Title>
              </Col>

              <Col span={2}>
                <TagStyled color="#F0F4F7" style={{ width: 120 }}>
                  <TextBody>PO#49318386</TextBody>
                </TagStyled>
              </Col>
              <Col span={4}>
                <Flex justify="start" align="center">
                  <Button type="link">Edit</Button>
                </Flex>
              </Col>

              <Col span={24} style={{ marginTop: 50, marginBottom: 40 }}>
                <Subtitle>Delivery Address</Subtitle>
              </Col>
              <Col span={24}>
                <CardTopBlue bodyStyle={{ padding: 0 }}>
                  <Table
                    columns={deliveryColumns}
                    dataSource={dataDelivery}
                    pagination={false}
                  />
                </CardTopBlue>
              </Col>
              <Col span={8} style={{ marginTop: 20 }}>
                <ButtonDark style={{ width: 180, padding: 7, fontSize: 14 }}>
                  + Add delivery note
                </ButtonDark>
              </Col>

              <Col span={24} style={{ marginTop: 60, marginBottom: 30 }}>
                <Subtitle>Invoice Address</Subtitle>
              </Col>
              <Col span={24}>
                <CardTopBlue bodyStyle={{ padding: 0 }}>
                  <Table
                    columns={deliveryColumns}
                    dataSource={dataDelivery}
                    pagination={false}
                  />
                </CardTopBlue>
              </Col>
              <Col span={24} style={{ marginTop: 60, marginBottom: 30 }}>
                <Subtitle>Items</Subtitle>
              </Col>
              <Col span={24}>
                <ButtonDark style={{ width: 120, fontSize: 14, padding: 7 }}>
                  + Add Item
                </ButtonDark>
              </Col>
              <Col span={24} style={{ margin: '20px 0' }}>
                <ItemsTable />
              </Col>
              <Col span={24} style={{ margin: '20px 0' }}>
                <Space direction="vertical">
                  <TextBody strong>Delivery charge</TextBody>
                  <Space>
                    <Input disabled />
                    <ButtonDark style={{ padding: 5, fontSize: 14, width: 80 }}>
                      Edit
                    </ButtonDark>
                    <ButtonDanger
                      style={{ padding: 5, fontSize: 14, width: 80 }}
                    >
                      Remove
                    </ButtonDanger>
                  </Space>
                </Space>
              </Col>

              <Col span={24} style={{ marginTop: 60, marginBottom: 30 }}>
                <Subtitle>Services</Subtitle>
              </Col>

              <Col span={24}>
                <Services />
              </Col>

              <Col span={24} style={{ marginTop: 60 }}>
                <Row>
                  <Col span={12}>
                    <Subtitle>Order Summary</Subtitle>

                    <SummaryQuoteCard
                      deliveryTotals={summaryDeliveryTotalsMock}
                      leaseTotals={summaryLeaseTotalsMock}
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <InsuranceCard deliveryTotals={insuraceMockData} />
                  </Col>
                </Row>
              </Col>

              <Col span={24} style={{ marginTop: 40 }}>
                <ButtonDark style={{ padding: 5, fontSize: 14, width: 205 }}>
                  Save changes
                </ButtonDark>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutV1>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddressEditContent />
      </Modal>
    </>
  );
};

export default OrdersEditPage;
