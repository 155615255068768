import { FC, Key } from 'react';
import { Col, Row, Select, Form, Typography } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../../img/icons/filled-arrow-down.svg';
import { ReactComponent as Calendar } from '../../../../../img/icons/calendar.svg';
import {
  InputStyled,
  DatePickerStyled,
  FeeInputStyled,
  SubTitle,
  InputLabel,
  InputWrapper,
  StyledSelect,
  SelectWrapper,
} from '../styled/new-offer.styled';

const { Text } = Typography;
const { Option } = Select;

interface IOfferDetailsItem {
  key: Key;
  value: string | number;
}

interface IOfferDetailsProps {
  mockedData: Array<IOfferDetailsItem>;
}

const OfferDetailsSection: FC<IOfferDetailsProps> = ({ mockedData }) => {

  return (
    <Row
      style={{ marginBottom: '54px' }}
    >
      <Col
        span={8}
      >
        <SubTitle>1. Offer Details</SubTitle>
        <Form
          name="Offer Details"
        >
        <InputWrapper>
          <InputLabel>
            Offer Number
            <Text
              type="danger"
            >
              *
            </Text>
          </InputLabel>
          <Form.Item
              name="Оffer Number"
              rules={[{ required: true }]}
          >
            <InputStyled
              placeholder='123-456'
            />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
            <InputLabel>
                Start Date
              <Text
                type="danger"
              >
                *
              </Text>
            </InputLabel>
            <Form.Item
              name="Start Date"
              rules={[{ required: true }]}
            >
              <DatePickerStyled
                placeholder='Select a date'
                suffixIcon={<Calendar />}
              />
            </Form.Item>
        </InputWrapper>
        <InputWrapper>
            <InputLabel>
                End Date
                <Text
                  type="danger"
                >
                  *
                </Text>
            </InputLabel>
            <Form.Item
                name="End Date"
                rules={[{ required: true }]}
            >
              <DatePickerStyled
                placeholder='Select a date'
                suffixIcon={<Calendar />}
              />
            </Form.Item>
        </InputWrapper>
        <InputWrapper>
            <InputLabel>
              Platform Fee
              <Text
                type="danger"
              >
                *
              </Text>
            </InputLabel>
            <Form.Item
              name="Platform Fee"
              rules={[{ required: true }]}
            >
              <FeeInputStyled
                placeholder='%'
              />
            </Form.Item>
        </InputWrapper>
        <InputLabel>
        Currency
        <Text
          type="danger"
        >
          *
        </Text>
        </InputLabel>
        <SelectWrapper>
          <Form.Item
            name="currency"
            rules={[{ required: true }]}
            style={{ marginBottom: '0px' }}
          >
            <StyledSelect
              defaultValue="€"
              suffixIcon={<ArrowDown />}
            >
              {mockedData.map((item) => (
                <Option
                  style={{ lineHeight: 2, color: '#102F54' }}
                  value={item.value}
                  key={item.key}
                >
                  {item.value}
                </Option>
              ))}
            </StyledSelect>
          </Form.Item>
        </SelectWrapper>
        </Form>
      </Col>
    </Row>
  );
};

  export default OfferDetailsSection;
