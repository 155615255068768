import { useHistory } from 'react-router-dom';
import { useGetSupplierOrdersStatusQuery } from '../../../../api/services/supplier/ordersApi';
import ROUTER_CONFIG from '../../../../router/router-config';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';
import SupplierLayout from '../../layout/SupplierLayout';
import { OrdersOverviewTableRow } from '../../types';
import OrdersOverview from './OrdersOverview';

export default function OrdersOverviewPage() {
  const { push } = useHistory();

  const { data, error } = useGetSupplierOrdersStatusQuery('Quote');

  const tableData: Array<OrdersOverviewTableRow> = [
    {
      key: '1',
      dateSubmitted: formatDateDDMMMYYYY(new Date('21 nov 2021')),
      poNumber: '12318381',
      status: 'Placed',
      buyer: 'Coombe Wood School',
      paymentMethod: 'Supplier Credit',
      total: showPriceDigits(20928),
    },
    {
      key: '2',
      dateSubmitted: formatDateDDMMMYYYY(new Date('22 nov 2021')),
      poNumber: '49318386',
      status: 'Placed',
      buyer: 'Adderlane Academy',
      paymentMethod: 'Operating Lease',
      total: showPriceDigits(20928),
    },
  ];
  const onRowClickHandler = (orderId: string, paymentType: string) =>
    push(
      ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({ orderId, paymentType })
    );

  return (
    <SupplierLayout>
      <OrdersOverview
        tableData={tableData}
        onRowClickHandler={onRowClickHandler}
      />
    </SupplierLayout>
  );
}
