import { FC } from 'react';
import { Row, Col, Space, Button, Select } from 'antd';

import { Subtitle, TextBody } from '../typography/typography.styled';
import { CardStyled } from '../card/card.styled';
import { StatusCardTable } from './styled';

import { ReactComponent as ChevronDownIcon } from '../../../../img/icons/chevron-down.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';

import { useStatusCard } from '../../../../hooks/useStatusCard';
import { ORDER_STATUS } from '../../types';

interface IStatusCardProps {
  status: ORDER_STATUS;
  onChange?: (status: ORDER_STATUS) => void;
}

export const columns = [
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Admin',
    key: 'admin',
    dataIndex: 'admin',
  },
];

const dataSource = [
  {
    key: '1',
    date: '06/06/2021',
    status: 'Placed',
    admin: 'John Smith',
  },
  {
    key: '2',
    date: '06/06/2021',
    status: 'Accepted',
    admin: 'John Smith',
  },
  {
    key: '3',
    date: '06/06/2021',
    status: 'Out of delivery',
    admin: 'John Smith',
  },
];

const { Option } = Select;

const StatusCard: FC<IStatusCardProps> = ({ status, onChange }) => {
  const {
    isStatusEdit,
    isShowStatusHistoryOpen,
    onStatusUpdateClick,
    onShowStatusHistoryClick,
    onStatusEditClick,
  } = useStatusCard();

  return (
    <Row>
      <Col span={24}>
        <Subtitle>Status</Subtitle>
      </Col>
      <Col span={24} style={{ margin: '10px 0' }}>
        <CardStyled bodyStyle={{ padding: 20 }}>
          <Row>
            <Col span={8} style={{ display: 'flex' }}>
              <Space align="center" style={{}}>
                <TextBody type="secondary" strong>
                  Current Status:
                </TextBody>
                {isStatusEdit ? (
                  <Select
                    onChange={(value) => onChange && onChange(value)}
                    style={{ width: 120 }}
                    defaultValue={status}
                    suffixIcon={<ArrowDownIcon />}
                  >
                    {Object.values(ORDER_STATUS).map((_status) => (
                      <Option key={_status} value={_status}>
                        {_status}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <TextBody strong>{status}</TextBody>
                )}
              </Space>
            </Col>
            <Col span={2} style={{ textAlign: 'center' }}>
              {!isStatusEdit ? (
                <Button type="link" onClick={onStatusEditClick}>
                  Edit
                </Button>
              ) : (
                <Button type="link" onClick={onStatusUpdateClick}>
                  Update status
                </Button>
              )}
            </Col>
            <Col span={8} offset={6} style={{ textAlign: 'end' }}>
              <Button type="link" onClick={onShowStatusHistoryClick}>
                Show status history
                <ChevronDownIcon style={{ marginLeft: 5 }} />
              </Button>
            </Col>
          </Row>
          {isShowStatusHistoryOpen && (
            <StatusCardTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          )}
        </CardStyled>
      </Col>
    </Row>
  );
};

export default StatusCard;
