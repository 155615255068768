import { FC, useMemo } from 'react';
import { Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import {
  CustomStyledTable,
  LongSelectWrapper,
  StyledLink,
  StyledLongSelect,
} from '../../styled/new-offer.styled';
import { EligibleSuppliersTableRow } from '../../../../types';
import ROUTER_CONFIG from '../../../../../../router/router-config';
import { ReactComponent as DeleteIcon } from '../../../../../../img/icons/deleteIcon.svg';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/filled-arrow-down.svg';
import { useGetSuppliersListQuery } from '../../../../../../api/services/superadmin/suppliersApi';

const { Option } = Select;
interface IEligibleSuppliersTableProps {
  dataSource: Array<EligibleSuppliersTableRow>;
  isEligibleSuppliers?: boolean;
  onDelete?: (id: number) => void
}

const EligibleSuppliersTable: FC<IEligibleSuppliersTableProps> = ({
  dataSource,
  isEligibleSuppliers,
  onDelete
}) => {
  const {data: supplier} = useGetSuppliersListQuery();
  const { push } = useHistory();
  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_SUPPLIERS.getPath());

  const columnsItems: ColumnsType<EligibleSuppliersTableRow> = useMemo(() => [
    {
      title: 'Suppliers',
      dataIndex: 'suppliers',

      render: () => (
        <LongSelectWrapper>
          <StyledLongSelect
            defaultValue={supplier?.[0].name}
            suffixIcon={<ArrowDown />}
          >
            {supplier && (supplier.map((item) => (
              <Option
                style={{ lineHeight: 2, color: '#102F54' }}
                value={item.name}
                key={item._id}
              >
                {item.name}
              </Option>
            )))}
          </StyledLongSelect>
        </LongSelectWrapper>
      ),
    },
    {
      render: () => {
        return (
          <StyledLink onClick={() => onBack()}>
            View Supplier Details
          </StyledLink>
        );
      },
    },
    {
      render: (record: any) => {
        return (
          <DeleteIcon
            onClick={() => onDelete && onDelete(record.key)}
            style={{ cursor: 'pointer' }}
          />
        );
      },
    },
  ], [onBack, onDelete]);

  return (
    <CustomStyledTable
      dataSource={dataSource}
      columns={columnsItems}
      pagination={false}
      rowClassName={() => (isEligibleSuppliers ? 'blueBackground' : '')}
    />
  );
};

export default EligibleSuppliersTable;