import { FC } from 'react';
import { Col, Row } from 'antd';
import { BackWrapper, Title, TitleWrapper } from '../styled/new-offer.styled';
import { ReactComponent as BackIcon } from '../../../../../img/icons/backIcon.svg';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../../router/router-config';

const TitleComponent: FC = () => {
  const { push } = useHistory();
  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_OFFERS.getPath());

  return (
    <Row>
      <Col
        span={8}
      >
        <TitleWrapper>
          <BackWrapper onClick={onBack}>
            <BackIcon />
          </BackWrapper>
          <Title>Create New Offer</Title>
        </TitleWrapper>
      </Col>
    </Row>
  );
};

  export default TitleComponent;
