import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import AdminSideBar from './AdminSidebar/AdminSidebarPage';

interface AdminLayoutTypes {
  children: ReactNode
}

export default function AdminLayout({ children }: AdminLayoutTypes) {
  return (
    <AdminLayoutWrap>
      <AdminSideBar />
      {children}
    </AdminLayoutWrap>
  );
}

const AdminLayoutWrap = styled.div`
  display: flex;
`;