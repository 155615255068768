import styled from 'styled-components/macro';
import { Checkbox } from 'antd';

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
  margin-bottom: 30px;

  .ant-checkbox-inner {
    width: 11px;
    height: 11px;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-inner::after {
    width: 4px;
    height: 5px;
  }
`;
