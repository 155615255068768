import { FC, memo } from 'react';
import { Row, Col, Form, Select, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import {
  FormItemLabel,
  Subtitle,
  TextBody,
} from '../typography/typography.styled';

import { ReactComponent as ArrowDownIcon } from '../../../../img/icons/arrowDown.svg';
import { IFormComponentProps } from '../../types';
import { InputStyled } from '../inputs/input-styled/InputStyled';

const { Option } = Select;

interface IOrganisationTypeFormProps extends IFormComponentProps {
  isOrganisationTypeSelected: boolean;
}

const OrganisationTypeForm: FC<IOrganisationTypeFormProps> = ({
  form,
  isOrganisationTypeSelected,
}) => {
  return (
    <>
      <Row style={{ marginTop: 40, marginBottom: 30 }}>
        <Col span={8}>
          <Subtitle>1. Select a School or a Trust & Recipient</Subtitle>
        </Col>
      </Row>
      <Form
        name="orgTypeForm"
        layout="vertical"
        form={form}
        wrapperCol={{ span: 8 }}
      >
        <Form.Item
          name="orgType"
          label={<FormItemLabel>School or Trust</FormItemLabel>}
          rules={[{ required: true }]}
        >
          <Select placeholder="(Select)" suffixIcon={<ArrowDownIcon />}>
            <Option value="School">School</Option>
            <Option value="Trust">Trust</Option>
          </Select>
        </Form.Item>

        <Col span={8}>
          <Divider>
            <TextBody type="secondary">Or</TextBody>
          </Divider>
        </Col>

        <Col span={8} style={{ marginBottom: 20 }}>
          <TextBody>
            Create an order for a school or a trust without an account
          </TextBody>
        </Col>

        <Form.Item
          name="schoolOrTrustName"
          label={<FormItemLabel>Find School or Trust</FormItemLabel>}
        >
          <InputStyled
            placeholder="School/Trust name"
            suffix={<SearchOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="recipientName"
          label={<FormItemLabel>Recipient Name</FormItemLabel>}
          rules={[{ required: true }]}
        >
          <InputStyled
            disabled={!isOrganisationTypeSelected}
            placeholder="Please select a school or a trust first"
          />
        </Form.Item>

        <Form.Item
          name="recipientEmail"
          label={<FormItemLabel>Recipient Email</FormItemLabel>}
          rules={[{ required: true }]}
        >
          <InputStyled
            disabled={!isOrganisationTypeSelected}
            placeholder="Please select a school or a trust first"
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(OrganisationTypeForm);
