import styled from 'styled-components/macro';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { QuotesTableRow } from '../../types';
import { ButtonLight } from '../../../../ui/Button';
import { TableStyled } from '../../../superadmin/shared/table/TableStyled';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';

interface QuotesTableProps {
  tableData: Array<QuotesTableRow>;
  onViewDocClickHandler: (id: string) => void;
}

export default function QuotesTable({
  tableData,
  onViewDocClickHandler,
}: QuotesTableProps) {
  const tableConfig: ColumnsType<QuotesTableRow> = [
    {
      title: (
        <>
          Date
          <ArrowDown style={{
            marginLeft: '8px',
            marginBottom: '1px',
          }}
          />
        </>
      ),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (_v, r) => dayjs(r.createdAt)
        .format('DD/MM/YYYY'),
      sorter: (a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Reference',
      key: 'reference',
      dataIndex: 'reference',
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      width: '25%',
      align: 'right',
    },
    {
      title: '',
      key: 'linkToDoc',
      dataIndex: 'linkToDoc',
      render: (_v, r) => <ViewDocButton onClick={() => onViewDocClickHandler(r.key)}>View Document</ViewDocButton>,
      width: '200px',
    },
  ];

  return (
    <CustomTableStyled
      columns={tableConfig}
      dataSource={tableData}
    />
  );
}

const ViewDocButton = styled(ButtonLight)`
  width: 156px;
  height: 33px;
  font-size: 14px;
`;

const CustomTableStyled = styled(TableStyled)`
  .ant-table tr {
    font-weight: 500;
  }

  .ant-table-thead th {
    font-weight: 600;
  }
`;
