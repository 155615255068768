import { FC } from 'react';
import { useGetSupplierPaymentsQuery } from '../../../../api/services/supplier/paymentsApi';
import SupplierLayout from '../../layout/SupplierLayout';
import PaymentsOverview from './PaymentsOverview';
import { useGetSupplierPaymentsSummaryQuery } from '../../../../api/services/supplier/paymentsApi';

const PaymentsOverviewPage: FC = () => {
  const { data: payments } = useGetSupplierPaymentsQuery();
  const { data: summary } = useGetSupplierPaymentsSummaryQuery();

  return (
    <SupplierLayout>
      {payments && summary && <PaymentsOverview
        tableData={payments!.items}
        summaryData={summary}
      />}
    </SupplierLayout>
  );
}

export default PaymentsOverviewPage;

