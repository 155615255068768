import styled from 'styled-components';
import { Table, TableProps } from 'antd';
import { FC } from 'react';

export const TableStyled: FC<TableProps<any>> = styled(Table)`
  .ant-table-tbody > tr > td {
    border-bottom: 2px solid #d5dce5;
  }

  .ant-table-thead th {
    color: #6b7a87;
    font-weight: 500;
    font-size: 13px;
    border-bottom: 2px solid #d5dce5;
    border-top: 2px solid #2682de;
  }

  .ant-table tr {
    color: #102f54;
    font-size: 14px;
    font-weight: 600;
  }

  .ant-table {
    border-right: 2px solid #d5dce5 !important;
    border-left: 2px solid #d5dce5 !important;
  }

  .ant-table-thead
    tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: relative;
  }

  .ant-checkbox-inner {
    width: 11px !important;
    height: 11px !important;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox-inner::after {
    width: 4px;
    height: 5px;
  }

  .ant-table-column-sorter {
    display: none;
  }
`;
