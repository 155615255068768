import styled from 'styled-components';
import SelectInput from '../../../../ui/forms/SelectInput';
import { IProduct } from '../../../buyer/types';
import { ButtonDark } from '../../../../ui/Button';
import { FC, useState } from 'react';
import RelatedProductRowItem from './RelatedProductRowItem';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';

interface IProps {
  formikInstance: any;
  productsList: Array<IProduct>;
  isEditMode: boolean;
}

const getSelectOptions = (
  products: Array<IProduct>,
  productsList: Array<IProduct>
): string[] => {
  return productsList
    .map((product) => product.name)
    .filter((option) => !products.find((product) => product.name === option));
};

const ProductRelelatedProducts: FC<IProps> = ({
  formikInstance,
  productsList,
  isEditMode,
}) => {
  const optionList = productsList.map((product) => product.name);
  const [selectedRelatedProduct, setSelectedReletedProductta] = useState(
    optionList[0]
  );

  const relatedProducts = formikInstance.values
    .relatedProducts as Array<IProduct>;

  const onSelectNewRelatedProduct = (_field: string, value: string) => {
    setSelectedReletedProductta(value);
  };

  const removeRelatedProduct = (id: string) => {
    formikInstance.setFieldValue(
      'relatedProducts',
      relatedProducts.filter((product) => product._id !== id)
    );
  };

  const AddNewRelatedProduct = () => {
    const newRelatedProduct = productsList.find(
      (product) => product.name === selectedRelatedProduct
    );
    formikInstance.setFieldValue('relatedProducts', [
      ...relatedProducts,
      newRelatedProduct,
    ]);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <HeaderCell>Offer (Product)</HeaderCell>
          <HeaderCell>Product Headlines</HeaderCell>
          <HeaderCell></HeaderCell>
        </TableHeader>
        {relatedProducts.map((product) => (
          <RelatedProductRowItem
            key={product._id}
            product={product}
            onRemoveItem={isEditMode ? removeRelatedProduct : undefined}
          />
        ))}
        {isEditMode &&
          getSelectOptions(relatedProducts, productsList).length > 0 && (
            <Container>
              <StyledSelectInput
                name="selectNewAccessory"
                value={selectedRelatedProduct}
                setFieldValue={onSelectNewRelatedProduct}
                initialData={getSelectOptions(relatedProducts, productsList)}
                suffixIcon={<ArrowDown style={{ color: '#909DA8' }} />}
              />
              <ButtonDark onClick={AddNewRelatedProduct}>Add</ButtonDark>
            </Container>
          )}
      </Table>
    </>
  );
};

const Table = styled.div`
  border: 1px solid #d5dce5;
  border-top: 2px solid #2682de;
  padding-bottom: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 40px;
  background: #f9f9f9;
`;

const HeaderCell = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #6b7a87;
  flex: 1;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 17px 20px;
  border-bottom: 1px solid #d5dce5;
  margin: 0 20px;

  display: flex;
  gap: 30px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 400px;

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

export default ProductRelelatedProducts;
