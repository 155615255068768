import { superadminApi } from "./superadminApi";
import { ISupplierOffersResp } from "../../../components/supplier/types";

const supplierOffersApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getJoinedList: build.query<ISupplierOffersResp, void>({
      query: () => 'supplier/offers/active',
    }),
  }),
  overrideExisting: false,
});

export const { useGetJoinedListQuery } = supplierOffersApi;