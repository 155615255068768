import styled from 'styled-components/macro';
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { StyledTitleH2, TitleH2 } from '../../styled/text';
import EligibleControls from './eligible-table/EligibleControls';
import EligibleTableData from './eligible-table/EligibleTableData';
import { EligibleNodeTableRow, EligibleSuppliersTableRow, JoinedSuppliersTableRow } from '../../types';

interface EligibleSuppliersTableProps {
  tableData: Array<EligibleSuppliersTableRow> | Array<JoinedSuppliersTableRow> | Array<EligibleNodeTableRow>;
  setSelectedRows: (selectedIds: Array<string>) => void;
  selectedRows: Array<string>;
  onDeleteRowClickHandler: () => void;
  tableName: string;
  addButtonName: string;
  tableConfig: ColumnsType<EligibleSuppliersTableRow> | ColumnsType<JoinedSuppliersTableRow> | ColumnsType<EligibleNodeTableRow>;
  onAddClickHandler: () => void;
  isSwitch?: boolean;
}

const EligibleTable: React.FC<EligibleSuppliersTableProps> = ({
  tableData,
  selectedRows,
  setSelectedRows,
  onDeleteRowClickHandler,
  tableName,
  addButtonName,
  tableConfig,
  onAddClickHandler,
  isSwitch = true,
}) => {
  const [isShowingTable, setShowTable] = useState<boolean>(false);

  useEffect(() => {
    if (!isShowingTable) {
      setSelectedRows([]);
    }
  }, [isShowingTable, setSelectedRows]);

  return (
    <Container>
      {isSwitch ? <TitleH2>{tableName}</TitleH2> : <StyledTitleH2>{tableName}</StyledTitleH2>}
      <EligibleControls
        onDeleteRowClickHandler={onDeleteRowClickHandler}
        onAddClickHandler={onAddClickHandler}
        setShowTable={setShowTable}
        isShowingTable={isShowingTable}
        addButtonName={addButtonName}
        isSwitch={isSwitch}
      />
      <EligibleTableData
        isShowingTable={isShowingTable}
        setShowTable={setShowTable}
        tableData={tableData}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        tableConfig={tableConfig}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 56px
`;

export default EligibleTable;
