import styled from 'styled-components/macro';
import AddAddressModal from '../components/AddAddressModal'
import ModalInPortal from "../../../../../../ui/Modal/ModalPortal";
import { Dropdown, Menu } from 'antd';
import { useMemo } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDownBlue.svg';

const AddressTableControl = () => {
  const InviteAddOfferModal = ModalInPortal();
  const onAddUserClickHandler = () => InviteAddOfferModal.onShow();
  const onSubmitAddOfferForm = (values: any) => console.log(values);

  const dropdownContent = useMemo(() => (
    <Menu>
    <Menu.Item>
        Remove Address
    </Menu.Item>
    </Menu>
), []);

    return(
        <Container>
                  <InviteButton onClick={onAddUserClickHandler}>+ Add New Address</InviteButton>
                  {InviteAddOfferModal.ModalRender && (
                  <InviteAddOfferModal.ModalRender>
                    <AddAddressModal
                      onClose={InviteAddOfferModal.onClose}
                      onSubmit={onSubmitAddOfferForm}
                      />
                  </InviteAddOfferModal.ModalRender>
                  )}
                    <Dropdown overlay={dropdownContent}>
                        <InviteButton onClick={(e) => e.preventDefault()}>
                        Actions
                        {' '}
                        <ArrowDown style={{ marginBottom: '2px' }} />
                        </InviteButton>
                    </Dropdown>
        </Container>
    )
}
const Container = styled.div`
  display: flex;
  gap: 30px;
  justify-content: end;
  margin-bottom: 10px;
  padding-right: 10px;
`;

const InviteButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export default AddressTableControl