import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../../../router/router-config';
import styled from 'styled-components/macro';
import BackIconImg from '../../../../../../img/icons/backIcon.svg';

const GoBackContainer = () => {
    const { push } = useHistory();
    const onBackClickHandler = () => push(ROUTER_CONFIG.SUPER_ADMIN_BUYERS.getPath());

    return(
        <GoBack onClick={() => onBackClickHandler()}>
            <Info>
              <BackIcon />
              <Title>Demo Buyer A</Title>
            </Info>
          </GoBack>
    )
}

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
`;

const BackIcon = styled.img.attrs({
    src: BackIconImg,
  })`
  margin-right: 20px;
  margin-left: -54px;
  `;
  
  
  const Info = styled.div`
    display: -webkit-box;
  `;

const GoBack = styled.div`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 54px;
  cursor: pointer;
`;

export default GoBackContainer