import { FC, useMemo } from 'react';
import { CustomStyledTable, LongSelectWrapper, StyledLink, StyledLongSelect } from '../../styled/new-offer.styled';
import { EligibleNodesTableRow } from '../../../../types';
import { ReactComponent as DeleteIcon } from '../../../../../../img/icons/deleteIcon.svg';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/filled-arrow-down.svg';
import ROUTER_CONFIG from '../../../../../../router/router-config';
import { Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

const { Option } = Select;

interface IEligibleNodesTableRowProps {
  dataSource: Array<EligibleNodesTableRow>;
  isEligibleNodes?: boolean;
  onDelete?: (id: number) => void
}

const EligibleNodesTable: FC<IEligibleNodesTableRowProps> = ({
  dataSource,
  isEligibleNodes,
  onDelete
}) => {
  const { push } = useHistory();
  const onNodeDetails = () => push(ROUTER_CONFIG.SUPER_ADMIN_OFFERS_HOME.getPath());

  const columnsItems: ColumnsType<EligibleNodesTableRow> = useMemo(() => [
    {
      title: 'Node',
      dataIndex: 'node',

      render: () => (
        <LongSelectWrapper>
          <StyledLongSelect
            defaultValue="Intel"
            suffixIcon={<ArrowDown />}
          >
            <Option
              value="Intel"
              style={{
                lineHeight: 2,
                color: '#102F54',
              }}
            >
              Intel
            </Option>
          </StyledLongSelect>
        </LongSelectWrapper>
      ),
    },
    {
      render: () => {
        return (
        <StyledLink
          onClick={() => onNodeDetails()}
        >
          View Node Details
        </StyledLink>
        )
      },
    },
    {
      render: (record :any) => {
        return (
          <DeleteIcon
            onClick={() => onDelete && onDelete(record.key)}
            style={{ cursor: 'pointer' }}
          />
        );
      },
    },
  ], [onNodeDetails, onDelete]);

  return (
    <CustomStyledTable
      dataSource={dataSource}
      columns={columnsItems}
      pagination={false}
      rowClassName={() => (isEligibleNodes ? 'blueBackground' : '')}
    />
  );
};

export default EligibleNodesTable;
