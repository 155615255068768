import { FC } from 'react';
import { Col, Row, Space, Form } from 'antd';

import LayoutV1 from '../../../layout/LayoutV1';
import {
  Title,
  Subtitle,
  TextBody,
} from '../../shared/typography/typography.styled';
import { StyledButtonDark } from '../quotes-list/styled/quotes.styled';
import { SummaryQuoteCard } from './summary-card/SummaryCard';
import OverviewCard from '../../shared/overview-card/OverviewCard';
import { Flex } from '../../shared/utils/utils.styled';

import OrganisationTypeForm from '../../shared/forms/OrganisationTypeForm';
import ResselerForm from '../../shared/forms/ResellerForm';
import OrderTypeForm from '../../shared/forms/OrderTypeForm';

import Section4 from './sections/section-4/Section4';
import Section5 from './sections/section-5/Section5';

import InsuranceForm from '../../shared/forms/InsuraceForm';
import ExpirationForm from '../../shared/forms/ExpirationForm';
import { useQuotesCreate } from '../../../../hooks/superadmin/useQuoteCreate';
import { ReactComponent as BackIcon } from '../../../../img/icons/backIcon.svg';

import {
  buyerOverviewMock,
  lessorOverviewMock,
  resellerOverviewMock,
} from './quotes.create.mock';
import {
  summaryDeliveryTotalsMock,
  summaryLeaseTotalsMock,
} from '../quotes.mock';

export const QuotesCreatePage: FC = () => {
  const { orgTypeForm, resellerForm } = useQuotesCreate();

  const orgTypeWatch = Form.useWatch('orgType', orgTypeForm);

  return (
    <LayoutV1>
      <Row>
        <Col span={1}>
          <Flex align="center" justify="flex-start" style={{ marginTop: 5 }}>
            <BackIcon cursor="pointer" />
          </Flex>
        </Col>
        <Col span={23}>
          <Row>
            <Col span={8}>
              <Title>Build New Quote</Title>
            </Col>
          </Row>

          <Form.Provider
            onFormFinish={(name, { values, forms }) => {
              const schoolOrTrustValues = forms.schoolOrTrustForm.getFieldsValue();
              const resselerValues = forms.resselerForm.getFieldsValue();
              const orderTypeValues = forms.orderTypeForm.getFieldsValue();
              const items = forms.itemsForm.getFieldsValue();
              const services = forms.servicesForm.getFieldsValue();
              const insurance = forms.insuranceForm.getFieldsValue();
              const expiration = forms.expirationForm.getFieldsValue();
            }}
          >
            <OrganisationTypeForm
              form={orgTypeForm}
              isOrganisationTypeSelected={!!orgTypeWatch}
            />

            <Row>
              <Col span={8}>
                <Subtitle>2. Select a Reseller</Subtitle>
              </Col>
            </Row>

            <ResselerForm
              form={resellerForm}
              isOrganisationTypeSelected={!!orgTypeForm}
            />

            <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Space direction="vertical">
                  <Subtitle>3. Order Type</Subtitle>
                  <TextBody>
                    Please select if the quote is for an lease or an outright
                    purchase?
                  </TextBody>
                </Space>
              </Col>
            </Row>

            <OrderTypeForm />

            <Section4 />

            <Row>
              <Col span={8}>
                <Subtitle>5. Add Services</Subtitle>
              </Col>
            </Row>

            <Section5 />

            <Row style={{ marginTop: 80, marginBottom: 15 }}>
              <Col span={8}>
                <Subtitle>6. Insurance</Subtitle>
              </Col>
            </Row>

            <InsuranceForm />

            <Row style={{ marginBottom: 15, marginTop: 60 }}>
              <Col span={8}>
                <Space direction="vertical">
                  <Subtitle>7. Quote Expiration Date</Subtitle>
                  <TextBody>
                    Once this date is passed the quote is void and can’t be used
                    to place an order
                  </TextBody>
                </Space>
              </Col>
            </Row>

            <ExpirationForm />

            <Row style={{ marginTop: 40 }}>
              <Col span={12}>
                <Subtitle>Quote Summary</Subtitle>

                <SummaryQuoteCard
                  deliveryTotals={summaryDeliveryTotalsMock}
                  leaseTotals={summaryLeaseTotalsMock}
                />
              </Col>
              <Col span={8} offset={4}>
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: '100%' }}
                >
                  <OverviewCard
                    title="BUYER OVERVIEW"
                    data={buyerOverviewMock}
                  />
                  <OverviewCard
                    title="RESELLER OVERVIEW"
                    data={resellerOverviewMock}
                  />
                  <OverviewCard
                    title="LESSOR OVERVIEW"
                    data={lessorOverviewMock}
                  />
                </Space>
              </Col>
            </Row>

            <Form name="basicForm">
              <Form.Item>
                <StyledButtonDark type="submit" style={{ marginTop: 200 }}>
                  Generate Quote
                </StyledButtonDark>
              </Form.Item>
            </Form>
          </Form.Provider>
        </Col>
      </Row>
    </LayoutV1>
  );
};
