import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useGetProductsListQuery, useGetSearchProductQuery } from '../../../api/services/superadmin/productApi';
import ROUTER_CONFIG from '../../../router/router-config';
import { ProductTypesEnum } from '../../buyer/types';
import LayoutV1 from '../../layout/LayoutV1';
import Products from './Products';

const ProductsPage: FC = () => {
  const { push } = useHistory();
  const [searchDevicesValue, setSearchDevicesValue] = useState<string>('');
  const [searchAccessoriesValue, setSearchAccessoriesValue] = useState<string>('');
  const [submittedValue, setSubmittedValue] = useState<{ name: string }>({
    name: '',
  });

  const { data: productsData } = useGetProductsListQuery();
  const { data: searchProducts } = useGetSearchProductQuery(submittedValue);

  const [productType, setProductType] = useState<ProductTypesEnum>(
    ProductTypesEnum.DEVICE,
  );
  const onSubmitSearchDevicesValue = () => {
    setSubmittedValue({ name: searchDevicesValue });
  };

  const onSubmitSearchAccessoriesValue = () => {
    setSubmittedValue({ name: searchAccessoriesValue });
  };

  const onCreateProduct = () => push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCT_CREATE.getPath());

  const onDeleteProducts = useCallback(
    () => console.log('delete products'),
    [],
  );

  const products = useMemo(() => {
    if (!searchDevicesValue && !searchAccessoriesValue) {
      setSubmittedValue({ name: '' });
    }
    if (submittedValue.name) {
      return searchProducts;
    }
    return productsData;
  }, [searchProducts, productsData, searchDevicesValue, searchAccessoriesValue, submittedValue.name]);

  return (
    <LayoutV1>
      {products && (
        <Products
          products={
            products?.filter((product) => product.category === productType)
            || []
          }
          onCreateProduct={onCreateProduct}
          onDeleteProducts={onDeleteProducts}
          setProductType={setProductType}
          searchDevicesValue={searchDevicesValue}
          searchAccessoriesValue={searchAccessoriesValue}
          setSearchDevicesValue={setSearchDevicesValue}
          setSearchAccessoriesValue={setSearchAccessoriesValue}
          onSubmitSearchDevicesValue={onSubmitSearchDevicesValue}
          onSubmitSearchAccessoriesValue={onSubmitSearchAccessoriesValue}
        />
      )}
    </LayoutV1>
  );
};

export default ProductsPage;
