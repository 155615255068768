import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React from 'react';
import { FormConfig, renderFormConfig } from '../../../../../../utils/form';
import { AddOfferForm } from '../../types';
import CloseIcon from '../../../../../../img/close.svg';
import { addOfferForm } from '../../offerGroup-schemas';
import CustomSelectInput from '../../../../../../ui/forms/CustomSelectInput';
import {
  CloseButton,
  ContainerForPopover,
  InviteButton,
  LinkAfterButton,
  StyledButtonDark,
  Text,
  Title,
} from '../styled-offerGropDetails/popover.styled';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';
import { useGetOfferListQuery } from '../../../../../../api/services/superadmin/offersApi';
import { Typography } from 'antd';

const StyledSelect = styled(CustomSelectInput)`
  width: 100%;
  max-width: 425px;

  .ant-select-selector {
    height: 36px !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

interface InviteAddOfferModalContentProps {
  onClose: () => void;
  onSubmit: (values: AddOfferForm) => void;
}

const InviteAddOfferModalContent: React.FC<InviteAddOfferModalContentProps> = ({ onClose, onSubmit }) => {
  const {data: offers} = useGetOfferListQuery();

  const SELECTED_DATA = offers?.map((item: any) => (
    <Typography key={item.offerNumber}>
      {item.offerNumber}
    </Typography>
    ))

  const formConfig: FormConfig = [
    {
      title: 'Offer Number',
      name: 'offerNumber',
      component: StyledSelect,
      props: {
        initialData: SELECTED_DATA,
        suffixIcon: <ArrowDown />,
        dropdownStyle: {
          zIndex: 200000,
          position: 'fixed',
        },
      },
      isRequired: true,
    },
  ];

  const formik = useFormik({
    initialValues: {
      numberOffer: '006-007',
    },
    validationSchema: addOfferForm,
    onSubmit: (values :any) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <ContainerForPopover onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>Add Offer</Title>
      {renderFormConfig(formConfig, formik)}
      <StyledButtonDark type="submit">Add Offer</StyledButtonDark>
      <LinkAfterButton>
        <Text>Or</Text>
        <InviteButton>Create new Offer</InviteButton>
      </LinkAfterButton>
    </ContainerForPopover>
  );
};


export default InviteAddOfferModalContent;
