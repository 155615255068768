import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ROUTER_CONFIG from '../../../router/router-config';
import { ButtonDark } from '../../../ui/Button';
import { MainHeader } from '../../../ui/Headers';
import {
  IProduct,
  ProductTypesEnum,
  SUBCATEGORY_TYPES_ENUM,
} from '../../buyer/types';
import { CREATE_PRODUCT_STEPS } from '../types';
import CreateProductSteps from './create-steps/CreateProductSteps';
import ProductGalleryForm from './create-steps/ProdictGalleryForm';
import ProductAccessories from './create-steps/ProductAccessoriesForm';
import ProductBasicInfoForm from './create-steps/ProductBasicInfoForm';
import ProductOverview from './create-steps/ProductOverviewForm';
import ProductRelatedFiles from './create-steps/ProductRelatedFilesForm';
import ProductRelelatedProducts from './create-steps/ProductRelatedProductsForm';
import ProductTechSpecs from './create-steps/ProductTechSpecsForm';
import { productStepsSchema } from './product-schemas';
import { ReactComponent as BackIcon } from '../../../img/icons/backIcon.svg';

interface IProps {
  onFormSubmit: (
    formData: Omit<
      IProduct,
      '_id' | 'operatingLeasePrice' | 'outrightPurchasePrice'
    >
  ) => void;
  productsList: Array<IProduct>;
  isLoading: boolean;
}

const ProductCreate: FC<IProps> = ({
  productsList,
  onFormSubmit,
  isLoading,
}) => {
  const { push } = useHistory();

  const [currentStep, setCurrentStep] = useState<CREATE_PRODUCT_STEPS>(
    CREATE_PRODUCT_STEPS.BASIC_INFORMATION
  );

  const [completedSteps, setCompletedSteps] = useState<
    Array<CREATE_PRODUCT_STEPS>
  >([]);

  const formik = useFormik({
    initialValues: {
      SKU: '',
      name: '',
      headlines: '',
      description: '',
      category: ProductTypesEnum.DEVICE,
      manufacturer: '',
      defaultVatRate: 0,
      insuranceAvailable: false,
      overview: '',
      accessories: [],
      techSpecs: {},
      subcategory: SUBCATEGORY_TYPES_ENUM.NOTEBOOK,
      relatedProducts: [],
      otherPhotosUrl: [],
      relatedFilesUrl: [],
      attributes: [],
      imageUrl: '',
    },
    validationSchema: productStepsSchema[currentStep],
    onSubmit: (values) => onFormSubmit(values),
    isInitialValid: false,
  });

  const onBack = () => push(ROUTER_CONFIG.SUPER_ADMIN_PRODUCTS.getPath());

  const onContinue = () => {
    switch (currentStep) {
      case CREATE_PRODUCT_STEPS.BASIC_INFORMATION: {
        setCompletedSteps([CREATE_PRODUCT_STEPS.BASIC_INFORMATION]);
        setCurrentStep(CREATE_PRODUCT_STEPS.ATTRIBUTES);
        break;
      }
      case CREATE_PRODUCT_STEPS.ATTRIBUTES: {
        setCompletedSteps([
          CREATE_PRODUCT_STEPS.BASIC_INFORMATION,
          CREATE_PRODUCT_STEPS.ATTRIBUTES,
        ]);
        setCurrentStep(CREATE_PRODUCT_STEPS.OVERVIEW);
        break;
      }
      case CREATE_PRODUCT_STEPS.OVERVIEW: {
        setCompletedSteps([
          CREATE_PRODUCT_STEPS.BASIC_INFORMATION,
          CREATE_PRODUCT_STEPS.ATTRIBUTES,
          CREATE_PRODUCT_STEPS.OVERVIEW,
        ]);
        if (formik.values.category === ProductTypesEnum.DEVICE) {
          setCurrentStep(CREATE_PRODUCT_STEPS.ACCESSORIES);
        } else {
          setCurrentStep(CREATE_PRODUCT_STEPS.RELATED_PRODUCTS);
        }
        break;
      }
      case CREATE_PRODUCT_STEPS.ACCESSORIES: {
        setCompletedSteps([
          CREATE_PRODUCT_STEPS.BASIC_INFORMATION,
          CREATE_PRODUCT_STEPS.ATTRIBUTES,
          CREATE_PRODUCT_STEPS.OVERVIEW,
          CREATE_PRODUCT_STEPS.ACCESSORIES,
        ]);
        setCurrentStep(CREATE_PRODUCT_STEPS.RELATED_PRODUCTS);
        break;
      }
      case CREATE_PRODUCT_STEPS.RELATED_PRODUCTS: {
        setCompletedSteps([
          CREATE_PRODUCT_STEPS.BASIC_INFORMATION,
          CREATE_PRODUCT_STEPS.ATTRIBUTES,
          CREATE_PRODUCT_STEPS.OVERVIEW,
          CREATE_PRODUCT_STEPS.ACCESSORIES,
          CREATE_PRODUCT_STEPS.RELATED_PRODUCTS,
        ]);
        setCurrentStep(CREATE_PRODUCT_STEPS.RELATED_FILES);
        break;
      }
      case CREATE_PRODUCT_STEPS.RELATED_FILES: {
        setCompletedSteps([
          CREATE_PRODUCT_STEPS.BASIC_INFORMATION,
          CREATE_PRODUCT_STEPS.ATTRIBUTES,
          CREATE_PRODUCT_STEPS.OVERVIEW,
          CREATE_PRODUCT_STEPS.ACCESSORIES,
          CREATE_PRODUCT_STEPS.RELATED_PRODUCTS,
          CREATE_PRODUCT_STEPS.RELATED_FILES,
        ]);
        setCurrentStep(CREATE_PRODUCT_STEPS.GALLERY);
        break;
      }
      case CREATE_PRODUCT_STEPS.GALLERY: {
        formik.handleSubmit();
        break;
      }
    }
  };

  return (
    <Wrapper>
      <Header>
        <StyledMainHeader>
          <div onClick={onBack} style={{ cursor: 'pointer', marginRight: 15 }}>
            <BackIcon />
          </div>
          <>New Product</>
        </StyledMainHeader>
      </Header>
      <CreateProductSteps
        currentStep={currentStep}
        onStepClick={setCurrentStep}
        completedSteps={completedSteps}
        productType={formik.values.category}
      />
      <StepsContainer>
        <StepsContent>
          {currentStep === CREATE_PRODUCT_STEPS.BASIC_INFORMATION && (
            <ProductBasicInfoForm formikInstance={formik} isEditMode />
          )}
          {currentStep === CREATE_PRODUCT_STEPS.ATTRIBUTES && (
            <ProductTechSpecs formikInstance={formik} isEditMode />
          )}
          {currentStep === CREATE_PRODUCT_STEPS.OVERVIEW && (
            <ProductOverview formikInstance={formik} isEditMode />
          )}
          {currentStep === CREATE_PRODUCT_STEPS.ACCESSORIES && (
            <ProductAccessories
              formikInstance={formik}
              isEditMode
              accessoriesList={productsList.filter(
                (product) => product.category === ProductTypesEnum.ACCESSORY
              )}
            />
          )}
          {currentStep === CREATE_PRODUCT_STEPS.RELATED_PRODUCTS && (
            <ProductRelelatedProducts
              formikInstance={formik}
              isEditMode
              productsList={productsList.filter(
                (product) => product.category === ProductTypesEnum.DEVICE
              )}
            />
          )}
          {currentStep === CREATE_PRODUCT_STEPS.RELATED_FILES && (
            <ProductRelatedFiles formikInstance={formik} isEditMode />
          )}
          {currentStep === CREATE_PRODUCT_STEPS.GALLERY && (
            <ProductGalleryForm formikInstance={formik} isEditMode />
          )}
        </StepsContent>
      </StepsContainer>
      {!isLoading ? (
        <StyledButtonDark disabled={!formik.isValid} onClick={onContinue}>
          {currentStep !== CREATE_PRODUCT_STEPS.GALLERY
            ? 'Continue'
            : 'Save Product'}
        </StyledButtonDark>
      ) : (
        <StyledButtonDark disabled type="submit">
          ...Saving
        </StyledButtonDark>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.main`
  box-sizing: border-box;
  flex-grow: 1;
`;

const StepsContainer = styled.div`
  display: flex;
  gap: 56px;
`;

const StepsContent = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMainHeader = styled(MainHeader)`
  display: flex;
  font-size: 24px;
  margin-bottom: 36px;
`;

const StyledButton = styled(ButtonDark)`
  padding: 13px 60px;
  margin-top: 30px;
`;

const StyledButtonDark = styled(ButtonDark)`
  width: 220px;
  padding: 13px;
  border-radius: 3px;
  margin-top: 15px;

  &:disabled {
    background: #f5f5f5;
    color: #909da8;
  }
`;

export default ProductCreate;
