import styled from 'styled-components';
import SelectInput from '../../../../ui/forms/SelectInput';
import { IAccessory } from '../../../buyer/types';
import { ButtonDark } from '../../../../ui/Button';
import AccessoryRowItem from './AccessoryRowItem';
import { FC, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../img/icons/arrowDown.svg';

interface IProps {
  formikInstance: any;
  accessoriesList: Array<IAccessory>;
  isEditMode: boolean;
}

const getSelectOptions = (
  accessories: Array<IAccessory>,
  accessoriesList: Array<IAccessory>
): string[] => {
  return accessoriesList
    .map((acc) => acc.name)
    .filter((option) => !accessories.find((acc) => acc.name === option));
};

const ProductAccessories: FC<IProps> = ({
  formikInstance,
  accessoriesList,
  isEditMode,
}) => {
  const optionList = accessoriesList.map((acc) => acc.name);
  const [selectedNewAccesory, setSelectedNewAccesory] = useState(optionList[0]);

  const accessories = formikInstance.values.accessories as Array<IAccessory>;

  const onSelectNewAccessory = (_field: string, value: string) => {
    setSelectedNewAccesory(value);
  };

  const removeAccesory = (id: string) => {
    formikInstance.setFieldValue(
      'accessories',
      accessories.filter((acc) => acc._id !== id)
    );
  };

  const AddNewAccessory = () => {
    const newAccessory = accessoriesList.find(
      (acc) => acc.name === selectedNewAccesory
    );
    formikInstance.setFieldValue('accessories', [...accessories, newAccessory]);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <HeaderCell>Offer (Product)</HeaderCell>
          <HeaderCell>Product Description</HeaderCell>
          <HeaderCell></HeaderCell>
        </TableHeader>
        {accessories.map((accessory) => (
          <AccessoryRowItem
            key={accessory._id}
            accessory={accessory}
            onRemoveItem={isEditMode ? removeAccesory : undefined}
          />
        ))}
        {isEditMode &&
          getSelectOptions(accessories, accessoriesList).length > 0 && (
            <Container>
              <StyledSelectInput
                name="selectNewAccessory"
                value={selectedNewAccesory}
                setFieldValue={onSelectNewAccessory}
                initialData={getSelectOptions(accessories, accessoriesList)}
                suffixIcon={<ArrowDown />}
              />
              <ButtonDark onClick={AddNewAccessory}>Add</ButtonDark>
            </Container>
          )}
      </Table>
    </>
  );
};

const Table = styled.div`
  border: 1px solid #d5dce5;
  border-top: 2px solid #2682de;
  padding-bottom: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 40px;
  background: #f9f9f9;
`;

const HeaderCell = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #6b7a87;
  flex: 1;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 17px 20px;
  border-bottom: 1px solid #d5dce5;
  margin: 0 20px;

  display: flex;
  gap: 30px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 400px;

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

export default ProductAccessories;
