import { useFormik } from 'formik';
import CloseIcon from '../../../../../../img/close.svg';
import {
  CloseButton,
  ContainerForPopover,
  StyledButtonDark,
  Title,
} from '../../../../offers/offer-groups/offer-groups-details/styled-offerGropDetails/popover.styled';
import { renderFormConfig } from "../../../../../../utils/form";
import { AddAddressConfig } from '../config/configForm'


interface InviteAddOfferModalContentProps {
    onClose: () => void;
    onSubmit: (values: any) => void;
  }
  
const AddUserBuyersModal: React.FC<InviteAddOfferModalContentProps> = ({onClose, onSubmit}) => {
    const formik = useFormik({
      initialValues: {
        addUser: 'tc@movavaleshoocl.sch.uk',
      },
      onSubmit: (values) => {
        onSubmit(values);
        onClose();
      },
      });
    
    return (
      <ContainerForPopover onSubmit={formik.handleSubmit}>
        <CloseButton alt="" src={CloseIcon} onClick={onClose}/>
        <Title>New Address</Title>
        {renderFormConfig(AddAddressConfig, formik)}
        <StyledButtonDark type="submit" style={{ padding: '14px 84px' }}>Save</StyledButtonDark>
      </ContainerForPopover>
    );
  };

export default AddUserBuyersModal