import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useGetOfferQuery, useGetOrderQuery } from '../../../api/services/buyerApi';
import {
  BuyerOrderStateType, INITIAL_ORDER, IsBuyerAuthenticated, setOrder,
} from '../../../feature/buyerState';
import ROUTER_CONFIG from '../../../router/router-config';
import calculateOrderPrice from '../calculateOrderPrice';
import BuyerLayoutWithBack from '../layout/BuyerLayoutWithBack';
import { parseOrder } from '../parseOrder';
import { IBuyerQuotePublic } from '../types';
import BuyerQuoteDetailsView from './BuyerQuoteDetailsView';

export default function BuyerQuoteDetailsPage() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { quoteId } = useParams<{ quoteId: string }>();
  const isSignedIn = useSelector(IsBuyerAuthenticated);

  const { data: quote } = useGetOrderQuery({ orderId: quoteId });
  const { data: offer } = useGetOfferQuery(quote?.sellerOfferId || '', { skip: !quote?.sellerOfferId });

  const parsedQuote: IBuyerQuotePublic | undefined = quote && parseOrder(quote);

  const calculations = calculateOrderPrice({
    quantity: parsedQuote?.quantity || 0,
    reseller: parsedQuote?.reseller,
    accessories: parsedQuote?.accessories,
    offer,
    orderType: quote?.orderType,
    lease: parsedQuote?.lease,
  });

  const onBack = () => push(ROUTER_CONFIG.BUYER_QUOTES.getPath());
  const onCreateAccountClick = () => push(ROUTER_CONFIG.BUYER_REGISTER.getPath());
  const onGenerateOrderClick = () => {
    if (!parsedQuote) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, __v, ...rest } = parsedQuote;

    const quoteToOrderState: BuyerOrderStateType = {
      ...INITIAL_ORDER,
      ...rest,
    };

    dispatch(setOrder(quoteToOrderState));
    push(ROUTER_CONFIG.BUYER_ORDER_SECURE_CHECKOUT_FORM.getPath({ orderId: parsedQuote.sellerOfferId }));
  };
  const onLoginClick = () => push(ROUTER_CONFIG.BUYER_LOGIN.getPath());

  return (
    <BuyerLayoutWithBack onBack={onBack}>
      {parsedQuote && offer && (
        <BuyerQuoteDetailsView
          isSignedIn={isSignedIn}
          onCreateAccountClick={onCreateAccountClick}
          onGenerateOrderClick={onGenerateOrderClick}
          onLoginClick={onLoginClick}
          quote={parsedQuote}
          offer={offer}
          reseller={parsedQuote.reseller}
          calculations={calculations}
        />
      )}
    </BuyerLayoutWithBack>
  );
}
