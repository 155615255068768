import { useFormik } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ROUTER_CONFIG from '../../../router/router-config';
import { ButtonDark } from '../../../ui/Button';
import Input from '../../../ui/forms/Input';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import LayoutV1 from '../../layout/LayoutV1';
import Title from '../shared/header/Title';
import { createLessorForm } from './lessors-schemas';
import { useCreateLessorMutation } from '../../../api/services/superadmin/lessorsApi';
import { ReactComponent as BackIcon } from '../../../img/icons/backIcon.svg';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
`;

const FieldTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

const formConfig: FormConfig = [
  {
    title: <FieldTitle>Name</FieldTitle>,
    name: 'name',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Address line 1</FieldTitle>,
    name: 'addressLine1',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Address line 2</FieldTitle>,
    name: 'addressLine2',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Address line 3</FieldTitle>,
    name: 'addressLine3',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Town</FieldTitle>,
    name: 'town',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Country</FieldTitle>,
    name: 'country',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Postcode</FieldTitle>,
    name: 'postcode',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: <FieldTitle>Telephone Number</FieldTitle>,
    name: 'telephoneNumber',
    component: StyledInput,
  },
  {
    title: <FieldTitle>Contact Email</FieldTitle>,
    name: 'contactEmail',
    component: StyledInput,
    isRequired: true,
  },
];

const LessorCreatePage: FC = () => {
  const { push } = useHistory();

  const [createLessor, { isSuccess, isLoading }] = useCreateLessorMutation();

  const onBack = useCallback(
    () => push(ROUTER_CONFIG.SUPER_ADMIN_LESSORS.getPath()),
    []
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      town: '',
      country: '',
      postcode: '',
      telephoneNumber: '',
      contactEmail: '',
    },
    validationSchema: createLessorForm,
    onSubmit: (values) => {
      createLessor(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      push(ROUTER_CONFIG.SUPER_ADMIN_LESSORS.getPath());
    }
  }, [isSuccess]);

  return (
    <LayoutV1>
      <Container onSubmit={formik.handleSubmit}>
        <Title style={{ margin: '0 0 10px 0' }}>
          <div style={{ display: 'flex' }}>
            <div
              onClick={onBack}
              style={{ cursor: 'pointer', marginRight: 15 }}
            >
              <BackIcon />
            </div>
            New Lessor Account
          </div>
        </Title>
        <Subtitle>Organisation Details</Subtitle>
        {renderFormConfig(formConfig, formik)}
        {!isLoading ? (
          <StyledButtonDark type="submit">Create Account</StyledButtonDark>
        ) : (
          <StyledButtonDark disabled type="submit">
            ...Saving
          </StyledButtonDark>
        )}
      </Container>
    </LayoutV1>
  );
};

const Container = styled.form``;

const Subtitle = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 20px;
`;

export default LessorCreatePage;
