import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { EligibleNodeTableRow, EligibleSuppliersTableRow, JoinedSuppliersTableRow } from '../../../types';
import { TableStyled } from '../../../../shared/table/TableStyled';

interface NodesTableDataProps {
  tableData: Array<EligibleSuppliersTableRow> | Array<JoinedSuppliersTableRow> | Array<EligibleNodeTableRow>;
  setSelectedRows: (selectedIds: Array<string>) => void;
  selectedRows: Array<string>;
  tableConfig: ColumnsType<EligibleSuppliersTableRow> | ColumnsType<JoinedSuppliersTableRow> | ColumnsType<EligibleNodeTableRow>;
  isShowingTable: boolean;
  setShowTable: (values: boolean) => void;
}

const EligibleTableData: React.FC<NodesTableDataProps> = ({
  tableData,
  setSelectedRows,
  selectedRows,
  tableConfig,
  isShowingTable,
  setShowTable,
}) => {
  return (
    <TableStyled
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => {
          setSelectedRows(ids as Array<string>);
          setShowTable(false);
        },
        selectedRowKeys: isShowingTable ? tableData.map((item) => item.key) : selectedRows,
      }}
      columns={tableConfig}
      dataSource={tableData}
      pagination={false}
    />
  );
};
export default EligibleTableData;
