import React from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import ROUTER_CONFIG from './router/router-config';
import ScrollToTop from './utils/ScrollToTop';

export default function AppRouter() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {Object.entries(ROUTER_CONFIG).map(([key, route]) => (
            <Route exact={route.exact} path={route.path} key={key}>
              {route.children}
            </Route>
          ))}
        </Switch>
      </ScrollToTop>
    </Router>
  );
}