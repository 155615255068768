import styled from 'styled-components';
import AddressTableControl from './AddressTableControl';
import { TableStyled } from '../../../../shared/table/TableStyled';
import { addressConfig } from '../config/configTable';
import { FC } from "react";

interface IAddressProps {
  onSelectedAddress: (userIds: Array<string>) => void;
  selectedAddress: Array<string>;
  onRowClickHandler?: (id: string) => void;
}

const mockData = [
  {
    key: '1',
    address: 'Mona Vale School, 32 Vale Ave, South Croydon, Croydon CR9 7HY',
  }
];

const AddressTable: FC<IAddressProps> = ({onSelectedAddress, selectedAddress, onRowClickHandler}) => {
  return (
    <Container>
      <AddressTableControl/>
      <CustomStyledTable
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectedAddress(ids as Array<string>);
          },
          selectedRowKeys: selectedAddress,
        }}
        columns={addressConfig}
        dataSource={mockData}
        onRow={(rowData) => ({
          onClick: () => onRowClickHandler ? onRowClickHandler(rowData?._id) : null,
          style: { cursor: 'pointer' },
        })}
        pagination={false}
      />
    </Container>
  );
};

const Container = styled.div`
`;

const CustomStyledTable = styled(TableStyled)`
  margin-bottom: 205px;
  
  .ant-table-thead > tr > th {
    color: #102F54 !important;
    font-size: 14px;
  }
  
  .ant-table-row {
    font-weight: 500 !important;
  }
  
  .ant-table-selection {
    display: none;
  }
  
  .ant-table-column-sorter {
    display: none;
  }
`;

export default AddressTable;