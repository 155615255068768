import styled from 'styled-components/macro';
import React, { FC } from 'react';
import { ButtonDark } from '../../../../../ui/Button';
import Title from '../../../shared/header/Title';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../../router/router-config';

const OfferGroupsHeader: React.FC = () => {
  const { push } = useHistory();
  const onNewOfferGroupClickHandler = () => {
    push(ROUTER_CONFIG.SUPER_ADMIN_OFFER_GROUP_CREATE.getPath());
  };

  return (
  <Container>
    <Title style={{ margin: '0 0 40px 0' }}>Offer Groups</Title>
    <OfferGroupsButton
     onClick={onNewOfferGroupClickHandler}
    >
      + Create New Offer Group
    </OfferGroupsButton>
  </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OfferGroupsButton = styled(ButtonDark)`
  padding: 11px 35px;
  font-size: 16px;
  height: fit-content;
  align-self: center;
`;

export default OfferGroupsHeader;
