import styled from 'styled-components/macro';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';

export interface OptionsType {
  key: string,
  value: string,
}

interface AutocompleteInputType {
  options: OptionsType[];
  onSearch: (value: string) => void;
  onSelect: any;
  placeholder: string;
}

export const AutocompleteInput = ({
  options,
  onSearch,
  placeholder,
  onSelect,
}: AutocompleteInputType) => {
  return (
    <Container>
      <StyledAutoComplete
        placeholder={placeholder}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
      />
      <StyledSearch />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledSearch = styled(SearchOutlined)`
  position: absolute;
  right: 14px;
  top: 10px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 424px;
  height: 36px;

  .ant-select-selector {
    height: 100% !important;
  }

  .ant-select-selection-placeholder {
    line-height: 35px !important;
  }
`;

export default AutocompleteInput;
