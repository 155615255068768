import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router";
import styled from 'styled-components/macro';
import {
  HomeIcon, OffersIcon, OrdersIcon, PaymentsIcon, QuotesIcon,
} from './SideMenuSvgIcons';
import ROUTER_CONFIG from '../../../../router/router-config';

interface SideMenuProps {
  className?: string;
}

export default function SideMenu({className}: SideMenuProps) {
  const {offerId, orderId, paymentType}: any = useParams();
  const [ isPaymentsOpen, setIsPaymentsOpen ] = useState(false);
  const {push, location} = useHistory();
  
  return (
    <Container className={className}>
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPPLIER_OVERVIEW.path
          || location.pathname === ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({
            orderId: `${orderId}`,
            paymentType: `${paymentType}`
          })
        }
        onClick={() => push(ROUTER_CONFIG.SUPPLIER_OVERVIEW.getPath())}>
        <HomeIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPPLIER_OVERVIEW.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({
              orderId: `${orderId}`,
              paymentType: `${paymentType}`
            })
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPPLIER_OVERVIEW.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({
              orderId: `${orderId}`,
              paymentType: `${paymentType}`
            })
          }>
          Home
        </MenuItemText>
      </MenuItem>
      
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPPLIER_OFFERS.path
          || location.pathname === ROUTER_CONFIG.SUPPLIER_VIEW_OFFER.getPath({offerId: `${offerId}`})
          || location.pathname === ROUTER_CONFIG.SUPPLIER_JOIN_OFFER.getPath({offerId: offerId})
          || location.pathname === ROUTER_CONFIG.SUPPLIER_EDIT_OFFER.getPath({offerId: offerId})
        }
        onClick={() => push(ROUTER_CONFIG.SUPPLIER_OFFERS.getPath())}>
        <OffersIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPPLIER_OFFERS.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_VIEW_OFFER.getPath({offerId: `${offerId}`})
            || location.pathname === ROUTER_CONFIG.SUPPLIER_JOIN_OFFER.getPath({offerId: offerId})
            || location.pathname === ROUTER_CONFIG.SUPPLIER_EDIT_OFFER.getPath({offerId: offerId})
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPPLIER_OFFERS.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_VIEW_OFFER.getPath({offerId: `${offerId}`})
            || location.pathname === ROUTER_CONFIG.SUPPLIER_JOIN_OFFER.getPath({offerId: offerId})
            || location.pathname === ROUTER_CONFIG.SUPPLIER_EDIT_OFFER.getPath({offerId: offerId})
          }>
          Offers
        </MenuItemText>
      </MenuItem>
      
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPPLIER_ORDERS.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPPLIER_ORDERS.getPath())}>
        <OrdersIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPPLIER_ORDERS.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPPLIER_ORDERS.path
          }>
          Orders
        </MenuItemText>
      </MenuItem>
      
      <MenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPPLIER_QUOTES.path
          || location.pathname === ROUTER_CONFIG.SUPPLIER_BUILD_QUOTE.path
        }
        onClick={() => push(ROUTER_CONFIG.SUPPLIER_QUOTES.getPath())}>
        <QuotesIcon
          isSelected={
            location.pathname === ROUTER_CONFIG.SUPPLIER_QUOTES.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_BUILD_QUOTE.path
          }
        />
        <MenuItemText
          isSelect={
            location.pathname === ROUTER_CONFIG.SUPPLIER_QUOTES.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_BUILD_QUOTE.path
          }>
          Quotes
        </MenuItemText>
      </MenuItem>
      
      <PaymentsMenuItem
        isSelect={
          location.pathname === ROUTER_CONFIG.SUPPLIER_PAYMENTS.path
          || location.pathname === ROUTER_CONFIG.SUPPLIER_BALANCE_TRANSFERS.path
        }
        onMouseOver={() => setIsPaymentsOpen(true)}
        onMouseLeave={() => setIsPaymentsOpen(false)}>
        <PaymentMenuLink onClick={() => push(ROUTER_CONFIG.SUPPLIER_PAYMENTS.getPath())}>
          <PaymentsIcon isSelected={
            location.pathname === ROUTER_CONFIG.SUPPLIER_PAYMENTS.path
            || location.pathname === ROUTER_CONFIG.SUPPLIER_BALANCE_TRANSFERS.path
          }
          />
          <PaymentsMenuItemText
            isSelect={
              location.pathname === ROUTER_CONFIG.SUPPLIER_PAYMENTS.path
              || location.pathname === ROUTER_CONFIG.SUPPLIER_BALANCE_TRANSFERS.path
            }
            isOpen={isPaymentsOpen}>
            Payments
          </PaymentsMenuItemText>
        </PaymentMenuLink>
        {isPaymentsOpen && (
          <BalanceTransferText onClick={() => push(ROUTER_CONFIG.SUPPLIER_BALANCE_TRANSFERS.getPath())}>
            Balance Transfers
          </BalanceTransferText>
        )}
      </PaymentsMenuItem>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  background-color: #F5F5F5;
`;

const MenuItemText = styled.span<{ isSelect?: boolean }>`
  font-size: 14px;
  line-height: 17px;
  color: ${({isSelect}) => (isSelect ? '#102F54' : '#6B7A87')};
`;

const BalanceTransferText = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
  padding: 12px 20px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const PaymentsMenuItemText = styled(MenuItemText)<{ isOpen: boolean }>`
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 6px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    transform: ${({isOpen}) => (isOpen ? 'none' : 'rotateZ(-90deg)')};
  }
`;

const MenuItem = styled.div<{ isSelect?: boolean }>`
  display: flex;
  gap: 16px;
  padding: 24px 12px;
  align-items: center;
  cursor: pointer;
  background-color: ${({isSelect}) => (isSelect && '#FFFFFF')};
  
  &:hover {
    background: ${({isSelect}) => !isSelect && '#ecedee'};
    
    ${MenuItemText} {
      text-decoration: underline;
    }
  }
`;

const PaymentsMenuItem = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;
`;

const PaymentMenuLink = styled.div`
  display: flex;
  gap: 16px;
`;