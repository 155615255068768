import * as yup from 'yup';
import { EditFormType } from './types';

export const editOfferForm: yup.SchemaOf<Partial<EditFormType>> = yup.object({
  offerNumber: yup.string().required(),
  startDate: yup.string().optional(),
  endDate: yup.string().optional(),
  platformFee: yup.number().required(),
  currency: yup.string().optional(),
  product: yup.string().required(),
  oem: yup.string().required(),
  quantity: yup.number().required(),
  stockAvailableDate: yup.string().optional(),
  indicativeBuyerPrice: yup.number().required(),
  nte: yup.number().required(),
  insurances: yup.array().optional(),
  leases: yup.array().optional(),
  offerGroup: yup.string().optional(),
  eligibleSellers: yup.array().optional(),
  allowForAllSellers: yup.number().optional(),
});
