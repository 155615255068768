import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React from 'react';
import { FormConfig, renderFormConfig } from '../../../../../../utils/form';
import CloseIcon from '../../../../../../img/close.svg';
import { CloseButton } from '../../../styled/buttons';
import {
  ContainerForPopover,
  DarkButtonForPopover,
  DetailsButtonForPopover,
  LinkAfterButton,
  StyledSelect,
  TextForPopover,
  TitleForPopover,
} from '../../../styled/popover';
import { addNodeForm } from '../../offers-schemas';
import { AddNodesForm } from '../../../types';
import { ReactComponent as ArrowDown } from '../../../../../../img/icons/arrowDown.svg';

const selectData = [
  'Intel',
];

const formConfig: FormConfig = [
  {
    title: 'Node Number',
    name: 'numberNode',
    component: StyledSelect,
    props: {
      initialData: selectData,
      suffixIcon: <ArrowDown />,
      dropdownStyle: {
        zIndex: 200000,
        position: 'fixed',
      },
    },
    isRequired: true,
  },
];

interface AddNodeModalContentProps {
  onSubmit: (values: AddNodesForm) => void;
  onClose: () => void;
}

const AddNodeModalContent: React.FC<AddNodeModalContentProps> = ({ onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      numberNode: selectData[0],
    },
    validationSchema: addNodeForm,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <ContainerForPopover onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <TitleForPopover>Add Node</TitleForPopover>
      {renderFormConfig(formConfig, formik)}
      <DarkButtonForPopover type="submit">Add Node</DarkButtonForPopover>
      <StyledLinkAfterButton>
        <TextForPopover>Or</TextForPopover>
        <DetailsButtonForPopover>Create new Node</DetailsButtonForPopover>
      </StyledLinkAfterButton>
    </ContainerForPopover>
  );
};

const StyledLinkAfterButton = styled(LinkAfterButton)`
  max-width: 229px;
`;

export default AddNodeModalContent;
