import { supplierApi } from './supplierApi';
import {
  ISupplierOrder,
  OrdersOverviewTableRow,
  SupplierOrderStatus,
} from '../../../components/supplier/types';

const ordersApi = supplierApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierOrders: build.query<Array<OrdersOverviewTableRow>, void>({
      query: () => 'order',
    }),
    getSupplierOrdersStatus: build.query<
      Array<OrdersOverviewTableRow>,
      SupplierOrderStatus
    >({
      query: (status) => `order/${status}`,
    }),
    getSupplierOrder: build.query<ISupplierOrder, string>({
      query: (id) => `order/${id}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSupplierOrdersQuery,
  useGetSupplierOrderQuery,
  useGetSupplierOrdersStatusQuery,
} = ordersApi;
