import styled from 'styled-components/macro';
import { Select } from 'antd';
import { CSSProperties, ReactElement, ReactNode } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { Option } = Select;

interface SelectInputProps {
  setFieldValue: (field: string, value: string) => void;
  initialData: Array<string> | Array<any>;
  name: string;
  value: string;
  className?: string;
  placeholder?: string;
  dropdownStyle?: CSSProperties;
  disabled?: boolean;
  size?: SizeType;
  suffixIcon?: ReactNode | ReactElement;
  isAllowClear?: boolean;
  optionName?: string;
}

export default function SelectInput({
  setFieldValue,
  name,
  value,
  className,
  initialData,
  placeholder,
  dropdownStyle,
  disabled,
  size,
  suffixIcon,
  isAllowClear = false,
  optionName,
}: SelectInputProps) {
  return (
    <StyledSelect
      allowClear={isAllowClear}
      dropdownStyle={dropdownStyle}
      placeholder={placeholder}
      className={className}
      value={value}
      disabled={disabled}
      size={size}
      suffixIcon={suffixIcon}
      onChange={(v: any) => setFieldValue(name, v as string)}
    >
      {optionName && initialData[0]?.[optionName] ? initialData.map((d) => (
        <Option key={d._id} value={d._id}>
          {d[optionName] ? d[optionName] : ''}
        </Option>
      )) : initialData.map((d) => (
        <Option key={d} value={d}>
          {d}
        </Option>
      ))}
    </StyledSelect>
  );
}

export const StyledSelect = styled(Select)`
  border-radius: 4px;

  .ant-select-selector {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    padding: 0;
    font-size: 14px;
    height: 56px;
  }
`;
