import * as yup from 'yup';
import { AddNodesForm, AddSupplierForm, AddSupplierWithPriceForm } from '../types';

export const addSupplierForm: yup.SchemaOf<AddSupplierForm> = yup.object({
  supplierName: yup.string().required(),
});

export const addNodeForm: yup.SchemaOf<AddNodesForm> = yup.object({
  numberNode: yup.string().required(),
});

export const addSupplierWithPriceForm: yup.SchemaOf<AddSupplierWithPriceForm> = yup.object({
  supplierName: yup.string().required(),
  customerBuyPrice: yup.string().required(),
});
