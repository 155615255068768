import React, { FC, useMemo, useState } from "react";
import ModalInPortal from "../../../../../ui/Modal/ModalPortal";
import AddUserModalContent from "./AddUserModalContent";
import { mockData } from "./moks/mockData";
import { UserTableContainer, ViewButton } from "../suppliers.styled";
import { TableStyled } from "../../../../../ui/Table/table.styled";
import { ColumnsType } from "antd/lib/table";
import { SupplierUsersColumnsProps } from "../types";
import { ReactComponent as DeleteIcon } from "../../../../../img/icons/deleteIcon.svg";

const AddUserToTable: FC = () => {
  const [ supplier, setSupplier ] = useState<any>(mockData);
  
  const InviteAddOfferModal = ModalInPortal();
  const onAddOfferClickHandler = () => InviteAddOfferModal.onShow();
  
  const onSubmitAddOfferForm = (values: any) => console.log(values);
  const onDeleteSupplier = (key: string | undefined) => {
    setSupplier(supplier?.filter((item: any) => item?.key !== key));
  };

  const tableConfig: ColumnsType<SupplierUsersColumnsProps> = useMemo(() => [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Second Name',
      dataIndex: 'secondName',
      key: 'secondName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      render: (value, record, index) => {
        return (
          <DeleteIcon
            key={record.key}
            onClick={() => onDeleteSupplier && onDeleteSupplier(record.key)}
            style={{cursor: 'pointer'}}
          />
        );
      },
    },
  ], [onDeleteSupplier]);
  
  return (
    <UserTableContainer>
      <ViewButton onClick={onAddOfferClickHandler}>
        + Add User
      </ViewButton>
      {InviteAddOfferModal.ModalRender && (
        <InviteAddOfferModal.ModalRender>
          <AddUserModalContent
            onClose={InviteAddOfferModal.onClose}
            onSubmit={onSubmitAddOfferForm}
          />
        </InviteAddOfferModal.ModalRender>
      )}
      <TableStyled
        columns={tableConfig}
        dataSource={supplier}
        pagination={false}
      />
    </UserTableContainer>
  );
};

export default AddUserToTable;