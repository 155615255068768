import { IBuyersOrders, IBuyersQuotes, IBuyersDashboard, IBuyersOrganisation } from '../../../components/superadmin/types';
import { superadminApi } from './superadminApi';

const buyersCurdApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getBuyersOrder: build.query<Array<IBuyersOrders>, void>({
      query: () => 'buyer/orders',
    }),
    getBuyersQuotes: build.query<Array<IBuyersQuotes>, void>({
      query: () => 'buyer/quote',
    }),
    getBuyersDashboard: build.query<Array<IBuyersDashboard>, void>({
      query: () => 'buyer/dashboard',
    }),
    updateOrganisationDetails: build.mutation<IBuyersOrganisation, any>({
      query: (body) => ({
        url: 'buyer',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { 
  useGetBuyersOrderQuery, 
  useGetBuyersQuotesQuery, 
  useGetBuyersDashboardQuery,
  useUpdateOrganisationDetailsMutation
} = buyersCurdApi;
