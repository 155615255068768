import { FC } from 'react';
import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';

export const CustomBigButton: FC<any> = styled(ButtonDark)`
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  max-width: 255px;
  width: 100%;
  margin-left: auto;
  margin-top: -35px;
`;

export const InviteButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  align-self: end;

  &:hover {
    text-decoration: underline;
  }
`;

export const Search = styled.span`
  color: #333;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 215px;
  height: 36px;
  padding: 10px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 96% center;
`;
