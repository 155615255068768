import { IInsurance } from '../../../components/superadmin/insurers/types';
import { superadminApi } from './superadminApi';

const insuranceApi = superadminApi.injectEndpoints({
  endpoints: (build) => ({
    getInsurenceList: build.query<IInsurance[], void>({
      providesTags: ['INSURANCE'],
      query: () => ({
        url: 'insurance',
      }),
    }),
    createInsurancePolicy: build.mutation<void, Omit<IInsurance, '_id'>>({
      invalidatesTags: ['INSURANCE'],
      query: (body: IInsurance) => ({
        url: 'insurance',
        method: 'POST',
        body,
      }),
    }),
    updateInsurancePolicy: build.mutation<
      void,
      { body: Omit<IInsurance, '_id'>; insurancePolicyId: string }
    >({
      invalidatesTags: ['INSURANCE'],
      query: ({ body, insurancePolicyId }) => ({
        url: `insurance/${insurancePolicyId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteInsurancePolicy: build.mutation<void, { insurancePolicyId: string }>({
      invalidatesTags: ['INSURANCE'],
      query: ({ insurancePolicyId }) => ({
        url: `insurance/${insurancePolicyId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetInsurenceListQuery,
  useCreateInsurancePolicyMutation,
  useUpdateInsurancePolicyMutation,
  useDeleteInsurancePolicyMutation,
} = insuranceApi;
