import styled from 'styled-components/macro';

export const TitleH2 = styled.h2`
  margin-bottom: 15px;
  font-size: 21px;
  line-height: 31px;
  color: #102F54;
  font-weight: bold;
`;

export const StyledTitleH2 = styled(TitleH2)`
  margin: 0;
`;

export const TitleH3 = styled.h3`
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 15px;
  color: #102F54;
  font-weight: 600;
`;

export const DarkText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #102F54;
`;

export const BoldDarkText = styled(DarkText)`
  font-weight: 600;
`;

export const LightText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #6B7A87;
`;

export const BlueText = styled.p`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
